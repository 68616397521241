<template>
  <div class="ex-deposit-manual-fiat">
    <portal to="toolbar__title">
      Fiat Currency Deposit
    </portal>
    <el-tabs v-model="activeTab">
      <el-tab-pane v-if="isAvailable" name="card" label="Bank card" lazy>
        <ex-deposit-card :user-profile="profile" @change-currency="updateUrl($route.params.type, $event)" />
      </el-tab-pane>
      <el-tab-pane name="request" label="Bank transfer" lazy>
        <ex-fiat-d-r-a :user-profile="profile" @change-currency="updateUrl($route.params.type, $event)" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// lib
import {mapState} from 'vuex';

// component
import ExDepositCard from '@/pages/deposit/fiat/ex-deposit-card';
import ExFiatDRA from '@/pages/deposit/fiat/ex-fiat-dra';

export default {
  name: 'ExDepositManualFiat',
  components: {
    ExDepositCard,
    ExFiatDRA,
  },
  data() {
    return {
      activeTab: this.$route.params.type,
    };
  },
  computed: {
    ...mapState('currentUser', ['profile']),
    isAvailable() {
      return this.profile.acquiring !== 0;
    },
  },
  methods: {
    updateUrl(type, currency) {
      this.$router.replace({
        name: 'FiatDeposit',
        params: {type, currency},
      });
    },
  },
  watch: {
    activeTab(value) {
      let type = 'card';
      if (value === 'request') {
        type = 'request';
      }
      this.updateUrl(type, this.$route.params.currency);
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-deposit-manual-fiat {
  ::v-deep .el-tabs__item {
    font-size: 16px;
  }
  ::v-deep .ex-deposit-card,
  ::v-deep .ex-fiat-dra {
    .el-input,
    .el-select {
      width: 100%;
    }
  }
}
</style>
