<template>
  <div class="ex-funding-history">
    <portal to="toolbar__title">
      Funding History for {{currency}}
    </portal>
    <ex-list-content>
      <el-table
        v-loading="isFetching"
        :data="fundingList"
        stripe
        highlight-current-row
        row-key="timestamp"
        class="ex-funding-history__table"
      >
          <el-table-column label="Timestamp">
            <template #default="{row}">{{utilTimeFormatOrMessage(row.requestCreateAt)}}</template>
          </el-table-column>
          <el-table-column label="Funding method">
            <template #default="{row}">{{getMethodLabel(row.paymentMethod)}}</template>
          </el-table-column>
          <el-table-column label="Type">
            <template #default="{row}">{{getTypeLabel(row.requestName)}}</template>
          </el-table-column>
          <el-table-column label="Amount" prop="amount" />
          <el-table-column label="Status">
            <template #default="{row}">{{getStatusLabel(row.requestName, row.requestStatus)}}</template>
          </el-table-column>
          <el-table-column label="Actions">
            <template #default="{row}">
              <ex-report
                v-if="requestIsComplete(row)"
                :type="row.requestName"
                :value="{id: row.requestId}"
                :uniqueId="row.id"
              />
            </template>
          </el-table-column>
        </el-table>
      <template #pagination v-if="pagination.totalPages > 1">
        <el-pagination
          background
          style="padding: 0;"
          layout="prev, pager, next, jumper"
          :current-page="pagination.currentPage"
          :page-size="pagination.limitItems"
          :total="pagination.totalItems"
          @current-change="fetchFundingHistory"
        />
      </template>
    </ex-list-content>
  </div>
</template>

<script>
// component
import ExListContent from '@/components/ex-list-content';
import ExReport from '@/components/ex-report';

// api
import TransactionApi from '@/api/transactionService/transaction.api';

// const
import {FETCH_LIST_FUNDING_HISTORY} from '@/constants/events/transaction/actions.type';
import {
  DEPOSIT_STATUS,
  INIT_PAGINATION,
  NAMES_TRANSACTION,
  WITHDRAW_STATUS,
} from '@/constants/common';

// util
import {socketRequest} from '@/utils/socket';
import {utilTimeFormatOrMessage} from '@/utils/converters/time';

// labels
const METHODS_LABEL = {
  1: 'SEPA Transfer',
  2: 'Wallet Transfer',
  4: 'SWIFT Transfer',
};
const STATUS_LABEL = {
  [NAMES_TRANSACTION.DEPOSIT]: {
    1: 'Executed',
    2: 'Executed',
    3: 'Reversed',
  },
  [NAMES_TRANSACTION.WITHDRAWAL]: {
    1: 'Executed',
    2: 'Pending',
    3: 'Executed',
    4: 'Canceled by timeout',
    6: 'Reversed',
  },
  [NAMES_TRANSACTION.WITHDRAWAL_AUTO]: {
    1: 'Executed',
    2: 'Pending Auto',
    3: 'Pending Investigation',
    4: 'Canceled',
    5: 'Executed',
    6: 'Reversed',
  },
};
const TYPE_LABEL = {
  [NAMES_TRANSACTION.DEPOSIT]: 'Deposit',
  [NAMES_TRANSACTION.WITHDRAWAL]: 'Withdrawal',
  [NAMES_TRANSACTION.WITHDRAWAL_AUTO]: 'Withdrawal Auto',
};

export default {
  name: 'ExFundingHistory',
  components: {ExReport, ExListContent},
  data() {
    return {
      fundingList: [],
      pagination: {...INIT_PAGINATION},
      isFetching: false,
    };
  },
  computed: {
    currency() {
      return this.$route.params.currency;
    },
  },
  created() {
    this.fetchFundingHistory();
  },
  methods: {
    utilTimeFormatOrMessage,
    fetchFundingHistory(page = this.pagination.currentPage) {
      socketRequest.call(this, FETCH_LIST_FUNDING_HISTORY,
        (payload) => {
          this.fundingList = payload.list;
          this.pagination = payload.pagination;
        },
        () => {},
        () => {
          this.isFetching = false;
        },
      );
      this.isFetching = true;
      TransactionApi.fetchFundingHistory(FETCH_LIST_FUNDING_HISTORY, {
        page,
        limit: INIT_PAGINATION.limitItems,
        filters: {currency: this.currency},
      });
    },
    getMethodLabel(paymentMethod) {
      return METHODS_LABEL[paymentMethod] || '';
    },
    getStatusLabel(requestName, statusId) {
      return STATUS_LABEL[requestName][statusId] || '';
    },
    getTypeLabel(requestName) {
      return TYPE_LABEL[requestName] || '';
    },
    requestIsComplete(request) {
      return !(
        (request.entityName === NAMES_TRANSACTION.WITHDRAWAL
          && (request.transactionStatus !== WITHDRAW_STATUS.EXECUTED)
        )
        || (request.entityName === NAMES_TRANSACTION.DEPOSIT && request.transactionStatus === DEPOSIT_STATUS.REVERSED));
    },
  },
};
</script>
