<template>
  <div class="ex-deposit-card">
    <el-form :model="form" v-loading="isFetchingIP || isFetchingUser" :rules="rules" ref="form" label-position="top">
      <el-form-item prop="currency" label="Currency">
        <ex-coin-list :list="list" :value="form.currency" @input="form.currency = $event.currency" />
      </el-form-item>
      <el-form-item :label="`Amount of ${form.currency}`" prop="amount">
        <el-input v-mask:fiat v-model="form.amount" />
      </el-form-item>
      <template v-if="isFenige">
        <el-form-item label="Country" prop="country">
          <el-select v-model="form.country" :loading="isFetchingCountry" loading-text="Countries are loading...">
            <el-option
              v-for="country in countryList"
              :key="country.country_code"
              :label="`${country.name} (${country.country_code})`"
              :value="country.country_code"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="hasState" label="State" prop="state">
          <el-select v-model="form.state" :loading="isFetchingState" loading-text="States are loading...">
            <el-option
              v-for="state in currentCountryStates"
              :key="state.state_code"
              :label="`${state.name} (${state.state_code})`"
              :value="state.state_code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="City" prop="city">
          <el-input v-model="form.city" />
        </el-form-item>
        <el-form-item label="Address" prop="address">
          <el-input v-model="form.address" />
        </el-form-item>
        <el-form-item label="Postcode" prop="zipCode">
          <el-input v-model="form.zipCode" />
        </el-form-item>
      </template>
    </el-form>
    <el-button type="danger" @click="$router.push({name: 'BalancesIndex'})">
      Cancel
    </el-button>
    <el-button :loading="isCreating" @click="handleCreate">
      Create
    </el-button>
  </div>
</template>

<script>
// utils
import {filterCoinByList, filterCoinsAndFormType} from '@/utils/filters';
import {numberFromLocaleString} from '@/utils/converters/currency';
import {ruleRequired, ruleAmount} from '@/utils/elementUITypicalValidation';
import {openExternalURL} from '@/utils/externalUrl';
import {socketRequest} from '@/utils/socket';

// api
import CardPayApi from '@/api/cardPay.api';
import CommonApi from '@/api/common.api';
import KYCApi from '@/api/userService/KYC.api';

// component
import ExCoinList from '@/components/ex-coin-list';

// const
import {FETCH_COUNTRY, FETCH_STATE, FETCH_USER} from '@/constants/events/KYC/actions.type';
import {CREATE_LINK} from '@/constants/events/cardPay/actions.type';
import {ACQUIRING_TYPE} from '@/constants/common';

// app
import {mapActions} from 'vuex';

const COUNTRIES_WITH_MANDATORY_STATES = ['US', 'CA', 'AU'];
const AVAILABLE_COINS_FENIGE = ['EUR'];

export default {
  name: 'ExDepositCard',
  components: {ExCoinList},
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.isFenige) {
      this.fetchUser();
      this.fetchCountry();
      this.fetchUserIp();
    }
  },
  data() {
    return {
      rules: {
        currency: [ruleRequired],
        amount: [ruleRequired, ruleAmount],
        country: [ruleRequired],
        state: [ruleRequired],
        city: [ruleRequired],
        address: [ruleRequired],
        zipCode: [ruleRequired],
      },
      form: {
        currency: AVAILABLE_COINS_FENIGE.includes(this.$route.params.currency) ? this.$route.params.currency : '',
        amount: null,
        country: '',
        state: '',
        city: '',
        address: '',
        zipCode: '',
      },
      userData: {
        firstname: '',
        lastname: '',
        country: '',
        state: '',
        city: '',
        address: '',
        zipCode: '',
        description: 'Test',
      },
      isFetchingIP: false,
      isFetchingUser: false,
      isFetchingCountry: false,
      isFetchingState: false,
      isCreating: false,
      userIPAddress: '',
      countryList: [],
      stateList: [],
    };
  },
  computed: {
    list() {
      if (this.isFenige) {
        return filterCoinByList(this.userProfile.balance, AVAILABLE_COINS_FENIGE);
      }
      return filterCoinsAndFormType(this.userProfile.balance, 'fiat');
    },
    hasState() {
      return COUNTRIES_WITH_MANDATORY_STATES.includes(this.form.country);
    },
    type() {
      return this.userProfile.acquiring;
    },
    isXpate() {
      return this.type === ACQUIRING_TYPE.XPATE;
    },
    isFenige() {
      return this.type === ACQUIRING_TYPE.FENIGE;
    },
    currentCountryStates() {
      return this.stateList.filter((item) => item.country_code === this.form.country);
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    handleCreate() {
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;
        this.executeRequest(this.prepareData(this.form), this.type);
      });
    },
    prepareData(form) {
      let data = {
        email: this.userProfile.email,
        amount: numberFromLocaleString(form.amount),
        currency: form.currency,
      };
      if (this.isFenige) {
        data = Object.assign(this.userData, form, data);
        data.ipAddress = this.userIPAddress;
        data.phone = this.userProfile.phone;
      }
      return data;
    },
    executeRequest(data) {
      if (this.isXpate) {
        CardPayApi.createXpate(CREATE_LINK, data);
      }
      if (this.isFenige) {
        CardPayApi.createFenige(CREATE_LINK, data);
      }
      socketRequest.call(this, CREATE_LINK,
        (payload) => {
          openExternalURL(payload.url);
          this.logout();
        },
        () => {},
        () => this.isCreating = false,
      );
    },
    async fetchUserIp() {
      try {
        this.isFetchingIP = true;
        const addressObject = await CommonApi.fetchUserIPAddress();
        this.isFetchingIP = false;
        const addressString = addressObject.data;
        this.userIPAddress = addressString.includes(',') ? addressString.split(',')[0] : addressString;
      } catch (e) {
        this.isFetchingIP = false;
        this.errorMessage('Error');
      }
    },
    fetchUser() {
      KYCApi.fetchUser(FETCH_USER);
      this.isFetchingUser = true;
      socketRequest.call(this, FETCH_USER,
        (payload) => {
          /* eslint-disable-next-line */
          const {firstname, lastname, email, ...data} = payload;
          this.form = Object.assign(this.form, data);
          this.userData = Object.assign(this.userData, payload);
        },
        () => {},
        () => {
          this.isFetchingUser = false;
        },
      );
    },
    fetchCountry() {
      KYCApi.fetchCountry(FETCH_COUNTRY);
      this.isFetchingCountry = true;
      socketRequest.call(this, FETCH_COUNTRY,
        (payload) => {
          this.countryList = payload;
        },
        () => {},
        () => {
          this.isFetchingCountry = false;
        },
      );
    },
    fetchState() {
      KYCApi.fetchState(FETCH_STATE);
      this.isFetchingState = true;
      socketRequest.call(this, FETCH_STATE,
        (payload) => {
          this.stateList = payload;
        },
        () => {},
        () => {
          this.isFetchingState = false;
        },
      );
    },
  },
  watch: {
    'form.currency': {
      handler(value) {
        this.$emit('change-currency', value);
      },
    },
    'form.country': {
      handler(value) {
        if (COUNTRIES_WITH_MANDATORY_STATES.includes(value) && this.stateList.length === 0) {
          this.fetchState();
        }
      },
      immediate: true,
    },
  },
};
</script>
