import {EXCHANGE_STATUS} from '@/constants/common';

const classes = {
  1: 'primary',
  2: 'info',
  3: 'success',
  4: 'danger',
  5: 'secondary',
  6: 'warning',
  7: 'success',
  8: 'danger',
  [EXCHANGE_STATUS.ACCEPT_AUTOMATIC]: 'success',
  [EXCHANGE_STATUS.REJECT_AUTOMATIC]: 'danger',
};

/**
 * @param {number} status
 * @return {string}
 */
export function getClassByStatus(status) {
  return `text-${classes[status]}`;
}
