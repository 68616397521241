<template>
  <div class="ex-auto-trading">
    <div class="ex-auto-trading__progress">
      <ex-countdown-linear
        :time-data="time"
        :has-stop="hasStop"
        class="ex-auto-trading__linear"
        @timeout="$emit('timeout')"
      >
        <template #info-prepend>Request - #{{requestId}}</template>
      </ex-countdown-linear>
    </div>
    <div class="ex-auto-trading__card">
      <div class="ex-auto-trading__content">
        <div class="ex-auto-trading__left">
          <ex-auto-trading-label
            :currency="currencyLeft"
            :type="labelLeft"
            :date="dateLabel"
            :amount="amountLeft"
          />
        </div>
        <div class="ex-auto-trading__center">
          <ex-countdown-circle :time-data="smallCycle" :has-stop="hasStop">{{displayPrice}}</ex-countdown-circle>
        </div>
        <div class="ex-auto-trading__right">
          <ex-auto-trading-label
            :currency="currencyRight"
            :type="labelRight"
            :date="dateLabel"
            :amount="amountRight"
            with-align-right
          />
        </div>
      </div>
      <div class="ex-auto-trading__actions">
        <el-button type="danger" class="ex-auto-trading__action" :disabled="disabledCancel" @click="$emit('cancel')">
          CANCEL
        </el-button>
        <ex-report v-show="showReport" :value="{id: requestId}" :type="NAMES_TRANSACTION.EXCHANGE">
          Get PDF Report
        </ex-report>
        <el-button type="success" class="ex-auto-trading__action" :disabled="disabledAccept" @click="$emit('accept')">
          ACCEPT
        </el-button>
      </div>
    </div>
    <div class="ex-auto-trading__info">
      <ex-alert :type="statusInfo">
        <div v-html="info" />
      </ex-alert>
    </div>
  </div>
</template>

<script>
// util
import {checkExpectedKeys} from '@/utils/validatorPropObjects';

// components
import ExAutoTradingLabel from '@/pages/requests/auto-trading/ex-auto-trading-label';
import ExCountdownLinear from '@/components/ex-countdown-linear';
import ExCountdownCircle from '@/components/ex-countdown-circle';
import ExReport from '@/components/ex-report';
import ExAlert from '@/components/ex-alert';

// constant
import {NAMES_TRANSACTION} from '@/constants/common';

export default {
  name: 'ExAutoTrading',
  components: {
    ExAutoTradingLabel,
    ExCountdownCircle,
    ExCountdownLinear,
    ExReport,
    ExAlert,
  },
  props: {
    currencyLeft: String,
    labelLeft: String,
    dateLabel: String,
    amountLeft: String,
    currencyRight: String,
    labelRight: String,
    amountRight: String,
    displayPrice: String,
    requestId: Number,
    time: {
      type: Object,
      validator(value) {
        return checkExpectedKeys(['from', 'to'], value);
      },
    },
    smallCycle: {
      type: Object,
      validator(value) {
        return checkExpectedKeys(['from', 'to'], value);
      },
    },
    hasStop: Boolean,
    disabledCancel: Boolean,
    disabledAccept: Boolean,
    isLoading: Boolean,
    info: String,
    statusInfo: String,
    showReport: Boolean,
  },
  data() {
    return {
      NAMES_TRANSACTION,
    };
  },
};
</script>

<style lang="scss" scoped>
.ex-auto-trading {
  &__progress {}
  &__linear {
    position: relative;
    z-index: 1;
  }
  &__card {
    background-color: #F9F9FA;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0;
  }
  &__left {
    flex: 1;
  }
  &__center {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  &__right {
    flex: 1;
  }
  &__actions {
    padding: 18px;
    display: flex;
    justify-content: space-between;
  }
  &__action {
    height: 40px;
    width: 100%;
    max-width: 225px;
  }
  &__info {
    margin: 20px 0;
  }
}
</style>
