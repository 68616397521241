<template>
  <div class="ex-otc-trading-view">
    <div class="ex-otc-trading-view-row">
      <div class="ex-otc-trading-view-row-label">Created At</div>
      <div class="ex-otc-trading-view-row-value">{{utilTimeFormatOrMessage(request.createAt)}}</div>
    </div>
    <div class="ex-otc-trading-view-row">
      <div class="ex-otc-trading-view-row-label">Updated At</div>
      <div class="ex-otc-trading-view-row-value">{{utilTimeFormatOrMessage(request.updateAt)}}</div>
    </div>
    <div class="ex-otc-trading-view-row">
      <div class="ex-otc-trading-view-row-label">Pair</div>
      <div class="ex-otc-trading-view-row-value">{{currencyBuy}} / {{currencySell}}</div>
    </div>
    <div class="ex-otc-trading-view-row">
      <div class="ex-otc-trading-view-row-label">Order Type</div>
      <div class="ex-otc-trading-view-row-value">{{ orderType }}</div>
    </div>
    <div class="ex-otc-trading-view-row">
      <div class="ex-otc-trading-view-row-label">Client Side</div>
      <div class="ex-otc-trading-view-row-value">{{orderTypeLabel(request.transactionType)}}</div>
    </div>
    <ex-otc-trading-amount
      v-if="REQUEST_TRANSACTION_TYPES.buy === request.transactionType"
      :amount="amountBuy"
      :currency="currencyBuy"
      :label="getValueLabel(request.transactionType, request.transactionStatus).valueOne"
    />
    <ex-otc-trading-amount
      v-if="REQUEST_TRANSACTION_TYPES.buy === request.transactionType"
      :amount="amountSell"
      :currency="currencySell"
      :label="getValueLabel(request.transactionType, request.transactionStatus).valueTwo"
    />
    <ex-otc-trading-amount
      v-if="REQUEST_TRANSACTION_TYPES.sell === request.transactionType"
      :amount="amountSold.amountBuy"
      :currency="amountSold.currencyBuy"
      :label="getValueLabel(request.transactionType, request.transactionStatus).valueOne"
    />
    <ex-otc-trading-amount
      v-if="REQUEST_TRANSACTION_TYPES.sell === request.transactionType"
      :amount="amountSell"
      :currency="currencySell"
      :label="getValueLabel(request.transactionType, request.transactionStatus).valueTwo"
    />
    <div class="ex-otc-trading-view-row">
      <div class="ex-otc-trading-view-row-label">Client {{requestTypeAltClient}}</div>
        <div class="ex-otc-trading-view-row-value">
          <span v-if="isTransactionRequestNew && isPriceBehaviourTypeEqMarket">Market</span>
          <span v-else>
            <b>{{
                lastTransaction.clientPrice !== '-'
                  ? formatCurrency(lastTransaction.clientPrice)
                  : lastTransaction.clientPrice
              }}</b>
            {{getPairCurrencies(request.currencyPair)[1]}}
          </span>
<!--          TODO REMOVE NOT USE NO COUNTER-->
<!--          <router-link-->
<!--            v-if="request.transactionStatus === 6"-->
<!--            :to="{name: 'Trading', params: {id: request.id, type: 'edit'}}"-->
<!--          >-->
<!--            <el-button type="warning" :disabled="mix_exchangeMixin.isCanceling || isApplying">Counter</el-button>-->
<!--          </router-link>-->
        </div>
    </div>
    <div class="ex-otc-trading-view-row">
      <div class="ex-otc-trading-view-row-label">Trader {{requestTypeAltTrader}}</div>
      <div class="ex-otc-trading-view-row-value">
        <span>
          <b>{{
              lastTransaction.traderPrice !== '-'
                ? formatCurrency(lastTransaction.traderPrice)
                : lastTransaction.traderPrice
            }}</b>
          {{getPairCurrencies(request.currencyPair)[1]}}
        </span>
        <el-button
          v-if="request.transactionStatus === 6"
          type="success"
          :loading="isApplying"
          :disabled="mix_exchangeMixin.isCanceling"
          @click="acceptExchange"
        >
          Accept
        </el-button>
      </div>
    </div>
    <div class="ex-otc-trading-view-row">
      <div class="ex-otc-trading-view-row-label">Timeout</div>
      <div class="ex-otc-trading-view-row-value">
        <ex-countdown
          :time-data="period"
          :has-stop="!EXCHANGE_STATUS_TYPES.IN_PROGRESS.includes(request.transactionStatus)"
        />
      </div>
    </div>
    <div class="ex-otc-trading-view__footer">
      <el-button
        v-if="EXCHANGE_STATUS_TYPES.IN_PROGRESS.includes(request.transactionStatus)"
        @click="mix_exchangeMixin_cancelExchange(request.id)"
        :loading="mix_exchangeMixin.isCanceling"
        :disabled="isApplying"
        type="danger"
      >
        Cancel
      </el-button>
      <div :class="getClassByStatus(request.transactionStatus)">
        {{getLabelOfStatus(request)}}
      </div>
    </div>
  </div>
</template>

<script>
// components
import ExOtcTradingAmount from '@/pages/requests/otc-trading/ex-otc-trading-amount';
import ExCountdown from '@/components/ex-countdown';

// utils
import {utilTimeFormat, utilTimeFormatOrMessage} from '@/utils/converters/time';
import {formatCurrency, getAmountInCurrencyFormat, getPairCurrencies, isTONLike} from '@/utils/converters/currency';
import {orderTypeLabel} from '@/utils/converters/numberToLabel';
import {getClassByStatus} from '@/utils/styleClassNames';
import {socketRequest} from '@/utils/socket';

// lib
import momentTz from 'moment-timezone';

// api
import ExchangeApi from '@/api/requestService/exchange.api';

// mixin
import ExchangeMixin from '@/mixins/ExchangeMixin';

// const
import {
  EXCHANGE_STATUS,
  EXCHANGE_STATUS_TYPES,
  REQUEST_TRANSACTION_TYPES,
  EXCHANGE_TRANSACTION_STATUS,
  EXCHANGE_PRICE_BEHAVIOUR_TYPES,
} from '@/constants/common';
import {
  CURRENCY_TYPE,
  FIAT_CURRENCIES_LIST,
} from '@/constants/currencies';
import {TRANSACTION_TYPES} from '@/constants/request';
import {ACCEPT_EXCHANGE_ORDER} from '@/constants/events/exchange/actions.type';

export default {
  name: 'ExOtcTradingView',
  components: {ExCountdown, ExOtcTradingAmount},
  mixins: [ExchangeMixin],
  props: {
    request: {
      type: Object,
      required: true,
      default: () => {},
    },
    lastTransaction: Object,
    history: Array,
  },
  data() {
    return {
      isApplying: false,
      CURRENCY_TYPE,
      EXCHANGE_STATUS_TYPES,
      REQUEST_TRANSACTION_TYPES,
    };
  },
  computed: {
    requestTypeAltClient() {
      const typesList = {
        1: 'Bid',
        2: 'Offer',
      };
      return typesList[this.request.transactionType];
    },
    requestTypeAltTrader() {
      const typesList = {
        1: 'Offer',
        2: 'Bid',
      };
      return typesList[this.request.transactionType];
    },
    amountBuy() {
      return formatCurrency(
        this.request.amount,
        CURRENCY_TYPE.CRYPTO,
        null,
        isTONLike(this.currencyBuy) ? this.currencyBuy : null,
      );
    },
    amountSell() {
      if (this.isPriceBehaviourTypeEqMarket) {
        const dealCommissionPercent = 5;
        const dealAmount = (this.request.price * (this.request.amount / (100 + dealCommissionPercent) * 100));
        return formatCurrency(
          dealAmount,
          this.currencyType,
          null,
          isTONLike(this.currencySell) ? this.currencySell : null,
        );
      }
      return formatCurrency(
        this.request.price * this.request.amount,
        this.currencyType,
        null,
        isTONLike(this.currencySell) ? this.currencySell : null,
      );
    },
    currencyBuy() {
      return getPairCurrencies(this.request.currencyPair)[0];
    },
    currencySell() {
      return getPairCurrencies(this.request.currencyPair)[1];
    },
    isTransactionRequestNew() {
      return EXCHANGE_TRANSACTION_STATUS.NEW === this.request.transactionStatus;
    },
    isPriceBehaviourTypeEqMarket() {
      return EXCHANGE_PRICE_BEHAVIOUR_TYPES.MARKET === this.request.priceBehaviourType;
    },
    orderType() {
      const labels = {
        [EXCHANGE_PRICE_BEHAVIOUR_TYPES.ORDER]: '',
        [EXCHANGE_PRICE_BEHAVIOUR_TYPES.RFQ]: '',
        [EXCHANGE_PRICE_BEHAVIOUR_TYPES.MARKET]: 'Floating Size / Market',
        [EXCHANGE_PRICE_BEHAVIOUR_TYPES.AUTO]: 'Auto',
      };
      return labels[this.request.priceBehaviourType] != null ? labels[this.request.priceBehaviourType] : '';
    },
    amountSold() {
      if (this.isTransactionRequestNew && this.isPriceBehaviourTypeEqMarket) {
        return {amountBuy: 'Market', currencyBuy: ''};
      } else {
        return {amountBuy: this.amountBuy, currencyBuy: this.currencyBuy};
      }
    },
    currencyType() {
      const fiat = Object.values(FIAT_CURRENCIES_LIST);
      if (fiat.find((item) => item === this.currencySell)) {
        return CURRENCY_TYPE.FIAT;
      }
      return CURRENCY_TYPE.CRYPTO;
    },
    labelByStatus() {
      return {
        [EXCHANGE_STATUS.NEW]: () => 'Awaiting Trader',
        [EXCHANGE_STATUS.IN_PROGRESS_TRADER]: () => 'Processing',
        [EXCHANGE_STATUS.ACCEPT_BY_USER]: () => 'Accepted',
        [EXCHANGE_STATUS.REJECT]: () => 'Rejected',
        [EXCHANGE_STATUS.CANCELLED]: () => 'Canceled',
        [EXCHANGE_STATUS.IN_PROGRESS_USER]: () => 'Awaiting your reaction',
        [EXCHANGE_STATUS.ACCEPT_BY_TRADER]: () => 'Accepted by Trader',
        [EXCHANGE_STATUS.CANCELED_BY_TIMEOUT]: () => 'Canceled by timeout',
        [EXCHANGE_STATUS.REVERSED]: (request) => `Reversed ${utilTimeFormat(request.updateAt)}`,
        [EXCHANGE_STATUS.ACCEPT_AUTOMATIC]: () => 'Accepted by automatic',
        [EXCHANGE_STATUS.REJECT_AUTOMATIC]: () => 'Rejected by automatic',
      };
    },
    amountValueLabels() {
      return {
        [TRANSACTION_TYPES.BUY]: {
          one: {
            valueOne: 'Amount To Buy',
            valueTwo: 'Amount To Pay',
          },
          two: {
            valueOne: 'Amount Bought',
            valueTwo: 'Amount Paid',
          },
        },
        [TRANSACTION_TYPES.SELL]: {
          one: {
            valueOne: 'Amount To Sell',
            valueTwo: 'Amount To Receive',
          },
          two: {
            valueOne: 'Amount Sold',
            valueTwo: 'Amount Received',
          },
        },
      };
    },
    period() {
      return (this.request.timeoutAt?.date && this.request.timeoutAt?.timezone)
        ? {
          from: Date.now(),
          to: momentTz.tz(this.request.timeoutAt.date, this.request.timeoutAt.timezone).valueOf(),
        }
        : {from: 0, to: 0};
    },
  },
  methods: {
    utilTimeFormatOrMessage,
    getPairCurrencies,
    getClassByStatus,
    orderTypeLabel,
    getAmountInCurrencyFormat,
    formatCurrency,
    acceptExchange() {
      socketRequest.call(this, ACCEPT_EXCHANGE_ORDER,
        () => {},
        () => {},
        () => {
          this.isApplying = false;
        },
      );
      this.isApplying = true;
      ExchangeApi.acceptExchangeOrder(ACCEPT_EXCHANGE_ORDER, {exchangeId: this.request.id});
    },
    getValueLabel(transactionType, transactionStatus) {
      if (EXCHANGE_STATUS_TYPES.IN_PROGRESS.includes(transactionStatus)
        || EXCHANGE_STATUS_TYPES.CANCELED.includes(transactionStatus)
      ) {
        return this.amountValueLabels[transactionType].one;
      } else if (EXCHANGE_STATUS_TYPES.COMPLETED.includes(transactionStatus)) {
        return this.amountValueLabels[transactionType].two;
      } else if (EXCHANGE_STATUS_TYPES.FINISHED.includes(transactionStatus)) {
        return this.amountValueLabels[transactionType].one;
      }
    },
    getLabelOfStatus(order) {
      return this.labelByStatus[order.transactionStatus](order);
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-otc-trading-view {
  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5ea;
    padding: 15px 20px;
    &-label {
      color: #8e8e93;
    }
  }
  &__footer {
    background-color: rgba(0, 0, 25, 0.03);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.text-success {
  color: #28a745;
}
.text-primary,
.text-warning {
   color: #ff9500;
}
.text-secondary {
  color: #6c757d;
}
.text-info {
  color: #17a2b8;
}
.text-danger {
  color: #dc3545;
}
</style>
