<template>
  <div class="ex-otc-trading-amount">
    <div class="ex-otc-trading-amount-label">{{label}}</div>
    <div class="ex-otc-trading-amount-value">
      {{getAmountInCurrencyFormat(amount, currency)}}
      {{currency}}
    </div>
  </div>
</template>

<script>
import {getAmountInCurrencyFormat, numberFromLocaleString} from '@/utils/converters/currency';

export default {
  name: 'ExOtcTradingAmount',
  props: {
    label: String,
    currency: String,
    amount: String,
  },
  methods: {
    getAmountInCurrencyFormat,
    numberFromLocaleString,
  },
};
</script>

<style lang="scss" scoped>
.ex-otc-trading-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5ea;
  padding: 15px 20px;
  &-label {
    color: #8e8e93;
  }
}
</style>
