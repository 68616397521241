<template>
  <ex-setting-template class="ex-setting-profile">
    <ex-setting-template-row>
      <template #label>Account created</template>
      <template>{{profile.createdAtString}}</template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>{{profile.organizationName ? 'Corporate ID' : 'Client ID'}}</template>
      <template>{{profile.clientId}}</template>
    </ex-setting-template-row>
    <ex-setting-template-row v-if="profile.organizationName">
      <template #label>Organization name</template>
      <template>{{profile.organizationName}}</template>
    </ex-setting-template-row>
    <ex-setting-template-row v-if="profile.organizationName">
      <template #label>Organization number</template>
      <template>{{profile.organizationRegNumber}}</template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>Your name</template>
      <template>{{`${profile.username} ${profile.lastName}`}}</template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>Recipient ID</template>
      <template>{{preset.internalTransfer.recipientId}}</template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>Phone</template>
      <template>{{profile.phone}}</template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>E-Mail address</template>
      <template>{{profile.email}}</template>
    </ex-setting-template-row>
  </ex-setting-template>
</template>

<script>
// app
import {mapState} from 'vuex';

// components
import ExSettingTemplate from '@/pages/setting/ex-setting-template';
import ExSettingTemplateRow from '@/pages/setting/ex-setting-template-row';

export default {
  name: 'ExSettingProfile',
  components: {ExSettingTemplateRow, ExSettingTemplate},
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('currentUser', ['preset']),
  },
};
</script>
