<template>
  <el-button class="ex-switch-icon" :icon="icon" :style="{color}" @click="$emit('input', !value)" type="text" />
</template>

<script>
export default {
  name: 'ExSwitchIcon',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    activeIcon: {
      type: String,
      default: 'el-icon-lock',
    },
    inactiveIcon: {
      type: String,
      default: 'el-icon-unlock',
    },
    activeColor: {
      type: String,
      default: '',
    },
    inactiveColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    icon() {
      return this.value ? this.activeIcon : this.inactiveIcon;
    },
    color() {
      return this.value ? this.activeColor : this.inactiveColor;
    },
  },
};
</script>
