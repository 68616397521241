<template>
  <div class="ex-deposit-result">
    <ex-deposit-template :auth-function="authFunction">
      <template #icon>
        <i class="ex-deposit-result__icon el-icon-check" />
      </template>
      <template #title>Fenige transaction fail/success page in Customer personal cabinet:</template>
      Thank you ! Your deposit is currently being processed. Please check your balance or email for updates.
    </ex-deposit-template>
  </div>
</template>

<script>
// component
import ExDepositTemplate from '@/pages/deposit/fiat/ex-deposit-template';

// api
import CardPayApi from '@/api/cardPay.api';

export default {
  name: 'ExDepositResult',
  components: {ExDepositTemplate},
  methods: {authFunction: CardPayApi.authFenige},
};
</script>

<style lang="scss" scoped>
.ex-deposit-result {
  &__icon {
     color: #909399;
   }
}
</style>
