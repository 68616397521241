<template>
  <div class="ex-summary-info">
    <div class="ex-summary-info__title">Summary</div>
    <div class="ex-summary-info__content">
      <div class="ex-summary-info__row">
        <div class="ex-summary-info__item">
          <div class="ex-summary-info__card ex-summary-info__card-small">
            <div class="ex-summary-info__label">Gross amount</div>
            <div class="ex-summary-info__value">
              {{getAmountInCurrencyFormat(grossAmount, grossCurrency ? grossCurrency : netCurrency)}}
            </div>
          </div>
        </div>
        <div v-if="extended || subExtended" class="ex-summary-info__item">
          <div class="ex-summary-info__card ex-summary-info__card-small">
            <div class="ex-summary-info__label">Net amount</div>
            <div class="ex-summary-info__value">{{getAmountInCurrencyFormat(netAmount)}}</div>
          </div>
        </div>
        <div v-if="extended || subExtended" class="ex-summary-info__item">
          <div class="ex-summary-info__card ex-summary-info__card-small">
            <div class="ex-summary-info__label">Service fee</div>
            <div class="ex-summary-info__value">{{getAmountInCurrencyFormat(serviceFee)}}</div>
          </div>
        </div>
        <div v-if="!subExtended" class="ex-summary-info__item">
          <div class="ex-summary-info__card ex-summary-info__card-small">
            <div class="ex-summary-info__label">Network fee</div>
            <div class="ex-summary-info__value">{{networkFee || 'N/A'}}</div>
          </div>
        </div>
      </div>
      <div class="ex-summary-info__row">
        <div class="ex-summary-info__item">
          <div class="ex-summary-info__card ex-summary-info__card-big">
            <div class="ex-summary-info__label">
              <slot name="address">Address</slot>
            </div>
            <div class="ex-summary-info__value">{{address || 'No address'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAmountInCurrencyFormat, isTONLike, numberFromLocaleString} from '@/utils/converters/currency';

export default {
  name: 'ExSummaryInfo',
  props: {
    grossAmount: {
      type: [String, Number],
      default: '0',
    },
    netAmount: {
      type: [String, Number],
      default: '0',
    },
    serviceFee: {
      type: [String, Number],
      default: 0,
    },
    networkFee: {
      type: [String, Number],
      default: 0,
    },
    address: {
      type: String,
    },
    netCurrency: {
      type: String,
      required: true,
    },
    grossCurrency: {
      type: String,
    },
    extended: {
      type: Boolean,
      default: false,
    },
    subExtended: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getAmountInCurrencyFormat(amount, currency = this.netCurrency) {
      if (amount <= 0) return 'N/A';
      return getAmountInCurrencyFormat(numberFromLocaleString(amount), currency, isTONLike(currency) ? 8 : null);
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-summary-info {
  color: #606266;
  &__title {
    font-size: 16px;
    font-weight: 600;
  }
  &__content {
    border-top: 3px solid #E4E7ED;
    padding-top: 15px;
    margin-top: 15px;
  }
  &__row {
    display: flex;
    margin: 0 -5px 15px;
    justify-content: space-between;
    align-items: center;
  }
  &__item {
    flex: 1 1 auto;
    padding: 0 5px;
    height: 100px;
  }
  &__card{
    background: #F9F9FA;
    border: 3px solid transparent;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    &-small {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-big {
      flex-direction: column;
      padding-left: 26px;
      justify-content: center;
    }
  }
  .ex-summary-info__label {
    tab-size: 20px;
    font-weight: 600;
    text-decoration: underline;
    padding-bottom: 8px;
  }
  .ex-summary-info__value {
    font-size: 20px;
    font-weight: 500;
  }
}
</style>
