import {getLocalStorageItem, setLocalStorageItem} from '@/utils/web-storage/local';
import {getSessionItem, setSessionItem} from '@/utils/web-storage/session';

/**
 *
 */
export class RecoveryStorage {
  /**
   * @param {any} data
   */
  static set recoveryStage(data) {
    if (!data) {
      setLocalStorageItem('recoveryStage');
      return;
    }
    setLocalStorageItem('recoveryStage', data);
  }

  /**
   * @return {{}|*}
   */
  static get recoveryStage() {
    const recoveryStage = getLocalStorageItem('recoveryStage');
    if (recoveryStage) return recoveryStage;
    return {};
  }

  /**
   * @param {any} data
   */
  static set verificationOptions(data) {
    if (!data) {
      setLocalStorageItem('verificationOptions');
      return;
    }
    setLocalStorageItem('verificationOptions', data);
  }

  /**
   * @return {{}|*}
   */
  static get verificationOptions() {
    const verificationOptions = getLocalStorageItem('verificationOptions');
    if (verificationOptions) return verificationOptions;
    return {Email: false, SMS: false, GA: false};
  }

  /**
   * @param {any} data
   */
  static set email(data) {
    if (!data) {
      setSessionItem('recovery:email');
      return;
    }
    setSessionItem('recovery:email', data);
  }

  /**
   * @return {{}|*}
   */
  static get email() {
    const email = getSessionItem('recovery:email');
    if (email) return email;
    return '';
  }

  /**
   * @param {any} data
   */
  static set emailExpire(data) {
    if (!data) {
      setSessionItem('recovery:email:expire');
      return;
    }
    setSessionItem('recovery:email:expire', data);
  }

  /**
   * @return {{}|*}
   */
  static get emailExpire() {
    const emailExpire = getSessionItem('recovery:email:expire');
    if (emailExpire) return emailExpire;
    return 0;
  }

  /**
   * @param {any} data
   */
  static set smsExpire(data) {
    if (!data) {
      setSessionItem('recovery:sms:expire');
      return;
    }
    setSessionItem('recovery:sms:expire', data);
  }

  /**
   * @return {{}|*}
   */
  static get smsExpire() {
    const smsExpire = getSessionItem('recovery:sms:expire');
    if (smsExpire) return smsExpire;
    return 0;
  }
}
