<template>
  <div class="form__group">
    <div class="phone-field">
      <vue-tel-input
        type="tel"
        :value="value"
        :enabledCountryCode="false"
        placeholder="Type your phone number"
        class="form__field"
        @onInput="handleInputTel"
        @validate="handleValidate"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import VueTelInput from 'vue-tel-input';
import {Input as ElInput} from 'element-ui';

export default {
  name: 'ExTelInput',
  extends: ElInput,
  components: {VueTelInput},

  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleInputTel(v) {
      const event = {
        target: {
          value: v.number,
        },
      };
      this.handleInput(event);

      this.$emit('country', v.country.name);
      this.$emit('valid', v.isValid);
    },

    handleValidate(v) {
      this.$emit('country', v.country.name);
      this.$emit('valid', v.isValid);
    },
  },
};
</script>

<style lang="scss" scoped>
.form__group{
  margin-top: 0;
}
</style>
