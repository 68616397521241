import {mapActions, mapState} from 'vuex';
import EventBus from '@/utils/eventBus';

const LogoutMixin = {
  data() {
    return {
      mix_logoutMixin: {
        timeoutLogoutId: null,
      },
    };
  },
  mounted() {
    let timeoutMouseStop = null;
    const timeoutCycle = () => {
      if (timeoutMouseStop) clearTimeout(timeoutMouseStop);
      timeoutMouseStop = setTimeout(this.restartTimerIfMouseStop, 200);
    };
    window.addEventListener('mousemove', timeoutCycle);
  },
  computed: {
    ...mapState('auth', ['isLoggedIn']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
    restartTimerIfMouseStop() {
      if (this.mix_logoutMixin.timeoutLogoutId) clearTimeout(this.mix_logoutMixin.timeoutLogoutId);
      this.mix_logoutMixin.timeoutLogoutId = setTimeout(() => {
        if (this.isLoggedIn) EventBus.$emit('logout');
      }, 90*60*1000);
    },
  },
};

export default LogoutMixin;
