<template>
  <ex-one-input-form
    @click="verifyEmail"
    @resend="initEmailVerification"
    :message="message"
    :code-expire="codeExpire"
    title=""
    label="Code from Email"
    placeholder="Type code from email"
    action-label="Continue"
    class="ex-registration-email"
  />
</template>

<script>
// component
import ExOneInputForm from '@/components/authorization/layout/ex-one-input-form';

// util
import {socketRequest} from '@/utils/socket';
import {RegistrationSessionStorage} from '@/utils/web-storage/session';
import settingsKeeper from '@/utils/settingsKeeper';

// api
import RegistrationApi from '@/api/userService/registration.api';

// const
import {INIT_EMAIL_VERIFICATION, VERIFY_EMAIL} from '@/constants/events/registration/actions.type';

export default {
  name: 'ExRegistrationEmail',
  components: {ExOneInputForm},
  created() {
    if (this.$route.params.code && this.$route.params.token) {
      this.verifyEmail(this.$route.params.code, this.$route.params.token);
    } else if (
      RegistrationSessionStorage.emailCodeExpire.socketId === settingsKeeper.socketId
      && RegistrationSessionStorage.emailCodeExpire.codeExpire > Date.now()
    ) {
      this.codeExpire = RegistrationSessionStorage.emailCodeExpire.codeExpire;
    } else {
      RegistrationSessionStorage.emailCodeExpire = undefined;
      this.initEmailVerification();
    }
  },
  data() {
    return {
      code: '',
      message: '',
      codeExpire: 0,
    };
  },
  methods: {
    initEmailVerification() {
      socketRequest.call(this, INIT_EMAIL_VERIFICATION,
        (payload) => {
          const codeExpire = (payload.expire + payload.timestamp) * 1000;
          this.codeExpire = codeExpire;
          RegistrationSessionStorage.emailCodeExpire = {codeExpire, socketId: settingsKeeper.socketId};
          this.successMessage('Verify your email address', 'Code sent successfully');
        },
        (payload) => {
          if (payload.handledErrorMessage) {
            this.message = payload.handledErrorMessage;
          }
        },
        () => {
          this.$emit('disableLoader');
        },
      );
      this.$emit('enableLoader');
      this.message = '';
      RegistrationApi.initEmailVerification(INIT_EMAIL_VERIFICATION);
    },
    verifyEmail(code, token = settingsKeeper.socketId) {
      socketRequest.call(this, VERIFY_EMAIL,
        () => {
          this.$emit('nextStage');
          RegistrationSessionStorage.emailCodeExpire = undefined;
        },
        (payload) => {
          if (payload.handledErrorMessage) {
            this.message = payload.handledErrorMessage;
          }
        },
        () => {
          this.$emit('disableLoader');
        },
      );
      this.$emit('enableLoader');
      this.message = '';
      RegistrationApi.verifyEmail(VERIFY_EMAIL, {code, token});
    },
  },

};
</script>

<style lang="scss" scoped>
.ex-registration-email {
  ::v-deep .ex-authorization {
    &__content {
      padding-bottom: 0;
    }
  }
}
</style>
