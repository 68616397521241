<template>
  <div class="ex-login-credential">
    <ex-authorization>
      <template #title>Join the {{brand}} Digital!</template>
      <template #subTitle>
        Sign up for free or login to your account
      </template>
      <ex-authorization-form v-loading="isLoading" :message="{value: errMessage, type: 'danger'}" @click="initLogin">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item prop="type" class="ex-login-credential__choose">
            <el-radio-group v-model="type">
              <el-radio-button :label="USER_TYPE.PERSON">
                <i class="el-icon-user" />
                Person
              </el-radio-button>
              <el-radio-button :label="USER_TYPE.COMPANY">
                <i class="el-icon-office-building" />
                Company
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Your email" prop="email">
            <el-input
              ref="focusMe"
              v-model="form.email"
              placeholder="Type your email"
              autofocus
              @keyup.enter.native="initLogin"
            />
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input
              v-model="form.password"
              placeholder="Type your password"
              show-password
              @keyup.enter.native="initLogin"
            />
          </el-form-item>
        </el-form>
        <template #actionLabel>Sign In</template>
      </ex-authorization-form>
      <template #footer>
        <div>
          Don't have an account?
          <ex-link to="UserRegistration" color="primary">
            Sign up
          </ex-link>
        </div>
        <div>
          <ex-link to="RecoveryIdentification" color="primary">
            Forgot your password?
          </ex-link>
        </div>
      </template>
    </ex-authorization>
  </div>
</template>

<script>
// components
import ExAuthorization from '@/components/authorization/layout/ex-authorization-layout';
import ExAuthorizationForm from '@/components/authorization/layout/ex-authorization-form';
import ExLink from '@/components/authorization/ui/ex-link';

// api
import AuthorizationApi from '@/api/authorization.api';

// utils
import {ruleEmail, ruleRequired} from '@/utils/elementUITypicalValidation';
import {socketRequest} from '@/utils/socket';
import {checkExpectedKeys} from '@/utils/validatorPropObjects';

// const
import {INIT_LOGIN} from '@/constants/events/login/actions.type';
import {USER_TYPE} from '@/constants/common';
import {mapState} from 'vuex';

export default {
  name: 'ExLoginCredential',
  components: {ExLink, ExAuthorization, ExAuthorizationForm},
  props: {
    authorizationProfile: {
      type: Object,
      required: true,
      validator: (authorizationProfile) => checkExpectedKeys(['numberOfAccounts'], authorizationProfile),
    },
  },
  data() {
    return {
      type: USER_TYPE.COMPANY,
      form: {
        email: '',
        password: '',
      },
      rules: {
        email: [ruleRequired, ruleEmail()],
        password: [ruleRequired],
      },
      errMessage: '',
      isLoading: false,
      USER_TYPE,
    };
  },
  computed: {...mapState('content', {brand: (state) => state.content.brand})},
  created() {
    this.$emit('resetAuthorizationProfile');
  },
  mounted() {
    this.$refs.focusMe.focus();
  },
  methods: {
    initLogin() {
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;
        socketRequest.call(this, INIT_LOGIN,
          (payload) => {
            this.$emit('saveAuthorizationProfile', payload);
            if (this.type === USER_TYPE.PERSON) {
              this.$router.push({name: 'Login2FA'});
            } else if (this.type === USER_TYPE.COMPANY) {
              const soleCompanyId = this.getSoleCompanyId(payload.organizations);
              if (this.authorizationProfile.numberOfAccounts === 2 && soleCompanyId) {
                this.$emit('setSelectedAccount', soleCompanyId);
                this.$router.push({name: 'Login2FA'});
              } else if (this.authorizationProfile.numberOfAccounts > 1) {
                this.$router.push({name: 'LoginSelectType'});
              } else {
                this.$router.push({name: 'Login2FA'});
              }
            }
          },
          (payload) => {
            if (payload.handledErrorMessage) {
              this.errMessage = payload.handledErrorMessage;
            }
          },
          () => {
            this.isLoading = false;
          },
        );
        this.isLoading = true;
        AuthorizationApi.initLogin(INIT_LOGIN, this.form);
      });
    },
    getSoleCompanyId(companies) {
      for (const key in companies) {
        if (Object.hasOwnProperty.call(companies, key) && companies[key].name !== 'individual') {
          return companies[key].id;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.ex-login-credential {
  &__choose {
    margin-bottom: 15px !important;
  }
}
</style>
