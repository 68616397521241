// api
import ContentApi from '@/api/content';

// util
import {hasPayloadError} from '@/utils/error';
import {resetState} from '@/utils/storeUtils';
import vueInstance from '@/utils/vueInstance';

const initState = {
  meta: {
    loading: {
      isFetchingGDPR: false,
      isFetchingContent: false,
    },
  },
  GDPR: {
    version: '',
    text: '',
  },
  content: {
    brand: null,
    supportEmail: null,
    onboardingEmail: null,
  },
};

const state = {...initState};

const mutations = {
  reset(state) {
    resetState(initState, state);
  },
  setLoadingStatus(state, objectId) {
    state.meta.loading[objectId] = true;
  },
  removeLoadingStatus(state, objectId) {
    state.meta.loading[objectId] = false;
  },
  setGDPR(state, gdpr) {
    state.GDPR = {...gdpr};
  },
  setContent(state, content) {
    Object.assign(state.content, content);
  },
};

const actions = {

  // TODO Take into account when initializing the application

  async fetchGDPR({commit}) {
    // todo Visualize the loading process
    const errorMsg = 'Not received the current version GDPR policy';
    try {
      const result = await ContentApi.fetch();
      commit('setLoadingStatus', 'isFetchingGDPR');
      if (hasPayloadError(result?.response?.data) || hasPayloadError(result.data)) {
        commit('removeLoadingStatus', 'isFetchingGDPR');
        return vueInstance.errorMsg(errorMsg);
      }
      commit('setGDPR', result.data);
    } catch (e) {
      return vueInstance.errorMsg(errorMsg);
    } finally {
      commit('removeLoadingStatus', 'isFetchingGDPR');
    }
    // todo Visualize the loading process
  },
  async fetchBasicContent({commit}) {
    const errorMsg = 'Not received the content';
    try {
      commit('setLoadingStatus', 'isFetchingContent');
      const content = await ContentApi.fetchBasicContent();
      if (hasPayloadError(content?.response?.data) || hasPayloadError(content.data)) {
        return vueInstance.errorMsg(errorMsg);
      }
      commit('setContent', content.data);
    } catch (e) {
      return vueInstance.errorMsg(errorMsg);
    } finally {
      commit('removeLoadingStatus', 'isFetchingContent');
    }
  },
};

export default {namespaced: true, state, mutations, actions};
