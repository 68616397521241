<template>
  <div class="ex-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ExContainer',
};
</script>

<style lang="scss" scoped>
.ex-container {
  height: 100%;
  margin: 0 auto;

  @include mix--media('xl') {
    max-width: 1728px;
  }
  @include mix--media('lg') {
    max-width: 1296px;
  }
  @include mix--media('md') {
    max-width: 864px;
  }
  @include mix--media('sm') {
    max-width: 672px;
  }
  @include mix--media('xs') {
    max-width: 100%;
    padding: 0 10px;
  }
}
</style>
