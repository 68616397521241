'use strict';

/**
 * @param {String} query
 * @param {Function} request
 * @param {Number} delay
 * @param {Number} minLength
 */
export function debounceSearch(query, request, delay = 800, minLength = 3) {
  if (!query || query.length < minLength) return;
  const debFunction = _.debounce(() => {
    request(query);
  }, delay);
  debFunction();
}

/**
 * Removes null records in an object
 * @param {{}} filters
 * @return {*}
 */
export function removeEmptyFilters(filters) {
  const localFilters = Object.assign({}, filters);
  for (const filter in localFilters) {
    // eslint-disable-next-line no-prototype-builtins
    if (localFilters.hasOwnProperty(filter)) {
      const value = localFilters[filter];
      if ([null, undefined].includes(value) ||(value.isArray && value.length === 0)) {
        delete localFilters[filter];
      }
    }
  }
  return localFilters;
}

/**
 * @param {number | string} value
 * @return {*|number}
 */
export function numberSimbolsAfterComma(value) {
  const stringValue = (typeof value === 'string') ? value : value.toString();
  return stringValue.includes('.') ? stringValue.split('.').pop().length : 0;
}

/**
 * @param {string} firstCurrency
 * @param {string} secondCurrency
 * @return {{text: string, value: string}}
 */
export function createPair(firstCurrency, secondCurrency) {
  return {
    label: `${firstCurrency}/${secondCurrency}`,
    value: `${firstCurrency}:${secondCurrency}`,
  };
}

/**
 * @param {number} ms
 * @return {Promise<unknown>}
 */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
