<template>
  <el-button :loading="isLoading" @click="fetchReport" class="ex-report">
    <slot>Report</slot>
  </el-button>
</template>

<script>
// api
import TransactionReportApi from '@/api/transactionService/transactionReport.api';

// util
import {openExternalURL} from '@/utils/externalUrl';
import {typicalPostReqSocketRes} from '@/utils/socket';
import {checkExpectedKeys} from '@/utils/validatorPropObjects';

// const
import {NAMES_TRANSACTION} from '@/constants/common';

export default {
  name: 'ExReport',
  props: {
    value: {
      type: Object,
      validator: (value) => {
        return checkExpectedKeys(['dateStart', 'dateEnd'], value) ||
         checkExpectedKeys(['dateStart', 'dateEnd', 'typeOrder'], value.filters) ||
         checkExpectedKeys(['filters'], value) && _.has(value.filters, 'dateEnd') ||
          _.has(value, 'id');
      },
    },
    type: {
      type: String,
      required: true,
    },
    uniqueId: Number,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    prepRequest() {
      const data = this.value;
      const {id} = data;
      switch (this.type) {
        case 'Balance': return {
          event: 'fetch:pdf-balances-report',
          request: TransactionReportApi.fetchPdfBalancesReport,
          data,
        };
        case 'TransactionsCsv': return {
          event: 'fetch:csv-transaction-report',
          request: TransactionReportApi.fetchCsvTransactionsReport,
          data,
        };
        case 'TransactionsPdf': return {
          event: 'fetch:pdf-transaction-report',
          request: TransactionReportApi.fetchPdfTransactionsReport,
          data: {filters: data},
        };
        case NAMES_TRANSACTION.EXCHANGE: return {
          event: 'fetch:pdf-exchange-report',
          request: TransactionReportApi.fetchPdfExchangeReport,
          data: {exchangeId: id},
        };
        case NAMES_TRANSACTION.DEPOSIT: return {
          event: 'fetch:pdf-deposit-report',
          request: TransactionReportApi.fetchPdfDepositReport,
          data: {depositId: id},
        };
        case NAMES_TRANSACTION.WITHDRAWAL: return {
          event: 'fetch:pdf-withdrawal-report',
          request: TransactionReportApi.fetchPdfWithdrawalReport,
          data: {withdrawId: id},
        };
        case NAMES_TRANSACTION.WITHDRAWAL_AUTO: return {
          event: 'fetch:pdf-withdrawal-auto-report',
          request: TransactionReportApi.fetchPdfWithdrawalAutoReport,
          data: {autoWithdrawId: id},
        };
        case NAMES_TRANSACTION.IT_WITHDRAWAL: return {
          event: 'fetch:pdf-withdrawal-internal-transfer-report\'',
          request: TransactionReportApi.fetchPdfWithdrawalInternalTransfer,
          data: {withdrawId: id},
        };
        case NAMES_TRANSACTION.IT_DEPOSIT: return {
          event: 'fetch:pdf-deposit-internal-transfer-report',
          request: TransactionReportApi.fetchPdfDepositInternalTransfer,
          data: {depositId: id},
        };
        default: throw new Error('Event & api-method undefined ');
      }
    },
    async fetchReport() {
      try {
        this.isLoading = true;
        const {event, ...other} = this.prepRequest();
        const {url} = await typicalPostReqSocketRes({
          context: this,
          event: event + '-' + this.uniqueId,
          ...other,
          expectedKeys: 'url',
        });
        if (url) openExternalURL(url);
      } catch (e) {/* empty */} finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-report {
  color: #fff;
  background-color: #8884B6;
  border-color: #8884B6;
}
</style>
