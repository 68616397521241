<template>
  <img
    :src="`/img/${name}`"
    :alt="name"
    :style="styles"
    class="ex-image"
  >
</template>

<script>
export default {
  name: 'ExImage',
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
  },
  computed: {
    styles() {
      let value = '';

      if (this.width) {
        value += `max-width: ${this.width}px;`;
      }
      if (this.height) {
        value += ` max-height: ${this.height}px`;
      }

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-image {
  width: 100%;
  height: 100%;
}
</style>
