<template>
  <div class="ex-transaction">
    <portal to="toolbar__title">Transactions</portal>
    <div class="ex-transaction__in-process">
      <ex-transaction-in-progress />
    </div>
    <ex-list-content>
      <template #action>
        <div class="ex-transaction__action">
          <div class="ex-transaction__action-main">
            <el-button @click="showFilters = !showFilters">
              <span>
                FILTERS
                <i
                  class="ex-transaction__filter-icon"
                  :class="{
                    'el-icon-arrow-down': showFilters,
                    'el-icon-arrow-up': !showFilters,
                  }"
                />
              </span>
            </el-button>
            <div>
              <ex-report type="TransactionsCsv" :value="{dateStart, dateEnd}">
                Get CSV report
              </ex-report>
              <ex-report type="TransactionsPdf" :value="{dateStart, dateEnd, typeOrder: filters.typeOrder}">
                Get PDF Report
              </ex-report>
            </div>
          </div>
          <div v-show="showFilters" class="ex-transaction__action-filter">
            <el-form label-position="top" inline>
              <el-form-item label="Date range">
                <el-date-picker
                  v-model="dateRange"
                  @change="handleChangeFilterDateRange"
                  type="daterange"
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  value-format="yyyy-MM-dd"
                  class="ex-transaction__date"
                />
              </el-form-item>
              <el-form-item label="Type exchange">
                <el-select
                  @change="handleChangeFilter('typeOrder', $event)"
                  :value="filters.typeOrder"
                  class="ex-transaction__select"
                >
                  <el-option
                    v-for="(item, key) in LABEL_ORDERS"
                    :key="key"
                    :label="item"
                    :value="key"
                  />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </template>
      <div class="ex-transaction__table">
        <ex-transaction-table v-loading="isFetching" :data="transactionList">
          <template #actions="{row}">
            <ex-report
              v-if="availableReport.includes(row.entityName)"
              :type="row.requestName"
              :value="{id: row.requestId}"
              :uniqueId="row.id"
            />
          </template>
        </ex-transaction-table>
      </div>
      <template #pagination>
        <el-pagination
          background
          :current-page="pagination.currentPage"
          :page-size="pagination.limitItems"
          layout="prev, pager, next, jumper"
          :total="pagination.totalItems"
          @current-change="fetchTransactionList($event)"
        />
      </template>
    </ex-list-content>
  </div>
</template>

<script>
// util
import {typicalPostReqSocketRes} from '@/utils/socket';

// api
import TransactionApi from '@/api/transactionService/transaction.api';

// components
import ExTransactionTable from '@/pages/transactions/ex-transaction-table';
import ExReport from '@/components/ex-report';
import ExListContent from '@/components/ex-list-content';
import ExTransactionInProgress from '@/pages/transactions/ex-transaction-in-progress';
import {SUBSCRIBE_TRANSACTIONS} from '@/constants/events/subscriptions.type';

// lib
import momentTz from 'moment-timezone';

// constants
import {INIT_PAGINATION, NAMES_TRANSACTION} from '@/constants/common';

// setting
const LABEL_ORDERS = {
  '': 'All',
  [NAMES_TRANSACTION.DEPOSIT]: NAMES_TRANSACTION.DEPOSIT,
  [NAMES_TRANSACTION.DEPOSIT_FEE]: 'Deposit Fee',
  [NAMES_TRANSACTION.WITHDRAWAL]: 'Withdrawal',
  [NAMES_TRANSACTION.WITHDRAWAL_AUTO]: 'Withdrawal Auto',
  [NAMES_TRANSACTION.WITHDRAWAL_FEE]: 'Withdrawal Fee',
  [NAMES_TRANSACTION.WITHDRAWAL_AUTO_FIAT]: 'Fiat Auto Withdrawal',
  [NAMES_TRANSACTION.NETWORK_FEE]: 'Network Fee',
  [NAMES_TRANSACTION.EXCHANGE]: NAMES_TRANSACTION.EXCHANGE,
  [NAMES_TRANSACTION.EXCHANGE_BUY]: 'Exchange Buy',
  [NAMES_TRANSACTION.EXCHANGE_SELL]: 'Exchange Sell',
  [NAMES_TRANSACTION.MANUAL_COMMISSION]: 'Manual Commission',
  [NAMES_TRANSACTION.IT_WITHDRAWAL]: 'Internal Withdrawal',
  [NAMES_TRANSACTION.IT_DEPOSIT]: 'Internal Deposit',
};

export default {
  name: 'ExTransaction',
  components: {
    ExTransactionTable,
    ExListContent,
    ExReport,
    ExTransactionInProgress,
  },
  data() {
    return {
      isFetching: false,
      csvLoading: false,
      showFilters: true,
      dateRange: null,
      filters: {
        dateStart: '',
        dateEnd: '',
        typeOrder: '',
      },
      transactionList: [],
      pagination: {...INIT_PAGINATION},
      availableReport: [
        NAMES_TRANSACTION.EXCHANGE,
        NAMES_TRANSACTION.DEPOSIT,
        NAMES_TRANSACTION.WITHDRAWAL,
        NAMES_TRANSACTION.WITHDRAWAL_AUTO,
        NAMES_TRANSACTION.IT_WITHDRAWAL,
        NAMES_TRANSACTION.IT_DEPOSIT,
      ],
      notify: null,
      timeoutId: null,
      hasNewTransaction: false,
      subscribedTransactions: false,
      LABEL_ORDERS,
    };
  },
  computed: {
    dateStart() {
      return this.dateRange ? Date.parse(this.dateRange[0]) / 1000 : null;
    },
    dateEnd() {
      return this.dateRange ? Date.parse(`${this.dateRange[1]}T23:59:59`) / 1000 : null;
    },
  },
  created() {
    this.fetchTransactionList();
  },
  destroyed() {
    if (this.subscribedTransactions) this.sockets.unsubscribe(SUBSCRIBE_TRANSACTIONS);
    if (this.notify !== null && !this.notify?.closed) this.notify.close();
  },
  methods: {
    async fetchTransactionList(page) {
      try {
        this.isFetching = true;
        const {list, pagination} = await typicalPostReqSocketRes({
          context: this,
          event: 'fetch:transactions',
          request: TransactionApi.fetchTransactionList,
          data: {
            page: page || this.pagination.currentPage,
            limit: INIT_PAGINATION.limitItems,
            filters: this.filters,
          },
          waitInSeconds: 20,
          expectedKeys: ['list', 'pagination'],
        });
        this.transactionList = list;
        this.pagination = pagination;
        this.subscribeTransactions();
      } catch (e) {/* empty */} finally {
        this.isFetching = false;
      }
    },
    subscribeTransactions() {
      if (!this.subscribedTransactions) {
        this.subscribedTransactions = true;
        this.sockets.subscribe(SUBSCRIBE_TRANSACTIONS, (payload) => {
          this.handleTransaction(payload);
        });
      }
    },
    handleChangeFilterDateRange(value) {
      this.filters.dateStart = value[0] ? value[0] : '';
      this.filters.dateEnd = value[1] ? value[1] : '';
      this.fetchTransactionList(INIT_PAGINATION.currentPage);
    },
    handleChangeFilter(field, value) {
      this.filters[field] = value;
      this.fetchTransactionList(INIT_PAGINATION.currentPage);
    },
    handleTransaction(data) {
      if (![data.requestName, ''].includes(this.filters.typeOrder)) return;

      const itemTimestamp = momentTz.tz(data.createAt.date, data.createAt.timezone).valueOf();
      const dateStart = Date.parse(this.filters.dateStart);
      const dateEnd = Date.parse(this.filters.dateEnd);
      if (itemTimestamp < dateStart || itemTimestamp > dateEnd) return;

      this.handleTransactionList();
    },
    handleTransactionList() {
      if ((this.notify === null || this.notify?.closed === true) && !this.timeoutId) {
        this.notify = this.$notify.info({
          title: 'Info',
          message: 'There are new transactions, ' +
            'to get updated data click on this message in and you will be redirected to the first page',
          duration: 0,
          onClose: () => {
            this.initTimeout();
          },
          onClick: () => {
            this.fetchTransactionList(1);
            this.notify.close();
            this.initTimeout();
          },
        });
      } else if (this.timeoutId) {
        this.hasNewTransaction = true;
      }
    },
    initTimeout() {
      if (this.timeoutId) return;
      this.timeoutId = setTimeout(() => {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
        if (this.hasNewTransaction) {
          this.hasNewTransaction = false;
          this.handleTransactionList();
        }
      }, 60 * 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-transaction {
  &__btn {
    color: #fff;
    background-color: #8884B6;
    border-color: #8884B6;
    margin-right: 10px;
  }
  &__btn-report {
    display: inline-block;
  }
  &__date {
    width: 410px;
  }
  &__filter-icon {
    padding-left: 4px;
  }
  &__action {
    &-main {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
  }
  &__table {
    ::v-deep {
      .cell {
        word-break: break-word;
      }
    }
  }
  &__select {
    width: 200px;
  }
}
</style>
