<template>
  <div class="ex-table-remote-filter-radio">
    <ex-table-remote-filter :placement="placement" :cancel-trigger="cancelTrigger" @confirm="handleUpdate">
      <template #label>
        {{label}}
      </template>
      <el-radio-group v-model="value" class="ex-table-remote-filter-radio__group-vertical">
        <el-radio v-for="item in filters" :key="item.value" :label="item.value">{{item.text}}</el-radio>
      </el-radio-group>
      <div>
        <el-button @click="handleCancel" size="mini">Reset</el-button>
      </div>
      <template #displayValue>
        <div v-if="displayValue" class="ex-table-remote-filter-radio__display_value">
          {{displayValue}}
        </div>
      </template>
    </ex-table-remote-filter>
  </div>
</template>

<script>
// component
import ExTableRemoteFilter from '@/components/TableFilters/ex-table-remote-filter';

export default {
  name: 'ExTableRemoteFilterRadio',
  components: {ExTableRemoteFilter},
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    clearTrigger: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: null,
      cancelTrigger: false,
    };
  },
  computed: {
    displayValue() {
      if (![null, undefined].includes(this.value)) {
        const filter = this.filters.find((item) => item.value === this.value);
        if (filter) {
          return filter.text;
        }
      }
      return '';
    },
  },
  watch: {
    clearTrigger(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearValue();
      }
    },
  },
  methods: {
    handleCancel() {
      this.clearValue();
      this.cancelTrigger = !this.cancelTrigger;
      this.handleUpdate();
    },
    handleUpdate() {
      this.$emit('update', this.value);
    },
    clearValue() {
      this.value = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-table-remote-filter-radio {
  display: inline-block;
  &, div {
    padding: initial;
    line-height: initial;
    overflow: initial;
  }
  &__group-vertical {
    padding-top: 12px;
    label {
      display: block;
    }
  }
  &__display_value {
    word-break: break-word;
  }
}
</style>
