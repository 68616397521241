const CONFIRMATION_NOT_FOUND = 'Confirmation not found';

export default {
  1: 'Currency pair not found',
  2: 'Invoice not found',
  3: 'Wrong percentage',
  4: 'Invoice status is not new',
  5: CONFIRMATION_NOT_FOUND,
  6: 'Market to pair not found',
  7: CONFIRMATION_NOT_FOUND,
  8: CONFIRMATION_NOT_FOUND,
  9: 'Exchange order invoice not found',
  10: 'Data already filled in the invoice',
  11: 'Type not found',
  12: 'Invoice cannot be canceled',
};
