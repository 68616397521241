export default {
  methods: {
    errorMsg(message = '', title = 'Error', duration = 10000) {
      this.$notify.error({title, message, duration, customClass: 'notification-error'});
    },
    successMsg(message = '', title = 'Success', duration = 10000) {
      this.$notify.success({title, message, duration, customClass: 'notification-success'});
    },
    /**
     * todo use errorMsg
     * @param {string} title
     * @param {string} text
     * @deprecated
     */
    errorMessage(title, text) {
      this.$notify.error({
        customClass: 'notification-error',
        title: title,
        message: text,
      });
    },
    /**
     * todo use successMsg
     * @param {string} title
     * @param {string} text
     * @deprecated
     */
    successMessage(title, text) {
      this.$notify.success({
        customClass: 'notification-success',
        title: title,
        message: text,
      });
    },
  },
};
