import BaseApi from '@/api/base';

export default new class AuthorizationApi extends BaseApi {
  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.email
   * @param {string} data.password
   * @return {Promise<*>}
   */
  initLogin(event, data) {
    return super.post(event, '/api/login/init-login', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.authType
   * @param {string} data.userId
   * @param {string} [data.organizationId]
   * @return {Promise<*>}
   */
  requestCode(event, data) {
    return super.post(event, '/api/login/request-code', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.userId
   * @return {Promise<*>}
   */
  reRequestCode(event, data) {
    return super.post(event, '/api/login/re-request-code', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.userId
   * @param {string} data.authType
   * @param {string} data.code
   * @return {Promise<*>}
   */
  verifyCode(event, data) {
    return super.post(event, '/api/login/verify-code', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.userId
   * @return {Promise<*>}
   */
  login(event, data) {
    return super.post(event, '/api/login/login', {data});
  }
};
