<template>
  <div class="ex-countdown-linear">
    <ex-countdown
      :time-data="timeData"
      :has-stop="hasStop"
      @timeout="$emit('timeout')">
      <template v-slot:default="{prop}">
        <div class="ex-countdown-linear__text">
          <div v-if="hasStop && $slots.stopLabel" class="ex-countdown-linear__label">
            <slot name="stopLabel"></slot>
          </div>
          <div v-show="!hasStop || !$slots.stopLabel" style="display: flex; justify-content: space-between;">
            <div>
              <div class="ex-countdown-linear__label">
                <slot name="info-prepend" />
              </div>
              <div
                class="ex-countdown-linear__time"
                :style="`color: ${hasStop || prop.time === '00:00' ? '#67C23A' : '#F56C6C'};`"
              >
                {{prop.time}}
              </div>
            </div>
            <div class="ex-countdown-linear__label">
              <slot name="info-append" />
            </div>
          </div>
        </div>
        <div class="ex-countdown-linear__progress">
          <el-progress
            :color="hasStop || prop.percentage === 100 ? '#67C23A' : '#F56C6C'"
            :percentage="prop.percentage"
            :show-text="false"
          />
        </div>
      </template>
    </ex-countdown>
  </div>
</template>

<script>
// util
import {checkExpectedKeys} from '@/utils/validatorPropObjects';

// component
import ExCountdown from '@/components/ex-countdown';

export default {
  name: 'ExCountdownLinear',
  components: {ExCountdown},
  props: {
    timeData: {
      type: Object,
      validator(value) {
        return checkExpectedKeys(['from', 'to'], value);
      },
    },
    hasStop: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-countdown-linear {
  &__text {
    line-height: 19px;
    padding-bottom: 5px;
  }
  &__label {
    display: inline-block;
    padding-right: 5px;
    span {
      font-size: 0.875rem;
      font-style: italic;
    }
  }
  &__time {
    display: inline-block;
  }
  &__progress {}
}
</style>
