<template>
  <div class="ex-table-remote-filter">
    <div v-if="$slots.label" class="ex-table-remote-filter__title">
      <slot name="label" />
    </div>
    <el-popover
      class="ex-table-remote-filter__popover"
      :placement="placement"
      @hide="handleHide"
      trigger="click"
      ref="popover"
    >
      <div class="ex-table-remote-filter__filter">
        <slot />
      </div>
      <el-button slot="reference" :icon="icon" type="text" @click="handleOpen" />
    </el-popover>
    <div v-if="$slots.displayValue" class="ex-table-remote-filter__display_values">
      <slot name="displayValue" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExTableRemoteFilter',
  props: {
    placement: {
      type: String,
      default: 'bottom',
    },
    cancelTrigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    icon() {
      return this.isVisible ? 'el-icon-arrow-up' : 'el-icon-arrow-down';
    },
  },
  watch: {
    cancelTrigger(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleClose();
      }
    },
  },
  methods: {
    handleOpen() {
      this.isVisible = true;
    },
    handleHide() {
      this.isVisible = false;
      this.$emit('confirm');
      this.handleClose();
    },
    handleClose() {
      this.$refs.popover.doClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-table-remote-filter {
  display: inline-block;
  &, div {
    padding: initial;
    line-height: initial;
    overflow: initial;
  }
  &__display_values {
    display: block;
    font-size: 10px;
    color: #67C23A;
  }
}
</style>
