// const
import {getWSA} from '@/constants/events/actions.type';
import {SPAM_PRICE} from '@/constants/events/store/requests/actions.type';

const initState = {
  spamPricesAutoRequest: [],
};

const state = {...initState};

const getters = {
  getSpamPrice: (state) => (id) => state.spamPricesAutoRequest.find((item) => item.requestId === id),
};

const mutations = {
  reset: (state) => {
    Object.keys(initState).forEach((key) => {
      state[key] = initState[key];
    });
  },
  updateSpamPriceAutoRequest: (state, payload) => {
    const index = state.spamPricesAutoRequest.findIndex((item) => {
      if (item.requestId === payload.requestId) return true;
    });

    if (index !== -1) {
      state.spamPricesAutoRequest.splice(index, 1);
    }

    state.spamPricesAutoRequest.push(payload);
  },
};

const actions = {
  [getWSA(SPAM_PRICE)]: ({commit, dispatch}, payload)=> {
    commit('updateSpamPriceAutoRequest', payload);
  },
};

export default {namespaced: true, state, getters, mutations, actions};
