<template>
  <el-form v-loading="isLoading" :model="form" ref="form" label-width="100px" class="ex-deposit-manual-crypto">
    <ex-alert
      v-if="SPECIAL_CURRENCIES_LIST.includes(form.currency)"
      type="warning"
      class="ex-deposit-manual-crypto__message"
    >
      {{WARNING_MESSAGE_CURRENCY_EXPERIMENTAL}}
    </ex-alert>
    <el-form-item label="Asset">
      <el-select :value="form.currency" class="ex-deposit-manual-crypto__select" @change="handleSelectCurrency">
        <el-option v-for="(item, index) in cryptoCurrencyList" :key="index" :value="item" />
      </el-select>
    </el-form-item>
    <el-form-item label="Address">
      {{address}}
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        :disabled="address !== ''"
        @click="assignCryptoBalanceToAddress(form.currency)"
        :loading="isAssigning"
      >
        Generate
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
// api
import UserApi from '@/api/userService/user.api';

// lib
import {mapGetters, mapState} from 'vuex';

// utils
import {socketRequest} from '@/utils/socket';

// component
import ExAlert from '@/components/ex-alert';

// const
import {WARNING_MESSAGE_CURRENCY_EXPERIMENTAL} from '@/constants/commonMessage';
import {SPECIAL_CURRENCIES_LIST} from '@/constants/currencies';
import {
  ASSIGN_CRYPTO_BALANCE_TO_ADDRESS,
  FETCH_MANUAL_CRYPTO_DEPOSIT_ADDRESS_LIST,
} from '@/constants/events/currentUser/actions.type';

export default {
  name: 'ExDepositManualCrypto',
  components: {ExAlert},
  mounted() {
    this.form.currency = this.$route.params.currency;
    this.fetchCryptoDepositAddressList();
  },
  data() {
    return {
      isLoading: false,
      isAssigning: false,
      wallets: [],
      form: {
        currency: '',
      },
      WARNING_MESSAGE_CURRENCY_EXPERIMENTAL,
      SPECIAL_CURRENCIES_LIST,
    };
  },
  computed: {
    ...mapState('currentUser', ['profile']),
    ...mapGetters('currentUser', ['cryptoBalances']),
    cryptoCurrencyList() {
      return this.cryptoBalances.map((item) => item.currency);
    },
    address() {
      const wallet = this.wallets.filter((item) => item.currency === this.form.currency);
      return wallet.length ? wallet[0].wallet : '';
    },
  },
  methods: {
    fetchCryptoDepositAddressList() {
      this.isLoading = true;
      UserApi.fetchCryptoDepositAddressList(
        FETCH_MANUAL_CRYPTO_DEPOSIT_ADDRESS_LIST,
        {balanceId: this.profile.balanceId},
      );
      socketRequest.call(this, FETCH_MANUAL_CRYPTO_DEPOSIT_ADDRESS_LIST,
        (payload) => {
          this.wallets = payload;
        },
        () => {},
        () => {
          this.isLoading = false;
        },
      );
    },
    assignCryptoBalanceToAddress(currency) {
      this.isAssigning = true;
      UserApi.assignCryptoBalanceToAddress(
        ASSIGN_CRYPTO_BALANCE_TO_ADDRESS,
        {balanceId: this.profile.balanceId, currency},
      );
      socketRequest.call(this, ASSIGN_CRYPTO_BALANCE_TO_ADDRESS,
        () => {
          this.fetchCryptoDepositAddressList();
          return 'You have been assigned a new address!';
        },
        () => {},
        () => {
          this.isAssigning = false;
        },
      );
    },
    handleSelectCurrency(value) {
      this.$router.replace({name: 'DepositCreate', params: {currency: value}});
      this.form.currency = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-deposit-manual-crypto {
  max-width: 490px;
  padding-top: 20px;
  &__select {
     width: 100%;
  }
  &__message {
    margin-bottom: 26px;
  }
}
</style>
