<template>
  <div v-if="request">
    <ex-auto-trading-container v-if="isAutomatedTrading" v-loading="isFetching.request" :request="request" />
    <ex-otc-trading-container
      v-else
      v-loading="isFetching.request"
      :request="request"
      :last-transaction="lastTransaction"
      :history="history"
      :is-fetching-history="isFetching.history"
    />
  </div>
</template>

<script>
// api
import RequestApi from '@/api/requestService/request.api';

import ExAutoTradingContainer from '@/pages/requests/auto-trading/ex-auto-trading-container';
import ExOtcTradingContainer from '@/pages/requests/otc-trading/ex-otc-trading-container';

// util
import {socketRequest} from '@/utils/socket';

// app
import {mapGetters} from 'vuex';

// const
import {EXCHANGE_PRICE_BEHAVIOUR_TYPES} from '@/constants/common';
import {FETCH_REQUEST} from '@/constants/events/requests/actions.type';
import {FETCH_REQUEST_HISTORY, FETCH_REQUEST_LAST_TRANSACTION} from '@/constants/events/transaction/actions.type';
import {SUBSCRIBE_REQUEST_HISTORY, SUBSCRIBE_REQUEST} from '@/constants/events/subscriptions.type';

export default {
  name: 'ExTradingContainer',
  components: {
    ExOtcTradingContainer,
    ExAutoTradingContainer,
  },
  data() {
    return {
      isFetching: {
        request: false,
        lastTransaction: false,
        history: false,
      },
      request: undefined,
      history: [],
      lastTransaction: {},
      successfulMessage: '',
    };
  },
  computed: {
    ...mapGetters('currentUser', ['currentId']),
    requestId() {
      return Number(this.$route.params.id);
    },
    isAutomatedTrading() {
      return parseInt(this.request.priceBehaviourType) === EXCHANGE_PRICE_BEHAVIOUR_TYPES.AUTO;
    },
  },
  created() {
    this.fetchRequest();
    this.sockets.subscribe(SUBSCRIBE_REQUEST_HISTORY, (payload) => {
      if (parseInt(payload.last.id) === this.requestId) {
        this.history = payload.list;
        this.lastTransaction = Object.assign({}, this.lastTransaction, payload.last);
      }
    });
    this.sockets.subscribe(SUBSCRIBE_REQUEST, (payload) => {
      if (payload.id === this.requestId) {
        this.request = Object.assign({}, this.request, payload);
      }
    });
  },
  destroyed() {
    this.sockets.unsubscribe(SUBSCRIBE_REQUEST_HISTORY);
    this.sockets.unsubscribe(SUBSCRIBE_REQUEST);
  },
  methods: {
    fetchRequest() {
      this.isFetching.request = true;
      socketRequest.call(this, FETCH_REQUEST,
        (payload) => {
          this.request = payload;
          if (!this.isAutomatedTrading) {
            this.fetchRequestLastTransaction();
            this.fetchRequestHistory();
          }
        },
        () => {},
        () => {
          this.isFetching.request = false;
        },
      );
      RequestApi.fetchRequest(FETCH_REQUEST, {userId: this.currentId, requestId: this.requestId});
    },
    fetchRequestLastTransaction() {
      this.isFetching.lastTransaction = true;
      socketRequest.call(this, FETCH_REQUEST_LAST_TRANSACTION,
        (payload) => {
          this.lastTransaction = payload;
        },
        () => {},
        () => {
          this.isFetching.lastTransaction = false;
        },
      );
      RequestApi.fetchRequestLastTransaction(FETCH_REQUEST_LAST_TRANSACTION, {exchangeId: this.requestId});
    },
    fetchRequestHistory() {
      this.isFetching.history = true;
      socketRequest.call(this, FETCH_REQUEST_HISTORY,
        (payload) => {
          this.history = payload;
        },
        () => {},
        () => {
          this.isFetching.history = false;
        },
      );
      RequestApi.fetchRequestTransactionHistory(FETCH_REQUEST_HISTORY, {exchangeId: this.requestId});
    },
  },
};
</script>
