<template>
  <div class="ex-two-columns">
    <ex-container>
      <div class="ex-two-columns__wrap">
        <div class="ex-two-columns__left">
          <slot name="left">
            <div class="ex-two-columns__image">
              <ex-image
                name="software.png"
                :width="783"
                :height="601"
              />
            </div>
          </slot>
        </div>
        <div class="ex-two-columns__right">
          <slot />
        </div>
      </div>
    </ex-container>
  </div>
</template>

<script>
// components
import ExImage from '@/components/authorization/ui/ex-image';
import ExContainer from '@/components/ex-container';

export default {
  name: 'ExTwoColumns',
  components: {ExContainer, ExImage},
};
</script>

<style lang="scss" scoped>
.ex-two-columns {
  &__wrap {
    padding-bottom: 98px;
    display: flex;
    justify-content: space-between;
    & > * {
      flex: 1;
      display: flex;
    }
    @include mix--media('sm', 'xs') {
      justify-content: center;
    }
  }
  &__image {
    margin-top: 30px;
  }
  &__left {}
  &__right {
    justify-content: flex-end;
    max-width: 430px;
    @include mix--media('xs') {
      padding-top: 48px;
    }
  }
  @include mix--media('lg') {
    &__image {
      ::v-deep .ex-image {
        width: 696px;
        height: 534px;
      }
    }
  }
  @include mix--media('md') {
    &__image {
      padding-top: 186px;
      ::v-deep .ex-image {
        width: 394px;
        height: 268px;
      }
    }
  }
  @include mix--media('sm', 'xs') {
    &__left {
      display: none;
    }
    &__right {
      justify-content: center;
    }
  }
}
</style>
