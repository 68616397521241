<template>
  <div class="ex-navbar">
    <ex-container>
      <div class="ex-navbar__wrap">
        <div class="ex-navbar__logo"><ex-link :href="landingPageUrl"><ex-logo /></ex-link></div>
        <div class="ex-navbar__stub" />
        <div class="ex-navbar__actions">
          <ex-button :type="isSignUpText" color="accent" size="sm" @click="handleSignUp">Sign Up</ex-button>
          <ex-button :type="isSignInText" color="accent" size="sm" @click="handleSignIn">Sign In</ex-button>
        </div>
        <div class="ex-navbar__hamburger"><ex-hamburger v-model="menuIsOpen" /></div>
      </div>
      <transition name="fade">
        <div v-if="menuIsOpen" class="ex-navbar__menu">
          <ex-menu v-model="menuIsOpen" @sign-in="handleSignIn" @sign-up="handleSignUp" />
        </div>
      </transition>
    </ex-container>
  </div>
</template>

<script>
// component
import ExContainer from '@/components/ex-container';
import ExLogo from '@/components/authorization/ui/ex-logo';
import ExButton from '@/components/authorization/ui/ex-button';
import ExLink from '@/components/authorization/ui/ex-link';
import ExMenu from '@/components/authorization/ui/ex-menu';
import ExHamburger from '@/components/authorization/ui/ex-hamburger';

// util
import {LANDING_URL} from '@/utils/appsURLs';

export default {
  name: 'ExNavbar',
  components: {
    ExContainer,
    ExLogo,
    ExButton,
    ExLink,
    ExMenu,
    ExHamburger,
  },
  data() {
    return {
      menuIsOpen: false,
    };
  },
  methods: {
    closeMenu() {
      this.menuIsOpen = false;
    },
    handleSignIn() {
      this.$router.push({name: 'LoginCredential'});
    },
    handleSignUp() {
      this.$router.push({name: 'UserRegistration'});
    },
  },
  computed: {
    landingPageUrl() {
      return `${LANDING_URL}`;
    },
    isSignInText() {
      if (
        this.$route.name === 'LoginCredential' ||
        this.$route.name === 'Login2FA' ||
        this.$route.name === 'LoginSelectType'
      ) {
        return '';
      }
      return 'text';
    },
    isSignUpText() {
      if (
        this.$route.name === 'UserRegistration' ||
        this.$route.name === 'UserRegistrationByEmailLink'
      ) {
        return '';
      }
      return 'text';
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-navbar {
  height: 94px;
  margin-bottom: 30px;
  @include mix--media('xs') {
    height: 100%;
  }
  &__wrap {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }
  &__logo {
    @include mix--media('xs') {
      display: none;
    }
    ::v-deep .ex-link {
      display: inline-block;
    }
  }
  &__stub {
    flex: 1;
    @include mix--media('xs') {
      display: none;
    }
  }
  &__actions {
    @include mix--media('xs') {
      display: none;
    }
  }
  &__hamburger {
    display: none;
    @include mix--media('xs') {
      position: absolute;
      top: 64px;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  &__menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
