<template>
  <a
    :href="href"
    :target="target"
    :class="additionalClasses"
    @click="handleClick"
    class="ex-link"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'ExLink',
  props: {
    to: {
      type: [Object, String],
      default: null,
    },
    href: {
      type: String,
      default: '#default',
    },
    color: {
      type: String,
      default: 'default',
    },
    target: {
      type: String,
      default: '_self',
    },
  },

  computed: {
    additionalClasses() {
      return `ex-link_color-${this.color}`;
    },
  },

  methods: {
    handleClick(event) {
      this.$emit('click');

      if (this.to) {
        event.preventDefault();
        return this.$router.push({name: this.to});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a,
a:visited,
a:hover {
  text-decoration: underline !important;
}
.ex-link {
  @include mix--typo-button;
  font-weight: 500;
  transition: color 0.3s;

  // colors
  &_color-default {
    &, &:visited {
      color: $--color-auth-typo-link;
    }
    &:hover {
      color: $--color-auth-typo-link-active;
    }
    &:active {
      color: $--color-auth-typo-link;
    }
  }
  &_color-primary {
    &, &:visited {
      color: $--color-auth-primary;
    }
    &:hover {
      color: $--color-auth-primary-l1;
    }
    &:active {
      color: $--color-auth-primary;
    }
  }
  &_color-light {
    &, &:visited {
      color: $--color-auth-typo-link2;
    }
    &:hover {
      color: $--color-auth-typo-link2-active;
    }
    &:active {
      color: $--color-auth-typo-link2;
    }
  }
  &_color-secondary-alt {
    &, &:visited {
      color: $--color-auth-secondary-alt;
    }
    &:hover {
      color: $--color-auth-secondary-alt-l1;
    }
    &:active {
      color: $--color-auth-secondary-alt;
    }
  }
}
</style>
