<template>
  <div class="ex-transaction-table ex-transaction-table_without-data">
    <el-table :data="data" stripe>
      <el-table-column>
        <template #default="{row}">
          <ex-transaction-table-row :value="makeData(row)">
            <template v-slot:actions>
              <slot name="actions" v-bind="{row: makeData(row)}" />
            </template>
          </ex-transaction-table-row>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// components
import ExTransactionTableRow from '@/pages/transactions/ex-transaction-table-row';

// constant
import {REQUEST_TRANSACTION_TYPES} from '@/constants/common';

export default {
  name: 'ExTransactionTable',
  components: {ExTransactionTableRow},
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      type: REQUEST_TRANSACTION_TYPES,
    };
  },
  methods: {
    handleOpenContent(index) {
      this.table[index].isOpen = !this.table[index].isOpen;
    },
    makeData(row) {
      return {
        ...row,
        entityName: row.requestName,
        date: row.createAt,
        type: row.requestType,
        status: row.requestStatus,
        fee: row.rateCommission,
        commissionType: row.type,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-transaction-table {
  &_without-data {
    ::v-deep {
      .el-table__empty-block {
        border-radius: 4px;
        background: #FAFAFA;
      }
    }
  }
  ::v-deep {
    .el-table__header-wrapper, .el-table__footer-wrapper {
      display: none;
    }
    .el-table td {
      border-bottom: none;
      padding-top: 0;
      padding-bottom: 0;
    }
    .el-table .cell {
      padding-left: 0;
      padding-right: 0;
    }
    .el-table--group::after, .el-table--border::after, .el-table::before {
      background-color: transparent;
    }
  }
}
</style>
