import BaseApi from '@/api/base';

/**
 * Serve internal transfer with User-service, Request-service
 */
export default new class InternalTransferApi extends BaseApi {
  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchPresetInternalTransfer(event) {
    return super.post(event, '/api/internal-transfer/fetch-presets-internal-transfer');
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchCurrenciesInternalTransfer(event) {
    return super.post(event, '/api/internal-transfer/fetch-currencies-internal-transfer');
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.recipientId
   * @param {string} data.currency
   * @param {number} data.amount
   * @param {boolean} data.hasNetAmount
   * @param {string} data.reference
   * @return {Promise<*>}
   */
  createInternalTransfer(event, data) {
    return super.post(event, '/api/internal-transfer/create-internal-transfer', {data});
  }
};
