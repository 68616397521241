const settingsKeeper = (() => {
  let instance = null;
  return class SettingsKeeper {
    /**
     * @return {SettingsKeeper}
     */
    constructor() {
      if (instance === null) instance = this;
      return instance;
    }

    /**
     * @param {string} socketId
     */
    set socketId(socketId) {
      this.socketId = socketId;
    }

    /**
     * @return {string}
     */
    get socketId() {
      return this.socketId;
    }

    /**
     * @param {boolean} socketState
     */
    set socketConnected(socketState) {
      this.socketConnected = socketState;
    }

    /**
     * @return {boolean}
     */
    get socketConnected() {
      return this.socketConnected;
    }
  };
});

export default settingsKeeper();
