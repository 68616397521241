<template>
  <div class="ex-input" :class="{'ex-input_type-lock': hasLock, 'ex-input_lock': hasLock && isLock}">
    <el-input v-bind="$attrs" v-on="$listeners" :disabled="(hasLock && isLock) || $attrs.disabled" ref="exInput">
      <template v-slot:prepend>
        <el-button
          v-if="hasLock"
          :icon="isLock ? 'el-icon-lock' : 'el-icon-unlock'"
          @click="$emit('change-lock', !isLock)"
        />
      </template>
      <template v-if="$slots.append" v-slot:append>
        <div v-if="!isLock && !$attrs.disabled">
          <slot name="append" />
        </div>
      </template>
    </el-input>
  </div>
</template>

<script>
// util
import inputmasks from '@/utils/inputmasks';
import {getPrecisionByCurrency} from '@/utils/converters/currency';

// const
import {CRYPTO_CURRENCIES} from '@/constants/currencies';

export default {
  name: 'ExInput',
  props: {
    isLock: {
      type: Boolean,
      default: false,
    },
    hasLock: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: CRYPTO_CURRENCIES.BTC,
      required: true,
    },
  },
  watch: {
    currency(newCurrency, oldCurrency) {
      if (getPrecisionByCurrency(newCurrency) !== getPrecisionByCurrency(oldCurrency)) this.setMask(newCurrency);
    },
  },
  mounted() {
    this.setMask(this.currency);
  },
  methods: {
    setMask(currency) {
      inputmasks.addMaskByCurrency(currency, this.$refs.exInput);
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-input {
  &_lock {
    ::v-deep .el-button i {
      color: #F47621 !important;
    }
  }
  &_type-lock {
    ::v-deep {
      .el-input__inner {
        padding: 0 66px 0 41px;
        border-radius: 4px;
      }
    }
  }
  ::v-deep {
    .el-input-group__prepend {
      background: transparent;
      padding: 0;
      z-index: 1;
      border: 0;
      .el-button {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        margin: 0;
        border: 0;
        padding: 9px 13px;
        &:focus {
          outline: none;
        }
        i {
          font-size: 17px;
          font-weight: bold;
          color: #8883b6;
        }
      }
    }
    .el-input-group__append {
      background: transparent;
      padding: 0;
      border: 0;
      .el-button {
        position: absolute;
        right: 21px;
        top: 10px;
        span {
          color: #8883b6;
        }
      }
    }
  }
}
</style>
