/**
 * WSM - префикс для констант типа web socket mutation.
 * Везде где используется STORE_MUTATIONS_PREFIX ставить префикс
 * <WSM><EVENT_NAME>
 * @type {string}
 */
export const STORE_MUTATIONS_PREFIX = 'wsm:';
/**
 * WSA - префикс для констант типа web socket action.
 * Везде где используется STORE_ACTIONS_PREFIX ставить префикс <WSA><EVENT_NAME>
 * @type {string}
 */
export const STORE_ACTIONS_PREFIX = 'wsa:';
