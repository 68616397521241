<template>
  <div class="ex-order-history">
    <ex-list-content>
      <div class="ex-order-history__table">
        <el-table stripe highlight-current-row :data="list">
          <el-table-column label="Timestamp">
            <template v-slot="{row}">
              {{utilTimeFormatOrMessage(row.updateAt)}}
            </template>
          </el-table-column>
          <el-table-column label="Pair">
            <template v-slot="{row}">
              {{getPairLabel(row.currencyPair)}}
            </template>
          </el-table-column>
          <el-table-column label="Client side">
            <template v-slot="{row}">
              {{orderTypeLabel(row.transactionType)}}
            </template>
          </el-table-column>
          <el-table-column label="Amount">
            <template v-slot="{row}">
              {{formatCurrency(row.amount, CURRENCY_TYPE.CRYPTO)}}
            </template>
          </el-table-column>
          <el-table-column label="Execution Price">
            <template v-slot="{row}">
              {{formatCurrency(row.price, CURRENCY_TYPE.FIAT)}}
            </template>
          </el-table-column>
          <el-table-column label="Actions">
            <template v-slot="{row}">
              <el-button
                @click="$router.push({name: 'Trading', params: {id: row.id, type: 'view'}})"
                type="warning"
                size="mini"
              >
                Details
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #pagination v-if="pagination.totalPages > 1">
        <el-pagination
          background
          style="padding: 0;"
          layout="prev, pager, next, jumper"
          :current-page="pagination.currentPage"
          :page-size="pagination.limitItems"
          :total="pagination.totalItems"
          @current-change="fetchList"
        />
      </template>
    </ex-list-content>
  </div>
</template>

<script>
// util
import {orderTypeLabel} from '@/utils/converters/numberToLabel';
import {socketRequest} from '@/utils/socket';
import {formatCurrency, getPairLabel} from '@/utils/converters/currency';
import {utilTimeFormatOrMessage} from '@/utils/converters/time';

// component
import ExListContent from '@/components/ex-list-content';

// const
import {
  FETCH_ORDER_HISTORY_LIST,
  FETCH_TRADE_HISTORY_LIST,
} from '@/constants/events/requests/actions.type';
import {CURRENCY_TYPE} from '@/constants/currencies';

// api
import RequestApi from '@/api/requestService/request.api';

export default {
  name: 'ExOrdersHistory',
  components: {ExListContent},
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    isTrade: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      pagination: {
        currentPage: 1,
        limitItems: 10,
        totalItems: null,
        totalPages: null,
      },
      CURRENCY_TYPE,
    };
  },
  created() {
    this.fetchList();
  },
  methods: {
    utilTimeFormatOrMessage,
    orderTypeLabel,
    formatCurrency,
    getPairLabel,
    fetchList(page) {
      let type = FETCH_ORDER_HISTORY_LIST;
      const data = {
        page: page || this.pagination.currentPage,
        limit: 10,
      };

      if (this.isTrade) {
        type = FETCH_TRADE_HISTORY_LIST;
        RequestApi.fetchTradeHistoryList(FETCH_TRADE_HISTORY_LIST, {data});
      } else {
        RequestApi.fetchOrderHistoryList(FETCH_ORDER_HISTORY_LIST, {data});
      }
      socketRequest.call(this, type,
        (payload) => {
          this.list = payload.list;
          this.pagination = payload.pagination;
        },
      );
    },
  },
};
</script>
