<template>
  <div class="ex-copy">
    <el-popover
      ref="popover"
      popper-class="ex-copy-popover"
      trigger="manual"
      v-bind="$attrs"
      content="copied!"
      @show="handleHidePopover($refs.popover)"
    />
    <span v-popover:popover @click="handleCopyClipboard" class="ex-copy__reference">
      <slot name="default" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'ExCopy',
  props: {
    showTimeout: {
      type: Number,
      default: 750,
    },
    value: {
      required: true,
      validator: (prop) => typeof prop === 'number' || typeof prop === 'string' || prop === null,
    },
    externalControl: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCopyClipboard() {
      if (this.externalControl) return;
      this.copyClipboard();
    },
    copyClipboard(value = this.value) {
      this.$copyText(value).then(() => {
        this.$refs.popover.doShow();
      });
    },
    handleHidePopover() {
      setTimeout(() => {
        this.$refs.popover.doClose();
      }, this.showTimeout);
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-copy {
  display: inline-block;
  &__reference {
    display: inline-block;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.ex-copy-popover {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #606266 !important;
  border-radius: 25px !important;
  padding: 8px !important;
  color: #FFFFFF !important;
  border: 1px solid #606266 !important;
}
.el-popper.ex-copy-popover[x-placement^=top] .popper__arrow {
  border-top-color: #606266;
}
.el-popper.ex-copy-popover[x-placement^=top] .popper__arrow::after {
  border-top-color: #606266;
}
.el-popper.ex-copy-popover[x-placement^=bottom] .popper__arrow {
  border-bottom-color: #606266 !important;
}
.el-popper.ex-copy-popover[x-placement^=bottom] .popper__arrow::after {
  border-bottom-color: #606266 !important;
}
.el-popper.ex-copy-popover[x-placement^=left] .popper__arrow {
  border-left-color: #606266 !important;
}
.el-popper.ex-copy-popover[x-placement^=left] .popper__arrow::after {
  border-left-color: #606266 !important;
}
.el-popper.ex-copy-popover[x-placement^=right] .popper__arrow {
  border-right-color: #606266 !important;
}
.el-popper.ex-copy-popover[x-placement^=right] .popper__arrow::after {
  border-right-color: #606266 !important;
}
</style>
