<template>
  <div class="ex-setting-tabs">
    <portal to="toolbar__title">Settings</portal>
    <el-tabs v-model="activeTab">
      <el-tab-pane name="profile" label="Profile" lazy>
        <ex-setting-profile :profile="profile" />
      </el-tab-pane>
      <el-tab-pane name="gdpr" label="GDPR" lazy>
        <ex-setting-gdpr :profile="profile" />
      </el-tab-pane>
      <el-tab-pane name="security" label="Security" lazy>
        <ex-setting-security :sms="sms" :profile="profile" />
      </el-tab-pane>
      <el-tab-pane v-if="integration" name="integration" label="Integration" lazy>
        <ex-setting-integration :profile="profile" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// components
import ExSettingGdpr from '@/pages/setting/ex-setting-gdpr';
import ExSettingProfile from '@/pages/setting/ex-setting-profile';
import ExSettingSecurity from '@/pages/setting/ex-setting-security';
import ExSettingIntegration from '@/pages/setting/ex-setting-integration';

// utils
import {utilTimeDefaultFormat, utilTimeFormat} from '@/utils/converters/time';

// lib
import {mapState} from 'vuex';

export default {
  name: 'ExSettingTabs',
  components: {ExSettingIntegration, ExSettingSecurity, ExSettingGdpr, ExSettingProfile},
  data() {
    return {
      activeTab: 'profile',
    };
  },
  computed: {
    ...mapState('currentUser', {currentProfile: 'profile'}),
    ...mapState('content', {
      sms: (state) => state.content.availableSections.settings?.security?.sms,
      integration: (state) => state.content.availableSections.settings?.integration,
    }),
    profile() {
      return {
        ...this.currentProfile,
        createdAtString: utilTimeDefaultFormat(this.currentProfile.createdAt),
        policyAdoptedAtString: utilTimeFormat(this.currentProfile.policyAdoptedAt, 'DD-MM-YYYY'),
        requestPersonalDateString: this.lastDateRequestPersonalData,
      };
    },
    lastDateRequestPersonalData() {
      if (this.currentProfile.requestPersonalDate !== null) {
        return utilTimeFormat(this.currentProfile.requestPersonalDate, 'DD-MM-YYYY');
      }
      return 'Never';
    },
  },
};
</script>
