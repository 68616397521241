import BaseApi from '@/api/base';

export default new class RegistrationApi extends BaseApi {
  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.email
   * @param {string} data.phone
   * @param {string} data.password
   * @param {number} data.userType
   * @param {string} data.emailLink
   * @param {string} [data.registrationCode]
   * @param {string} [data.organizationName]
   * @param {string} [data.organizationNumber]
   * @param {string} [data.policyVersion]
   * @return {Promise<*>}
   */
  initRegistration(event, data) {
    return super.post(event, '/api/registration/init-registration', {data});
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  initPhoneNumberVerification(event) {
    return super.post(event, '/api/registration/init-phone-number-verification');
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.code
   * @return {Promise<*>}
   */
  verifyPhoneNumber(event, data) {
    return super.post(event, '/api/registration/verify-phone-number', {data});
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  initEmailVerification(event) {
    return super.post(event, '/api/registration/init-email-verification');
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.token
   * @param {string} data.code
   * @return {Promise<*>}
   */
  verifyEmail(event, data) {
    return super.post(event, '/api/registration/verify-email', {data});
  }
};
