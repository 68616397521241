import BaseApi from '@/api/base';

/**
 **/
export default new class TradingApi extends BaseApi {
  /**
   * @param {string} event
   * @return {Promise<unknown>}
   */
  fetchTradingPreset(event) {
    return super.post(event, '/api/trading/fetch-trading-preset');
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.requestId
   * @return {Promise<void>}
   */
  fetchTradingMargin(event, data) {
    return super.post(event, '/api/trading/fetch-trading-margin', {data});
  }

  /**
   * @param {string} event
   * @return {Promise<void>}
   */
  updateTradingWebhook(event) {
    return super.post(event, '/api/trading/update-trading-webhook');
  }

  /**
   * @param {string} event
   * @return {Promise<void>}
   */
  updateTradingKey(event) {
    return super.post(event, '/api/trading/update-trading-key');
  }
};
