<template>
  <el-table :data="history">
    <el-table-column label="Timestamp">
      <template #default="{row}">
        {{utilTimeFormatOrMessage(row.transactionTime, 'DD-MM-YYYY HH:mm')}}
      </template>
    </el-table-column>
    <el-table-column label="Action">
      <template #default="{row}">
        {{getLabelOfStatusForHistory(row)}}
      </template>
    </el-table-column>
    <el-table-column :label="`Client ${priceLabel}`">
      <template #default="{row}">
        {{formatPrice(row.clientPrice)}}
      </template>
    </el-table-column>
    <el-table-column :label="`Trader ${traderPriceLabel}`">
      <template #default="{row}">
        {{formatPrice(row.traderPrice)}}
      </template>
    </el-table-column>
    <el-table-column label="Price">
      <template #default="{row}">
        {{formatPrice(row.price)}}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
// const
import {EXCHANGE_STATUS} from '@/constants/common';
import {CURRENCY_TYPE} from '@/constants/currencies';
import {TRANSACTION_TYPES} from '@/constants/request';

// utils
import {utilTimeFormat, utilTimeFormatOrMessage} from '@/utils/converters/time';
import {formatCurrency, getPairCurrencies} from '@/utils/converters/currency';

export default {
  name: 'ExOtcTradingHistory',
  props: {
    request: Object,
    history: Array,
  },
  data() {
    return {
      exchange: this.request,
    };
  },
  computed: {
    priceLabel() {
      let label = '';
      if (this.exchange !== undefined) {
        switch (this.exchange.transactionType) {
          case 1:
            label = 'Bid';
            break;
          case 2:
            label = 'Offer';
            break;
        }
      }
      return label;
    },
    traderPriceLabel() {
      let label = '';
      if (this.exchange !== undefined) {
        switch (this.exchange.transactionType) {
          case 2:
            label = 'Bid';
            break;
          case 1:
            label = 'Offer';
            break;
        }
      }
      return label;
    },
    currency() {
      return getPairCurrencies(this.request.currencyPair).first;
    },
    label() {
      return {
        [EXCHANGE_STATUS.NEW]: () => 'Order Created',
        [EXCHANGE_STATUS.IN_PROGRESS_TRADER]: (request) =>
          `Client ${request.transactionType === TRANSACTION_TYPES.BUY ? 'Offer' : 'Bid'} Posted`,
        [EXCHANGE_STATUS.ACCEPT_BY_USER]: () => 'Accepted by User',
        [EXCHANGE_STATUS.REJECT]: () => 'Canceled by Trader',
        [EXCHANGE_STATUS.CANCELLED]: () => 'Canceled by Client',
        [EXCHANGE_STATUS.IN_PROGRESS_USER]: (request) => `Trader ${request.transactionType === TRANSACTION_TYPES.BUY ? 'Bid' : 'Offer'} Posted`,
        [EXCHANGE_STATUS.ACCEPT_BY_TRADER]: () => 'Accepted by Trader',
        [EXCHANGE_STATUS.CANCELED_BY_TIMEOUT]: () => 'Canceled by timeout',
        [EXCHANGE_STATUS.REVERSED]: (request) => `Reversed ${utilTimeFormat(request.updateAt)}`,
        [EXCHANGE_STATUS.ACCEPT_AUTOMATIC]: () => 'Accepted by automatic',
        [EXCHANGE_STATUS.REJECT_AUTOMATIC]: () => 'Rejected by automatic',
      };
    },
  },
  methods: {
    utilTimeFormatOrMessage,
    formatPrice(price) {
      if (price > 0) {
        return formatCurrency(price, CURRENCY_TYPE.CRYPTO);
      }
    },
    getLabelOfStatusForHistory(order) {
      return this.label[order.transactionStatus](order);
    },
  },
};
</script>
