<template>
  <div
    class="ex-coin-card"
    :class="{
      'ex-coin-card_isActive': isActive,
      'ex-coin-card_isHighlight': isHighlight,
    }"
    @click="$emit('click')">
    <div class="ex-coin-card__container">
      <div class="ex-coin-card__coin">
        <div class="ex-coin-card__image">
          <img :src="coinProp.icon" alt="icon" />
        </div>
        <div class="ex-coin-card__labels">
          <div class="ex-coin-card__shot-label">
            {{coinProp.name}}
          </div>
          <div class="ex-coin-card__full-label">
            {{coinProp.label}}
          </div>
        </div>
        <div class="ex-coin-card__arrow">
          <i class="el-icon-arrow-right" />
        </div>
      </div>
      <div class="ex-coin-card__amount">
        <div class="ex-coin-card__wallet-icon">
          <i class="el-icon-wallet" />
        </div>
        <div class="ex-coin-card__amount">
          {{getAmountInCurrencyFormat(value, coin, isTONLike(coin) ? 9 : null)}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// util
import {getCurrency} from '@/utils/exchange';
import {getAmountInCurrencyFormat, isTONLike} from '@/utils/converters/currency';

export default {
  name: 'ExCoinCard',
  props: {
    coin: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isHighlight: {
      type: Boolean,
    },
  },
  computed: {
    coinProp() {
      return getCurrency(this.coin);
    },
  },
  methods: {
    isTONLike,
    getAmountInCurrencyFormat,
  },
};
</script>

<style lang="scss" scoped>
.ex-coin-card {
  display: inline-block;
  cursor: pointer;
  width: 241px;
  height: 116px;
  background: #F9F9FA;
  border: 3px solid transparent;
  border-radius: 20px;
  &:hover {
    background: darken(#F9F9FA, 3%);
    .ex-coin-card__arrow {
      color: darken(#E8823D, 10%);
      border: 1.5px solid darken(#E8823D, 10%);
    }
  }
  &:active {
    background: darken(#F9F9FA, 6%);
  }
  &_isHighlight {
    opacity: 0.5;
  }
  &_isActive {
    border: 3px solid #E8823D;
    background: transparent;
    .ex-coin-card__arrow {
      color: darken(#E8823D, 10%);
      border: 1.5px solid darken(#E8823D, 10%);
    }
  }
  &__container {
    padding: 20px 22px;
  }
  &__coin {
    display: flex;
    align-items: center;
  }
  &__image {
    margin-right: 12px;
    width: 40px;
    height: 40px;
  }
  &__labels {
    flex: 1 1 auto;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
  &__shot-label {
    font-weight: 500;
    color: #606266;
  }
  &__full-label {
    color: #8F8F8F;
  }
  &__arrow {
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8F8F8F;
    border: 1.5px solid #8F8F8F;
    border-radius: 100%;
    i {
      font-size: 14px;
      font-weight: bold;
    }
  }
  &__amount {
    display: flex;
    align-items: center;
    color: #383838;
    overflow: hidden;
  }
  &__wallet-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    color: #B5B5B5;
    font-size: 20px;
  }
}
</style>
