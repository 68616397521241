// util
import ErrorNotifier from '@/utils/socketError/errorNotifier';
import {checkExpectedKeys} from '@/utils/validatorPropObjects';
import {hasPayloadError} from '@/utils/error';
import eventBus from '@/utils/eventBus';

// const
import {TIMEOUT_MESSAGE} from '@/constants/commonMessage';

const WAITING_IN_SECONDS = 20;

/**
 * Typical socket handle
 * NEEDED CONTEXT
 * @param {string} event
 * successCallback:
 * return undefined if you don't need to display a message
 * return '' if you want to display a "successful" notification without a message
 * return 'text' if you want to display a "successful" notification with a text message
 * @param {function} successCallback
 * return undefined if you don't need to display a message
 * return '' if you want to display a "error" notification without a message
 * return 'text' if you want to display a "error" notification with a text message
 * @param {function} errorCallback
 * @param {function} finallyCallback
 // TODO Uncomment when notification functionality is created
 // * @param {number} second
 */
export async function socketRequest(
  event,
  successCallback = () => {},
  errorCallback = () => {},
  finallyCallback = () => {},
  // TODO Uncomment when notification functionality is created
  // second = WAITING_IN_SECONDS,
) {
  // TODO Uncomment when notification functionality is created
  // let hasResponse = false;

  // eslint-disable-next-line no-invalid-this
  this.sockets.subscribe(event, (payload) => {
    // TODO Uncomment when notification functionality is created
    // hasResponse = true;

    // eslint-disable-next-line no-invalid-this
    this.sockets.unsubscribe(event);
    /* eslint-disable-next-line */
    let type, title, message;
    if (hasPayloadError(payload)) {
      title = 'Error';
      type = 'error';
      const errorMessage = ErrorNotifier.getMessage(payload, event);
      errorCallback({...payload, handledErrorMessage: errorMessage});
      if (!ErrorNotifier.isExcludedErrorMessageByEvent(payload, event)) message = errorMessage;
    } else {
      title = 'Success';
      type = 'success';
      message = successCallback(payload);
    }
    if (typeof message === 'string' || message instanceof String) {
      eventBus.$emit('notify', {type, title, message: (message ? message : '')});
    }
    finallyCallback(payload);
  });

  // TODO Uncomment when notification functionality is created
  // const timer = await initWaitingResponse(second);
  // if (timer === 'timeout' && !hasResponse) {
  //   // eslint-disable-next-line no-invalid-this
  //   this.sockets.unsubscribe(event);
  //   eventBus.$emit('notify', {
  //     type: 'error',
  //     title: 'Error',
  //     message: TIMEOUT_MESSAGE,
  //   });
  //   errorCallback({data: {}, handledErrorMessage: TIMEOUT_MESSAGE});
  //   finallyCallback({data: {}});
  // }
}

/**
 * @param {number} second
 * @return {Promise<unknown>}
 */
function initWaitingResponse(second = WAITING_IN_SECONDS) {
  if (typeof second !== 'number' || second < 0) throw new Error('Data type error');
  const count = second * 1000;
  const workerCode = `self.onmessage = (event) => {setTimeout(() => postMessage(null), ${count});}`;
  const URL = window.URL || window.webkitURL;
  const blob = new Blob([workerCode], {type: 'application/javascript'});
  const objectURL = URL.createObjectURL(blob);
  const worker = new Worker(objectURL);
  worker.postMessage(null);
  return new Promise((reject) => {
    worker.onmessage = () => {
      worker.terminate();
      reject('timeout');
    };
  });
}

/**
 * @param {Object} context
 * @param {string} event
 * @param {function} request
 * @param {Object} data
 * @param {number|string|null|undefined} waitInSeconds
 * @param {string|string[]} expectedKeys
 * @param {boolean} localErrorHandling
 * @param {boolean} dynamicallyHandlingSocketEvent
 * @return {Promise<*>}
 */
export async function typicalPostReqSocketRes({
  context,
  event,
  request,
  data,
  waitInSeconds,
  expectedKeys,
  localErrorHandling,
  dynamicallyHandlingSocketEvent,
}) {
  if (dynamicallyHandlingSocketEvent) context.$socketEventHandler.addItemDynamicEventList(event);
  const socketMessage = new Promise((resolve) => context.sockets.subscribe(event, (payload) => {
    if (
      !expectedKeys || hasPayloadError(payload)
      || (typeof expectedKeys === 'string' && payload?.[expectedKeys])
      || (Array.isArray(expectedKeys) && checkExpectedKeys(expectedKeys, payload))
    ) resolve(payload);
  }));
  await request(event, data);
  const expectedResponses = [socketMessage];
  if (typeof waitInSeconds === 'number' && waitInSeconds > 0) expectedResponses.push(initWaitingResponse(waitInSeconds));
  const payload = await Promise.race(expectedResponses);
  if (dynamicallyHandlingSocketEvent) context.$socketEventHandler.removeItemDynamicEventList(event);
  context.sockets.unsubscribe(event);
  if (payload === 'timeout') {
    if (!localErrorHandling) eventBus.$emit('notify', {type: 'error', title: 'Error', message: TIMEOUT_MESSAGE});
    throw (payload);
  }
  if (hasPayloadError(payload)) {
    payload.errorMessage = ErrorNotifier.getMessage(payload, event);
    if (!localErrorHandling) ErrorNotifier.showErrorMessage(payload, event);
    throw (payload);
  }
  return payload;
}
