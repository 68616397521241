var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ex-auto-trading-label"},[_c('div',{class:{'ex-auto-trading-label__header': true, 'ex-auto-trading-label__header__with-right': _vm.withAlignRight}},[_c('div',{class:{
      'ex-auto-trading-label__header-item': true,
      'ex-auto-trading-label__header-item__with-right': _vm.withAlignRight
    }},[_c('img',{attrs:{"src":_vm.currencyProp.icon,"alt":"icons"}})]),_c('div',{class:{
      'ex-auto-trading-label__header-item': true,
      'ex-auto-trading-label__header-item__with-right': _vm.withAlignRight,
    }},[_c('div',{staticClass:"ex-auto-trading-label__title"},[_vm._v(" "+_vm._s(_vm.currencyProp.label)+" ("+_vm._s(_vm.currencyProp.name)+") ")]),_c('div',{staticClass:"ex-auto-trading-label__subtitle"},[_vm._v(" "+_vm._s(_vm.date)+" ")])])]),_c('div',{class:{'ex-auto-trading-label__label': true, 'ex-auto-trading-label__label__with-right': _vm.withAlignRight}},[_vm._v(" "+_vm._s(_vm.type)+" ")]),_c('div',{staticClass:"ex-auto-trading-label__content"},[_c('div',{class:{
      'ex-auto-trading-label__amount': true,
      'ex-auto-trading-label__amount__with-right': _vm.withAlignRight,
    }},[_vm._v(" "+_vm._s(_vm.amount)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }