// const
import {
  CRYPTO_CURRENCIES as CRYPTO_CURRENCIES_N,
  FIAT_CURRENCIES as FIAT_CURRENCIES_N,
  CURRENCY_TYPE as CURRENCY_TYPE_N,
} from '@/constants/currencies';

export const CRITICAL_ERRORS_CODES = [500, 501, 502, 503, 504, 505, 506, 550];

export const CODE_AUTH_SESSION_TIME_LIVED = '5:251';
export const RECOVERY_CODE_NOT_EXPIRE = '2:234';
export const RECOVERY_SESSION_TIME_LIVED = '2:233';

/**
 * @type {boolean}
 */
export const IS_DEV = process.env.NODE_ENV !== 'production';

/**
 * TODO REPLACE TO CURRENCY_TYPE FROM src/constants/currencies.js
 * @deprecated
 */
export const CURRENCY_TYPE = CURRENCY_TYPE_N;

export const PERSON = 1;
export const COMPANY = 2;
export const USER_TYPE = {
  PERSON,
  COMPANY,
};

/**
 * TODO REPLACE TO CRYPTO_CURRENCIES FROM src/constants/currencies.js
 * @deprecated
 */
export const CRYPTO_CURRENCIES_LIST = CRYPTO_CURRENCIES_N;

/**
 * TODO REPLACE TO FIAT_CURRENCIES FROM src/constants/currencies.js
 * @deprecated
 */
export const FIAT_CURRENCIES_LIST = FIAT_CURRENCIES_N;

export const REQUEST_TRANSACTION_TYPE_BUY = 1;
export const REQUEST_TRANSACTION_TYPE_SELL = 2;
export const REQUEST_TRANSACTION_TYPES = {
  buy: REQUEST_TRANSACTION_TYPE_BUY,
  sell: REQUEST_TRANSACTION_TYPE_SELL,
  [REQUEST_TRANSACTION_TYPE_BUY]: 'buy',
  [REQUEST_TRANSACTION_TYPE_SELL]: 'sell',
};

export const PAYMENT_METHODS = {
  0: '',
  1: 'Sepa',
  2: 'Blockchain',
  3: 'Card',
  4: 'Swift',
  Sepa: 1,
  Blockchain: 2,
  Card: 3,
  Swift: 4,
};

export const DEPOSIT_STATUS = {
  DEFAULT: 1,
  AUTOMATIC: 2,
  REVERSED: 3,
};

export const DEPOSIT_FEE_STATUS = {
  REVERSED: 2,
};

export const WITHDRAW_STATUS = {
  NEW: 1,
  PENDING: 2,
  EXECUTED: 3,
  CANCEL_BY_TRADER: 4,
  CANCEL_BY_USER: 5,
  REVERSED: 6,
};

export const WITHDRAW_AUTO_STATUS = {
  NEW: 1,
  PENDING_AUTO: 2,
  PENDING_INVESTIGATION: 3,
  CANCELLED: 4,
  EXECUTED: 5,
  REVERSED: 6,
};

export const WITHDRAW_FEE_STATUS = {
  DEFAULT: 1,
  REVERSED: 2,
};

export const NETWORK_FEE_STATUS = {
  DEFAULT: 1,
  REVERSED: 2,
};

export const EXCHANGE_STATUS = {
  NEW: 1,
  IN_PROGRESS_TRADER: 2,
  ACCEPT_BY_USER: 3,
  REJECT: 4,
  CANCELLED: 5,
  IN_PROGRESS_USER: 6,
  ACCEPT_BY_TRADER: 7,
  CANCELED_BY_TIMEOUT: 8,
  ACCEPT_AUTOMATIC: 9,
  REJECT_AUTOMATIC: 10,
  REVERSED: 11,
};

export const EXCHANGE_STATUS_TYPES = {
  IN_PROGRESS: [
    EXCHANGE_STATUS.NEW,
    EXCHANGE_STATUS.IN_PROGRESS_TRADER,
    EXCHANGE_STATUS.IN_PROGRESS_USER,
  ],
  FINISHED: [
    EXCHANGE_STATUS.ACCEPT_BY_USER,
    EXCHANGE_STATUS.REJECT,
    EXCHANGE_STATUS.CANCELLED,
    EXCHANGE_STATUS.ACCEPT_BY_TRADER,
    EXCHANGE_STATUS.CANCELED_BY_TIMEOUT,
    EXCHANGE_STATUS.ACCEPT_AUTOMATIC,
    EXCHANGE_STATUS.REJECT_AUTOMATIC,
    EXCHANGE_STATUS.REVERSED,
  ],
  COMPLETED: [
    EXCHANGE_STATUS.ACCEPT_BY_USER,
    EXCHANGE_STATUS.ACCEPT_BY_TRADER,
    EXCHANGE_STATUS.ACCEPT_AUTOMATIC,
  ],
  CANCELED: [
    EXCHANGE_STATUS.REJECT,
    EXCHANGE_STATUS.CANCELLED,
    EXCHANGE_STATUS.CANCELED_BY_TIMEOUT,
    EXCHANGE_STATUS.REJECT_AUTOMATIC,
    EXCHANGE_STATUS.REVERSED,
  ],
};

export const PATTERNS = {
  NUMBERS_FLOAT: '[0-9]+[.]{0,1}[0-9]*',
  AMOUNT_CRYPTO: '[0-9]+[.]{0,1}[0-9]{0,8}',
  AMOUNT_FIAT: '[0-9]+[.]{0,1}[0-9]{0,2}',
};

export const WALLET_TYPES = {
  TYPE_DEFAULT: 0,
  TYPE_CPLUS: 1,
};

export const EXCHANGE_PRICE_BEHAVIOUR_TYPES = {
  RFQ: 1,
  OTC: 2,
  /**
   * @deprecated
   */
  ORDER: 2,
  MARKET: 3,
  AUTO: 4,
};

export const EXCHANGE_TRANSACTION_STATUS = {
  NEW: 1,
  IN_PROGRESS_TRADER: 2,
  ACCEPT_BY_USER: 3,
  REJECT: 4,
  CANCELLED: 5,
  IN_PROGRESS_USER: 6,
  ACCEPT_BY_TRADER: 7,
  CANCELED_BY_TIMEOUT: 8,
  ACCEPT_AUTOMATIC: 9,
  REJECT_AUTOMATIC: 10,
  REVERSE: 11,
};

export const TRADING_MODE_LIST = {
  [EXCHANGE_PRICE_BEHAVIOUR_TYPES.RFQ]: 'OTC RFQ',
  [EXCHANGE_PRICE_BEHAVIOUR_TYPES.ORDER]: 'OTC ORDER',
  [EXCHANGE_PRICE_BEHAVIOUR_TYPES.AUTO]: 'DIGITAL RFQ',
};

export const GENERAL_DESCRIPTION_TRADING_MODE = {
  [EXCHANGE_PRICE_BEHAVIOUR_TYPES.ORDER]: {
    LABEL: 'OTC TRADING',
    ROUTE_LABEL: 'otc-trading',
    DESCRIPTION: 'Trading mode for larger orders, where our traders can give a live quote or execute a limit order.',
  },
  [EXCHANGE_PRICE_BEHAVIOUR_TYPES.AUTO]: {
    LABEL: 'AUTOMATED TRADING',
    ROUTE_LABEL: 'automated-trading',
    DESCRIPTION: 'Trading mode for smaller orders, where our system will automatically price the trade and instantly fill it upon user acceptance.',
  },
};

export const NAMES_INTERNAL_TRANSFER_TRANSACTION = {
  IT_WITHDRAWAL: 'InternalWithdraw',
  IT_WITHDRAWAL_FEE: 'InternalWithdrawFEE',
  IT_DEPOSIT: 'InternalDeposit',
  IT_DEPOSIT_FEE: 'InternalDepositFEE',
};

export const NAMES_TRANSACTION = {
  EXCHANGE: 'Exchange',
  EXCHANGE_BUY: 'ExchangeBuy',
  EXCHANGE_SELL: 'ExchangeSell',
  DEPOSIT: 'Deposit',
  DEPOSIT_FEE: 'DepositFEE',
  DEPOSIT_NETWORK_FEE: 'NetworkFEEDeposit',
  WITHDRAWAL: 'Withdraw',
  WITHDRAWAL_AUTO: 'WithdrawAuto',
  WITHDRAWAL_AUTO_FIAT: 'FiatAutoWithdraw',
  WITHDRAWAL_FEE: 'WithdrawFEE',
  WITHDRAWAL_NETWORK_FEE: 'NetworkFEEWithdrawal',
  MANUAL_COMMISSION: 'ManualCommission',
  NETWORK_FEE: 'NetworkFEE',
  ...NAMES_INTERNAL_TRANSFER_TRANSACTION,
};

export const ACQUIRING_TYPE = {
  NOT_SELECTED: 0,
  XPATE: 1,
  FENIGE: 2,
};

export const COMMISSION_CATEGORY = {
  FEE: 1,
  REWARD: 2,
};

export const COMMISSION_CATEGORY_LABEL = {
  1: 'Fee',
  2: 'Reward',
};

export const COMMISSION_TYPE = {
  CUSTODY: 'Custody Fee',
  DEPOSIT: 'Deposit Fee',
  WITHDRAWAL: 'Withdrawal Fee',
  NETWORK: 'Network Fee',
};

export const COMMISSION_STATUS = {
  DEFAULT: 1,
  REVERSED: 2,
};

export const INIT_PAGINATION = {
  currentPage: 1,
  limitItems: 10,
  totalItems: 0,
  totalPages: 0,
};

export const REGISTRATION_STAGES = {
  BASE: 1,
  PHONE: 2,
  EMAIL: 3,
  FINAL: 4,
};
export const CUSTOMER_STATUSES = {
  ACTIVE: 1,
  CLOSED: 2,
  FREEZE: 3,
};
