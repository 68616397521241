<template>
  <ex-setting-template class="ex-setting-integration">
    <ex-setting-template-row>
      <template #label>Tunell Gateway token</template>
      <template>
        <div class="ex-setting-integration__row">
          <el-popconfirm
            title="Token will be updated!"
            confirm-button-text="I agree"
            cancel-button-text="No, Thanks"
            @confirm="updateTunnelGatewayApiKey"
          >
            <el-button
              :loading="tunnel.isUpdatingApiKey"
              slot="reference"
              :disabled="isNoTunnelGatewayApiKey"
            >
              Generate
            </el-button>
          </el-popconfirm>
          <div class="ex-setting-integration__key">
            <div v-show="isNoTunnelGatewayApiKey">You don't have an Tunell Gateway account</div>
            <div v-show="!isNoTunnelGatewayApiKey" class="ex-setting-integration__row">
              <el-button
                icon="el-icon-view"
                type="text"
                circle
                @click="tunnel.isShowApiKey = !tunnel.isShowApiKey"
              />
              <div  class="ex-setting-integration__key-value">
                {{tunnel.isShowApiKey ? tunnel.apiKey : '*********************************'}}
              </div>
              <ex-copy class="ex-setting-integration__copy" :value="tunnel.apiKey">
                <i class="el-icon-copy-document" />
              </ex-copy>
            </div>
          </div>
        </div>
      </template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>Callback token for Tunell Gateway</template>
      <template>
        <div class="ex-setting-integration__row">
          <el-popconfirm
            title="Callback token will be updated!"
            confirm-button-text="I agree"
            cancel-button-text="No, Thanks"
            @confirm="updateTunnelWebhook"
          >
            <el-button :loading="tunnel.isLoadingTunnel" slot="reference">
              Generate
            </el-button>
          </el-popconfirm>
          <div class="ex-setting-integration__token">
            {{tunnel.tunnelValue || '***********************************'}}
            <ex-copy v-if="tunnel.tunnelValue"  class="ex-setting-integration__copy" :value="tunnel.tunnelValue">
              <i class="el-icon-copy-document" />
            </ex-copy>
          </div>
        </div>
      </template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>Trading token</template>
      <template>
        <div class="ex-setting-integration__row">
          <el-popconfirm
            title="Token will be updated!"
            confirm-button-text="I agree"
            cancel-button-text="No, Thanks"
            @confirm="updateTradingApiKey"
          >
            <el-button
              :loading="trading.isUpdatingTradingApiKey"
              slot="reference"
              :disabled="isNoTunnelGatewayApiKey"
            >
              Generate
            </el-button>
          </el-popconfirm>
          <div class="ex-setting-integration__key">
            <div v-show="isNoTradingApiKey">You don't have an Tunell Gateway account</div>
            <div v-show="!isNoTradingApiKey" class="ex-setting-integration__row">
              <el-button
                icon="el-icon-view"
                type="text"
                circle
                @click="trading.isShowTradingApiKey = !trading.isShowTradingApiKey"
              />
              <div  class="ex-setting-integration__key-value">
                {{trading.isShowTradingApiKey ? trading.apiKey : '*********************************'}}
              </div>
              <ex-copy v-if="trading.apiKey" class="ex-setting-integration__copy" :value="trading.apiKey">
                <i class="el-icon-copy-document" />
              </ex-copy>
            </div>
          </div>
        </div>
      </template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>Callback token for Trading</template>
      <template>
        <div class="ex-setting-integration__row">
          <el-popconfirm
            title="Callback token will be updated!"
            confirm-button-text="I agree"
            cancel-button-text="No, Thanks"
            @confirm="updateTradingWebhook"
          >
            <el-button :loading="trading.isLoadingTrading" slot="reference">
              Generate
            </el-button>
          </el-popconfirm>
          <div class="ex-setting-integration__token ex-setting-integration__key-value">
            {{trading.webhookSecret || '***********************************'}}
          </div>
          <ex-copy v-if="trading.webhookSecret" class="ex-setting-integration__copy" :value="trading.webhookSecret">
            <i class="el-icon-copy-document" />
          </ex-copy>
        </div>
      </template>
    </ex-setting-template-row>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible="visiblePopup"
      class="ex-setting-integration__popup"
      width="60%"
    >
      <template #title>
        <div class="ex-setting-integration__popup-title">
          New API key and API secret
        </div>
      </template>
      <div class="ex-setting-integration__popup-content">
        <div class="ex-setting-integration__popup-row">
          <div class="ex-setting-integration__popup-subtitle">API key</div>
          <div class="ex-setting-integration__popup-value">
            {{apiKey}}
            <ex-copy v-if="apiKey"  class="ex-setting-integration__copy" :value="apiKey">
              <i class="el-icon-copy-document" />
            </ex-copy>
          </div>
        </div>
        <div class="ex-setting-integration__popup-row">
          <div class="ex-setting-integration__popup-subtitle">API secret</div>
          <div class="ex-setting-integration__popup-value">
            {{secret}}
            <ex-copy v-if="secret"  class="ex-setting-integration__copy" :value="secret">
              <i class="el-icon-copy-document" />
            </ex-copy>
          </div>
        </div>
        <div class="ex-setting-integration__popup-row">
          <ex-alert type="warning">
            <div class="ex-setting-integration__popup-label">WARNING</div>
            <div>You will see the API Secret only once, so make sure you saved it securely.</div>
            <div>If you compromise or lose your API Key or API Secret, use the re-genereation option.</div>
          </ex-alert>
        </div>
        <div class="ex-setting-integration__popup-row ex-setting-integration__popup-action">
          <el-button type="warning" @click="showPopup">
            I saved API Key and API Secret
          </el-button>
        </div>
      </div>
    </el-dialog>
  </ex-setting-template>
</template>

<script>
// components
import ExSettingTemplate from '@/pages/setting/ex-setting-template';
import ExSettingTemplateRow from '@/pages/setting/ex-setting-template-row';
import ExAlert from '@/components/ex-alert';
import ExCopy from '@/components/ex-copy';

// const
import {UPDATE_TUNNEL_API_KEY, UPDATE_TUNNEL_WEBHOOK} from '@/constants/events/tunnel/actions.type';

// utils
import {socketRequest, typicalPostReqSocketRes} from '@/utils/socket';

// api
import tunnelGateway from '@/api/tunnel.api';
import TradingApi from '@/api/trading.api';

// lib
import {mapState} from 'vuex';

export default {
  name: 'ExSettingIntegration',
  components: {ExAlert, ExCopy, ExSettingTemplateRow, ExSettingTemplate},
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apiKey: '',
      secret: '',
      tunnel: {
        value: '',
        tunnelValue: '',
        isSaved: false,
        isLoadingTunnel: false,
        isUpdatingApiKey: false,
        isShowApiKey: false,
        apiKey: '',
        secret: '',
      },
      trading: {
        webhookSecret: '',
        isSaved: false,
        isLoadingTrading: false,
        isUpdatingTradingApiKey: false,
        isShowTradingApiKey: false,
        apiKey: '',
        secret: '',
      },
      visiblePopup: false,
    };
  },
  computed: {
    ...mapState('currentUser', ['preset']),
    isNoTunnelGatewayApiKey() {
      return this.tunnel.apiKey === false;
    },
    isNoTradingApiKey() {
      return this.tunnel.apiKey === false;
    },
  },
  created() {
    this.tunnel.apiKey = this.preset.billing.apiKey ? this.preset.billing.apiKey : false;
    this.trading.apiKey = this.preset.trading.apiKey ? this.preset.trading.apiKey : false;
  },
  methods: {
    updateTunnelWebhook() {
      socketRequest.call(this, UPDATE_TUNNEL_WEBHOOK,
        (payload) => {
          this.tunnel.tunnelValue = payload.webhookToken;
        },
        () => {},
        () => {
          this.tunnel.isLoadingTunnel = false;
        },
      );
      this.tunnel.isLoadingTunnel = true;
      tunnelGateway.updateTunnelWebhook(UPDATE_TUNNEL_WEBHOOK);
    },
    updateTunnelGatewayApiKey() {
      socketRequest.call(this, UPDATE_TUNNEL_API_KEY,
        (payload) => {
          this.tunnel.apiKey = payload.apiKey;
          this.apiKey = payload.apiKey;
          this.tunnel.secret = payload.apiSecret;
          this.secret = payload.apiSecret;
          this.visiblePopup = true;
        },
        () => {},
        () => {
          this.tunnel.isUpdatingApiKey = false;
        },
      );
      this.tunnel.isUpdatingApiKey = true;
      tunnelGateway.updateTunnelApiKey(UPDATE_TUNNEL_API_KEY);
    },
    async updateTradingApiKey() {
      try {
        this.trading.isUpdatingTradingApiKey = true;
        const {apiKey, apiSecret} = await typicalPostReqSocketRes({
          context: this,
          event: 'update:trading-key',
          request: TradingApi.updateTradingKey,
        });
        this.apiKey = apiKey;
        this.trading.apiKey = apiKey;
        this.secret = apiSecret;
        this.trading.secret = apiSecret;
        this.visiblePopup = true;
      } catch (e) {/* empty */} finally {
        this.trading.isUpdatingTradingApiKey = false;
      }
    },
    async updateTradingWebhook() {
      try {
        this.trading.isLoadingTrading = true;
        const {webhookSecret} = await typicalPostReqSocketRes({
          context: this,
          event: 'update:trading-webhook',
          request: TradingApi.updateTradingWebhook,
        });
        this.trading.webhookSecret = webhookSecret;
        this.trading.isSaved = true;
      } catch (e) {/* empty */} finally {
        this.trading.isLoadingTrading = false;
      }
    },
    showPopup() {
      this.visiblePopup = !this.visiblePopup;
      this.tunnel.secret = '';
      this.trading.secret = '';
      this.secret = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-setting-integration {
  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__token, &__key {
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-right: 10px;
    margin-top: -2px;
    font-size: 16px;
    color: #333;
  }
  &__key {
    &-value {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 245px;
    }
    ::v-deep .el-button--text {
      color: #606266;
    }
  }
  &__copy {
    margin-left: 7px;
  }
  &__popup {
    &-row {
      padding-bottom: 16px;
    }
    &-title  {
      font-size: 22px;
      font-weight: 600;
    }
    &-subtitle {
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 8px;
    }
    &-value {
      font-size: 16px;
    }
    &-action {
      display: flex;
      justify-content: flex-end;
    }
    &-label {
      font-weight: 600;
    }
  }
}
.el-icon-copy-document {
  color: #67c23a;
  font-size: 24px;
  font-weight: 500;
}
</style>
