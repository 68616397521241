const EventBusMixin = {
  created() {
    this.EventBus.$on('logout', () => {
      this.logout();
      this.$router.push({name: 'LoginCredential'});
    });
    this.EventBus.$on('blocked', () => {
      if (this.$router.currentRoute.name === 'ErrorPage') {
        return;
      }
      this.logout();
      this.$router.push({name: 'ErrorPage', params: {error: 403}});
    });
    this.EventBus.$on('notify', ({type, title, message}) => {
      if (type === 'error') {
        this.errorMsg(message, title);
      } else if (type === 'success') {
        this.successMsg(message, title);
      }
    });
  },
};

export default EventBusMixin;
