<template>
  <div v-loading="isLoading" class="ex-recovery-validation-sms">
    <ex-one-input-form
      :message="message"
      :sub-title="email"
      label="Code from SMS"
      placeholder="Type code from SMS"
      action-label="Continue"
      :code-expire="codeExpire"
      @click="validateCode"
      @resend="requestSmsCode"
    />
  </div>
</template>

<script>
// component
import ExOneInputForm from '@/components/authorization/layout/ex-one-input-form';

// api
import RecoveryApi from '@/api/userService/recovery.api';

// const
import {RECOVERY_VALIDATE_SMS_CODE} from '@/constants/events/recovery/actions.type';
import {RECOVERY_CODE_NOT_EXPIRE, RECOVERY_SESSION_TIME_LIVED} from '@/constants/common';

// util
import {socketRequest, typicalPostReqSocketRes} from '@/utils/socket';
import {RecoveryStorage} from '@/utils/web-storage/recovery';
import settingsKeeper from '@/utils/settingsKeeper';

export default {
  name: 'ExRecoveryValidationSms',
  components: {ExOneInputForm},
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      message: '',
      isLoading: false,
      codeExpire: 0,
    };
  },
  created() {
    if (RecoveryStorage.smsExpire > Date.now()) {
      this.codeExpire = RecoveryStorage.smsExpire;
    } else {
      RecoveryStorage.smsExpire = undefined;
      this.requestSmsCode();
    }
  },
  methods: {
    validateCode(code) {
      this.message = '';
      socketRequest.call(this, RECOVERY_VALIDATE_SMS_CODE,
        () => {
          this.$emit('smsStageCompleted');
        },
        (payload) => {
          if (payload.error.code === RECOVERY_SESSION_TIME_LIVED) {
            this.$emit('restartRecovery');
          } else if (payload.handledErrorMessage) {
            this.message = payload.handledErrorMessage;
          }
        },
        () => {
          this.isLoading = false;
        },
      );
      this.isLoading = true;
      RecoveryApi.validateRecoveryCode(
        RECOVERY_VALIDATE_SMS_CODE,
        {token: settingsKeeper.socketId, validationType: 'SMS', code},
      );
    },
    async requestSmsCode() {
      try {
        this.isLoading = true;
        const {expire, timestamp} = await typicalPostReqSocketRes({
          context: this,
          event: 'fetch:recovery-code',
          request: RecoveryApi.fetchCode,
          localErrorHandling: true,
        });
        this.codeExpire = (expire + timestamp) * 1000;
        RecoveryStorage.smsExpire = this.codeExpire;
        this.successMsg('The Sms code has been successfully sent');
      } catch (e) {
        if (e.error?.code === RECOVERY_CODE_NOT_EXPIRE) return this.$router.push({name: 'RecoveryValidation'});
        this.message = e.errorMessage;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
