import {isNull, isUndefined} from 'lodash';

/**
 * @param {string} name
 * @param {any} defaultReturn
 * @return {null|any}
 */
export const getLocalStorageItem = (name, defaultReturn = null) => {
  const str = localStorage.getItem(name);
  if (!str || str === 'undefined') {
    return defaultReturn;
  }
  return JSON.parse(str);
};

/**
 * @param {string} name
 * @param {any} data
 */
export const setLocalStorageItem = (name, data = null) => {
  if (!isNull(data) && !isUndefined(data)) {
    localStorage.setItem(name, JSON.stringify(data));
  } else {
    localStorage.removeItem(name);
  }
};
