<template>
  <ex-one-input-form
    @click="verifyPhoneNumber"
    @resend="initPhoneNumberVerification"
    :code-expire="codeExpire"
    :message="message"
    title=""
    label="Code from SMS"
    placeholder="Type code from SMS"
    action-label="Confirm"
    class="ex-registration-phone"
  />
</template>

<script>
// component
import ExOneInputForm from '@/components/authorization/layout/ex-one-input-form';

// util
import {socketRequest} from '@/utils/socket';
import {RegistrationSessionStorage} from '@/utils/web-storage/session';
import settingsKeeper from '@/utils/settingsKeeper';

// api
import RegistrationApi from '@/api/userService/registration.api';

// const
import {INIT_PHONE_NUMBER_VERIFICATION, VERIFY_PHONE_NUMBER} from '@/constants/events/registration/actions.type';

export default {
  name: 'ExRegistrationPhone',
  components: {ExOneInputForm},
  data() {
    return {
      message: '',
      codeExpire: 0,
    };
  },
  created() {
    if (
      RegistrationSessionStorage.phoneCodeExpire.socketId === settingsKeeper.socketId
      && RegistrationSessionStorage.phoneCodeExpire.codeExpire > Date.now()
    ) {
      this.codeExpire = RegistrationSessionStorage.phoneCodeExpire.codeExpire;
    } else {
      RegistrationSessionStorage.phoneCodeExpire = undefined;
      this.initPhoneNumberVerification();
    }
  },
  methods: {
    initPhoneNumberVerification() {
      socketRequest.call(this, INIT_PHONE_NUMBER_VERIFICATION,
        (payload) => {
          const codeExpire = (payload.expire + payload.timestamp) * 1000;
          this.codeExpire = codeExpire;
          RegistrationSessionStorage.phoneCodeExpire = {codeExpire, socketId: settingsKeeper.socketId};
          this.successMessage('Verify your phone number', 'SMS code sent successfully');
        },
        (payload) => {
          if (payload.handledErrorMessage) {
            this.message = payload.handledErrorMessage;
          }
        },
        () => {
          this.$emit('disableLoader');
        },
      );
      this.$emit('enableLoader');
      this.message = '';
      RegistrationApi.initPhoneNumberVerification(INIT_PHONE_NUMBER_VERIFICATION);
    },
    verifyPhoneNumber(code) {
      socketRequest.call(this, VERIFY_PHONE_NUMBER,
        () => {
          this.$emit('nextStage');
          return 'Your phone number has been successfully verified';
        },
        (payload) => {
          if (payload.handledErrorMessage) {
            this.message = payload.handledErrorMessage;
          }
        },
        () => {
          this.$emit('disableLoader');
        },
      );
      this.$emit('enableLoader');
      this.message = '';
      RegistrationApi.verifyPhoneNumber(VERIFY_PHONE_NUMBER, {code});
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-registration-phone {
  ::v-deep .ex-authorization {
    &__content {
      padding-bottom: 0;
    }
  }
}
</style>
