<template>
  <div class="ex-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ExLayout',
};
</script>
