<template>
  <div class="ex-registration">
    <ex-registration-base
      @changeBaseData="changeBaseData"
      @nextStage="goToStage(REGISTRATION_STAGES.PHONE)"
      :display-mode="!baseStage"
      :is-loading="isLoading"
    >
      <ex-registration-phone
        v-if="phoneStage"
        @nextStage="goToStage(REGISTRATION_STAGES.EMAIL)"
        @enableLoader="enableLoader"
        @disableLoader="disableLoader"
      />
      <ex-registration-email
        v-if="emailStage"
        @nextStage="goToStage(REGISTRATION_STAGES.FINAL)"
        @enableLoader="enableLoader"
        @disableLoader="disableLoader"
      />
    </ex-registration-base>
    <ex-registration-final v-if="finalStage"/>
  </div>
</template>

<script>
// constant
import {REGISTRATION_STAGES} from '@/constants/common';

// util
import {RegistrationSessionStorage} from '@/utils/web-storage/session';

// components
import ExRegistrationFinal from '@/pages/registration/ex-registration-final';
import ExRegistrationBase from '@/pages/registration/ex-registration-base';
import ExRegistrationPhone from '@/pages/registration/ex-registration-phone';
import ExRegistrationEmail from '@/pages/registration/ex-registration-email';

export default {
  name: 'ExRegistration',
  components: {
    ExRegistrationEmail,
    ExRegistrationPhone,
    ExRegistrationBase,
    ExRegistrationFinal,
  },
  data() {
    return {
      currentStage: REGISTRATION_STAGES.BASE,
      isLoading: false,
      REGISTRATION_STAGES,
    };
  },

  created() {
    if (this.$route.params.token && this.$route.params.code) {
      this.goToStage(REGISTRATION_STAGES.EMAIL);
    }
  },
  computed: {
    baseStage() {
      return this.currentStage === REGISTRATION_STAGES.BASE;
    },
    phoneStage() {
      return this.currentStage === REGISTRATION_STAGES.PHONE;
    },
    emailStage() {
      return this.currentStage === REGISTRATION_STAGES.EMAIL;
    },
    finalStage() {
      return this.currentStage === REGISTRATION_STAGES.FINAL;
    },
  },
  watch: {
    finalStage() {
      RegistrationSessionStorage.user = undefined;
      RegistrationSessionStorage.phoneCodeExpire = undefined;
      RegistrationSessionStorage.emailCodeExpire = undefined;
    },
  },
  methods: {
    goToStage(stage = REGISTRATION_STAGES.BASE) {
      this.currentStage = stage;
    },
    changeBaseData() {
      this.goToStage(REGISTRATION_STAGES.BASE);
      this.disableLoader();
    },
    enableLoader() {
      this.isLoading = true;
    },
    disableLoader() {
      this.isLoading = false;
    },
  },
};
</script>
