/**
 * @param {number} eventRate
 * @return {Worker}
 */
export default function getWorkerNonSleepTimer(eventRate = 1000) {
  const workerCode = `self.onmessage = (event) => {setInterval(() => postMessage(null), ${eventRate})}`;
  const URL = window.URL || window.webkitURL;
  const blob = new Blob([workerCode], {type: 'application/javascript'});
  const objectURL = URL.createObjectURL(blob);
  return new Worker(objectURL);
}
