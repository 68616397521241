import Vue from 'vue';
// Application imports
import App from '@/App';
import routerConstruct from '@/routes';
import store from '@/store';

// settings
import setting from '@/config/setting';

// Global plugins imports
import _ from 'lodash';

console.log('ENVIRONMENT', process.env.NODE_ENV);

const router = routerConstruct(store);
setting(store, router);

Vue.config.productionTip = false; // TODO RESEARCH

new Vue({el: '#app', router, store, _, components: {App}, template: '<App />'});
