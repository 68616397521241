import BaseApi from '@/api/base';

export default new class DRAApi extends BaseApi {
  /**
   * Get a list of banks to create PDF
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchBanks(event) {
    return super.post(event, '/api/dra/fetch-dra-banks');
  }

  /**
   * Create PDF
   * @param {string} event
   * @param {Object} data
   * @param {string} data.currency
   * @param {number} data.amount
   * @param {string} data.requestDate
   * @param {number} data.bankId
   * @param {number} data.paymentAccountId
   * @param {number} [data.emailSendTo] 1=toClient, 2=toTrader
   * @return {Promise<*>}
   */
  createDepositRequest(event, data) {
    return super.post(event, '/api/dra/create-dra', {data});
  }
};
