<template>
  <div class="ex-withdrawal-list">
    <portal to="toolbar__title">Withdrawal</portal>
    <div class="ex-withdrawal-list__tabs">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="Fiat Auto Withdrawal" name="fiatAutoWithdrawal">
          <ex-withdrawal-list-fiat-auto />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
// Components
import ExWithdrawalListFiatAuto from '@/pages/withdrawal/ex-withdrawal-list-fiat-auto';

export default {
  name: 'ExWithdrawalList',
  components: {
    ExWithdrawalListFiatAuto,
  },
  data() {
    return {
      activeTab: 'fiatAutoWithdrawal',
      typeIndex: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.ex-withdrawal-list {
  &__tabs{
    ::v-deep {
      .el-tabs__item {
        font-size: 16px;
      }
    }
  }
}
</style>
