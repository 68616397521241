import Inputmask from 'inputmask';
import {
  CRYPTO_CURRENCIES_LIST,
  CURRENCY_TYPE,
  TON_LIKE_CURRENCIES_LIST,
  USDT_LIKE_CURRENCIES_LIST,
} from '@/constants/currencies';
import {isFiat, isUSDTLike} from '@/utils/converters/currency';

/**
 *
 * @type {{
 * numeric: (function())<Inputmask>,
 * decimal: (function())<Inputmask>
 * }}
 */
const inputmasks = {
  decimal: () => new Inputmask('(,999){+|1}.00', {
    positionCaretOnClick: 'radixFocus',
    radixPoint: '.',
    _radixDance: true,
    numericInput: true,
    placeholder: '0',
    definitions: {
      0: {
        validator: '[0-9\uFF11-\uFF19]',
      },
    },
  }),
  cardNumber() {
    return new Inputmask({
      mask: ['X999 9999 9999 9[999]'],
      jitMasking: true,
      definitions: {
        'X': {
          validator: '[4-5]',
        },
      },
      onBeforeMask(value) {
        return value;
      },
      onUnMask(value) {
        return value;
      },
      onBeforePaste: handleBeforePaste.bind(this),
    });
  },
  [CURRENCY_TYPE.CRYPTO](min = undefined, max = undefined) {
    return new Inputmask('numeric', {
      groupSeparator: ',',
      placeholder: '0',
      digits: 8,
      allowMinus: false,
      autoGroup: true,
      digitsOptional: true,
      rightAlign: false,
      min,
      max,
      onBeforeMask(value) {
        return value;
      },
      postValidation(valueArr) {
        const value = valueArr.slice().reverse().join('').replace(/,/g, '');

        return value >= 0 && value <= 99999999.99999999;
      },
      onUnMask(value) {
        return value;
      },
      onBeforePaste: handleBeforePaste.bind(this),
    });
  },
  [CURRENCY_TYPE.FIAT](min = undefined, max = undefined) {
    return new Inputmask('numeric', {
      groupSeparator: ',',
      placeholder: '0',
      digits: 2,
      allowMinus: false,
      autoGroup: true,
      digitsOptional: true,
      rightAlign: false,
      min,
      max,
      onBeforeMask(value) {
        return value;
      },
      postValidation(valueArr) {
        const value = valueArr.slice().reverse().join('').replace(/,/g, '');

        return value >= 0 && value <= 99999999.99;
      },
      onUnMask(value) {
        return value;
      },
      onBeforePaste: handleBeforePaste.bind(this),
    });
  },
  USDT_ERC20(min = undefined, max = undefined) {
    return new Inputmask('numeric', {
      groupSeparator: ',',
      placeholder: '0',
      digits: 6,
      autoGroup: true,
      digitsOptional: true,
      rightAlign: false,
      min,
      max,
      onBeforeMask(value) {
        return value;
      },
      postValidation(valueArr) {
        const value = valueArr.slice().reverse().join('').replace(/,/g, '');

        return value >= 0 && value <= 99999999.999999;
      },
      onUnMask(value) {
        return value;
      },
      onBeforePaste: handleBeforePaste.bind(this),
    });
  },
  USDTLike(min = undefined, max = undefined) {
    return new Inputmask('numeric', {
      groupSeparator: ',',
      placeholder: '0',
      digits: 6,
      autoGroup: true,
      digitsOptional: true,
      rightAlign: false,
      min,
      max,
      onBeforeMask(value) {
        return value;
      },
      postValidation(valueArr) {
        const value = valueArr.slice().reverse().join('').replace(/,/g, '');

        return value >= 0 && value <= 99999999.999999;
      },
      onUnMask(value) {
        return value;
      },
      onBeforePaste: handleBeforePaste.bind(this),
    });
  },
  TONLike(min = undefined, max = undefined) {
    return new Inputmask('numeric', {
      groupSeparator: ',',
      placeholder: '0',
      digits: 9,
      autoGroup: true,
      digitsOptional: true,
      rightAlign: false,
      min,
      max,
      onBeforeMask(value) {
        return value;
      },
      postValidation(valueArr) {
        const value = valueArr.slice().reverse().join('').replace(/,/g, '');

        return value >= 0 && value <= 99999999.99999999;
      },
      onUnMask(value) {
        return value;
      },
      onBeforePaste: handleBeforePaste.bind(this),
    });
  },
  addMaskByCurrency(currency, ref, min = undefined, max = undefined) {
    const refExtended = ref.$el.getElementsByTagName('INPUT')[0];
    this[getMaskTypeByCurrency(currency)].call(ref, min, max).mask(refExtended);
  },
  recipientId() {
    return new Inputmask({
      mask: ['INTuuuuuuuuTR'],
      jitMasking: true,
      definitions: {
        I: {
          validator: '[I]',
        },
        N: {
          validator: '[N]',
        },
        T: {
          validator: '[T]',
        },
        R: {
          validator: '[R]',
        },
        u: {
          validator: '[0-9A-Za-z]',
        },
      },
      onBeforeMask(value) {
        return value;
      },
      onUnMask(value) {
        return value;
      },
      onBeforePaste: handleBeforePaste.bind(this),
    });
  },
};

/**
 * @param {String} currency
 * @return {String}
 */
export function getMaskTypeByCurrency(currency) {
  return TON_LIKE_CURRENCIES_LIST.includes(currency)
    ? 'TONLike'
    : USDT_LIKE_CURRENCIES_LIST.includes(currency)
      ? 'USDTLike'
      : CRYPTO_CURRENCIES_LIST.includes(currency)
        ? CURRENCY_TYPE.CRYPTO
        : CURRENCY_TYPE.FIAT;
}

/**
 * Handler for inputmask (before paste)
 * @param {string} pasted
 * @return {number}
 * @this {inputmasks.crypto|inputmasks.fiat}
 */
function handleBeforePaste(pasted) {
  const listeners = this.$listeners || {};

  this.$nextTick && this.$nextTick(() => {
    const value = this.$el.querySelector('input').value;
    listeners.input(value);
  });

  return pasted;
}

/**
 * @param {string} currency
 * @return {string}
 */
export function getInputMaskNameByCurrency(currency) {
  return isFiat(currency) ? CURRENCY_TYPE.FIAT : isUSDTLike(currency) ? 'USDTLike' : CURRENCY_TYPE.CRYPTO;
}

export default inputmasks;
