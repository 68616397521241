<template>
  <div class="ex-incoming-addresses-list">
    <ex-list-content>
      <template #action>
        <div class="ex-incoming-addresses-list__actions">
          <div class="ex-incoming-addresses-list__switch">
            <div v-show="currencies.includes(CRYPTO_CURRENCIES.BTC)">
              <div class="ex-incoming-addresses-list__label">BTC Legacy Address</div>
              <el-switch v-model="showLegacyAddress" />
            </div>
          </div>
          <el-button @click="handleClearAllFilters">Reset filters</el-button>
        </div>
      </template>
      <el-table
        v-loading="isFetching"
        :data="addressesList"
        class="ex-incoming-addresses-list__content"
        stripe
        border
      >
        <el-table-column label="Timestamp" width="85">
          <template v-slot="{row}">
            {{utilTimeFormatOrMessage(row.createAt)}}
          </template>
        </el-table-column>
        <el-table-column label="Address">
          <template #default="{row}">
            {{row.address}}
            <ex-copy :value="row.address">
              <i class="el-icon-copy-document" />
            </ex-copy>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showLegacyAddress && currencies.includes(CRYPTO_CURRENCIES.BTC)"
          label="Legacy address"
        >
          <template #default="{row}">
            {{row.legacyAddress ? row.legacyAddress : '-'}}
            <ex-copy v-if="row.legacyAddress" :value="row.legacyAddress">
              <i class="el-icon-copy-document" />
            </ex-copy>
          </template>
        </el-table-column>
        <el-table-column label="Reference ID" prop="referenceId" />
        <el-table-column prop="currency" width="106">
          <template #header>
            <ex-table-remote-filter-checkbox
              label="Receive"
              :clearTrigger="clearTrigger"
              :filters="receiveOptions"
              @update="filterHandler('currency', $event)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="currencyLast" width="100">
          <template #header>
            <ex-table-remote-filter-checkbox
              label="Convert To"
              :clearTrigger="clearTrigger"
              :filters="convertOptions"
              @update="filterHandler('currencyLast', $event)"
            />
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template #header>
            <div class="ex-incoming-addresses-list__column_archive">
              <ex-switch-icon v-model="lockArchiveColumn" class="ex-incoming-addresses-list__lock_button" />
              <ex-table-remote-filter-radio
                label="Archive"
                :clearTrigger="clearTrigger"
                :filters="ARCHIVE_OPTIONS"
                @update="filterHandler('archive', $event)"
              />
            </div>
          </template>
          <template #default="{row}">
            <div class="ex-incoming-addresses-list__switch">
              <el-switch
                :value="row.archive"
                :disabled="lockArchiveColumn || !!processedAddress"
                @change="updateAddressArchiveStatus(row.id, $event)"
              />
              <div
                v-show="processedAddress === row.addressId"
                class="ex-incoming-addresses-list__switch-loader"
              >
                <i class="el-icon-loading " />
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <template #pagination v-if="pagination.totalPages > 1">
        <el-pagination
          class="ex-incoming-addresses-list__pagination"
          layout="prev, pager, next, jumper"
          :current-page="pagination.currentPage"
          :page-size="pagination.limitItems"
          :total="pagination.totalItems"
          @current-change="fetchAddressList($event)"
          @size-change="fetchAddressList(1, $event)"
          background
        />
      </template>
    </ex-list-content>
  </div>
</template>

<script>
// utils
import {utilTimeFormatOrMessage} from '@/utils/converters/time';
import {socketRequest} from '@/utils/socket';
import {removeEmptyFilters} from '@/utils/common';

// api
import TunnelApi from '@/api/tunnel.api';

// component
import ExListContent from '@/components/ex-list-content';
import ExCopy from '@/components/ex-copy';
import ExSwitchIcon from '@/components/ex-switch-icon';
import ExTableRemoteFilterRadio from '@/components/TableFilters/ex-table-remote-filter-radio';
import ExTableRemoteFilterCheckbox from '@/components/TableFilters/ex-table-remote-filter-checkbox';

// const
import {INIT_PAGINATION} from '@/constants/common';
import {CRYPTO_CURRENCIES} from '@/constants/currencies';
import {FETCH_INCOMING_ADDRESSES, UPDATE_ADDRESS_ARCHIVE_STATUS} from '@/constants/events/tunnel/actions.type';
const INIT_FILTERS = {
  currency: null,
  currencyLast: null,
  archive: null,
};
const ARCHIVE_OPTIONS = [
  {text: 'Active', value: false},
  {text: 'Disactive', value: true},
];

export default {
  name: 'ExIncomingAddressesList',
  components: {
    ExTableRemoteFilterCheckbox,
    ExTableRemoteFilterRadio,
    ExListContent,
    ExSwitchIcon,
    ExCopy,
  },
  props: {
    fetchTrigger: {
      type: Boolean,
      default: false,
      required: true,
    },
    currencies: {
      type: Array,
      default: () => [],
    },
    receiveOptions: {
      type: Array,
      default: () => [],
    },
    convertOptions: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.fetchAddressList(1);
  },
  data() {
    return {
      addressesList: [],
      isFetching: false,
      processedAddress: null,
      showLegacyAddress: false,
      lockArchiveColumn: true,
      clearTrigger: false,
      pagination: {...INIT_PAGINATION},
      filters: {...INIT_FILTERS},
      ARCHIVE_OPTIONS,
      CRYPTO_CURRENCIES,
    };
  },
  watch: {
    fetchTrigger(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchAddressList(1);
      }
    },
  },
  methods: {
    utilTimeFormatOrMessage,
    filterHandler(filter, value) {
      this.filters[filter] = value;
      this.fetchAddressList(1);
    },
    fetchAddressList(page = this.pagination.currentPage, limit = this.pagination.limitItems) {
      this.isFetching = true;
      socketRequest.call(this, FETCH_INCOMING_ADDRESSES,
        (payload) => {
          this.addressesList = payload.list;
          this.pagination = Object.assign({}, this.pagination, payload.pagination);
        },
        () => {},
        () => {
          this.isFetching = false;
        },
      );
      TunnelApi.fetchIncomingAddresses(
        FETCH_INCOMING_ADDRESSES,
        {page, limit, filters: removeEmptyFilters(this.filters)},
      );
    },
    updateAddressArchiveStatus(addressId, isArchived) {
      this.processedAddress = addressId;
      socketRequest.call(this, UPDATE_ADDRESS_ARCHIVE_STATUS,
        () => {
          let address = this.addressesList.find((item) => item.id === addressId);
          if (address) {
            address = Object.assign(address, {archive: isArchived});
          }
          if (this.filters.archive !== null) {
            if (this.addressesList.length === 1 && this.pagination.currentPage !== 1) {
              this.fetchAddressList(this.pagination.currentPage - 1);
            }
            this.fetchAddressList();
          }
        },
        () => {},
        () => {
          this.processedAddress = null;
        },
      );
      TunnelApi.updateAddressArchiveStatus(UPDATE_ADDRESS_ARCHIVE_STATUS, {addressId, isArchived});
    },
    handleClearAllFilters() {
      this.clearTrigger = !this.clearTrigger;
      this.filters = Object.assign({}, INIT_FILTERS);
      this.fetchAddressList(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-incoming-addresses-list {
  &__actions {
    display: flex;
    justify-content: space-between;
  }
  &__label {
    display: inline-block;
    padding-right: 12px;
    line-height: 36px;
    font-size: 14px;
    color: #606266;
  }
  &__switch {
    display: flex;
    align-items: center;
    &-loader {
      display: inline-block;
      font-size: 20px;
      padding-left: 6px;
    }
  }
  &__column_archive {
    padding: initial;
    display: flex;
    align-items: flex-start;
  }
  &__lock_button {
    margin-right: 3px;
  }
  &__pagination {
    padding: 0;
  }
  ::v-deep {
    .ex-copy {
      color: #67C23A;
      cursor: pointer;
      visibility: hidden;
      font-size: 24px;
      padding: 1px;
      font-weight: 500;
    }
    .el-table__body td {
      &:hover .ex-copy {
        visibility: visible;
      }
    }
  }
}
</style>
