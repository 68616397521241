<template>
  <div class="ex-ttc" :class="{'ex-ttc_close': !isOpen}">
    <div class="ex-ttc__left" />
    <div class="ex-ttc__right">
      <div class="ex-ttc__content">
        <div
          v-for="(row, key) in data"
          :key="key"
          class="ex-ttc__column"
          :class="{
            'ex-ttc__column_first': key === 0,
            'ex-ttc__column_last': (data.length - 1) === key,
          }"
        >
          <div
            v-for="(item, key) in row"
            :key="key"
            class="ex-ttc__item"
          >
            <div class="ex-ttc__label">{{item.label}}</div>
            <div v-if="item.href && item.value" style="display: flex;" class="ex-ttc__value">
              <div class="ex-ttc__hash">
                <a :href="item.href" target="_blank">{{item.value}}</a>
              </div>
              <ex-copy :value="item.value">
                <i class="el-icon-copy-document" />
              </ex-copy>
            </div>
            <div v-else class="ex-ttc__value">
              {{item.value}}
            </div>
          </div>
        </div>
      </div>
      <div class="ex-ttc__actions">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
// components
import ExCopy from '@/components/ex-copy';

export default {
  name: 'ExTransactionTableContent',
  components: {ExCopy},
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-ttc {
  padding: 22px 88px 22px 114px;
  max-height: 300px;
  display: flex;
  overflow: hidden;
  transition: max-height .3s linear, padding .3s linear;
  &_close {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    .ex-ttc__left {
      max-height: 0;
    }
    .ex-ttc__right {
      opacity: 0;
    }
  }
  &__right {
    opacity: 1;
    flex: 1;
    transition: opacity .3s linear .2s;
  }
  &__left {
    border-right: 4px solid #F3F3F3;
    width: 82px;
    max-height: 350px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: max-height .3s linear;
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__actions {
    padding-top: 22px;
  }
  &__column {
    display: flex;
    flex-direction: column;
  }
  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 22px;
    &_first {
      justify-content: flex-start;
      .ex-ttc__item-wrap {
        padding-left: 0;
      }
    }
    &_last {
      justify-content: flex-end;
      .ex-ttc__item-wrap {
        padding-left: 0;
        align-items: flex-end;
      }
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      padding-left: 32%;
    }
  }
  &__label {
    color: #8F8F8F;
  }
  &__value {
    font-size: 16px;
    color: #383838;
  }
  &__hash {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  ::v-deep {
    .ex-copy {
      color: #67C23A;
      cursor: pointer;
      padding: 1px;
      font-weight: 500;
      margin-left: 3px;
    }
  }
}
</style>
