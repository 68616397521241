<template>
  <div class="ex-deposit-success">
    <ex-deposit-template :auth-function="authFunction">
      <template #icon>
        <i class="ex-deposit-success__icon el-icon-circle-check" />
      </template>
      <template #title>
        Thank you!
      </template>
      Your balance will be added to your account once the transaction is confirmed.
    </ex-deposit-template>
  </div>
</template>

<script>
// component
import ExDepositTemplate from '@/pages/deposit/fiat/ex-deposit-template';

// api
import CardPayApi from '@/api/cardPay.api';

export default {
  name: 'ExDepositSuccess',
  components: {ExDepositTemplate},
  methods: {authFunction: CardPayApi.authXpate},
};
</script>

<style lang="scss" scoped>
.ex-deposit-success {
  &__icon {
    color: #67C23A;
  }
}
</style>
