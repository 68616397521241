<template>
  <div class="ex-recovery-validation">
    <ex-recovery-validation-link
      v-if="showValidation === 'EMAIL_LINK'"
      :email="recoveryProfile.email"
      @restartRecovery="$emit('restartRecovery')"
      @emailStageCompleted="emailStageCompleted"
    />
    <ex-recovery-validation-email
      v-if="showValidation === 'Email'"
      :email="recoveryProfile.email"
      @restartRecovery="$emit('restartRecovery')"
      @emailStageCompleted="emailStageCompleted"
    />
    <ex-recovery-validation-sms
      v-if="showValidation === 'SMS'"
      :email="recoveryProfile.email"
      @restartRecovery="$emit('restartRecovery')"
      @smsStageCompleted="smsStageCompleted"
    />
    <ex-recovery-validation-ga
      v-if="showValidation === 'GA'"
      :email="recoveryProfile.email"
      @restartRecovery="$emit('restartRecovery')"
      @gaStageCompleted="gaStageCompleted"
    />
  </div>
</template>

<script>
// components
import ExRecoveryValidationEmail from '@/pages/recoveryAccount/ex-recovery-validation-email';
import ExRecoveryValidationSms from '@/pages/recoveryAccount/ex-recovery-validation-sms';
import ExRecoveryValidationGa from '@/pages/recoveryAccount/ex-recovery-validation-ga';
import ExRecoveryValidationLink from '@/pages/recoveryAccount/ex-recovery-validation-link';

// util
import {checkExpectedKeys} from '@/utils/validatorPropObjects';

export default {
  name: 'ExRecoveryValidation',
  components: {
    ExRecoveryValidationLink,
    ExRecoveryValidationEmail,
    ExRecoveryValidationSms,
    ExRecoveryValidationGa,
  },
  props: {
    recoveryProfile: {
      type: Object,
      validator: (value) => checkExpectedKeys(['recoveryStage', 'verificationOptions', 'email'], value),
      required: true,
    },
  },
  data() {
    return {
      showValidation: '',
    };
  },
  created() {
    this.tryResetPassword();
  },
  methods: {
    emailStageCompleted() {
      this.$emit('emailStageCompleted');
      this.tryResetPassword();
    },
    smsStageCompleted() {
      this.$emit('smsStageCompleted');
      this.tryResetPassword();
    },
    gaStageCompleted() {
      this.$emit('gaStageCompleted');
      this.tryResetPassword();
    },
    tryResetPassword() {
      if ((this.$route.params.code && this.$route.params.token) // TODO CHECK
        && (this.recoveryProfile.verificationOptions.Email !== this.recoveryProfile.recoveryStage.Email)) {
        this.goEMAILLINKValidation();
        return;
      }
      if (this.recoveryProfile.verificationOptions.Email !== this.recoveryProfile.recoveryStage.Email) { // TODO CHECK
        this.goEMAILValidation();
        return;
      }
      if (this.recoveryProfile.verificationOptions.SMS !== this.recoveryProfile.recoveryStage.SMS) { // TODO CHECK
        this.goSMSValidation();
        return;
      }
      if (this.recoveryProfile.verificationOptions.GA !== this.recoveryProfile.recoveryStage.GA) { // TODO CHECK
        this.goGAValidation();
        return;
      }
      if ((this.recoveryProfile.verificationOptions.SMS === this.recoveryProfile.recoveryStage.SMS) // TODO CHECK
        && (this.recoveryProfile.verificationOptions.GA === this.recoveryProfile.recoveryStage.GA)
        && (this.recoveryProfile.verificationOptions.Email === this.recoveryProfile.recoveryStage.Email)) {
        this.$router.push({name: 'RecoveryNewPassword'});
      }
    },
    goEMAILValidation() {
      this.showValidation = 'Email';
    },
    goSMSValidation() {
      this.showValidation = 'SMS';
    },
    goGAValidation() {
      this.showValidation = 'GA';
    },
    goEMAILLINKValidation() {
      this.showValidation = 'EMAIL_LINK';
    },
  },
};
</script>
