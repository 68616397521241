import {SOMETHING_WENT_WRONG_MESSAGE} from '@/constants/commonMessage';

export default {
  51: {
    default: 'Mail format not valid',
  },
  53: {
    default: 'URL format not valid',
  },
  54: {
    address: 'Wallet address is invalid',
    default: SOMETHING_WENT_WRONG_MESSAGE,
  },
  55: {
    default: 'IP address format not valid',
  },
  56: {
    default: 'JSON format is not valid',
  },
  57: {
    default: 'Uuid format is not valid',
  },
  58: {
    default: 'This password was stolen in a data breach and cannot be used. Please use a different password',
  },
  101: {
    default: SOMETHING_WENT_WRONG_MESSAGE,
    hash: 'The price is outdated, request a new price',
  },
  301: {
    default: 'File is unreadable',
  },
  302: {
    default: 'File is not a valid image',
  },
  351: {
    default: 'This is an invalid Business Identification Code (BIC)',
  },
  352: {
    default: 'Unsupported card type or invalid card number',
  },
  353: {
    default: 'Invalid currency',
  },
  354: {
    default: 'Invalid card number',
  },
  355: {
    default: 'Invalid international bank account number (IBAN)',
  },
  402: {
    default: 'Invalid value received',
  },
  406: {
    default: 'Unexpected value received',
  },
  408: {
    email: 'This mail is already in use',
    phone: 'This phone is already in use',
    registrationNumber: 'This registration number is already in use',
    name: 'This company name is already in use',
    default: 'One of the entered values is already in use',
  },
};
