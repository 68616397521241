import eventBus from '@/utils/eventBus';
/**
 * Open external URL
 * @param {string} url
 * @param {string} target
 */
export function openExternalURL(url, target = '_self') {
  const win = window.open(url, target);
  if (win) {
    win.focus();
  } else {
    eventBus.$emit('notify', {type: 'error', message: 'Please allow popups for this website'});
  }
}
