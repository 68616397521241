// app
import Vue from 'vue';

// external package
import VueClipboard from 'vue-clipboard2';
import PortalVue from 'portal-vue';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';

// mixin
import NotificationsMixin from '@/mixins/NotificationMixin';

// util
import inputmasks from '@/utils/inputmasks';
import eventBus from '@/utils/eventBus';
import SocketErrorHandler from '@/utils/socketError/socketErrorHandler';

// config
import getSocketConfig from '@/config/socket';

/***/
function setUpMixin() {
  Vue.mixin(NotificationsMixin);
}

/***/
function setUpDirective() {
  Vue.directive('mask', {
    bind: function(el, binding) {
      const element = el.tagName === 'INPUT'
        ? el
        : el.getElementsByTagName('INPUT')[0];

      const inputmask = inputmasks[binding.arg] || function() {
        return {mask: () => {}};
      };
      inputmask().mask(element);
    },
  });
}

/***/
function setUpExternalPackage() {
  Vue.use(ElementUI, {size: 'medium', locale});
  Vue.use(VueClipboard);
  Vue.use(PortalVue);
}

/***/
function setUpPrototype() {
  Vue.prototype.EventBus = eventBus;
  Vue.prototype.parseDateObject = (dateObj) => { // TODO RESEARCH
    if (dateObj === null || dateObj === undefined) {
      return 'N/A';
    }
    return dateObj.date.split(' ')[0];
  };
}

/**
 * @param {VueRouter} router
 */
function setUpRoutesConfig(router) {
  router.beforeEach((to, from, next) => {
    // TODO RESEARCH
    if (!to.matched.length) {
      next('/error/404');
    }
    next();
  });
}

/**
 * @param {Object} store
 */
function setSocketConfig(store) {
  const vueSocketIO = getSocketConfig(store);
  Vue.use(vueSocketIO);
  new SocketErrorHandler(vueSocketIO);
}

/**
 * @param {Store} store
 * @param {VueRouter} router
 */
export default function(store, router) {
  setSocketConfig(store);
  setUpRoutesConfig(router);
  setUpExternalPackage();
  setUpDirective();
  setUpMixin();
  setUpPrototype();
}
