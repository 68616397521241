export default {
  251: 'Authorization time expired',
  252: 'Authorization type not found',
  253: 'Invalid code',
  254: 'The code is no longer valid',
  255: 'You have no companies',
  257: 'We can not send the code, the time has not yet expired',
  258: 'SMS code not verified',
  259: 'GA code not verified',
  260: 'You don\'t have GA',
  261: 'The user is blocked',
};
