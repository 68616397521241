import BaseApi from '@/api/base';

/**
 * Provides access to the transaction service
 */
export default new class TransactionApi extends BaseApi {
  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.dateStart
   * @param {number} data.dateEnd
   * @return {Promise<*>}
   */
  fetchCsvTransactionsReport(event, data) {
    return super.post(event, '/api/report/fetch-csv-transactions-report', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {Object} data.filters
   * @param {number} data.filters.dateStart
   * @param {number} data.filters.dateEnd
   * @param {string} data.filters.typeOrder
   * @return {Promise<*>}
   */
  fetchPdfTransactionsReport(event, data) {
    return super.post(event, '/api/report/fetch-pdf-transactions-report', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {Object} data.filters
   * @param {number} data.filters.dateEnd
   * @return {Promise<*>}
   */
  fetchPdfBalancesReport(event, data) {
    return super.post(event, '/api/report/fetch-pdf-balance-report', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.exchangeId
   * @return {Promise<*>}
   */
  fetchPdfExchangeReport(event, data) {
    return super.post(event, '/api/report/fetch-pdf-exchange-report', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.depositId
   * @return {Promise<*>}
   */
  fetchPdfDepositReport(event, data) {
    return super.post(event, '/api/report/fetch-pdf-deposit-report', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.withdrawId
   * @return {Promise<*>}
   */
  fetchPdfWithdrawalReport(event, data) {
    return super.post(event, '/api/report/fetch-pdf-withdrawal-report', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.autoWithdrawId
   * @return {Promise<*>}
   */
  fetchPdfWithdrawalAutoReport(event, data) {
    return super.post(event, '/api/report/fetch-pdf-withdrawal-auto-report', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.withdrawId
   * @return {Promise<*>}
   */
  fetchPdfWithdrawalInternalTransfer(event, data) {
    return super.post(event, '/api/report/fetch-pdf-withdrawal-internal-transfer', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.depositId
   * @return {Promise<*>}
   */
  fetchPdfDepositInternalTransfer(event, data) {
    return super.post(event, '/api/report/fetch-pdf-deposit-internal-transfer', {data});
  }
};
