<template>
  <ex-setting-template class="ex-setting-gdpr">
    <el-checkbox checked disabled class="ex-setting-gdpr__agreement">
      I have read and understood, and I agree with GDPR and Privacy Policy from
      {{profile.policyAdoptedAtString}}, version {{profile.policyVersion}}
    </el-checkbox>
    <ex-setting-template-row>
      <template #label>Revoke personal data</template>
      <template>
        <el-button :loading="isRevokePersonalData" @click="openRevokeMessage">Revoke</el-button>
      </template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>Request personal data</template>
      <template>
        <el-button :loading="isRequestPersonalData" @click="requestData">Request</el-button>
      </template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>Last date of request personal data</template>
      <template>{{profile.requestPersonalDateString}}</template>
    </ex-setting-template-row>
  </ex-setting-template>
</template>

<script>
// components
import ExSettingTemplate from '@/pages/setting/ex-setting-template';
import ExSettingTemplateRow from '@/pages/setting/ex-setting-template-row';

// api
import profileApi from '@/api/userService/profile.api';

// const
import {REQUEST_USER_PERSONAL_DATA, REVOKE_USER_PERSONAL_DATA} from '@/constants/events/currentUser/actions.type';

// utils
import {socketRequest} from '@/utils/socket';

export default {
  name: 'ExSettingGdpr',
  components: {ExSettingTemplateRow, ExSettingTemplate},
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isRequestPersonalData: false,
      isRevokePersonalData: false,
    };
  },
  computed: {},
  methods: {
    openRevokeMessage() {
      this.$confirm(
        'Are you sure you want to revoke your data? You will lose access to your account.',
        'Warning',
        {
          confirmButtonText: 'Ok',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
        this.revokeData();
      });
    },
    revokeData() {
      this.isRevokePersonalData = true;
      profileApi.revokeUserPersonalData(REVOKE_USER_PERSONAL_DATA);
      socketRequest.call(this, REVOKE_USER_PERSONAL_DATA,
        () => {
          this.EventBus.$emit('logout');
          return 'Revoke personal data is successful';
        },
        () => {},
        () => {
          this.isRevokePersonalData = false;
        },
      );
    },
    requestData() {
      this.isRequestPersonalData = true;
      profileApi.requestUserPersonalData(REQUEST_USER_PERSONAL_DATA);
      socketRequest.call(this, REQUEST_USER_PERSONAL_DATA,
        () => 'Request personal data is successful',
        () => {},
        () => {
          this.isRequestPersonalData = false;
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-setting-gdpr {
  &__agreement {
    font-size: 18px;
    margin: 10px 0 35px;
  }
}
::v-deep .el-checkbox__label {
  font-size: 16px;
  color: #888 !important;
}
::v-deep .el-checkbox__inner {
  width: 20px;
  height: 20px;
  &:after {
    content: "";
    border: 3px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 9px;
    left: 7px;
    top: 2px;
  }
}
</style>
