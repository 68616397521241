import BaseApi from '@/api/base';

/**
 * Provides access to the transaction service
 */
export default new class TransactionApi extends BaseApi {
  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.page
   * @param {number} data.limit
   * @param {number} data.filters
   * @return {Promise<*>}
   */
  fetchTransactionList(event, data) {
    return super.post(event, '/api/transactions/fetch-transactions', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @return {Promise<*>}
   */
  fetchFundingHistory(event, data) {
    return super.post(event, '/api/transactions/fetch-funding-history', {data});
  }

  /**
   * @param {string} event
   * @param {{
   * date: string,
   * balanceId: number,
   * }} data
   * @return {Promise<*>}
   */
  fetchBalanceHistory(event, data) {
    return super.post(event, '/api/transactions/fetch-balance-history', {data});
  }
};
