<template>
  <div class="ex-withdrawal-fiat-auto-list">
    <ex-list-content>
      <el-table :data="tableData" stripe v-loading="isLoading">
        <el-table-column min-width="115" label="Timestamp">
          <template #default="{row}">{{utilTimeFormatOrMessage(row.createAt)}}</template>
        </el-table-column>
        <el-table-column label="Currency">
          <template #default="{row}">{{row.currency}}</template>
        </el-table-column>
        <el-table-column label="Amount">
          <template #default="{row}">{{row.amount}}</template>
        </el-table-column>
        <el-table-column label="Status">
          <template #default="{row}">{{textStatus(row.transactionStatus)}}</template>
        </el-table-column>
      </el-table>
      <template v-if="pagination.totalPages > 1" #pagination>
        <el-pagination
          background
          style="padding: 0;"
          layout="prev, pager, next, jumper"
          :current-page="pagination.currentPage"
          :page-size="pagination.limitItems"
          :total="pagination.totalItems"
          @current-change="fetchList"
          @size-change="fetchList(1, $event)"
        />
      </template>
    </ex-list-content>
  </div>
</template>

<script>
// app
import {FETCH_FIAT_AUTO_WITHDRAWAL_LIST} from '@/constants/events/requests/actions.type';

// api
import RequestApi from '@/api/requestService/request.api';

// utils
import {socketRequest} from '@/utils/socket';
import {utilTimeFormatOrMessage} from '@/utils/converters/time';

// components
import ExListContent from '@/components/ex-list-content';

// constants
import {INIT_PAGINATION} from '@/constants/common';
import {SUBSCRIBE_FIAT_AUTO_WITHDRAWAL} from '@/constants/events/subscriptions.type';

export default {
  name: 'ExWithdrawalListFiatAuto',
  components: {ExListContent},
  data() {
    return {
      tableData: [],
      pagination: {...INIT_PAGINATION},
      isLoading: false,
    };
  },
  created() {
    this.fetchList();
    this.sockets.subscribe(SUBSCRIBE_FIAT_AUTO_WITHDRAWAL, (payload) => {
      this.handleUpdateItem(payload);
    });
  },
  destroyed() {
    this.sockets.unsubscribe(SUBSCRIBE_FIAT_AUTO_WITHDRAWAL);
  },
  methods: {
    handleUpdateItem(item) {
      const index = this.tableData.findIndex((element) => element.id === item.id);
      if (index !== -1) {
        this.$set(this.tableData, index, item);
      }
    },
    textStatus(status) {
      switch (status) {
        case 1: return 'New';
        case 2: return 'In progress';
        case 3: return 'Executed';
        case 4: return 'Canceled';
        case 5: return 'Reversed';
        case 6: return 'Canceled';
        default: return 'Undefined';
      }
    },
    fetchList(page) {
      this.isLoading = true;
      const data = {
        page: page || this.pagination.currentPage,
        limit: 10,
      };
      socketRequest.call(this, FETCH_FIAT_AUTO_WITHDRAWAL_LIST,
        (payload) => {
          this.tableData = payload.list;
          this.pagination = payload.pagination;
        },
        () => {},
        () => {
          this.isLoading = false;
        },
      );
      RequestApi.fetchFiatAutoWithdrawalList(FETCH_FIAT_AUTO_WITHDRAWAL_LIST, {data});
    },
    utilTimeFormatOrMessage,
  },
};
</script>
