export default {
  2: 'Tunell account not found',
  6: 'Currency not supported',
  13: 'Currency pair not supported',
  18: 'Wallet address is invalid',
  // 33: 'Amount must not exceed {limit} {currency}!', // local processing
  44: 'Reference ID is not unique',
  45: 'The amount must be greater than 0',
  47: 'Address status not defined',
  62: 'Rejected by the internal policy',
};
