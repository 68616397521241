<template>
  <div class="ex-deposit-failure">
    <ex-deposit-template :auth-function="authFunction">
      <template #icon>
        <i class="ex-deposit-failure__icon el-icon-info" />
      </template>
      <template #title>
        OOPS!
      </template>
      We are sorry, but there was an issue with your deposit.
      Please, try again another time
    </ex-deposit-template>
  </div>
</template>

<script>
// api
import CardPayApi from '@/api/cardPay.api';

// component
import ExDepositTemplate from '@/pages/deposit/fiat/ex-deposit-template';

export default {
  name: 'ExDepositFailure',
  components: {ExDepositTemplate},
  methods: {authFunction: CardPayApi.authXpate},
};
</script>

<style lang="scss" scoped>
.ex-deposit-failure {
  &__icon {
    color: #F56C6C;
  }
  &__reason {
    margin-bottom: 18px;
  }
}
</style>
