<template>
  <div id="app">
    <ex-splash-screen v-if="showSplashScreen" />
    <ex-layout v-else v-loading="!socketConnected" element-loading-text="Reconnection...">
      <template v-if="isNewLayout">
        <ex-navbar />
        <ex-two-columns>
          <router-view />
        </ex-two-columns>
      </template>
      <template v-else>
        <nav-bar :show-sidebar="!extendedContainerWrapper" :user="profile" />
        <div class="content-wrapper" :class="{extended: extendedContainerWrapper}">
          <ex-sidebar />
          <main class="main-container">
            <transition name="main-section-fade">
              <router-view />
            </transition>
          </main>
        </div>
      </template>
    </ex-layout>
  </div>
</template>

<style lang="scss">
  @import "./styles/vendor";
  @import "./styles/app";
  .el-button,
  .el-input__inner,
  .el-textarea__inner {
    font-family: "Roboto Condensed", sans-serif;
  }
</style>

<script>
// app
import {mapActions, mapGetters, mapState} from 'vuex';

// mixin
import EventBusMixin from '@/mixins/EventBusMixin';
import LogoutMixin from '@/mixins/LogoutMixin';

// component
import NavBar from '@/components/core/Navbar';
import ExNavbar from '@/components/ex-navbar';
import ExTwoColumns from '@/layouts/ex-two-column';
import ExSidebar from '@/components/ex-sidebar';
import ExSplashScreen from '@/components/core/ex-splash-screen';
import ExLayout from '@/components/core/ex-layout';

// setting
const ROUTE_NAMES_WITH_NEW_LAYOUT = [
  'LoginCredential',
  'LoginSelectType',
  'Login2FA',
  'RecoveryIdentification',
  'RecoveryValidation',
  'RecoveryNewPassword',
  'RecoveryByLink',
  'UserRegistration',
  'UserRegistrationByEmailLink',
];

export default {
  name: 'App',
  components: {
    ExSplashScreen,
    ExLayout,
    ExSidebar,
    ExTwoColumns,
    ExNavbar,
    NavBar,
  },
  mixins: [LogoutMixin, EventBusMixin],
  computed: {
    ...mapState('auth', ['isLoggedIn']),
    ...mapState('appState', ['socketConnected']),
    ...mapGetters('currentUser', ['userIsReady']),
    ...mapState('currentUser', {
      hasUserRoles: (state) => state.meta.isReceived.roles,
      hasUserProfile: (state) => state.meta.isReceived.profile,
      profile: (state) => state.profile,
    }),
    showSplashScreen() {
      return this.isLoggedIn && !this.userIsReady;
    },
    extendedContainerWrapper() {
      const extendedContainerWrapperRoutes = [
        'Home',
        'ErrorPage',
        'DepositSuccess',
        'DepositFailure',
        ...ROUTE_NAMES_WITH_NEW_LAYOUT,
      ];
      return extendedContainerWrapperRoutes.indexOf(this.$route.name) !== -1;
    },
    isNewLayout() {
      return ROUTE_NAMES_WITH_NEW_LAYOUT.includes(this.$route.name);
    },
  },
  watch: {
    isLoggedIn(v) {
      if (v) this.fetchPrimaryUserData();
    },
  },
  created() {
    this.fetchBasicContent();
    if (this.isLoggedIn) {
      this.fetchPrimaryUserData();
      this.refreshAuthToken();
    }
  },
  methods: {
    ...mapActions('auth', ['refreshAuthToken', 'logout']),
    ...mapActions('currentUser', ['fetchPrimaryUserData']),
    ...mapActions('content', ['fetchBasicContent']),
  },
};
</script>
