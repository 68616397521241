<template>
  <div class="ex-login-2fa-ga">
    <ex-one-input-form
      :message="message"
      sub-title="Please, use Google Authenticator app on your mobile device for finish authorization"
      label="Code from Google Authenticator"
      placeholder="Type code from GA"
      action-label="Confirm"
      @click="verifyCode"
    />
  </div>
</template>

<script>
// api
import AuthorizationApi from '@/api/authorization.api';

// component
import ExOneInputForm from '@/components/authorization/layout/ex-one-input-form';

// util
import {socketRequest} from '@/utils/socket';
import {checkExpectedKeys} from '@/utils/validatorPropObjects';

// const
import {REQUEST_GA_CODE_LOGIN, VERIFY_GA_CODE_LOGIN} from '@/constants/events/login/actions.type';
import {CODE_AUTH_SESSION_TIME_LIVED} from '@/constants/common';

export default {
  name: 'ExLogin2faGa',
  components: {ExOneInputForm},
  props: {
    authorizationProfile: {
      type: Object,
      required: true,
      validator: (authorizationProfile) => checkExpectedKeys(['userId'], authorizationProfile.external)
        && checkExpectedKeys(['selectedAccount'], authorizationProfile),
    },
  },
  data() {
    return {
      message: '',
    };
  },
  created() {
    this.requestGaCode();
  },
  methods: {
    requestGaCode() {
      socketRequest.call(this, REQUEST_GA_CODE_LOGIN,
        () => {},
        (payload) => {
          if (payload.error.code === CODE_AUTH_SESSION_TIME_LIVED) {
            this.$emit('resetLogin', payload.handledErrorMessage);
          } else if (payload.handledErrorMessage) {
            this.message = payload.handledErrorMessage;
          }
        },
        () => {
          this.isLoading = false;
        },
      );
      this.isLoading = true;
      const userId = this.authorizationProfile.external.userId;
      const organizationId = this.authorizationProfile.selectedAccount;
      AuthorizationApi.requestCode(REQUEST_GA_CODE_LOGIN, {
        authType: 'GA',
        userId,
        organizationId: userId !== organizationId ? organizationId : undefined,
      });
    },
    verifyCode(code) {
      socketRequest.call(this, VERIFY_GA_CODE_LOGIN,
        () => {
          this.$emit('gaStageCompleted');
        },
        (payload) => {
          if (payload.error.code === CODE_AUTH_SESSION_TIME_LIVED) {
            this.$emit('resetLogin', payload.handledErrorMessage);
          } else if (payload.handledErrorMessage) {
            this.message = payload.handledErrorMessage;
          }
        },
        () => {
          this.isLoading = false;
        },
      );
      this.message = '';
      this.isLoading = true;
      AuthorizationApi.verifyCode(VERIFY_GA_CODE_LOGIN, {
        code,
        authType: 'GA',
        userId: this.authorizationProfile.external.userId,
      });
    },
  },
};
</script>

