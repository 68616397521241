<template>
  <div v-loading="isLoading" class="ex-recovery-identification">
    <ex-one-input-form
      :message="message"
      sub-title="Reset password"
      label="Your email"
      placeholder="Type your email"
      action-label="Continue"
      :additional-rules="[{type: 'email', message: 'Please, enter a valid email address', trigger: ['blur', 'change']}]"
      @click="initRecovery"
    />
    <div class="ex-recovery-identification__return">
      <ex-link to="LoginCredential" color="primary">
        Return to login
      </ex-link>
    </div>
  </div>
</template>

<script>
// component
import ExOneInputForm from '@/components/authorization/layout/ex-one-input-form';
import ExLink from '@/components/authorization/ui/ex-link';

// api
import RecoveryApi from '@/api/userService/recovery.api';

// util
import {typicalPostReqSocketRes} from '@/utils/socket';

// const
import {RECOVERY_CODE_NOT_EXPIRE} from '@/constants/common';

export default {
  name: 'ExRecoveryIdentification',
  components: {ExOneInputForm, ExLink},
  data() {
    return {
      message: '',
      isLoading: false,
    };
  },
  methods: {
    async initRecovery(email) {
      try {
        this.isLoading = true;
        this.message = '';
        this.$emit('setRecoverySetting', await typicalPostReqSocketRes({
          context: this,
          event: 'init:recovery-acc',
          request: RecoveryApi.initRecoveryAccess,
          data: {email},
          localErrorHandling: true,
        }));
        this.$emit('saveEmail', email);
        await this.$router.push({name: 'RecoveryValidation'});
      } catch (e) {
        if (e.error?.code === RECOVERY_CODE_NOT_EXPIRE) return this.$router.push({name: 'RecoveryValidation'});
        this.message = e.errorMessage;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-recovery-identification {
  &__return {
    text-align: center;
    font-family: "Roboto", sans-serif;
  }
}
</style>
