<template>
  <div class="ex-transaction-in-process">
    <div class="ex-transaction-in-process__action">
      <el-button
        :type="tableDisabled ? 'info' : 'warning'"
        style="width: 100%"
        @click="isShow = !isShow"
        :disabled="tableDisabled"
        :loading="isFetching"
      >
        You have {{orderCounter}} order(s) pending.
        {{hintText}}
      </el-button>
    </div>
    <transition name="slide-fade">
      <div v-if="isShow" class="ex-transaction-in-process__table-section">
        <el-table
        v-loading="isFetching"
        class="ex-transaction-in-process__table"
        :data="transactionList"
        stripe
      >
        <el-table-column label="Timestamp">
          <template v-slot="{row}">
            {{utilTimeFormatOrMessage(row.createAt)}}
          </template>
        </el-table-column>
        <el-table-column label="Type">
          <template v-slot="{row}">
            {{getLabelForRequest(row.requestName)}}
          </template>
        </el-table-column>
        <el-table-column label="Currency" prop="currency" />
        <el-table-column label="Amount">
          <template v-slot="{row}">
            {{getSignAmountForRequest(row.requestName, row.amount)}}
          </template>
        </el-table-column>
        <el-table-column label="Status" width="70">
          <template>Pending</template>
        </el-table-column>
      </el-table>
      </div>
    </transition>
  </div>
</template>

<script>
// api
import RequestApi from '@/api/requestService/request.api';

// const
import {FETCH_REQUEST_LIST_IN_PROGRESS} from '@/constants/events/requests/actions.type';

// utils
import {socketRequest} from '@/utils/socket';
import {utilTimeFormatOrMessage} from '@/utils/converters/time';
import {formatCurrency} from '@/utils/converters/currency';
import {numberSimbolsAfterComma} from '@/utils/common';

export default {
  name: 'ExTransactionInProgress',
  data() {
    return {
      transactionList: [],
      isFetching: false,
      isShow: false,
    };
  },
  computed: {
    orderCounter() {
      return this.transactionList?.length ? this.transactionList.length : 0;
    },
    tableDisabled() {
      return this.orderCounter <= 0;
    },
    hintText() {
      if (this.tableDisabled) return '';
      if (this.isShow) return 'Click to hide';
      return 'Click to show';
    },
  },
  created() {
    this.fetchRequestList();
  },
  methods: {
    utilTimeFormatOrMessage,
    fetchRequestList() {
      this.isFetching = true;
      socketRequest.call(this, FETCH_REQUEST_LIST_IN_PROGRESS,
        (payload) => {
          this.transactionList = payload;
        },
        () => {},
        () => {
          this.isFetching = false;
        },
      );
      RequestApi.fetchWithdrawalListInProgress(FETCH_REQUEST_LIST_IN_PROGRESS);
    },
    getLabelForRequest(requestName) {
      switch (requestName) {
        case 'Withdraw': return 'Manual Withdraw';
        case 'WithdrawAuto':
        case 'FiatAutoWithdraw': return 'Auto Withdraw';
        case 'Deposit': return 'Deposit';
        default: return '';
      }
    },
    getSignAmountForRequest(requestName, amount) {
      const precision = numberSimbolsAfterComma(amount) <= 8 ? numberSimbolsAfterComma(amount) : 8;
      switch (requestName) {
        case 'Withdraw':
        case 'WithdrawAuto':
        case 'FiatAutoWithdraw': return `- ${formatCurrency(amount, '', precision)}`;
        case 'Deposit': return `+ ${formatCurrency(amount, '', precision)}`;
        default: return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-transaction-in-process {
  &__table-section, &__table {
    margin-bottom: 16px;
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
}
</style>
