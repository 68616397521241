<template>
  <div class="ex-coin-list">
    <vue-slick-carousel ref="carousel" v-if="list.length" v-bind="settings">
      <div class="ex-coin-list__item" v-for="(item, key) in list" :key="key">
        <ex-coin-card
          :is-highlight="item.currency === highlight"
          :coin="item.currency"
          :value="item.amount"
          :is-active="item.currency === value"
          @click="$emit('input', item)"
        />
      </div>
    </vue-slick-carousel>
  </div>
</template>

<script>
// component
import ExCoinCard from '@/components/ex-coin-card';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  name: 'ExCoinList',
  components: {ExCoinCard, VueSlickCarousel},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      required: true,
    },
    highlight: {
      type: String,
    },
  },
  computed: {
    slideToShow() {
      return this.list.length;
    },
    settings() {
      return {
        variableWidth: true,
        centerMode: true,
        centerPadding: '0px',
        infinite: false,
        speed: 500,
        slidesToShow: this.slideToShow,
        slidesToScroll: this.slideToShow,
        responsive: [
          {
            breakpoint: 825,
            settings: {
              slidesToShow: this.slideToShow,
              slidesToScroll: this.slideToShow,
              infinite: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 660,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    },
  },
  watch: {
    list(value) {
      const index = value.findIndex((item) => item.currency === this.value);
      if (value.length) {
        this.$nextTick(() => {
          this.$refs.carousel.goTo(index);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-coin-list {
  ::v-deep {
    .slick-slide {
      padding: 5px;
    }
  }
}
</style>
