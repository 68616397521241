<template>
  <div class="ex-countdown"><slot v-bind:prop="prop">{{prop.time}}</slot></div>
</template>

<script>
import {checkExpectedKeys} from '@/utils/validatorPropObjects';

const MAX_STATE_PROGRESS_BAR = 100;
const EVENT_RATE = 100;

export default {
  name: 'ExCountdown',
  props: {
    timeData: {
      type: Object,
      validator(value) {
        return checkExpectedKeys(['from', 'to'], value);
      },
    },
    hasStop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      prop: {
        time: '00:00',
        percentage: 0,
      },
      worker: null,
    };
  },
  destroyed() {
    if (this.worker !== null) {
      this.worker.terminate();
    }
  },
  computed: {
    stepSize() {
      if (this.timeData.to - Date.parse(new Date()) > 0) {
        return MAX_STATE_PROGRESS_BAR / (this.timeData.to - this.timeData.from) * EVENT_RATE;
      }

      return 0;
    },
  },
  watch: {
    timeData: {
      handler() {
        if (!this.hasStop) {
          this.initStart();
        }
      },
      immediate: true,
      deep: true,
    },
    hasStop: {
      handler(value) {
        if (value) {
          this.initStop();
        } else {
          this.initStart();
        }
      },
      immediate: true,
    },
  },
  methods: {
    startCountdown() {
      const workerCode =
        `self.onmessage = (event) => {
          setInterval(() => postMessage(null), ${EVENT_RATE});
        }`;

      const URL = window.URL || window.webkitURL;
      const blob = new Blob(
          [workerCode],
          {type: 'application/javascript'},
      );
      const objectURL = URL.createObjectURL(blob);
      this.worker = new Worker(objectURL);

      this.worker.onmessage = (event) => this.getTimeRemaining(event);

      this.worker.postMessage(null);
    },
    getTimeRemaining() {
      const total = this.timeData.to - Date.parse(new Date());

      if (total <= 0) {
        this.$emit('timeout');
        this.initStop();
      } else {
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.trunc(total / 1000 / 60 / 60);
        this.prop.time = hours > 0
          ? `${hours}:${this.addLeadZero(minutes)}:${this.addLeadZero(seconds)}`
          : `${this.addLeadZero(minutes)}:${this.addLeadZero(seconds)}`;

        if (MAX_STATE_PROGRESS_BAR - (this.prop.percentage + this.stepSize) > 0) {
          this.prop.percentage += this.stepSize;
        } else {
          this.prop.percentage = MAX_STATE_PROGRESS_BAR;
        }
      }
    },
    addLeadZero(value) {
      return ('0' + value).slice(-2);
    },
    prepareProgressBar() {
      const timeLeft = this.timeData.to - Date.parse(new Date());
      const totalTimeLeft = this.timeData.to - this.timeData.from;

      const percentage = Math.floor(MAX_STATE_PROGRESS_BAR - (timeLeft)/(totalTimeLeft) * 100);
      this.prop.percentage = percentage > 0 ? percentage : 0;
    },
    initStart() {
      if (this.worker !== null) {
        this.worker.terminate();
      }

      if (this.timeData.to - Date.parse(new Date()) > 0) {
        this.startCountdown();
        this.prepareProgressBar();
      } else {
        this.initStop();
      }
    },
    initStop() {
      if (this.worker !== null) {
        this.worker.terminate();
      }
      this.prop.percentage = MAX_STATE_PROGRESS_BAR;
      this.prop.time = '00:00';
    },
  },
};
</script>
