// util
import {socketRequest} from '@/utils/socket';

// const
import {CANCEL_EXCHANGE_ORDER} from '@/constants/events/exchange/actions.type';

// api
import ExchangeApi from '@/api/requestService/exchange.api';

const ExchangeMixin = {
  data() {
    return {
      mix_exchangeMixin: {
        isCanceling: false,
      },
    };
  },
  methods: {
    mix_exchangeMixin_cancelExchange(exchangeId, successCb = () => {}) {
      socketRequest.call(this, CANCEL_EXCHANGE_ORDER,
        () => {
          successCb(); // TODO DELETE AFTER SUBSCRIPTION
          return 'Request canceled';
        },
        () => {},
        () => {
          this.mix_exchangeMixin.isCanceling = false;
        },
      );
      this.mix_exchangeMixin.isCanceling = true;
      return ExchangeApi.cancelExchangeOrder(CANCEL_EXCHANGE_ORDER, {exchangeId});
    },
  },
};

export default ExchangeMixin;
