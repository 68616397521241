<template>
  <nav class="header">
    <div class="header__logo-flex">
      <div class="header__logo-container">
        <router-link :to="{name: 'BalancesIndex'}" class="links-container__item">
          <img src="/img/logo-navbar.svg" alt="logo" class="header__logo header__logo-l" />
        </router-link>
        <ex-clock />
      </div>
      <div class="header__toolbar_mobile">
        <div class="header__toolbar-title">
          <portal-target name="toolbar__title" />
        </div>
      </div>
    </div>
    <el-alert
      v-if="isFreezeAcc"
      :closable="false"
      :title="
        `Your account has been frozen. For more information,
        please check your email for previous inquiries from ${brand} Digital or contact us at ${email}`
      "
      type="error"
      class="header__frozen-alert"
    />
    <div class="header__links-container">
      <template v-if="checkAuthExpire">
        <el-dropdown trigger="click">
          <div class="el-dropdown-link header__menu-link">
            <i class="fa fa-user-circle" aria-hidden="true" />
            <i class="el-icon-arrow-down el-icon--right" />
          </div>
          <el-dropdown-menu>
            <el-dropdown-item v-if="authorizedLabel" class="header__menu-item header__menu-item-disabled">
              <div>You are logged in as</div>
              <div class="header__user-name">{{authorizedLabel}}</div>
            </el-dropdown-item>
            <el-dropdown-item
              @click.native="$router.push({name: 'route-user-profile'})"
              icon="el-icon-setting"
              class="header__menu-item"
            >
              Settings
            </el-dropdown-item>
            <el-dropdown-item
              @click.native="EventBus.$emit('logout')"
              icon="el-icon-switch-button"
              class="header__menu-item"
            >
              Logout
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </div>
    <div class="header__toolbar header__toolbar_desktop">
      <div class="content-wrapper">
        <div class="sidebar-container"></div>
        <div class="main-container header__toolbar-title">
          <portal-target name="toolbar__title" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// app
import {mapGetters, mapState} from 'vuex';

// component
import ExClock from '@/components/core/ex-clock';

export default {
  name: 'NavBar',
  components: {ExClock},
  props: {
    showSidebar: Boolean,
    user: Object,
  },
  computed: {
    ...mapState('auth', ['authExpire']),
    ...mapGetters('currentUser', ['isFreezeAcc']),
    ...mapState('content', {brand: (state) => state.content.brand, email: (state) => state.content.supportEmail}),
    authorizedLabel() {
      if (this.user == null) return '';
      if (this.user.organizationName != null) return this.user.organizationName;
      if (this.user.username != null) return `${ this.user.username } ${ this.user.lastName }`;
      return '';
    },
    checkAuthExpire() {
      const now = Date.now();
      if (this.authExpire === undefined) return false;
      const toExpire = this.authExpire - now;
      return toExpire > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  &__user-name {
    font-weight: 600;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__menu-link {
    white-space: nowrap;
    > i {
      color: #ff9500;
      &:first-child {
        font-size: 20px;
      }
      &:last-child {
        font-size: 18px;
      }
    }
  }
  &__menu-item {
    font-size: 16px !important;
    line-height: 1.5 !important;
    padding: 10px 22px !important;
    transition: background 0.3s;
  }
  &__menu-item-disabled {
    pointer-events: none;
  }
  &__logo {
    height: 33px !important;
  }
  &__logo-flex {
    display: flex;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
  &__frozen-alert {
    flex: 0 0 50%;
    max-width: 350px;
    margin: 0 20px;
  }
  &__logo-container {
    padding-right: 36px;
  }
  &__logo-container,
  &__links-container {
    z-index: 2;
    .el-dropdown {
      cursor: pointer;
    }
  }
  &__toolbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    &_desktop {
      @media (max-width: 1280.98px) {
        display: none;
      }
      @media (min-width: 1281px) {
        display: block;
      }
    }
    &_mobile {
      @media (max-width: 1280px) {
        display: block;
      }
      @media (min-width: 1281px) {
        display: none;
      }
    }
    &-title {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 23px;
      font-weight: bold;
      color: #606266;
      @media (max-width: 1280px) {
        padding-left: 1.5rem;
      }
      @media (max-width: 600px) {
        padding-left: 0;
        margin-top: 15px;
      }
    }
  }
}
.content-wrapper {
  height: 100%;
}
</style>
