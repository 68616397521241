<template>
  <div class="ex-fiat-dra">
    <div class="ex-fiat-dra__form">
      <el-form :model="form" :rules="rules" v-loading="isFetching" ref="form" label-position="top">
        <el-form-item prop="currency" label="Currency">
          <ex-coin-list
            class="ex-fiat-dra__coin-list"
            :list="currencyList"
            :value="form.currency"
            @input="form.currency = $event.currency"
          />
        </el-form-item>
        <el-form-item prop="amount" :label="`Amount of ${form.currency}`">
          <el-input v-model="form.amount" v-mask:fiat/>
        </el-form-item>
        <el-form-item prop="requestDate" label="Request date">
          <el-date-picker v-model="form.requestDate" type="datetime"/>
        </el-form-item>
        <el-form-item prop="bankId" label="Bank to">
          <el-select v-model="form.bankId">
            <el-option v-for="item in bankList" :key="item.key" :label="item.value" :value="item.key" />
          </el-select>
        </el-form-item>
        <el-form-item label="Account" prop="paymentAccountId">
          <el-select v-model="form.paymentAccountId">
            <el-option v-for="item in accountList" :key="item.accountId" :label="item.IBAN" :value="item.accountId" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="ex-fiat-dra__actions">
      <el-button type="danger" @click="$router.push({name: 'BalancesIndex'})">Cancel</el-button>
      <el-button :loading="isLoading" @click="handleCreate(DESTINATION_REQUEST.DEFAULT)">Generate PDF</el-button>
      <el-button :loading="isLoading" @click="handleCreate(DESTINATION_REQUEST.CLIENT)">Send to me</el-button>
    </div>
  </div>
</template>

<script>
// lib
import moment from 'moment';

// api
import DRAApi from '@/api/dra.api';

// const
import {USER_TYPE} from '@/constants/common';

// util
import {filterCoinByList} from '@/utils/filters';
import {numberFromLocaleString} from '@/utils/converters/currency';
import {ruleRequired} from '@/utils/elementUITypicalValidation';
import {openExternalURL} from '@/utils/externalUrl';
import {typicalPostReqSocketRes} from '@/utils/socket';

// component
import ExCoinList from '@/components/ex-coin-list';

// setting
const DESTINATION_REQUEST = {
  DEFAULT: 0,
  CLIENT: 1,
};

export default {
  name: 'ExFiatDRA',
  components: {ExCoinList},
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: {
        Account: null,
        Bank: null,
        Currency: null,
      },
      form: {
        bankId: '',
        paymentAccountId: '',
        currency: '',
        requestDate: '',
        amount: '',
      },
      rules: {
        bankId: [ruleRequired],
        paymentAccountId: [ruleRequired],
        currency: [ruleRequired],
        requestDate: [ruleRequired],
        amount: [ruleRequired],
      },
      isFetching: true,
      isLoading: false,
      DESTINATION_REQUEST,
    };
  },
  computed: {
    userType() {
      return this.userProfile.organizationId ? USER_TYPE.COMPANY : USER_TYPE.PERSON;
    },
    hasData() {
      return (this.data?.Bank || this.data?.Currency || this.data?.Account);
    },
    currencyList() {
      const availableCurrency = this.data?.Currency ? Object.keys(this.data.Currency) : [];
      return filterCoinByList(this.userProfile.balance, availableCurrency);
    },
    bankList() {
      if (!this.form.currency || !this.hasData) return [];

      const bankIds = this.data.Currency[this.form.currency] || [];
      const uniqueBankIds = _.union(bankIds);

      if (uniqueBankIds.length === 0) return [];

      return uniqueBankIds.map((key) => {
        return {key, value: this.data.Bank[key]};
      });
    },
    accountList() {
      if (!this.form.currency || !this.form.bankId || !this.hasData) return [];
      return this.data.Account[this.form.bankId][this.form.currency];
    },
    urlCurrency() {
      return this.$route.params.currency;
    },
  },
  created() {
    this.fetchBanks();
  },
  watch: {
    'form.currency'(value) {
      this.form.bankId = '';
      this.form.paymentAccountId = '';
      if (value !== this.urlCurrency) this.$emit('change-currency', value);
    },
    currencyList: {
      handler(value) {
        if (value.find((item) => item.currency === this.urlCurrency)) {
          this.form.currency = this.urlCurrency;
        }
      },
      immediate: true,
    },
    bankList(value) {
      if (value.length === 1) {
        this.form.paymentAccountId = '';
        this.form.bankId = value[0].key;
      }
    },
    accountList(value) {
      if (value.length === 1) {
        this.form.paymentAccountId = value[0].accountId;
      }
    },
  },
  methods: {
    async fetchBanks() {
      try {
        this.isFetching = true;
        this.data = await typicalPostReqSocketRes({
          context: this,
          event: 'fetch:bank-list',
          request: DRAApi.fetchBanks,
        });
      } catch (e) {/* empty */} finally {
        this.isFetching = false;
      }
    },
    handleCreate(sendTo = DESTINATION_REQUEST.DEFAULT) {
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;
        this.createDRA(this.getPrepareData(sendTo));
      });
    },
    async createDRA(data) {
      try {
        this.isLoading = true;
        const {url} = await typicalPostReqSocketRes({
          context: this,
          event: 'create:dra',
          request: DRAApi.createDepositRequest,
          data,
        });
        if (url) {
          this.successMsg('Deposit Request Acknowledgement PDF file successfully generated');
          openExternalURL(url);
        } else {
          this.successMsg('Deposit Request Acknowledgement PDF file generated and sent to your email');
        }
        const currency = this.urlCurrency;
        this.$refs.form.resetFields();
        this.form.currency = currency;
      } catch (e) {/* empty */} finally {
        this.isLoading = false;
      }
    },
    getPrepareData(sendTo) {
      const data = Object.assign({}, this.form);
      data.amount = numberFromLocaleString(data.amount);
      data.requestDate = moment(this.form.requestDate).format('YYYY-MM-DD HH:mm:ss');
      data.emailSendTo = sendTo;
      return data;
    },
  },
};
</script>
