// util
import {AuthSessionStorage} from '@/utils/web-storage/session';
import {hasPayloadError} from '@/utils/error';
import eventBus from '@/utils/eventBus';

// const
import {getWSA} from '@/constants/events/actions.type';
import {REFRESH_TOKEN} from '@/constants/events/store/auth/actions.type';

// api
import AuthApi from '@/api/auth';

const authSessionData = AuthSessionStorage.auth;

const initState = {
  token: null,
  refresh: null,
  accessId: null,
  authExpire: null,
  isLoggedIn: false,
};

const state = {...initState, ...authSessionData};

const getters = {
  isCompany(state) {
    const index = state.accessId.indexOf(':');
    return index > 0;
  },
};

const mutations = {
  reset(state) {
    Object.keys(initState).forEach((key) => {
      state[key] = initState[key];
    });
    sessionStorage.clear();
  },
  [REFRESH_TOKEN](state, {token, refresh, expire}) {
    state.token = token;
    state.refresh = refresh;
    state.authExpire = expire * 1000;
    AuthSessionStorage.auth = state;
  },
  login(state, {accessId}) {
    state.accessId = accessId;
    state.isLoggedIn = true;
    AuthSessionStorage.auth = state;
  },
  logout(state) {
    state = Object.assign(state, initState);
    sessionStorage.clear();
  },
};

let timerId = null;

const actions = {
  refreshAuthToken({state}) {
    if (state.isLoggedIn) {
      return AuthApi.refreshToken(REFRESH_TOKEN, {accessId: state.accessId, refreshToken: state.refresh});
    }
  },
  login({state, commit, dispatch}, payload) {
    commit(REFRESH_TOKEN, payload);
    commit('login', payload);
    dispatch('refreshTokenCycle', payload.expire * 1000);
    dispatch('currentUser/fetchPrimaryUserData', null, {root: true});
  },
  logout({commit}) {
    commit('appState/reset', null, {root: true});
    commit('reset');
    commit('content/reset', null, {root: true});
    commit('currentUser/reset', null, {root: true});
    commit('exchange/reset', null, {root: true});
    // commit('merchantInvoice/reset', null, {root: true}); // TODO
    commit('requests/reset', null, {root: true});
  },
  [getWSA(REFRESH_TOKEN)]({state, commit, dispatch}, payload) {
    if (hasPayloadError(payload)) return eventBus.$emit('logout');
    commit(REFRESH_TOKEN, payload);
    dispatch('refreshTokenCycle', payload.expire * 1000);
  },
  refreshTokenCycle({dispatch}, authExpire, lead = 5000) {
    if (!authExpire) return;
    const delay = authExpire - Date.now() - lead;
    if (delay > 0 && state.isLoggedIn) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        dispatch('refreshAuthToken');
      }, delay);
    }
  },
};

export default {namespaced: true, state, getters, mutations, actions};
