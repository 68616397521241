import { render, staticRenderFns } from "./ex-table-remote-filter-radio.vue?vue&type=template&id=64f9a840&scoped=true"
import script from "./ex-table-remote-filter-radio.vue?vue&type=script&lang=js"
export * from "./ex-table-remote-filter-radio.vue?vue&type=script&lang=js"
import style0 from "./ex-table-remote-filter-radio.vue?vue&type=style&index=0&id=64f9a840&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f9a840",
  null
  
)

export default component.exports