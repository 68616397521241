<template>
  <div class="ex-recovery">
    <transition name="fade">
      <router-view
        :recovery-profile="recoveryProfile"
        @resetRecovery="resetRecovery"
        @restartRecovery="restartRecovery"
        @setRecoverySetting="setRecoverySetting"
        @saveEmail="saveEmail"
        @emailStageCompleted="saveEmailStage"
        @smsStageCompleted="saveSMSStage"
        @gaStageCompleted="saveGAStage"
      />
    </transition>
  </div>
</template>

<script>
// utils
import {RecoveryStorage} from '@/utils/web-storage/recovery';

// settings
const defaultValidationSettings = {Email: false, SMS: false, GA: false};
const defaultValidationProfile = {
  recoveryStage: RecoveryStorage.recoveryStage,
  verificationOptions: RecoveryStorage.verificationOptions,
  email: RecoveryStorage.email,
  emailExpire: RecoveryStorage.emailExpire > Date.now() ? RecoveryStorage.emailExpire : 0,
};

export default {
  name: 'ExRecovery',
  data() {
    return {
      recoveryProfile: {
        recoveryStage: defaultValidationSettings,
        ...defaultValidationProfile,
      },
    };
  },
  beforeDestroy() {
    RecoveryStorage.recoveryStage = undefined;
    RecoveryStorage.verificationOptions = undefined;
    RecoveryStorage.email = undefined;
    RecoveryStorage.emailExpire = undefined;
  },
  methods: {
    resetRecovery() {
      this.recoveryProfile.recoveryStage = defaultValidationSettings;
      RecoveryStorage.recoveryStage = defaultValidationSettings;
      this.recoveryProfile.verificationOptions = undefined;
      RecoveryStorage.verificationOptions = undefined;
    },
    setRecoverySetting(data) {
      this.recoveryProfile.recoveryStage = {...defaultValidationSettings};
      RecoveryStorage.recoveryStage = {...defaultValidationSettings};
      this.recoveryProfile.verificationOptions =
        Object.assign({}, defaultValidationProfile.verificationOptions, data.validation);
      RecoveryStorage.verificationOptions = this.recoveryProfile.verificationOptions;
      this.recoveryProfile.emailExpire = (data.expire + data.timestamp) * 1000;
      RecoveryStorage.emailExpire = this.recoveryProfile.emailExpire;
    },
    saveEmail(email) {
      this.recoveryProfile.email = email;
      RecoveryStorage.email = email;
    },
    saveEmailStage() {
      this.recoveryProfile.recoveryStage.Email = true;
      RecoveryStorage.recoveryStage = this.recoveryProfile.recoveryStage;
    },
    saveSMSStage() {
      this.recoveryProfile.recoveryStage.SMS = true;
      RecoveryStorage.recoveryStage = this.recoveryProfile.recoveryStage;
    },
    saveGAStage() {
      this.recoveryProfile.recoveryStage.GA = true;
      RecoveryStorage.recoveryStage = this.recoveryProfile.recoveryStage;
    },
    restartRecovery() {
      this.resetRecovery();
      this.$router.push(
        this.$route.query.redirect
          ? {path: this.$route.query.redirect}
          : {name: 'RecoveryIdentification'},
      );
      this.errorMsg('Recovery session time is over', 'Log in');
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-recovery {
  width: 100%;
}
</style>
