<template>
  <div class="ex-setting-template">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ExSettingTemplate',
};
</script>

<style scoped>
.ex-setting-template {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
