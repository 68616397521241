<template>
  <div class="ex-auto-trading-label">
    <div :class="{'ex-auto-trading-label__header': true, 'ex-auto-trading-label__header__with-right': withAlignRight}">
      <div :class="{
        'ex-auto-trading-label__header-item': true,
        'ex-auto-trading-label__header-item__with-right': withAlignRight
      }">
        <img :src="currencyProp.icon" alt="icons" />
      </div>
      <div :class="{
        'ex-auto-trading-label__header-item': true,
        'ex-auto-trading-label__header-item__with-right': withAlignRight,
      }">
        <div class="ex-auto-trading-label__title">
          {{currencyProp.label}} ({{currencyProp.name}})
        </div>
        <div class="ex-auto-trading-label__subtitle">
          {{date}}
        </div>
      </div>
    </div>
    <div :class="{'ex-auto-trading-label__label': true, 'ex-auto-trading-label__label__with-right': withAlignRight}">
      {{type}}
    </div>
    <div class="ex-auto-trading-label__content">
      <div :class="{
        'ex-auto-trading-label__amount': true,
        'ex-auto-trading-label__amount__with-right': withAlignRight,
      }">
        {{amount}}
      </div>
    </div>
  </div>
</template>

<script>
import {getCurrency} from '@/utils/exchange';

export default {
  name: 'ExAutoTradingLabel',
  props: {
    currency: {
      type: String,
      default: 'NaN',
    },
    type: {
      type: String,
      default: 'NaN',
    },
    amount: {
      type: String,
      default: 'NaN',
    },
    withAlignRight: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: '',
    },
  },
  computed: {
    currencyProp() {
      return getCurrency(this.currency);
    },
  },
};
</script>

<style scoped lang="scss">
.ex-auto-trading-label {
  &__header {
    display: flex;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      &__with-right {
        align-items: flex-end;
        &:last-child {
          margin-left: 0 !important;
          margin-right: 8px !important;
        }
      }
      &:last-child {
        flex: 1;
        margin-left: 8px;
        margin-right: 0;
      }
      img {
        height: 36px;
        width: 36px;
      }
    }
    &__with-right {
      flex-direction: row-reverse;
    }
  }
  &__subtitle {
    font-size: 12px;
  }
  &__label {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    color: #5B5B5B;
    padding: 30px 0 0;
    text-align: left;
    &__with-right {
      text-align: right;
    }
  }
  &__amount {
    font-size: 16px;
    font-weight: bold;
    color: #5B5B5B;
    &__with-right {
      text-align: right;
    }
  }
}
</style>
