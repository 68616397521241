import BaseApi from '@/api/base';

/**
 * Provides an api for depositing funds through bank cards
 */
class CardPayApi extends BaseApi {
  /**
   * Create a link for replenishment by bank card (Xpate)
   * @param {String} event
   * @param {{}} data
   * @return {Promise<unknown>}
   */
  createXpate(event, data) {
    return super.post(event, '/api/card-pay/create-1', {data});
  }

  /**
   * Create a link for replenishment by bank card (Fenige)
   * @param {String} event
   * @param {{}} data
   * @return {Promise<unknown>}
   */
  createFenige(event, data) {
    return super.post(event, '/api/card-pay/create-2', {data});
  }

  /**
   * One-time key authorization (Xpate)
   * @param {String} event
   * @param {{}} data
   * @return {Promise<unknown>}
   */
  authXpate(event, data) {
    return super.post(event, '/api/card-pay/auth-1', {data});
  }

  /**
   * One-time key authorization (Fenige)
   * @param {String} event
   * @param {{}} data
   * @return {Promise<unknown>}
   */
  authFenige(event, data) {
    return super.post(event, '/api/card-pay/auth-2', {data});
  }
}

export default new CardPayApi();
