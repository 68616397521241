// app
import Vue from 'vue';

// mixin
import notification from '@/mixins/NotificationMixin';

const vueInstance = (() => {
  let instance = null;
  if (!instance) {
    instance = new Vue(notification);
  }
  return instance;
});

export default vueInstance();
