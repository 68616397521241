const FILES_NOT_FOUND = 'Files not found';

export default {
  1: 'Dokobit Error',
  2: 'TCP Error',
  3: 'Error KYC',
  4: 'Email error',
  5: FILES_NOT_FOUND,
  6: FILES_NOT_FOUND,
  7: 'Invalid file extension',
  8: 'Unknown mime file type',
  9: 'Invalid MIME file type',
  10: 'Large image resolution',
  11: 'Small image resolution',
  12: 'Large file size',
  13: 'No uploaded files in request',
  14: 'Unknown uploaded file keys',
  15: 'Folder creation error',
  16: 'File transfer error',
  17: 'Error changing permissions',
  18: 'File deletion error',
  19: FILES_NOT_FOUND,
  20: 'Not all files have been uploaded',
  21: 'iDenfy error',
  22: 'Unexpected response from iDenfy',
  23: 'No files found in iDenfy API',
};
