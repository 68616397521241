<template>
  <div class="ex-otc-order">
    <el-form ref="form" :model="form">
      <div class="ex-otc-order__row">
        <div class="ex-otc-order__left">
          <el-form-item label="You pay" prop="currencySell">
            <el-select :value="form.currencySell" @change="handleChangeCurrencySell">
              <el-option
                v-for="(currency, key) in currencyList"
                :key="key"
                :label="currency"
                :value="currency"
                :class="{
                  'ex-otc-order__option_active': currency === form.currencySell || getPair(currency, form.currencyBuy)
                }"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="ex-otc-order__right">
          <el-form-item label="You pay" prop="amountSell" :rules="isRfq && isBuyType ? [] : [ruleRequired]">
            <el-input v-if="isRfq && isBuyType" placeholder="You will find out the amount from the trader" disabled />
            <ex-input
              v-else
              :value="form.amountSell"
              :has-lock="!isRfq"
              :is-lock="!isRfq && locks[0]"
              :disabled="isRfq && isBuyType"
              :currency="form.currencySell"
              :key = reRenderInitKey
              :placeholder="isRfq && isBuyType
                ? 'You will find out the amount from the trader'
                : `Please input amount ${form.currencySell} for pay`"
              @change-lock="handleChangeLock(0, $event)"
              @input="handleChangeAmountSell"
            >
              <el-button slot="append" @click="handleChangeAmountSell(max)">MAX</el-button>
            </ex-input>
          </el-form-item>
        </div>
      </div>
      <div class="ex-otc-order__row">
        <div class="ex-otc-order__left ex-otc-order__left_center">
          <i class="el-icon-sort ex-otc-order__icon" @click="handleSwapCoins" />
        </div>
        <div class="ex-otc-order__right" :class="{'ex-otc-order__right_hide': isRfq}">
          <el-form-item
            :label="`Limit Price for 1 ${pair.split(':')[0]}`"
            :rules="isRfq ? [] : [ruleRequired]"
            prop="price"
          >
            <ex-input
              :value="form.price"
              :has-lock="!isRfq"
              :is-lock="locks[1]"
              :key = reRenderInitKey
              :currency="CRYPTO_CURRENCIES.BTC"
              placeholder="Please input price"
              @change-lock="handleChangeLock(1, $event)"
              @input="handleChangePrice"
            />
          </el-form-item>
        </div>
      </div>
      <div class="ex-otc-order__row">
        <div class="ex-otc-order__left">
          <el-form-item label="You pay" prop="currencyBuy">
            <el-select :value="form.currencyBuy" @change="handleChangeCurrencyBuy">
              <el-option
                v-for="(currency, key) in currencyList"
                :key="key"
                :label="currency"
                :value="currency"
                :class="{
                  'ex-otc-order__option_active': currency === form.currencyBuy || getPair(form.currencySell, currency)
                }"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="ex-otc-order__right">
          <el-form-item :rules="isRfq && isSellType ? [] : [ruleRequired]" label="You receive" prop="amountBuy">
            <el-input v-if="isRfq && isSellType" placeholder="You will find out the amount from the trader" disabled />
            <ex-input
              v-else
              :value="form.amountBuy"
              :placeholder="isRfq && isSellType
                ? 'You will find out the amount from the trader'
                : `Please input amount ${form.currencyBuy} for receive`"
              :currency="form.currencyBuy"
              :has-lock="!isRfq"
              :is-lock="!isRfq && locks[2]"
              :disabled="isRfq && isSellType"
              :key = reRenderInitKey
              @change-lock="handleChangeLock(2, $event)"
              @input="handleChangeAmountBuy"
            />
          </el-form-item>
        </div>
      </div>
      <ex-icon-alert class="ex-otc-order__result">
        You {{type}}
        {{getAmountInCurrencyFormat(
          (isBuyType ? amountBuy : amountSell) || 0,
          isBuyType ? form.currencyBuy : form.currencySell,
        )}}
        {{isBuyType ? form.currencyBuy : form.currencySell}}
        <span class="ex-otc-order__result-part" :class="{'ex-otc-order__result-part_hide': isRfq}">
          at limit price {{
            formatCurrency(
              price || 0,
              CURRENCY_TYPE.CRYPTO,
              null,
              [form.currencyBuy, form.currencySell].includes(CRYPTO_CURRENCIES.TON) ? CRYPTO_CURRENCIES.TON : null,
            )
          }}
          {{isBuyType ? form.currencyBuy : form.currencySell}}/{{isBuyType ? form.currencySell : form.currencyBuy}}.
          You {{isBuyType ? 'pay' : 'receive'}}
          {{getAmountInCurrencyFormat(
            (isBuyType ? amountSell : amountBuy) || 0,
            isBuyType ? form.currencySell : form.currencyBuy,
          )}}
          {{isBuyType ? form.currencySell : form.currencyBuy}}
        </span>
      </ex-icon-alert>
      <el-button type="primary" class="ex-otc-order__submit" :loading="isLoading" @click="handleSubmit">
        Create Order
      </el-button>
    </el-form>
  </div>
</template>

<script>
// lib
import {mapGetters, mapState} from 'vuex';

// component
import ExInput from '@/components/ex-input';
import ExIconAlert from '@/components/ex-icon-alert';

// util
import {
  roundPrecisionByCurrency,
  cutPrecisionByCurrency,
  getPrecisionByCurrency,
  numberFromLocaleString,
  formatCurrency,
  isCrypto,
  isUSDTLike,
  getAmountInCurrencyFormat,
} from '@/utils/converters/currency';
import {ruleRequired} from '@/utils/elementUITypicalValidation';
import {socketRequest} from '@/utils/socket';

// api
import ExchangeApi from '@/api/requestService/exchange.api';

// const
import {CRYPTO_CURRENCIES, CURRENCY_TYPE, FIAT_CURRENCIES} from '@/constants/currencies';
import {CREATE_EXCHANGE_OTC, CREATE_EXCHANGE_RFQ} from '@/constants/events/exchange/actions.type';

// settings
const AVAILABLE_CURRENCY_FOR_REQUEST = {
  [CRYPTO_CURRENCIES.BTC]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
    CRYPTO_CURRENCIES.USDT_ERC20,
  ],
  [CRYPTO_CURRENCIES.ETH]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
    CRYPTO_CURRENCIES.BTC,
    CRYPTO_CURRENCIES.USDT_ERC20,
  ],
  [CRYPTO_CURRENCIES.USDT_ERC20]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
    CRYPTO_CURRENCIES.USDC,
    CRYPTO_CURRENCIES.TON,
  ],
  [CRYPTO_CURRENCIES.USDT_TRC20]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
    CRYPTO_CURRENCIES.USDT_ERC20,
    CRYPTO_CURRENCIES.USDC,
    CRYPTO_CURRENCIES.TON,
  ],
  [CRYPTO_CURRENCIES.USDT_BEP20]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
    CRYPTO_CURRENCIES.USDT_ERC20,
  ],
  [CRYPTO_CURRENCIES.USDC]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
  ],
  [CRYPTO_CURRENCIES.DAI]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
    CRYPTO_CURRENCIES.USDT_ERC20,
  ],
  [CRYPTO_CURRENCIES.ETH_BEP20]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
    CRYPTO_CURRENCIES.USDT_ERC20,
  ],
  [CRYPTO_CURRENCIES.USDC_BEP20]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
    CRYPTO_CURRENCIES.USDT_ERC20,
  ],
  [CRYPTO_CURRENCIES.TRX]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
    CRYPTO_CURRENCIES.USDT_ERC20,
  ],
  [CRYPTO_CURRENCIES.TON]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
  ],
  [CRYPTO_CURRENCIES.USDT_Polygon]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
  ],
  [CRYPTO_CURRENCIES.MATIC_Polygon]: [
    FIAT_CURRENCIES.EUR,
    FIAT_CURRENCIES.USD,
  ],
};
const EXCLUDED_CURRENCY = [
  // CRYPTO_CURRENCIES.USDT_Polygon,
  // CRYPTO_CURRENCIES.MATIC_Polygon,
  // CRYPTO_CURRENCIES.BNB, // TODO RESEARCH
];

export default {
  name: 'ExOtcTradingCreate',
  components: {ExIconAlert, ExInput},
  props: {
    isRfq: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        currencySell: '',
        amountSell: null,
        price: null,
        currencyBuy: '',
        amountBuy: null,
      },
      reRenderInitKey: 1,
      locks: [false, true, false],
      ruleRequired,
      CRYPTO_CURRENCIES,
    };
  },
  computed: {
    CURRENCY_TYPE() {
      return CURRENCY_TYPE;
    },
    ...mapState('currentUser', ['profile']),
    ...mapGetters('currentUser', ['currentId']),
    currencyList() {
      return this.profile.balance.map((item) => item.currency)
        .filter((item) => !EXCLUDED_CURRENCY.includes(item));
    },
    pair() {
      return this.getPair(this.form.currencySell, this.form.currencyBuy);
    },
    type() {
      return this.getType(this.form.currencySell, this.form.currencyBuy);
    },
    isSellType() {
      return this.type === 'sell';
    },
    isBuyType() {
      return this.type === 'buy';
    },
    amountSell() {
      return numberFromLocaleString(this.form.amountSell);
    },
    amountBuy() {
      return numberFromLocaleString(this.form.amountBuy);
    },
    price() {
      return numberFromLocaleString(this.form.price);
    },
    max() {
      const balance = this.profile.balance.find((v) => v.currency === this.form.currencySell);
      return (balance.amount < 0) ? 0 : balance.amount;
    },
  },
  watch: {
    'isRfq': {
      handler(value) {
        if (value) {
          this.form.price = null;
          if (this.isSellType) {
            this.form.amountBuy = null;
          } else {
            this.form.amountSell = null;
          }
        }
      },
      immediate: true,
    },
    '$route.params.type': {
      handler(value) {
        if (value === 'sell') {
          this.changeCurrencySell(this.$route.params.currency);
        } else {
          this.changeCurrencyBuy(this.$route.params.currency);
        }
      },
      immediate: true,
    },
    'form.currencyBuy'(newCurrency, oldCurrency) {
      if (newCurrency !== oldCurrency && getPrecisionByCurrency(newCurrency) < getPrecisionByCurrency(oldCurrency)) {
        this.form.amountBuy = cutPrecisionByCurrency(this.amountBuy, newCurrency);
        this.handleChangeAmountBuy(this.form.amountBuy);
      }
    },
    'form.currencySell'(newCurrency, oldCurrency) {
      if (newCurrency !== oldCurrency && getPrecisionByCurrency(newCurrency) < getPrecisionByCurrency(oldCurrency)) {
        this.form.amountSell = cutPrecisionByCurrency(this.amountSell, newCurrency);
        this.handleChangeAmountSell(this.form.amountSell);
      }
    },
    type() {
      this.updatePrice();
    },
  },
  methods: {
    formatCurrency,
    getAmountInCurrencyFormat,
    handleSubmit() {
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;
        if (this.isRfq) {
          this.createRfq();
        } else {
          this.createOtc();
        }
      });
    },
    createOtc() {
      socketRequest.call(this, CREATE_EXCHANGE_OTC,
        (payload) => {
          if (payload.exchangeId) {
            this.$router.push({name: 'Trading', params: {id: payload.exchangeId, type: 'view'}});
            return 'New request created';
          }
        },
        () => {},
        () => {
          this.isLoading = false;
        },
      );
      this.isLoading = true;
      ExchangeApi.createExchangeOtc(CREATE_EXCHANGE_OTC, {
        currencyPair: this.pair,
        type: this.isSellType ? 2 : 1,
        amount: this.isBuyType ? this.amountBuy : this.amountSell,
        price: this.price,
      });
    },
    createRfq() {
      socketRequest.call(this, CREATE_EXCHANGE_RFQ,
        (payload) => {
          if (payload.exchangeId) {
            this.$router.push({name: 'Trading', params: {id: payload.exchangeId, type: 'view'}});
            return 'New request created';
          }
        },
        () => {},
        () => {
          this.isLoading = false;
        },
      );
      this.isLoading = true;
      ExchangeApi.createExchangeRfq(CREATE_EXCHANGE_RFQ, {
        currencyPair: this.pair,
        type: this.isSellType ? 2 : 1,
        amount: this.isBuyType ? this.amountBuy : this.amountSell,
      });
    },
    handleMoveToMax() {
      this.form.amountSell = this.max;
    },
    handleSwapCoins() {
      const exCurrencySell = this.form.currencySell;
      this.form.currencySell = this.form.currencyBuy;
      this.form.currencyBuy = exCurrencySell;
      this.changeCurrentRoute({type: this.type});
      const exAmountSell = this.form.amountSell;
      this.form.amountSell = this.form.amountBuy;
      this.form.amountBuy = exAmountSell;
      // Hack. Changing the mask of a child component must process before rerender the values
      this.reRenderInitKey++;
    },
    handleChangeCurrencySell(value) {
      if (this.form.currencyBuy === value) {
        this.handleSwapCoins();
        return;
      }

      this.changeCurrencySell(value);

      if (this.isSellType) this.changeCurrentRoute({currency: value});
    },
    changeCurrencySell(value) {
      const pair = this.getPair(value, this.form.currencyBuy);
      if (!pair) {
        this.form.currencyBuy = this.getFirstCoinFromPairs(value);
      }

      this.form.currencySell = value;
    },
    handleChangeCurrencyBuy(value) {
      if (this.form.currencySell === value) {
        this.handleSwapCoins();
        return;
      }

      this.changeCurrencyBuy(value);

      if (this.isBuyType) this.changeCurrentRoute({currency: value});
    },
    changeCurrencyBuy(value) {
      const pair = this.getPair(this.form.currencySell, value);
      if (!pair) {
        this.form.currencySell = this.getFirstCoinFromPairs(value);
      }

      this.form.currencyBuy = value;
    },
    handleChangeAmountSell(value) {
      if (this.isRfq || !this.locks[0]) {
        this.form.amountSell = value;
      }
      if (!this.isRfq && this.locks[1] && this.form.amountBuy) {
        this.updatePrice();
      } else if (this.locks[2] && this.form.price) {
        this.updateAmountBuy();
      }
    },
    handleChangeAmountBuy(value) {
      if (this.isRfq || !this.locks[2]) {
        this.form.amountBuy = value;
      }
      if (!this.isRfq && this.locks[0] && this.form.price) {
        this.updateAmountSell();
      } else if (this.locks[1] && this.form.amountSell) {
        this.updatePrice();
      }
    },
    handleChangePrice(value) {
      if (this.isRfq || !this.locks[1]) {
        this.form.price = value;
      }
      if (!this.isRfq && this.locks[0] && this.form.amountBuy) {
        this.updateAmountSell();
      } else if (this.locks[2] && this.form.amountSell) {
        this.updateAmountBuy();
      }
    },
    updatePrice() {
      const price = this.isBuyType ? (this.amountSell / this.amountBuy) : (this.amountBuy / this.amountSell);
      this.form.price = roundPrecisionByCurrency(price);
    },
    updateAmountSell() {
      const amountSell = this.isBuyType ? (this.amountBuy * this.price) : (this.amountBuy / this.price);
      this.form.amountSell = roundPrecisionByCurrency(amountSell, this.form.currencySell);
    },
    updateAmountBuy() {
      const amountBuy = this.isBuyType ? (this.amountSell / this.price) : (this.amountSell * this.price);
      this.form.amountBuy = roundPrecisionByCurrency(amountBuy, this.form.currencyBuy);
    },
    handleChangeLock(item, flag) {
      if (flag) {
        this.locks = this.locks.map(() => false);
        this.locks[item] = flag;
      }
    },
    getPair(seller, buyer) {
      const firstSeller = AVAILABLE_CURRENCY_FOR_REQUEST[seller] || [];
      const firstBuyer = AVAILABLE_CURRENCY_FOR_REQUEST[buyer] || [];
      const hasFirstSeller = firstSeller.includes(buyer);
      const hasFirstBuyer = firstBuyer.includes(seller);

      if (hasFirstSeller) {
        return `${seller}:${buyer}`;
      }

      if (hasFirstBuyer) {
        return `${buyer}:${seller}`;
      }

      return null;
    },
    getFirstPair(coin) {
      const foundPair = AVAILABLE_CURRENCY_FOR_REQUEST[coin];

      if (foundPair) {
        return `${coin}:${foundPair[0]}`;
      }

      let result = '';
      for (const key in AVAILABLE_CURRENCY_FOR_REQUEST) {
        if (!Object.prototype.hasOwnProperty.call(AVAILABLE_CURRENCY_FOR_REQUEST, key)) continue;

        const foundLastCoin = AVAILABLE_CURRENCY_FOR_REQUEST[key].includes(coin);

        if (foundLastCoin) {
          result = `${key}:${foundLastCoin}`;
          break;
        }
      }
      return result;
    },
    getFirstCoinFromPairs(coin) {
      const pair = this.getFirstPair(coin);
      return pair.split(':').find((value) => value !== coin);
    },
    getType(first, last) {
      const firstCoin = AVAILABLE_CURRENCY_FOR_REQUEST[first] || [];
      return firstCoin.includes(last) ? 'sell' : 'buy';
    },
    changeCurrentRoute({type, currency}) {
      if (currency === this.$route.params.currency) return;
      this.$router.replace({
        name: 'RequestCreate',
        params: {
          requestType: this.$route.params.requestType,
          type: type || this.$route.params.type,
          currency: currency || this.$route.params.currency,
        },
      });
    },
    getInputMaskType(currency) {
      return isUSDTLike(currency) ? 'USDTLike' : isCrypto(currency) ? 'crypto' : 'fiat';
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-otc-order {
  &__row {
    display: flex;
    height: 82px;
  }
  &__left {
    width: 150px;
    &_center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__right {
    flex: 1;
    margin-left: 15px;
    opacity: 1;
    transition: opacity .25s linear, width .25s linear;
    &_hide {
      width: 0;
      opacity: 0;
    }
  }
  &__icon {
    cursor: pointer;
    font-size: 36px;
    color: #8884B6;
    &:hover {
      color: darken(#8884B6, 10%);
    }
  }
  &__result {
    margin-top: 22px;
    &-part {
      opacity: 1;
      transition: opacity .25s linear;
      &_hide {
        opacity: 0;
      }
    }
  }
  &__submit {
    margin-top: 10px;
    width: 100%;
  }
  &__option_active {
    color: #67c23a;
  }
  ::v-deep {
    .ex-otc-order__left {
      .el-form-item__label {
        visibility: hidden;
      }
      .el-select {
        .el-input__inner {
          border-width: 0;
          background: #8884B6;
          color: white;
          text-transform: uppercase;
        }
        .el-icon-arrow-up:before {
          color: white;
        }
        &:hover .el-input__inner {
          background: darken(#8884B6, 10%);
        }
      }
    }
  }
}
</style>
