import BaseApi from '@/api/base';

export default new class RecoveryApi extends BaseApi {
  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.email
   * @return {Promise<*>}
   */
  initRecoveryAccess(event, data) {
    const emailLink = `${window.location.protocol}//${window.location.hostname}/recovery/validation/email`;
    return super.post(event, '/api/recovery/init-recovery', {data: {...data, emailLink}});
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchCode(event) {
    return super.post(event, '/api/recovery/fetch-recovery-code');
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.code
   * @param {string} data.token
   * @param {string} data.validationType
   * @return {Promise<*>}
   */
  validateRecoveryCode(event, data) {
    return super.post(event, '/api/recovery/validate-recovery-code', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.password
   * @return {Promise<*>}
   */
  completeRecovery(event, data) {
    return super.post(event, '/api/recovery/complete-recovery', {data});
  }
};
