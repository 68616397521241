export const DEFAULT_MSG_EXCLUDED = 'EXCLUDED';
export const DEFAULT_MSG_NO_PREFIX = 'Prefix not defined';
export const DEFAULT_MSG_NO_CODE = 'Error code not defined';
export const DEFAULT_MSG_NOT_DEFINE_PREFIX = 'Prefix does not exist';
export const DEFAULT_MSG_NOT_DEFINE_CODE = 'Code does not exist';
export const INTERNAL_ERRORS_SEARCH_ERROR_MESSAGES = [
  DEFAULT_MSG_NO_PREFIX,
  DEFAULT_MSG_NO_CODE,
  DEFAULT_MSG_NOT_DEFINE_PREFIX,
  DEFAULT_MSG_NOT_DEFINE_CODE,
];
export const DEFAULT_MSG_NO_ISSUE_ID = 'An error has occurred. Error ID not defined';
export const DEFAULT_MSG_ISSUE_ID = 'An error has occurred. Contact support and give them the code: ';
