// api
import ExchangeApi from '@/api/requestService/exchange.api';

// util
import {resetState} from '@/utils/storeUtils';

// const
import {getWSA} from '@/constants/events/actions.type';
import {EXCHANGE_PRICE_BEHAVIOUR_TYPES, EXCHANGE_STATUS} from '@/constants/common';
import {FETCH_COUNTER_PENDING_ORDERS} from '@/constants/events/store/exchange/actions.type';
import {SUBSCRIBE_REQUEST} from '@/constants/events/subscriptions.type';

const initState = {
  counterPendingOpenOrders: 0,
};

export default {
  namespaced: true,
  state: {...initState},
  actions: {
    [FETCH_COUNTER_PENDING_ORDERS]() {
      return ExchangeApi.counterPendingOrders(FETCH_COUNTER_PENDING_ORDERS);
    },
    [getWSA(FETCH_COUNTER_PENDING_ORDERS)]({commit}, {count}) {
      commit('saveCounterPendingOpenOrders', count);
    },
    [getWSA(SUBSCRIBE_REQUEST)]({dispatch}, payload) {
      if (
        [
          EXCHANGE_PRICE_BEHAVIOUR_TYPES.RFQ,
          EXCHANGE_PRICE_BEHAVIOUR_TYPES.OTC,
        ].includes(payload.priceBehaviourType)
        &&
        [
          EXCHANGE_STATUS.IN_PROGRESS_TRADER,
          EXCHANGE_STATUS.ACCEPT_BY_USER,
          EXCHANGE_STATUS.REJECT,
          EXCHANGE_STATUS.CANCELLED,
          EXCHANGE_STATUS.IN_PROGRESS_USER,
          EXCHANGE_STATUS.CANCELED_BY_TIMEOUT,
        ].includes(payload.transactionStatus)
      ) {
        dispatch(FETCH_COUNTER_PENDING_ORDERS);
      }
    },
  },
  mutations: {
    reset(state) {
      resetState(initState, state);
    },
    saveCounterPendingOpenOrders(state, count) {
      state.counterPendingOpenOrders = count;
    },
  },
};
