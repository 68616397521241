<template>
  <div class="ex-order-tabs">
    <portal to="toolbar__title">{{portalLabel}}</portal>
    <div class="ex-order-tabs__tabs">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="Open Orders" name="open-orders" lazy>
          <ex-open-orders />
        </el-tab-pane>
        <el-tab-pane label="Order History" name="order-history" lazy>
          <ex-orders-history />
        </el-tab-pane>
        <el-tab-pane label="Trade History" name="trade-history" lazy>
          <ex-orders-history is-trade />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
// components
import ExOrdersHistory from '@/pages/orders/ex-orders-history';
import ExOpenOrders from '@/pages/orders/ex-open-orders';

export default {
  name: 'ExOrderTabs',
  components: {ExOpenOrders, ExOrdersHistory},
  data() {
    return {
      activeTab: '',
    };
  },
  computed: {
    portalLabel() {
      switch (this.activeTab) {
        case 'trade-history': return 'Trader History';
        case 'order-history': return 'Order History';
        case 'open-orders':
        default: return 'Open Order';
      }
    },
    type() {
      return this.$route.params.type;
    },
  },
  watch: {
    activeTab(value) {
      if (this.type !== value) {
        this.$router.replace({name: 'Orders', params: {type: value}});
      }
    },
  },
  created() {
    if (['open-orders', 'trade-history', 'order-history'].includes(this.type)) {
      this.activeTab = this.type;
    } else {
      this.activeTab = 'open-orders';
    }
  },
};
</script>

<style lang="scss" scoped>
.ex-order-tabs {
  &__tabs{
    ::v-deep {
      .el-tabs__item {
        font-size: 16px;
      }
    }
  }
}
</style>
