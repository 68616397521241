<template>
  <div class="ex-create-new-request">
    <portal to="toolbar__title">New Request</portal>
    <div class="ex-create-new-request__wrapper">
      <el-tabs v-model="currentTab">
        <el-tab-pane label="OTC TRADING" name="otc-trading" lazy>
          <el-switch v-model="isRfq" active-text="RFQ" inactive-text="ORDER" />
          <div class="ex-create-new-request__content">
            <ex-otc-trading-create v-if="isRfq" is-rfq />
            <ex-otc-trading-create v-else />
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="autoTrading" label="AUTOMATED TRADING" name="automated-trading" lazy>
          <ex-auto-trading-create :type="$route.params.type" :currency="$route.params.currency" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
// components
import ExAutoTradingCreate from '@/pages/requests/auto-trading/ex-auto-trading-create';
import ExOtcTradingCreate from '@/pages/requests/otc-trading/ex-otc-trading-create';
import {mapState} from 'vuex';

export default {
  name: 'ExCreateNewRequest',
  components: {ExOtcTradingCreate, ExAutoTradingCreate},
  data() {
    return {
      currentTab: 'otc-trading',
      isRfq: false,
    };
  },
  computed: {...mapState('content', {autoTrading: (state) => state.content.availableSections.autoTrading})},
  watch: {
    '$route.params.requestType': {
      handler(value) {
        this.currentTab = value;
      },
      immediate: true,
    },
    currentTab(requestType) {
      this.changeCurrentRoute({requestType});
    },
  },
  methods: {
    changeCurrentRoute({requestType, type, currency}) {
      this.$router.replace({
        name: 'RequestCreate',
        params: {
          requestType: requestType || this.$route.params.requestType,
          type: type || this.$route.params.type,
          currency: currency || this.$route.params.currency,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-create-new-request {
  display: flex;
  @media (max-width: 1280.98px) {
    justify-content: center;
  }
  &__wrapper {
    max-width: 730px;
    width: 100%;
  }
  &__content {
    padding-top: 40px;
  }
  ::v-deep .el-tabs__header {
    margin-bottom: 11px;
  }
}
</style>
