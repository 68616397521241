<template>
  <div v-loading="isLoading" class="ex-recovery-validation-email">
    <ex-one-input-form
      :message="message"
      :sub-title="email"
      label="Code from Email"
      placeholder="Type code from email"
      action-label="Continue"
      @click="validateEmail"
    />
  </div>
</template>

<script>
// component
import ExOneInputForm from '@/components/authorization/layout/ex-one-input-form';

// api
import RecoveryApi from '@/api/userService/recovery.api';

// const
import {RECOVERY_VALIDATE_EMAIL_CODE} from '@/constants/events/recovery/actions.type';
import {RECOVERY_SESSION_TIME_LIVED} from '@/constants/common';

// util
import {socketRequest} from '@/utils/socket';
import settingsKeeper from '@/utils/settingsKeeper';

export default {
  name: 'ExRecoveryValidationEmail',
  components: {ExOneInputForm},
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      message: '',
    };
  },
  methods: {
    validateEmail(code) {
      socketRequest.call(this, RECOVERY_VALIDATE_EMAIL_CODE,
        () => {
          this.$emit('emailStageCompleted');
        },
        (payload) => {
          if (payload.error.code === RECOVERY_SESSION_TIME_LIVED) {
            this.$emit('restartRecovery');
          } else if (payload.handledErrorMessage) {
            this.message = payload.handledErrorMessage;
          }
        },
        () => {
          this.isLoading = false;
        },
      );
      this.isLoading = true;
      this.message = '';
      RecoveryApi.validateRecoveryCode(
        RECOVERY_VALIDATE_EMAIL_CODE,
        {token: settingsKeeper.socketId, validationType: 'Email', code},
      );
    },
  },
};
</script>
