<template>
  <div class="ex-countdown-circle">
    <ex-countdown :time-data="timeData" :has-stop="hasStop">
      <template v-slot:default="{prop}">
        <div class="ex-countdown-circle__container" :style="`width: ${diameter}px; height: ${diameter}px;`">
          <el-progress
            :width="diameter"
            type="circle"
            :show-text="false"
            :percentage="prop.percentage"
            color="#67C23A"
          />
          <div class="ex-countdown-circle__value"><slot>{{prop.time}}</slot></div>
        </div>
      </template>
    </ex-countdown>
  </div>
</template>

<script>
// Components
import ExCountdown from '@/components/ex-countdown';

// Utils
import {checkExpectedKeys} from '@/utils/validatorPropObjects';

export default {
  name: 'ExCountdownCircle',
  components: {ExCountdown},
  props: {
    timeData: {
      type: Object,
      validator(value) {
        return checkExpectedKeys(['from', 'to'], value);
      },
    },
    diameter: {
      type: Number,
      default: 200,
    },
    hasStop: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-countdown-circle {
  &__container {
    position: relative;
  }
  &__value {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #5B5B5B;
  }
}
</style>
