<template>
  <div class="ex-gateway">
    <portal to="toolbar__title">
      Gateway (AutoConvert)
    </portal>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Incoming" :name="tabNames.GatewayIncoming" lazy>
        <ex-incoming />
      </el-tab-pane>
      <el-tab-pane label="Outgoing" :name="tabNames.GatewayOutgoing" lazy>
        <ex-outgoing />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// Components
import ExOutgoing from '@/pages/gateway/ex-outgoing';
import ExIncoming from '@/pages/gateway/ex-incoming';

export default {
  name: 'ExGateway',
  components: {ExIncoming, ExOutgoing},
  data() {
    const tabNames = {
      GatewayIncoming: 'incoming',
      GatewayOutgoing: 'outgoing',
    };

    return {
      activeTab: 'incoming',
      tabNames,
    };
  },
  watch: {
    'this.$route.name': {
      handler() {
        this.activeTab = this.tabNames[this.$route.name] || '';
      },
      immediate: true,
    },
    activeTab(value) {
      let routeName = '';
      for (const key in this.tabNames) {
        if (Object.prototype.hasOwnProperty.call(this.tabNames, key)) {
          if (this.tabNames[key] === value) {
            routeName = key;
            break;
          }
        }
      }

      this.$router.replace({
        name: routeName,
        params: {type: value},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-gateway {
  ::v-deep {
    .el-tabs__header {
      margin-bottom: 0;
    }
    .el-tabs__item {
      font-size: 16px;
    }
  }
}
</style>
