import BaseApi from '@/api/base';

export default new class TunnelApi extends BaseApi {
  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  updateTunnelApiKey(event) {
    return super.post(event, '/api/tunnel/update-tunnel-key');
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  updateTunnelWebhook(event) {
    return super.post(event, '/api/tunnel/update-tunnel-webhook');
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchTunnelPreset(event) {
    return super.post(event, '/api/tunnel/fetch-tunnel-presets');
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchTunnelNetworkFeeCurrencyPair(event) {
    return super.post(event, '/api/tunnel/fetch-network-fee-currency-pair');
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.commissionCurrency
   * @param {string} data.currency
   * @param {string} data.address
   * @param {number} data.amount
   * @return {Promise<*>}
   */
  fetchTunnelNetworkFee(event, data) {
    return super.post(event, '/api/tunnel/fetch-network-fee', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.address
   * @param {number} data.amountGross
   * @param {number} data.amountNet
   * @param {string} data.amountType - choice("receive", "spend")
   * @param {string} data.currency
   * @param {string} [data.referenceId]
   * @param {string} [data.hashNetworkFee]
   * @param {string} [data.currencyNetworkFee]
   * @param {number} [data.amountNetworkFee]
   * @return {Promise<*>}
   */
  createWithdrawCryptoAuto(event, data) {
    return super.post(event, '/api/tunnel/create-withdraw-crypto-auto', {data});
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchDepositAutoCryptoCurrencies(event) {
    return super.post(event, '/api/tunnel/fetch-deposit-auto-crypto-currencies');
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.page
   * @param {number} data.limit
   * @param {Object} [data.filters]
   * @param {Array} [data.filters.currency]
   * @param {Array} [data.filters.currencyLast]
   * @param {boolean} [data.filters.archive]
   * @return {Promise<*>}
   */
  fetchDepositAutoCryptoAddresses(event, data) {
    return super.post(event, '/api/tunnel/fetch-deposit-auto-crypto-addresses', {data});
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchIncomingCurrencyPairs(event) {
    return super.post(event, '/api/tunnel/fetch-incoming-currency-pairs');
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.page
   * @param {number} data.limit
   * @param {Object} [data.filters]
   * @param {Array} [data.filters.currency]
   * @param {Array} [data.filters.currencyLast]
   * @param {boolean} [data.filters.archive]
   * @return {Promise<*>}
   */
  fetchIncomingAddresses(event, data) {
    return super.post(event, '/api/tunnel/fetch-incoming-addresses', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.currency
   * @param {string} [data.referenceId]
   * @return {Promise<*>}
   */
  createDepositAutoCryptoAddress(event, data) {
    return super.post(event, '/api/tunnel/create-deposit-auto-crypto-address', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.pair
   * @param {string} [data.referenceId]
   * @return {Promise<*>}
   */
  createIncomingAddress(event, data) {
    return super.post(event, '/api/tunnel/create-incoming-address', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.addressId
   * @param {boolean} data.isArchived
   * @return {Promise<*>}
   */
  updateAddressArchiveStatus(event, data) {
    return super.post(event, '/api/tunnel/update-address-archive-status', {data});
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchOutgoingCurrencyPairs(event) {
    return super.post(event, '/api/tunnel/fetch-outgoing-currency-pairs');
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.hash
   * @param {string} data.address
   * @param {string} data.feePaidBy - myself or counterparty
   * @param {string} data.currencyPay
   * @param {string} data.currencyBuy - crypto only
   * @param {string} data.amountType - pay or buy or receive
   * @param {number} data.amountPay
   * @param {number} data.amountBuy
   * @param {number} data.amountReceive
   * @param {string} data.currencyNetworkFee
   * @param {number} data.amountNetworkFee
   * @param {string} [data.referenceId] - will be added in the future
   * @return {Promise<*>}
   */
  createOutgoing(event, data) {
    return super.post(event, '/api/tunnel/create-outgoing', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.feePaidBy - myself or counterparty
   * @param {string} data.currencyPay
   * @param {string} data.currencyBuy - crypto only
   * @param {string} data.amountType - pay or buy or receive
   * @param {number} data.amount
   * @param {string} [data.address]
   * @param {string} [data.currencyNetworkFee]
   * @return {Promise<*>}
   */
  calculateOutgoing(event, data) {
    return super.post(event, '/api/tunnel/calculate-outgoing', {data});
  }
};
