import BaseApi from '@/api/base';
import axios from 'axios';

export default new class CommonApi extends BaseApi {
  /**
   * Fetch user ip address
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchUserIPAddress(event) {
    return super.post(event, '/api/common/address');
  }
};

/**
 * @return {Promise<{dateTime}>}
 */
export function getServerDateTime() {
  return axios.get('/server-time').then((res) => {
    if (res.status === 200 && res.data.dateTime) return res.data;
    return null;
  });
}
