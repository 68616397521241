import VueSocketIO from 'vue-socket.io';
import {STORE_ACTIONS_PREFIX, STORE_MUTATIONS_PREFIX} from '@/constants/socket';
import {IS_DEV} from '@/constants/common';

/**
 * @param {Store} store
 * @return {VueSocketIO<unknown>}
 */
export default function(store) {
  return new VueSocketIO({
    debug: IS_DEV,
    connection: `${window.location.protocol}//${window.location.hostname}`,
    options: {
      transports: ['websocket'],
      autoConnect: true,
    },
    vuex: {
      store,
      actionPrefix: STORE_ACTIONS_PREFIX,
      mutationPrefix: STORE_MUTATIONS_PREFIX,
    },
  });
}
