// lib
import WAValidator from 'multicoin-address-validator';
import TonWeb from 'tonweb';

// const
import {IS_DEV} from '@/constants/common';
import {CRYPTO_CURRENCIES, CRYPTO_CURRENCIES_LIST} from '@/constants/currencies';

/**
 * @param {string} currency
 * @param {string} address
 * @return {boolean}
 */
export function validateWalletAddress(currency, address) {
  const replacing = {
    USDT_OMNI: 'BTC',
    USDT_ERC20: 'ETH',
    USDT_BEP20: 'ETH',
    USDC: 'ETH',
    BUSD: 'ETH',
    DAI: 'ETH',
    BNB: 'ETH',
    ETH_BEP20: 'ETH',
    USDC_BEP20: 'ETH',
    USDT_Polygon: 'ETH',
    MATIC_Polygon: 'ETH',
    [CRYPTO_CURRENCIES.USDT_TRC20]: CRYPTO_CURRENCIES.TRX,
    BTC: (address) => {
      const BTC_REG = [/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}/];
      if (IS_DEV) BTC_REG.push(/(tb1|[2nm]|bcrt)[a-zA-HJ-NP-Z0-9]{25,40}/);
      return BTC_REG.some((regexp) => regexp.test(address));
    },
    BCH: (address) => {
      const regex = [
        /^([13][a-km-zA-HJ-NP-Z1-9]{25,34})/,
        /^((bitcoincash:)?(q|p)[a-z0-9]{41})/,
        /^((BITCOINCASH:)?(Q|P)[A-Z0-9]{41})$/,
      ];
      return regex.some((reg) => reg.exec(address));
    },
    [CRYPTO_CURRENCIES.TON]: (address) => {
      const tonWeb = new TonWeb();
      return tonWeb.utils.Address.isValid(address);
    },
  };

  // check
  if (!CRYPTO_CURRENCIES_LIST.includes(currency)) return false;
  if (replacing[currency] && typeof replacing[currency] === 'function') return replacing[currency](address);

  return WAValidator.validate(address, replacing[currency] || currency);
}
