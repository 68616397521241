import {
  USDT_LIKE_CURRENCIES_LIST,
  CRYPTO_CURRENCIES,
  FIAT_CURRENCIES,
  CURRENCY_TYPE,
  TON_LIKE_CURRENCIES_LIST,
  // AVAILABLE_CRYPTO_CURRENCY_LIST, // TON
} from '@/constants/currencies';

/**
 * @param {number|string} number
 * @param {string} format
 * @param {{
 * maximumFractionDigits?: number,
 * minimumFractionDigits?: number,
 * }} formatOptions
 * @return {string}
 */
function numberToLocaleString(number, format = 'en', formatOptions = {}) {
  return Number(number).toLocaleString(format, formatOptions);
}

/**
 *
 * @param {string} value
 * @param {string} format
 * @return {float|number}
 */
export function numberFromLocaleString(value, format = 'en') {
  switch (format) {
    case 'en':
      if (value !== null) {
        if (typeof value === 'string') {
          return +value.replaceAll(',', '');
        }
        return +value;
      }
      return 0;
    default:
      return 0;
  }
}

/**
 *
 * @param {string} currencyType
 * @return {number}
 */
function getDefaultCurrencyPrecision(currencyType) {
  switch (currencyType) {
    case CURRENCY_TYPE.FIAT:
      return 2;
    case CURRENCY_TYPE.CRYPTO:
      return 8;
    default:
      return 8;
  }
}

/**
 * @param {number|string} amount
 * @param {string} [currencyType]
 * @param {number} [precision]
 * @param {string} currency
 * @return {*}
 */
export function formatCurrency(amount, currencyType = '', precision = null, currency = null) {
  if (isNaN(parseFloat(amount)) && !isFinite(amount)) return amount;
  let precisionValue = precision;
  if (precision === null) {
    // if (AVAILABLE_CRYPTO_CURRENCY_LIST.includes(currency)) { // TON
    //   precisionValue = getPrecisionByCurrency(currency);
    // }
    // else precisionValue = getDefaultCurrencyPrecision(currencyType);
    precisionValue = getDefaultCurrencyPrecision(currencyType);
  }
  return numberToLocaleString(
    numberFromLocaleString(amount),
    'en',
    {
      maximumFractionDigits: precisionValue,
      minimumFractionDigits: precisionValue,
    },
  );
}

/**
 * @param {number|string} amount
 * @param {string} currency
 * @param {number} precision
 * @return {string}
 */
export function getAmountInCurrencyFormat(amount, currency, precision = null) {
  let precisionValue = precision;
  if (precision === null) {
    if (isFiat(currency)) {
      precisionValue = getDefaultCurrencyPrecision(CURRENCY_TYPE.FIAT);
    } else if (isUSDTLike(currency)) {
      precisionValue = 6;
    // } else if (isTONLike(currency)) { // TON
    //   precisionValue = 9;
    } else if (isCrypto(currency)) {
      precisionValue = getDefaultCurrencyPrecision(CURRENCY_TYPE.CRYPTO);
    } else {
      precisionValue = getDefaultCurrencyPrecision(CURRENCY_TYPE.CRYPTO);
    }
  }
  return numberToLocaleString(
    numberFromLocaleString(amount),
    'en',
    {
      maximumFractionDigits: precisionValue,
      minimumFractionDigits: precisionValue,
    },
  );
}

/**
 * @param {string} pair
 * @return {{'0', seller, '1', first, buyer, second}|{}}
 */
export function getPairCurrencies(pair) {
  if (pair !== undefined && _.isString(pair) && pair.split(':').length > 0) {
    const currencies = pair.split(':');

    return {
      0: currencies[0],
      1: currencies[1],
      seller: currencies[0],
      buyer: currencies[1],
      first: currencies[0],
      second: currencies[1],
    };
  }

  return {};
}

/**
 * Check currency as crypto
 * @param {string} currency
 * @return {boolean}
 */
export function isCrypto(currency) {
  return Object.values(CRYPTO_CURRENCIES).includes(currency);
}

/**
 * Check currency as fiat
 * @param {string} currency
 * @return {boolean}
 */
export function isFiat(currency) {
  return Object.values(FIAT_CURRENCIES).includes(currency);
}

/**
 * Check currency as USDTLike
 * @param {string} currency
 * @return {boolean}
 */
export function isUSDTLike(currency) {
  return USDT_LIKE_CURRENCIES_LIST.includes(currency);
}

/**
 * Check coin as USDTLike
 * @param {string} coin
 * @return {boolean}
 */
export function isTONLike(coin) {
  return TON_LIKE_CURRENCIES_LIST.includes(coin);
}
/**
 * @param {string} currency
 * @return {number}
 */
export function getPrecisionByCurrency(currency = 'default eight precision') {
  return isFiat(currency) ? 2 : isUSDTLike(currency) ? 6 : isTONLike(currency) ? 9 : 8;
}

/**
 * @param {number} value
 * @param {string} currency
 * @return {number}
 */
export function roundPrecisionByCurrency(value, currency= 'default eight precision') {
  return +(value.toFixed(getPrecisionByCurrency(currency)));
}

/**
 * @param {number} value
 * @param {string} currency
 * @return {number}
 */
export function cutPrecisionByCurrency(value, currency= 'default eight precision') {
  const precision = getPrecisionByCurrency(currency);
  const regExp = new RegExp('(\\d+\\.\\d{' + precision + '})(\\d)');
  const result = String(value).match(regExp);
  return result ? parseFloat(result[1]) : +value;
}

/**
 * @param {string} pair
 * @param {string} elseLabel
 * @return {string}
 */
export function getPairLabel(pair, elseLabel = 'N/A') {
  if (pair.indexOf(':') === -1) return elseLabel;
  const pairObject = getPairCurrencies(pair);
  return `${pairObject.first} / ${pairObject.second}`;
}
