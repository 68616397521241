const initialStatePagination = {
  page: 0,
  limit: 0,
};

let setFindingStatus = () => {};

/**
 * itemId {required}
 * page {required}
 * limit {required}
 * fetch {required}
 * findingItem {required}
 * refTable {required}
 * refItem {required}
 */
export default {
  methods: {
    /**
     * @param {Function} callback
     */
    $_itemSearchMixin_initiate(callback = () => {}) {
      if (!!(this.page && this.limit)) {
        if (this.itemId) {
          setFindingStatus = callback;

          setFindingStatus(true);

          initialStatePagination.page = this.page;
          initialStatePagination.limit = this.limit;
        }

        this.fetch(this.page, this.limit);
      } else {
        this.fetch(1, this.limit);
      }
    },
  },

  watch: {
    'itemsList': {
      handler(value) {
        if (this.findingItem && value.length > 0 && this.itemId) {
          const result = value.find((item) => item.id === this.itemId);
          if (result) {
            setFindingStatus(false);

            if (this.itemId) {
              this.$nextTick(() => {
                this.refTable.setCurrentRow(result);
                this.refItem.scrollIntoView();
              });
            }
          } else {
            if (this.page - initialStatePagination.page < 2) {
              this.fetch(this.page + 1, initialStatePagination.limit);
            } else {
              this.fetch(this.initialStatePagination.page, this.initialStatePagination.limit);

              setFindingStatus(false);
            }
          }
        }
      },
      immediate: true,
    },
  },
};

