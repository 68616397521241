export const CURRENCY_TYPE = {
  FIAT: 'fiat',
  CRYPTO: 'crypto',
};

export const CRYPTO_CURRENCIES = {
  'BTC': 'BTC',
  'BCH': 'BCH',
  'BNB': 'BNB',
  'ETH': 'ETH',
  'ETC': 'ETC',
  'LTC': 'LTC',
  'XRP': 'XRP',
  'C+': 'C+',
  'USDT_OMNI': 'USDT_OMNI',
  'USDT_ERC20': 'USDT_ERC20',
  'USDT_TRC20': 'USDT_TRC20',
  'USDT_BEP20': 'USDT_BEP20',
  'USDC': 'USDC',
  'BUSD': 'BUSD',
  'TUSD': 'TUSD',
  'GUSD': 'GUSD',
  'DAI': 'DAI',
  'ETH_BEP20': 'ETH_BEP20',
  'USDC_BEP20': 'USDC_BEP20',
  'USDT_Polygon': 'USDT_Polygon',
  'MATIC_Polygon': 'MATIC_Polygon',
  'TRX': 'TRX',
  'TON': 'TON',
};

export const CRYPTO_CURRENCIES_LIST = Object.values(CRYPTO_CURRENCIES);

export const FIAT_CURRENCIES = {
  EUR: 'EUR',
  USD: 'USD',
  RUB: 'RUB',
};

export const FIAT_CURRENCIES_LIST = Object.values(FIAT_CURRENCIES);

export const TEMPORARILY_UNAVAILABLE_COINS = [
  CRYPTO_CURRENCIES.BCH,
  CRYPTO_CURRENCIES.ETC,
  CRYPTO_CURRENCIES.LTC,
  CRYPTO_CURRENCIES.XRP,
  CRYPTO_CURRENCIES.USDT_OMNI,
  CRYPTO_CURRENCIES.USDT_OMNI,
  CRYPTO_CURRENCIES['C+'],
  CRYPTO_CURRENCIES.TUSD,
  CRYPTO_CURRENCIES.GUSD,
  CRYPTO_CURRENCIES.BUSD,
  FIAT_CURRENCIES.RUB,
];

export const AVAILABLE_CRYPTO_CURRENCY_LIST =
  CRYPTO_CURRENCIES_LIST.filter((item) => !TEMPORARILY_UNAVAILABLE_COINS.includes(item));

export const AVAILABLE_FIAT_CURRENCY_LIST =
  FIAT_CURRENCIES_LIST.filter((item) => !TEMPORARILY_UNAVAILABLE_COINS.includes(item));

export const AVAILABLE_CURRENCY_LIST = [...AVAILABLE_CRYPTO_CURRENCY_LIST, ...AVAILABLE_FIAT_CURRENCY_LIST];

export const USDT_LIKE_CURRENCIES_LIST = [
  CRYPTO_CURRENCIES.USDT_ERC20,
  CRYPTO_CURRENCIES.USDT_TRC20,
  CRYPTO_CURRENCIES.USDT_BEP20,
  CRYPTO_CURRENCIES.USDT_Polygon,
  CRYPTO_CURRENCIES.USDC,
  CRYPTO_CURRENCIES.BUSD,
  CRYPTO_CURRENCIES.DAI,
  CRYPTO_CURRENCIES.TRX,
];

export const ETH_LIKE_CURRENCIES_LIST = [
  CRYPTO_CURRENCIES.BNB,
  CRYPTO_CURRENCIES.BUSD,
  CRYPTO_CURRENCIES.DAI,
  CRYPTO_CURRENCIES.USDC,
  CRYPTO_CURRENCIES.USDT_ERC20,
  CRYPTO_CURRENCIES.USDT_BEP20,
  CRYPTO_CURRENCIES.ETH_BEP20,
  CRYPTO_CURRENCIES.USDC_BEP20,
  CRYPTO_CURRENCIES.USDT_Polygon,
  CRYPTO_CURRENCIES.MATIC_Polygon,
];

export const TON_LIKE_CURRENCIES_LIST = [
  CRYPTO_CURRENCIES.TON,
];

export const SPECIAL_CURRENCIES_LIST = [
  // CRYPTO_CURRENCIES.USDT_Polygon,
  // CRYPTO_CURRENCIES.MATIC_Polygon,
];
export const SPECIAL_CURRENCIES = SPECIAL_CURRENCIES_LIST;

export const CURRENCIES_DISCONNECTED_IN_BALANCES = [
  ...SPECIAL_CURRENCIES_LIST,
  CRYPTO_CURRENCIES.BNB,
];
