import BaseApi from '@/api/base';

export default new class ExchangeApi extends BaseApi {
  /**
   * @param {String} event
   * @param {Object} data
   * @return {Promise<void>}
   */
  initSpamPriceAutoRequest(event, data) {
    return super.post(event, '/api/auto-trading/init-spam-price', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.currencyPair
   * @param {number} data.amount
   * @param {number} data.type
   * @param {string} data.callbackPrice
   * @param {number} data.margin
   * @return {Promise<void>}
   */
  createAutoTradingOrderFirstCurrency(event, data) {
    return super.post(event, '/api/auto-trading/create-auto-first', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.currencyPair
   * @param {number} data.amountLast
   * @param {number} data.type
   * @param {string} data.callbackPrice
   * @param {number} data.margin
   * @return {Promise<void>}
   */
  createAutoTradingOrderSecondCurrency(event, data) {
    return super.post(event, '/api/auto-trading/create-auto-second', {data});
  }

  /**
   * @param {String} event
   * @param {Object} data
   * @param {number} data.requestId
   * @param {number} data.price
   * @param {number} data.margin
   * @param {number} data.marginFiat
   * @param {string} data.hash
   * @param {string} data.marginHash
   * @param {number} data.fullPrice
   * @return {Promise<void>}
   */
  acceptAutoFirstCurrencyOrder(event, data) {
    return super.post(event, '/api/auto-trading/accept-auto-first', {data});
  }

  /**
   * @param {String} event
   * @param {Object} data
   * @param {number} data.requestId
   * @return {Promise<void>}
   */
  cancelAutoFirstCurrencyOrder(event, data) {
    return super.post(event, '/api/auto-trading/cancel-auto-first', {data});
  }

  /**
   * @param {String} event
   * @param {Object} data
   * @param {number} data.requestId
   * @param {number} data.price
   * @param {number} data.margin
   * @param {number} data.marginFiat
   * @param {string} data.hash
   * @param {string} data.marginHash
   * @param {number} data.fullPrice
   * @return {Promise<*>}
   */
  acceptAutoSecondCurrencyOrder(event, data) {
    return super.post(event, '/api/auto-trading/accept-auto-second', {data});
  }

  /**
   * @param {String} event
   * @param {Object} data
   * @param {number} data.requestId
   * @return {Promise<*>}
   */
  cancelAutoSecondCurrencyOrder(event, data) {
    return super.post(event, '/api/auto-trading/cancel-auto-second', {data});
  }
};
