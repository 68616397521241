<template>
  <div v-loading="isLoading" class="ex-recovery-validation-link">
    <ex-authorization-layout>
      <template #title>Join the {{brand}} Digital!</template>
      <template #subTitle>{{email}}</template>
      <ex-alert v-if="message" type="danger">
        <div v-html="message" />
      </ex-alert>
    </ex-authorization-layout>
  </div>
</template>

<script>
// component
import ExAlert from '@/components/ex-alert';
import ExAuthorizationLayout from '@/components/authorization/layout/ex-authorization-layout';

// utils
import {socketRequest} from '@/utils/socket';

// api
import RecoveryApi from '@/api/userService/recovery.api';

// const
import {RECOVERY_VALIDATE_EMAIL_CODE} from '@/constants/events/recovery/actions.type';
import {RECOVERY_SESSION_TIME_LIVED} from '@/constants/common';
import {mapState} from 'vuex';

export default {
  name: 'ExRecoveryValidationLink',
  components: {ExAuthorizationLayout, ExAlert},
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      code: this.$route.params.code,
      token: this.$route.params.token,
      message: '',
    };
  },
  computed: {
    ...mapState('content', {brand: (state) => state.content.brand}),
  },
  created() {
    if (!this.code || !this.token) {
      this.message = 'Password reset link is invalid';
    }

    if (this.code && this.token) {
      this.validateEmail(this.code);
    }
  },
  methods: {
    validateEmail() {
      socketRequest.call(this, RECOVERY_VALIDATE_EMAIL_CODE,
        () => {
          this.$emit('emailStageCompleted');
        },
        (payload) => {
          if (payload.error.code === RECOVERY_SESSION_TIME_LIVED) {
            this.$emit('restartRecovery');
          } else if (payload.handledErrorMessage) {
            this.message = payload.handledErrorMessage;
          }
        },
        () => {
          this.isLoading = false;
        },
      );
      this.isLoading = true;
      this.message = '';
      RecoveryApi.validateRecoveryCode(
        RECOVERY_VALIDATE_EMAIL_CODE,
        {token: this.token, code: this.code, validationType: 'Email'},
      );
    },
  },
};
</script>
