export default {
  // Below is an example of an error message that will be suppressed
  // 8888: DEFAULT_MSG_EXCLUDED,

  202: 'User not found',
  204: 'Empty organization name or organization number',
  206: 'The email address or password you entered is incorrect. Please try again',
  207: 'No phone number',
  211: 'User verification status not found',
  212: 'Currency pair not found',
  213: 'This invite code has expired or doesn\'t exist',
  214: 'Currency not found',
  218: 'User not found to save data',
  219: 'Phone not verified',
  220: 'Email not verified',
  221: 'Verification code already sent',
  222: 'Verification code has expired',
  223: 'Incorrect code',
  224: 'Email code has not expired',
  225: 'Google Authenticator Not Found',
  226: 'CommonId not found',
  227: 'KYC data not found',
  228: 'Not all KYC fields are filled',
  229: 'ISO code not found',
  231: 'Code has expired',
  232: 'Incorrect code',
  233: 'Recovery session time is over',
  234: 'Code not expired',
  235: 'Email not verified',
  236: 'SMS not verified',
  237: 'Google Authenticator not verified',
  238: 'User has not completed verification',
  239: 'Risk category not defined',
  240: 'User not verified via KYC',
  244: 'Insufficient funds',
  247: 'Insufficient funds for network fee',
  249: 'Requires at least one user with Beneficial owner status',
  251: 'This invite code has expired or doesn\'t exist',
  252: 'Impossible to enable one-off code',
  255: 'Application already created',
  256: 'Application not found',
  257: 'Unknown status',
  258: 'Application not found',
  259: 'Company user not found',
  260: 'Agent not found',
  261: 'Agents not found',
  262: 'Agent already exists',
  263: 'Codes not found',
  267: 'Company users not found',
  270: 'Recipient ID is not active',
  271: 'Recipient ID matches yours',
  272: 'The user already exists in the organization',
  273: 'Organization verification status not found',
  274: 'Organization client ID not found',
  275: 'ISO code not found',
  276: 'Invalid verification transition',
  282: 'Wallet not found',
  283: 'Currency not found',
  285: 'No free wallets',
  286: 'Balance not found',
  287: 'Wrong currency wallet',
  291: 'You don\'t have enough funds',
  292: 'Order type not found',
  293: 'You don\'t have enough funds for network fee',
  295: 'Company user\'s KYC status is incomplete',
  296: 'Company user with MAIN USER role not found in organization',
  297: 'It is not possible to delete a company user with MAIN USER role',
  298: 'It is not possible to set/unset the MAIN USER role for a company user',
  299: 'Transaction canceled due to negative withdrawal currency balance',
  300: 'KYC Client Error',
  301: 'KYC Client Error. Invalid Company',
  302: 'KYC Client Error. Invalid Entity Type',
  351: 'Asset experience is correct, but required fields are empty',
  352: 'KYC data not updated',
  360: 'iDenfy Client Error',
  361: 'iDenfy unexpected response! Response: {response}',
  362: 'iDenfy data not found',
  363: 'The maximum number of failures has been reached',
  364: 'iDenfy signature verification error',
  403: 'Subscribers not found',
  368: 'This transaction can\'t be executed. Please, check all fields and try again',
  369: 'This transaction can\'t be executed. Please, check all fields and try again',
  370: 'Recipient ID is not active',
  371: 'Recipient ID matches yours',
  404: 'Object not found',
  605: 'Your data has already been sent to KYC',
  606: 'No data for KYC. Please verify again',
  607: 'Case not found in KYC',
  701: 'Bank not found',
  702: 'User not found for bank link',
  703: 'Organization not found for bank link',
  704: 'The currency payment account with the specified IBAN is already linked to another bank',
  705: 'Organization not found to link to bank',
};
