<template>
  <div class="spinner-wrapper">
    <div class="lds-css ng-scope"><div class="lds-eclipse"><div /></div></div>
  </div>
</template>

<script>
export default {
  name: 'ExSplashScreen',
};
</script>

<style lang="scss">
  @keyframes lds-eclipse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes lds-eclipse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .lds-eclipse {
    position: relative;
  }

  .lds-eclipse div {
    position: absolute;
    -webkit-animation: lds-eclipse 0.5s linear infinite;
    animation: lds-eclipse 0.5s linear infinite;
    width: 80px;
    height: 80px;
    top: 20px;
    left: 20px;
    border-radius: 50%;
    box-shadow: 0 4px 0 0 #ffb040;
    -webkit-transform-origin: 40px 41px;
    transform-origin: 40px 41px;
  }

  .lds-eclipse {
    width: 100px !important;
    height: 100px !important;
    -webkit-transform: translate(-50px, -50px) scale(1) translate(50px, 50px);
    transform: translate(-50px, -50px) scale(1) translate(50px, 50px);
  }

  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
