/**
 * @param {string} name
 * @param {string} value
 */
export function setCookie(name, value) {
  document.cookie = `${ name }=${ value }`;
}

/**
 * @param {string} name
 * @return {any|undefined}
 */
export function getCookieValue(name) {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${ name.replace(/([.$?*|{}()[]\/+^])/g, '\\$1') }=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

/**
 * @type {{
 * isInviteCodeEmpty: (function(): boolean),
 * getCookieInviteCode: (function(): any),
 * setCookieInviteCode: (function(v: string): void)}}
 */
export const registration = {
  /**
   * @param v {string}
   */
  setCookieInviteCode: (v) => {
    setCookie('affiliate-code', v);
  },

  /**
   * @return {any}
   */
  getCookieInviteCode: () => getCookieValue('affiliate-code'),

  /**
   * @return {boolean}
   */
  isInviteCodeEmpty: () => getCookieValue('affiliate-code') == null,
};
