<template>
  <div class="ex-list-content">
    <div v-if="$slots.action" class="ex-list-content__action"><slot name="action"/></div>
    <div class="ex-list-content__content"><slot /></div>
    <div v-if="$slots.pagination" class="ex-list-content__pagination"><slot name="pagination" /></div>
  </div>
</template>

<script>
export default {
  name: 'ExListContent',
};
</script>

<style lang="scss" scoped>
.ex-list-content {
  &__action {
    padding-top: 28px;
    padding-bottom: 12px;
  }
  &__pagination {
    padding: 20px 0 30px;
    ::v-deep {
      .el-pagination {
        padding: 0;
      }
      .el-pagination__jump {
        float: right;
      }
    }
  }
}
</style>
