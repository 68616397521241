'use strict';

/**
 * @param {Array} expectedKeys
 * @param {Object} object
 * @return {boolean}
 */
export function checkExpectedKeys(expectedKeys, object) {
  if (typeof object !== 'object') return false;
  const receivedKeys = Object.keys(object);

  const difference = expectedKeys.filter((x) => !receivedKeys.includes(x));
  return difference.length === 0;
}
