import Vue from 'vue';
import Router from 'vue-router';
import publicRoutes from './public';
import privateRoutes from './private';

Vue.use(Router);

const routes = (store) => [
  {path: '/', name: 'Home', redirect: {name: 'UserRedirect'}},
  {
    path: '/error/:error',
    component: () => import('@/pages/error/ex-error'),
    name: 'ErrorPage',
    props: true,
  },
].concat(publicRoutes(store), privateRoutes(store));

export default (store) => new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes(store),
});
