<template>
  <div class="ex-menu">
    <ex-container>
      <div class="ex-menu__wrap">
        <div class="ex-menu__toolbar">
          <div class="ex-menu__logo">
            <ex-link :href="landingPageUrl">
              <ex-logo />
            </ex-link>
          </div>
          <div class="ex-menu__hamburger">
            <ex-hamburger :value="value" @input="$emit('input', $event)" />
          </div>
        </div>
        <div class="ex-menu__actions">
          <ex-button color="accent" @click="handleClick('sign-up')">
            Sign up
          </ex-button>
          <ex-button type="text" @click="handleClick('sign-in')">
            Already have an account?
          </ex-button>
        </div>
      </div>
    </ex-container>
  </div>
</template>

<script>
// components
import ExContainer from '@/components/ex-container';
import ExHamburger from '@/components/authorization/ui/ex-hamburger';
import ExButton from '@/components/authorization/ui/ex-button';
import ExLink from '@/components/authorization/ui/ex-link';
import ExLogo from '@/components/authorization/ui/ex-logo';

// util
import {LANDING_URL} from '@/utils/appsURLs';

export default {
  name: 'ExMenu',
  components: {
    ExLogo,
    ExLink,
    ExContainer,
    ExHamburger,
    ExButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    landingPageUrl() {
      return `${LANDING_URL}`;
    },
  },
  methods: {
    handleClick(eventName) {
      this.$emit(eventName);
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-menu {
  height: 100%;
  padding-bottom: 48px;
  background: white;
  &__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__toolbar {
    position: relative;
  }
  &__hamburger {
    position: absolute;
    top: 48px;
    right: 0px;
  }
  &__logo {
    position: absolute;
    top: 48px;
    left: 0px;
  }
  &__nav {
    flex: 1;
    ::v-deep .ex-nav {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__link {
        margin-right: 0;
        margin-bottom: 48px;
      }
      .ex-link {
        @include mix--typo-heading4;
      }
    }
  }
  &__actions {
    padding-top: 280px;
    display: flex;
    flex-direction: column;
    ::v-deep .ex-button {
      &, &__button {
        width: 100%;
      }
    }
  }
}
</style>
