<template>
  <div class="ex-deposit-layout">
    <div v-if="$slots.icon" class="ex-deposit-layout__icon">
      <slot name="icon" />
    </div>
    <div v-if="$slots.title" class="ex-deposit-layout__title">
      <slot name="title" />
    </div>
    <div v-if="$slots.default" class="ex-deposit-layout__content">
      <slot />
    </div>
    <div class="ex-deposit-layout__loading">
      <slot name="loading">
        <i class="el-icon-loading ex-deposit-layout__loader" />
        <div class="ex-deposit-layout__caption">
          You will be redirected to Balances page in
          <ex-countdown
            class="ex-deposit-layout__timer"
            :time-data="time"
          >
            <template #default="{prop}">
              {{removeMinuteAddText(prop.time)}}
            </template>
          </ex-countdown>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
// component
import ExCountdown from '@/components/ex-countdown';

// const
import {AUTH} from '@/constants/events/cardPay/actions.type';

// util
import {socketRequest} from '@/utils/socket';

// app
import {mapActions, mapMutations} from 'vuex';

const DELAY = 5000;

export default {
  name: 'ExDepositTemplate',
  components: {ExCountdown},
  props: {
    authFunction: {
      type: Function,
      default: () => {},
      required: true,
    },
  },
  created() {
    this.authFunction(AUTH, {code: this.$route.params.key});
    socketRequest.call(this, AUTH,
      (payload) => {
        setTimeout(() => {
          this.setAuthData(payload);
        }, DELAY);
      },
      () => {
        setTimeout(() => {
          this.$router.push({name: 'BalancesIndex'});
        }, DELAY);
      },
    );
  },
  computed: {
    time() {
      return {
        from: Date.now(),
        to: Date.now() + DELAY,
      };
    },
  },
  methods: {
    ...mapActions('auth', ['login']),
    ...mapMutations('currentUser', ['setId']),
    setAuthData(payload) {
      const userId = payload.accessId.split(':')[0];
      this.login(payload);
      this.setId({userId});
    },
    removeMinuteAddText(time) {
      const second = time.split(':')[1];
      return `${second} ${!['01', '00'].includes(second) ? 'secs' : 'sec'}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-deposit-layout {
  text-align: center;
  &__icon {
    font-size: 48px;
    margin: 20px 0;
  }
  &__title {
    font-weight: 600;
  }
  &__content {
    margin-bottom: 35px;
  }
  &__loading {
    margin-bottom: 60px;
  }
  &__loader {
    font-size: 80px;
  }
  &__caption {
    font-size: 15px;
    color: #8e8e93;
  }
  &__timer {
    display: inline-block;
  }
}
</style>
