<template>
  <div class="ex-alert" :style="`background-color: ${typeProp.bg}; border-left-color: ${typeProp.color};`">
    <p>
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'ExAlert',
  props: {
    type: {
      type: String,
      default: 'info',
    },
  },

  computed: {
    typeProp() {
      switch (this.type) {
        case 'info':
          return {
            color: 'rgb(144, 147, 153)',
            bg: 'rgb(233, 233, 235)',
          };

        case 'danger':
          return {
            color: 'rgb(245, 108, 108)',
            bg: 'rgb(253, 226, 226)',
          };

        case 'warning':
          return {
            color: 'rgb(230, 162, 60)',
            bg: 'rgb(250, 236, 216)',
          };

        case 'success':
          return {
            color: 'rgb(102, 194, 58)',
            bg: 'rgb(225, 243, 215)',
          };

        default:
          return {color: 'silver', bg: 'transparent'};
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ex-alert {
  padding: 8px 16px;
  border-radius: 4px;
  border-left-width: 5px;
  border-left-style: solid;

  p {
    font-size: 16px;
    color: #5e6d82;
    line-height: 1.5em;
    margin: 14px 0;
  }
}
</style>
