import BaseApi from '@/api/base';

export default new class RequestApi extends BaseApi {
  /**
   * Fetch list of requests for "Order History" table
   * @param {string} event
   * @param {Object} data
   * @return {Promise<void>}
   */
  fetchOrderHistoryList(event, data) {
    return super.post(event, '/api/request/order-history-list', data);
  }

  /**
   * Fetch list of requests for "Trade History" table
   * @param {string} event
   * @param {Object} data
   * @return {Promise<void>}
   */
  fetchTradeHistoryList(event, data) {
    return super.post(event, '/api/request/trade-history-list', data);
  }

  /**
   * Fetch list of requests for "Open Orders" table
   * @param {string} event
   * @return {Promise<void>}
   */
  fetchOpenOrderList(event) {
    return super.post(event, '/api/request/open-order-list');
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.amount
   * @param {string} data.destinationAddress
   * @param {string} data.currency
   * @param {string} [data.hashNetworkFee]
   * @param {string} [data.currencyNetworkFee]
   * @param {number} [data.amountNetworkFee]
   * @param {string} [data.reference]
   * @return {Promise<void>}
   */
  createWithdrawCryptoManual(event, data) {
    return super.post(event, '/api/request/create-withdraw-crypto-manual', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.amount
   * @param {string} data.destinationIban
   * @param {string} data.currency
   * @param {string} [data.beneficiaryName]
   * @param {string} [data.reference]
   * @return {Promise<void>}
   */
  createWithdrawFiatManual(event, data) {
    return super.post(event, '/api/request/create-withdraw-fiat-manual', {data});
  }

  /**
  * Fetch list Fiat auto withdrawal table
  * @param {string} event
  * @param {Object} data
  * @return {Promise<void>}
  */
  fetchFiatAutoWithdrawalList(event, data) {
    return super.post(event, '/api/request/withdrawal/fiat-auto-withdrawal-list', data);
  }

  /**
  * Create Fiat auto withdrawal request
  * @param {string} event
  * @param {Object} data
  * @return {Promise<void>}
  */
  createFiatAutoWithdraw(event, data) {
    return super.post(event, '/api/request/withdrawal/fiat-auto-withdrawal-create', {data});
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchWithdrawalListInProgress(event) {
    return super.post(event, '/api/request/fetch-request-list-in-progress');
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.userId
   * @param {number} data.requestId
   * @return {Promise<*>}
   */
  fetchRequest(event, data) {
    return super.post(event, '/api/request/request', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.exchangeId
   * @return {Promise<*>}
   */
  fetchRequestLastTransaction(event, data) {
    return super.post(event, '/api/request/fetch-last-transaction', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.exchangeId
   * @return {Promise<*>}
   */
  fetchRequestTransactionHistory(event, data) {
    return super.post(event, '/api/request/fetch-history', {data});
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchWithdrawCryptoAutoListCurrencies(event) {
    return super.post(event, '/api/request/fetch-withdraw-crypto-auto-list-currencies');
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchWithdrawFiatManualListCurrencies(event) {
    return super.post(event, '/api/request/fetch-withdraw-fiat-manual-list-currencies');
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchWithdrawCryptoManualListCurrencies(event) {
    return super.post(event, '/api/request/fetch-withdraw-crypto-manual-list-currencies');
  }
};
