<template>
  <div class="ex-icon-alert">
    <div class="ex-icon-alert__icon">
      <i class="el-icon-info" />
    </div>
    <div class="ex-icon-alert__message">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExIconAlert',
  props: {
    type: {
      type: String,
      default: 'info',
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-icon-alert {
  border-radius: 4px;
  background: #F9F9FA;
  padding: 15px;
  display: flex;
  align-items: center;
  &__icon {
    padding-right: 15px;
    i {
      font-size: 30px;
      color: darken(#F9F9FA, 15%);
    }
  }
  &__message {
    width: 100%;
    font-size: 14px;
    color: #606266;
  }
}
</style>
