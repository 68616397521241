<template>
  <div class="ex-table-remote-filter-checkbox">
    <ex-table-remote-filter :placement="placement" :cancel-trigger="cancelTrigger" @confirm="handleUpdate">
      <template #label>
        {{label}}
      </template>
      <el-checkbox-group v-model="propValue" class="ex-table-remote-filter-checkbox__group-vertical">
        <el-checkbox v-for="(item, index) in filters" :key="index" :label="item.value">{{item.text}}</el-checkbox>
      </el-checkbox-group>
      <el-button @click="handleCancel" size="mini">Reset</el-button>
      <template #displayValue>
        <div v-if="displayValue" class="ex-table-remote-filter-checkbox__display_value">
          {{displayValue}}
        </div>
      </template>
    </ex-table-remote-filter>
  </div>
</template>

<script>
// component
import ExTableRemoteFilter from '@/components/TableFilters/ex-table-remote-filter';

// utils
import {getPairLabel} from '@/utils/converters/currency';

export default {
  name: 'ExTableRemoteFilterCheckbox',
  components: {ExTableRemoteFilter},
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    clearTrigger: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    label: {
      type: String,
      default: '',
    },
    hasPair: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      propValue: [],
      cancelTrigger: false,
    };
  },
  computed: {
    displayValue() {
      if (this.propValue) {
        return this.propValue.reduce((previousValue, item, index, array) => {
          const value = this.hasPair ? getPairLabel(item) : item;
          if (array[index + 1]) {
            return previousValue += `${value}, `;
          }
          return previousValue += value;
        }, '');
      }
      return '';
    },
  },
  watch: {
    clearTrigger(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearValue();
      }
    },
    value: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.propValue = newValue;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleCancel() {
      this.clearValue();
      this.cancelTrigger = !this.cancelTrigger;
      this.handleUpdate();
    },
    handleUpdate() {
      this.$emit('update', this.propValue);
    },
    clearValue() {
      this.propValue = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-table-remote-filter-checkbox {
  display: inline-block;
  line-height: initial;
  &, div {
    padding: initial;
    line-height: initial;
    overflow: initial;
  }
  &__group-vertical {
    padding-top: 12px;
    label {
      display: block;
    }
  }
  &__display_value {
    word-break: break-word;
  }
}
</style>
