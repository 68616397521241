import Vue from 'vue';
import Vuex from 'vuex';
import getters from '@/store/getters';
import auth from '@/store/modules/auth';
import currentUser from '@/store/modules/currentUser';
import requests from '@/store/modules/requests';
import merchantInvoice from '@/store/modules/merchantInvoice';
import content from '@/store/modules/content';
import appState from '@/store/modules/appState';
import exchange from '@/store/modules/exchange';

Vue.use(Vuex);

const StoreData = {
  modules: {
    auth,
    currentUser,
    requests,
    merchantInvoice,
    content,
    appState,
    exchange,
  },
  getters,
};

export default new Vuex.Store(StoreData);
