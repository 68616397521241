<template>
  <div class="ex-setting-template-row">
    <div class="ex-setting-template-row__label"><slot name="label" /></div>
    <div class="ex-setting-template-row__value"><slot /></div>
  </div>
</template>

<script>
export default {
  name: 'ExSettingTemplateRow',
};
</script>

<style lang="scss" scoped>
.ex-setting-template-row {
  margin-bottom: 35px;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  width: 50%;
  padding: 0 20px 0 0;
  &__block {
    font-size: 18px;
    width: 100%;
    margin-bottom: 35px;
  }
  &__label {
    color: #777;
    font-size: 15px;
    margin-bottom: 5px;
  }
  &__value {
    font-size: 20px;
  }
}
</style>
