import {TEMPORARILY_UNAVAILABLE_COINS} from '@/constants/currencies';

export const filterCoinsAndFormType = (balances, balanceType) =>
  balances.filter(
    (balance) => balance.type == balanceType &&
      !TEMPORARILY_UNAVAILABLE_COINS.some(
        (item) => item === balance.currency,
      ),
  );

export const filterCoinByList = (balances, list) =>
  balances.filter((balance) => list.includes(balance.currency));

export const filterCoins = (balances) =>
  balances.filter(
    (balance) => !TEMPORARILY_UNAVAILABLE_COINS.some(
      (item) => item === balance.currency,
    ),
  );
