<template>
  <div class="ex-withdrawal-fiat">
    <portal to="toolbar__title">Fiat Currency Withdrawal</portal>
    <div class="ex-withdrawal-fiat__coin">
      <ex-coin-list
        v-loading="isFetching"
        :list="listCurrencies"
        :value="form.currency"
        @input="form.currency = $event.currency"
      />
    </div>
    <div class="ex-withdrawal-fiat__form">
      <el-form :model="form" :rules="rules" label-position="top" ref="form">
        <el-form-item label="Advance">
          <el-switch v-model="isAdvance" active-text="Enable" inactive-text="Disable"/>
        </el-form-item>
        <el-form-item label="IBAN" prop="destinationIban">
          <el-input
            v-model.trim="form.destinationIban"
            placeholder="LT553510000030102251"
            name="iban"
            autocomplete="on"
          />
        </el-form-item>
        <el-form-item label="You spend" prop="amount">
          <el-input placeholder="ex: 100" ref="amount" :value="form.amount" @input="handleInputAmount">
            <el-button slot="append" ref="amount-max" @click="handleInputAmount(maxAmount)">MAX</el-button>
          </el-input>
        </el-form-item>
        <div v-if="isAdvance" class="ex-withdrawal-fiat__form-advance">
          <el-form-item label="Beneficiary name" prop="beneficiaryName">
            <el-input v-model.trim="form.beneficiaryName" placeholder="ex: Bob" />
          </el-form-item>
          <el-form-item label="Reference" prop="reference">
            <el-input v-model="form.reference" />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="ex-withdrawal-fiat__info">
      <ex-withdrawal-info
        :amount="form.amount"
        :address="form.destinationIban"
        :currency="form.currency"
        :is-no-funds="maxAmount <= 0"
      />
    </div>
    <div class="ex-withdrawal-fiat__action">
      <el-button @click="$router.push({name: 'BalancesIndex'})">CANCEL</el-button>
      <el-button
        type="warning"
        :disabled="maxAmount <= 0"
        :loading="isLoading"
        @click="handleSend"
      >
        SEND
      </el-button>
    </div>
  </div>
</template>

<script>
// api
import RequestApi from '@/api/requestService/request.api';

// util
import inputmasks from '@/utils/inputmasks';
import {numberFromLocaleString} from '@/utils/converters/currency';
import {socketRequest} from '@/utils/socket';
import {ruleTypicalCharacters, ruleMaxLength, ruleRequired} from '@/utils/elementUITypicalValidation';

// lib
import {mapGetters} from 'vuex';

// component
import ExCoinList from '@/components/ex-coin-list';
import ExWithdrawalInfo from '@/pages/withdrawal/ex-withdrawal-info';

// const
import {CURRENCY_TYPE} from '@/constants/currencies';
import {
  CREATE_WITHDRAW_FIAT_MANUAL,
  FETCH_WITHDRAW_FIAT_MANUAL_LIST_CURRENCIES,
} from '@/constants/events/requests/actions.type';

export default {
  name: 'ExWithdrawalFiat',
  components: {ExWithdrawalInfo, ExCoinList},
  data() {
    const onlyNumbersLettersSpaces = new RegExp(/^[a-z\d ]+$/, 'i');
    const descriptionValidator = (rule, value, callback) => {
      if (value && !onlyNumbersLettersSpaces.test(value)) {
        return callback(new Error('Only numbers, letters and spaces are allowed'));
      }
      return callback();
    };
    return {
      form: {
        currency: '',
        destinationIban: '',
        amount: '',
        beneficiaryName: '',
        reference: '',
      },
      rules: {
        destinationIban: [
          ruleRequired,
          ruleMaxLength(34),
          ruleTypicalCharacters,
        ],
        amount: [ruleRequired],
        beneficiaryName: [
          ruleRequired,
          ruleMaxLength(64),
          {validator: descriptionValidator, trigger: 'blur'},
        ],
        reference: [ruleRequired, ruleMaxLength(64)],
      },
      listCurrencies: [],
      isAdvance: false,
      isLoading: false,
      isFetching: false,
    };
  },
  computed: {
    ...mapGetters('currentUser', ['fiatBalances', 'currentId']),
    maxAmount() {
      if (this.form.currency === '') return 0;
      const coin = this.fiatBalances.find((item) => item.currency === this.form.currency);

      if (coin === undefined || !_.has(coin, 'amount')) return 0;
      if (coin.amount < 0) return 0;
      return coin.amount;
    },
  },
  watch: {
    'form.currency': {
      handler(newValue, oldValue) {
        if (newValue === oldValue) return;
        this.$emit('currency', newValue);
        this.handleInputAmount(this.form.amount);

        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        });
      },
    },
  },
  created() {
    this.fetchListCurrencies();
  },
  mounted() {
    const refAmount = this.$refs['amount'].$el.getElementsByTagName('INPUT')[0];
    inputmasks[CURRENCY_TYPE.FIAT].call(this.$refs['amount']).mask(refAmount);
  },
  methods: {
    handleInputAmount(amount) {
      const clearAmount = amount === '' ? 0 : numberFromLocaleString(amount);

      if (this.maxAmount >= clearAmount) {
        this.form.amount = amount;
      } else {
        this.form.amount = this.maxAmount;
      }
    },
    handleSend() {
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;

        const data = Object.assign({}, this.form);
        data.amount = numberFromLocaleString(data.amount);

        socketRequest.call(this, CREATE_WITHDRAW_FIAT_MANUAL,
          () => {
            this.$router.push({name: 'BalancesIndex'});
            return 'A new manual withdrawal request has been created';
          },
          () => {},
          () => {
            this.isLoading = false;
          },
        );
        this.isLoading = true;
        RequestApi.createWithdrawFiatManual(CREATE_WITHDRAW_FIAT_MANUAL, data);
      });
    },
    fetchListCurrencies() {
      socketRequest.call(this, FETCH_WITHDRAW_FIAT_MANUAL_LIST_CURRENCIES,
        (payload) => {
          if (!_.isArray(payload)) return;
          this.listCurrencies = this.fiatBalances.filter((item) => payload.includes(item.currency));
          if (payload.includes(this.$route.params.currency)) {
            this.form.currency = this.$route.params.currency;
          } else if (payload[0]) {
            this.form.currency = payload[0];
          }
        },
        () => {},
        () => {
          this.isFetching = false;
        },
      );
      this.isFetching = true;
      RequestApi.fetchWithdrawFiatManualListCurrencies(FETCH_WITHDRAW_FIAT_MANUAL_LIST_CURRENCIES);
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-withdrawal-fiat {
  &__message {
    margin-bottom: 10px;
  }
  &__coin {
    padding: 0 0 20px 0;
  }
  &__form {
    padding-bottom: 40px;
  }
  &__info {
    line-height: 21px;
    color: #606266;
    padding-bottom: 22px;
    &-highlight {
      font-weight: 700;
    }
  }
  &__action {
    display: flex;
  }
  &__action > * {
    margin-right: 10px;
  }
  &__fee-info {
    margin-left: 10px;
  }
}
</style>
