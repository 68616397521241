'use strict';
import {CRYPTO_CURRENCIES, FIAT_CURRENCIES} from '@/constants/currencies';

/**
 * @param {String} name
 * @return {Object}
 */
export function getCurrency(name) {
  const result = {
    icon: '',
    name: 'NaN',
    label: 'NaN',
  };

  switch (name) {
    case CRYPTO_CURRENCIES.BTC:
      return {
        ...result,
        icon: '/img/icons/coin_btc.svg',
        name: 'BTC',
        label: 'Bitcoin',
      };

    case CRYPTO_CURRENCIES.BCH:
      return {
        ...result,
        icon: '/img/icons/BTC.png',
        name: 'BCH',
        label: 'Bitcoin Cash',
      };

    case CRYPTO_CURRENCIES['C+']:
      return {
        ...result,
        icon: '/img/icons/C+.png',
        name: 'C+',
        label: 'C+',
      };

    case CRYPTO_CURRENCIES.ETC:
      return {
        ...result,
        icon: '/img/icons/ETC.png',
        name: 'ETC',
        label: 'Ethereum Classic',
      };

    case CRYPTO_CURRENCIES.ETH:
      return {
        ...result,
        icon: '/img/icons/coin_eth.svg',
        name: 'ETH',
        label: 'Ethereum',
      };

    case CRYPTO_CURRENCIES.BNB:
      return {
        ...result,
        icon: '/img/icons/coin_bnb.svg',
        name: 'BNB',
        label: 'Binance Coin',
      };

    case FIAT_CURRENCIES.EUR:
      return {
        ...result,
        icon: '/img/icons/EUR.png',
        name: 'EUR',
        label: 'Euro',
      };

    case CRYPTO_CURRENCIES.LTC:
      return {
        ...result,
        icon: '/img/icons/LTC.png',
        name: 'LTC',
        label: 'Litecoin',
      };

    case FIAT_CURRENCIES.RUB:
      return {
        ...result,
        icon: '/img/icons/RUB.png',
        name: 'RUB',
        label: 'Ruble',
      };

    case FIAT_CURRENCIES.USD:
      return {
        ...result,
        icon: '/img/icons/USD.png',
        name: 'USD',
        label: 'Dollar',
      };

    case CRYPTO_CURRENCIES.USDT_ERC20:
      return {
        ...result,
        icon: '/img/icons/usdt_erc20.svg',
        name: 'USDT_ERC20',
        label: 'Tether ERC20',
      };

    case CRYPTO_CURRENCIES.USDT_TRC20:
      return {
        ...result,
        icon: '/img/icons/usdt_trc20.svg',
        name: 'USDT_TRC20',
        label: 'Tether TRC20',
      };

    case CRYPTO_CURRENCIES.USDT_BEP20:
      return {
        ...result,
        icon: '/img/icons/usdt_bep20_new.svg',
        name: 'USDT_BEP20',
        label: 'Tether BEP20',
      };

    case CRYPTO_CURRENCIES.USDT_OMNI:
      return {
        ...result,
        icon: '/img/icons/USDT_OMNI.png',
        name: 'USDT_OMNI',
        label: 'Tether Omni',
      };

    case CRYPTO_CURRENCIES.XRP:
      return {
        ...result,
        icon: '/img/icons/XRP.png',
        name: 'XRP',
        label: 'XRP',
      };

    case CRYPTO_CURRENCIES.USDC:
      return {
        ...result,
        icon: '/img/icons/coin_usdc.svg',
        name: 'USDC',
        label: 'USD Coin',
      };

    case CRYPTO_CURRENCIES.BUSD:
      return {
        ...result,
        icon: '/img/icons/coin_busd.svg',
        name: 'BUSD',
        label: 'Binance USD',
      };

    case CRYPTO_CURRENCIES.DAI:
      return {
        ...result,
        icon: '/img/icons/coin_dai.svg',
        name: 'DAI',
        label: 'Dai',
      };

    case CRYPTO_CURRENCIES.ETH_BEP20:
      return {
        ...result,
        icon: '/img/icons/ETH_BEP20.png',
        name: 'ETH BEP20',
        label: 'ETH BEP20',
      };

    case CRYPTO_CURRENCIES.USDC_BEP20:
      return {
        ...result,
        icon: '/img/icons/USDC_BEP20.png',
        name: 'USDC BEP20',
        label: 'USDC BEP20',
      };

    case CRYPTO_CURRENCIES.MATIC_Polygon:
      return {
        ...result,
        icon: '/img/icons/MATIC_POLIGON.png',
        name: 'MATIC Polygon',
        label: 'MATIC Polygon',
      };

    case CRYPTO_CURRENCIES.USDT_Polygon:
      return {
        ...result,
        icon: '/img/icons/USDT_POLIGON.png',
        name: 'USDT Polygon',
        label: 'USDT Polygon',
      };

    case CRYPTO_CURRENCIES.TRX:
      return {
        ...result,
        icon: '/img/icons/TRX.png',
        name: 'TRX',
        label: 'TRON',
      };

    case CRYPTO_CURRENCIES.TON:
      return {
        ...result,
        icon: '/img/icons/TON.png',
        name: 'TON',
        label: 'TON',
      };

    default: return result;
  }
}
