'use strict';

const hostnames = {
  prod: 'app.ecng.digital',
  live: 'sandbox-live-app.ecng.digital',
  stage: 'staging-app.ecng.digital',
  dev: 'dev-app.ecng.digital',
  local: 'dev-app.ecng.local',
  corner: 'app.mycorner.digital',
};
const fileStorage = {
  prod: 'files.ecng.digital',
  live: 'sandbox-live-files.ecng.digital',
  stage: 'staging-files.ecng.digital',
  dev: 'dev-files.ecng.digital',
  local: 'dev-files.ecng.local',
  corner: 'files.mycorner.digital',
};
const invoice = {
  prod: 'invoicing.ecng.digital',
  live: 'sandbox-live-invoicing.ecng.digital',
  stage: 'staging-invoicing.ecng.digital',
  dev: 'dev-invoicing.ecng.digital',
  local: 'dev-invoicing.ecng.local',
  corner: 'invoicing.mycorner.digital',
};
const landing = {
  prod: 'ecng.digital',
  live: 'sandbox-live.ecng.digital',
  stage: 'staging.ecng.digital',
  dev: 'dev.ecng.digital',
  local: 'dev.ecng.local',
  corner: 'mycorner.digital',
};

const config = {hostnames, fileStorage, invoice, landing};

const internalServiceURLs = (name) => {
  if (config[name]) {
    for (const key in config.hostnames) {
      if (window.location.hostname === config.hostnames[key] && Object.hasOwnProperty.call(config.hostnames, key)) {
        return config[name][key];
      }
    }
  }
  return undefined;
};

export const APP_URL = `${window.location.protocol}//${window.location.hostname}`;
export const FILE_STORAGE_URL = `${window.location.protocol}//${internalServiceURLs('fileStorage')}`;
export const INVOICE_URL = `${window.location.protocol}//${internalServiceURLs('invoice')}`;
export const LANDING_URL = `${window.location.protocol}//${internalServiceURLs('landing')}`;
