<template>
  <div class="ex-internal-transfer-create">
    <portal to="toolbar__title">
      Internal Transfer
      <div class="ex-internal-transfer-create__title">
        Your recipient ID:
        <span class="ex-internal-transfer-create__title-info">{{recipientId}}</span>
      </div>
    </portal>
    <el-form :model="form" :rules="rules" label-position="top" ref="form" class="ex-internal-transfer-create__form">
      <ex-alert v-if="!hasInternalTransferData" type="danger" class="ex-internal-transfer-create__message">
        {{WARNING_MESSAGE_NO_ENOUGH_DATA}}
      </ex-alert>
      <el-form-item label="Recipient ID" prop="recipientId">
        <el-input
          v-model.trim="form.recipientId"
          placeholder="ex.: INT12345678TR"
          name="recipientId"
          ref="recipientId"
        />
<!-- autocomplete="on" TODO fix mask-->
      </el-form-item>
      <el-form-item prop="grossAmount">
        <template #label>
          <div class="ex-internal-transfer-create__input-label">Gross amount</div>
          <ex-tooltip-typical>
            Gross amount stands for the total amount before any deductions and fees
          </ex-tooltip-typical>
        </template>
        <el-input :value="form.grossAmount" @input="handleGrossAmount" ref="grossAmount" >
          <el-button slot="append" ref="amount-max" @click="handleGrossAmount(maxAmount)">MAX</el-button>
        </el-input>
      </el-form-item>
      <el-form-item prop="currency">
        <ex-coin-list :list="currencies" :value="form.currency" @input="form.currency = $event.currency" />
      </el-form-item>
      <el-form-item prop="netAmount">
        <template #label>
          <div class="ex-internal-transfer-create__input-label">Net amount</div>
          <ex-tooltip-typical>
            Net amount stands for the amount left over after all deductions are made
          </ex-tooltip-typical>
        </template>
        <el-input :value="form.netAmount" @input="handleNetAmount" ref="netAmount">
          <el-button slot="append" @click="handleGrossAmount(maxAmount)">MAX</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="Reference" prop="reference">
        <el-input v-model="form.reference" />
      </el-form-item>
    </el-form>
    <div class="ex-internal-transfer-create__info">
      <ex-summary-info
        :address="form.recipientId"
        :gross-amount="form.grossAmount"
        :net-amount="form.netAmount"
        :net-currency="form.currency"
        :service-fee="withdrawalFeeAmount"
        sub-extended
      >
        <template #address>Recipient ID</template>
      </ex-summary-info>
      <ex-alert v-if="!hasInternalTransferData" type="danger" class="ex-internal-transfer-create__message">
        {{WARNING_MESSAGE_NO_ENOUGH_DATA}}
      </ex-alert>
    </div>
    <div class="ex-internal-transfer-create__actions">
      <el-button
        :loading="isCreating"
        :disabled="!hasInternalTransferData"
        @click="createInternalTransfer"
        type="warning"
      >
        SEND
      </el-button>
    </div>
  </div>
</template>

<script>
// app
import {mapGetters, mapState} from 'vuex';

// util
import {socketRequest} from '@/utils/socket';
import {
  cutPrecisionByCurrency,
  getPrecisionByCurrency,
  numberFromLocaleString,
} from '@/utils/converters/currency';
import {ruleMaxLength, ruleRequired} from '@/utils/elementUITypicalValidation';
import inputmasks from '@/utils/inputmasks';

// api
import InternalTransferApi from '@/api/internalTransfer.api';

// component
import ExTooltipTypical from '@/components/ex-tooltip-typical';
import ExCoinList from '@/components/ex-coin-list';
import ExSummaryInfo from '@/components/ex-summary-info';
import ExAlert from '@/components/ex-alert';

// const
import {
  CREATE_INTERNAL_TRANSFER,
  FETCH_CURRENCIES_INTERNAL_TRANSFER,
} from '@/constants/events/internalTransfer/actions.type';
import {WARNING_MESSAGE_NO_ENOUGH_DATA} from '@/constants/commonMessage';

export default {
  name: 'ExInternalTransferCreate',
  components: {ExAlert, ExSummaryInfo, ExCoinList, ExTooltipTypical},
  data() {
    return {
      hasNetAmount: false,
      form: {
        recipientId: '',
        currency: '',
        grossAmount: '',
        netAmount: '',
        reference: '',
      },
      rules: {
        recipientId: [
          ruleRequired,
          // TODO ADD MASK, UPDATE AFTER
          {min: 13, max: 13, message: 'The field must be 13 characters long', trigger: ['blur', 'change']},
        ],
        currency: [ruleRequired],
        grossAmount: [ruleRequired],
        netAmount: [ruleRequired],
        reference: [ruleMaxLength(64)],
      },
      currencies: [],
      isFetching: false,
      isCreating: false,
      WARNING_MESSAGE_NO_ENOUGH_DATA,
    };
  },
  computed: {
    ...mapState('currentUser', {
      recipientId: (state) => state.preset.internalTransfer.recipientId,
      withdrawFee: (state) => state.preset.internalTransfer.withdrawFee,
    }),
    ...mapGetters('currentUser', ['cryptoBalances', 'hasInternalTransferData']),
    maxAmount() { // TODO CREATE AND USE UNIFIED METHOD maxAmount
      const coinBalance = this.cryptoBalances.find((item) => item.currency === this.form.currency);
      if (coinBalance === undefined || !_.has(coinBalance, 'amount')) return 0;
      if (coinBalance.amount <= 0) return 0;
      // TODO: HARD CODE! Numeric inputMask doesn't rounds value [https://github.com/RobinHerbots/Inputmask/issues/754]
      return cutPrecisionByCurrency(coinBalance.amount, this.form.currency);
    },
    withdrawalFeeAmount() {
      return (numberFromLocaleString(this.form.grossAmount) * this.withdrawFee) / 100;
    },
  },
  watch: {
    'form.currency': {
      handler(currency) {
        if (this.hasNetAmount && this.form.netAmount !== '') {
          this.handleNetAmount(cutPrecisionByCurrency(numberFromLocaleString(this.form.netAmount), currency));
        } else if (!this.hasNetAmount && this.form.grossAmount !== '') {
          this.handleGrossAmount(cutPrecisionByCurrency(numberFromLocaleString(this.form.grossAmount), currency));
        }

        this.$nextTick(() => {
          this.$refs.form.clearValidate();
          const refRecipientId = this.$refs['recipientId'].$el.getElementsByTagName('INPUT')[0];
          inputmasks.recipientId.call(this.$refs['recipientId']).mask(refRecipientId);
          inputmasks.addMaskByCurrency(currency, this.$refs.grossAmount);
          inputmasks.addMaskByCurrency(currency, this.$refs.netAmount);
        });
      },
    },
  },
  created() {
    this.fetchListCurrencies();
  },
  methods: {
    fetchListCurrencies() {
      socketRequest.call(this, FETCH_CURRENCIES_INTERNAL_TRANSFER,
        (payload) => {
          if (!_.isArray(payload)) return;
          this.currencies = this.cryptoBalances.filter((item) => payload.includes(item.currency));
          this.form.currency = payload[0];
        },
        () => {},
        () => {
          this.isFetching = false;
        },
      );
      this.isFetching = true;
      InternalTransferApi.fetchCurrenciesInternalTransfer(FETCH_CURRENCIES_INTERNAL_TRANSFER);
    },
    handleGrossAmount(amountStr) {
      const grossAmount = amountStr === '' ? 0 : numberFromLocaleString(amountStr);
      this.form.grossAmount = this.maxAmount >= grossAmount ? amountStr : this.maxAmount;
      const currentGrossAmount = numberFromLocaleString(this.form.grossAmount);
      const withdrawalFeeAmount = (currentGrossAmount * this.withdrawFee * 0.01)
        .toFixed(getPrecisionByCurrency(this.form.currency));
      this.form.netAmount = (currentGrossAmount - withdrawalFeeAmount)
        .toFixed(getPrecisionByCurrency(this.form.currency));
      this.hasNetAmount = false;
    },
    handleNetAmount(amount) {
      const netAmount = amount === '' ? 0 : numberFromLocaleString(amount);
      const grossAmount =
        (netAmount / ((100 - this.withdrawFee) * 0.01)).toFixed(getPrecisionByCurrency(this.form.currency));
      if (this.maxAmount >= grossAmount) {
        this.form.netAmount = amount;
        this.form.grossAmount = grossAmount;
        this.hasNetAmount = true;
      } else {
        this.handleGrossAmount(this.maxAmount);
      }
    },
    createInternalTransfer() {
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;
        socketRequest.call(this, CREATE_INTERNAL_TRANSFER,
          () => {
            this.$router.push({name: 'BalancesIndex'});
            return 'Internal transfer successfully created';
          },
          () => {},
          () => {
            this.isCreating = false;
          },
        );
        this.isCreating = true;
        const {grossAmount, netAmount, ...data} = this.form;
        data.hasNetAmount = this.hasNetAmount;
        data.amount = this.hasNetAmount ? numberFromLocaleString(netAmount) : numberFromLocaleString(grossAmount);
        InternalTransferApi.createInternalTransfer(CREATE_INTERNAL_TRANSFER, data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-internal-transfer-create {
  &__input-label {
    display: inline-block;
    padding-right: 8px;
  }
  &__title {
    font-size: 14px;
    font-weight: 300;
    &-info {
      font-weight: 600;
    }
  }
  &__message {
    margin-bottom: 10px;
  }
  &__form {
    padding-bottom: 8px;
  }
  &__actions {
    padding-bottom: 12px;
  }
}
</style>
