<template>
  <img
    :style="`width: ${size}px; height: ${size}px;`"
    src="/img/logo.png"
    class="ex-logo"
    alt="logo"
  >
</template>

<script>
export default {
  name: 'ExLogo',
  props: {
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-logo {
  cursor: pointer;
  &:hover {
    opacity: .7;
  }
  &:active {
    opacity: 1;
  }
}
</style>
