const UNVERIFIED = 1;
const WAITING = 4;
const GENERAL = 5;
const IDV_LIMITED = 2;
const IDV_GENERAL = 3;
const AML_APPROVED = WAITING;

export const VERIFICATION_STATUSES_INDIVIDUAL = {
  VALUE: {
    UNVERIFIED,
    IDV_LIMITED,
    IDV_GENERAL,
    AML_APPROVED,
    WAITING,
    GENERAL,
  },
  LABEL: {
    [UNVERIFIED]: 'No',
    [IDV_LIMITED]: 'IDV Limited',
    [IDV_GENERAL]: 'IDV General',
    [AML_APPROVED]: 'AML Approved',
    [WAITING]: 'AML Approved',
    [GENERAL]: 'General',
  },
};
const IDV_APPROVED = 2;
const KYB_SUBMITTED = 3;
const KYB_APPROVED = WAITING;
export const VERIFICATION_STATUSES_COMPANY = {
  VALUE: {
    UNVERIFIED,
    IDV_APPROVED,
    KYB_SUBMITTED,
    KYB_APPROVED,
    WAITING,
    GENERAL,
  },
  LABEL: {
    [UNVERIFIED]: 'No',
    [IDV_APPROVED]: 'IDV Approved',
    [KYB_SUBMITTED]: 'KYB Submitted',
    [KYB_APPROVED]: 'KYB Approved',
    [WAITING]: 'KYB Approved',
    [GENERAL]: 'General',
  },
};
