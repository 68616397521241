import {hasPayloadError} from '@/utils/error';
import ErrorNotifier from '@/utils/socketError/errorNotifier';
import eventBus from '@/utils/eventBus';
import StoreErrorNotifier from '@/store/storeErrorNotifier';

/**
 **/
export default class SocketErrorHandler {
  /**
   * @param {Object} vueSocketIO
   */
  constructor(vueSocketIO) {
    this.subscribeToSocketMessages(vueSocketIO);
  }

  /**
   * @param {Object} vueSocketIO
   */
  subscribeToSocketMessages(vueSocketIO) {
    const socket = vueSocketIO.io;
    socket.onevent = (packet) => {
      let [event, ...args] = packet.data;
      if (args.length === 1) args = args[0];
      if (hasPayloadError(args)) this.handleError(event, args);
      vueSocketIO.listener.onEvent(event, args);
    };
  }

  /**
   * @param {string} event
   * @param {Object} args
   */
  handleError(event, args) {
    const code = args.error?.code;
    if (code && ErrorNotifier.isCriticalError(code)) {
      if (ErrorNotifier.parseDirtyCode(code)?.code === 550) {
        eventBus.$emit('notify', {
          type: 'error',
          title: 'Error',
          message: ErrorNotifier.getMessage({error: {code}}, event)},
        );
      }
      eventBus.$emit('logout', {critical: true}); // TODO Delete {critical: true} after application initialization refactoring
    }
    StoreErrorNotifier.handleError(event, args);
  }
}
