<template>
  <div class="ex-tooltip-typical">
    <el-tooltip placement="right" effect="light">
      <template #content><slot /></template>
      <i class="el-icon-warning ex-tooltip-typical__icon" />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ExTooltipTypical',
};
</script>

<style lang="scss" scoped>
.ex-tooltip-typical {
  display: inline-block;
  vertical-align: middle;
  &__icon {
    color: #F47621;
    font-size: 22px;
  }
}
</style>
