/**
 * Checks for an error
 * @param {{}} payload
 * @return {boolean}
 */
export function hasPayloadError(payload) {
  return (_.isObject(payload) && payload.code !== undefined && payload.message !== undefined) ||
    !!(payload && payload.error && (payload.error.code || payload.error.code === 0) && payload.error.message) ||
    !!(_.isObject(payload) && payload.code && payload.error && payload.error.message);
}
