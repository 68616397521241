// guard
import {notAuthBehavior} from '@/routes/authRouters';

// component
import ExRegistration from '@/pages/registration/ex-registration';
const ExIdenfyResult = () => import('@/pages/onBoarding/ex-idenfy-result');
import ExLogin from '@/pages/login/ex-login';
import ExLoginCredential from '@/pages/login/ex-login-credential';
import ExLoginSelectType from '@/pages/login/ex-login-select-type';
import ExLogin2FA from '@/pages/login/ex-login-2fa';
import ExRecovery from '@/pages/recoveryAccount/ex-recovery';
import ExRecoveryIdentification from '@/pages/recoveryAccount/ex-recovery-identification';
import ExRecoveryValidation from '@/pages/recoveryAccount/ex-recovery-validation';
import ExRecoveryNewPassword from '@/pages/recoveryAccount/ex-recovery-new-password';
import ExDepositSuccess from '@/pages/deposit/fiat/ex-deposit-success';
import ExDepositFailure from '@/pages/deposit/fiat/ex-deposit-failure';
import ExDepositResult from '@/pages/deposit/fiat/ex-deposit-result';

export default (store) => {
  return [
    {
      path: '/verification-idenfy/success',
      component: ExIdenfyResult,
      name: 'idenfy-success',
      props: () => ({type: 'success'}),
    },
    {
      path: '/verification-idenfy/error',
      component: ExIdenfyResult,
      name: 'idenfy-error',
      props: () => ({type: 'error'}),
    },
    {
      path: '/deposit/success/:key',
      component: ExDepositSuccess,
      name: 'DepositSuccess',
      beforeEnter: notAuthBehavior(store),
      meta: {requiresIsLoggedIn: true},
    },
    {
      path: '/deposit/failure/:key',
      component: ExDepositFailure,
      name: 'DepositFailure',
      beforeEnter: notAuthBehavior(store),
      meta: {requiresIsLoggedIn: true},
    },
    {
      path: '/deposit/result/:key',
      component: ExDepositResult,
      name: 'DepositResult',
      beforeEnter: notAuthBehavior(store),
      meta: {requiresIsLoggedIn: true},
    },
    {
      path: '/signup',
      component: ExRegistration,
      beforeEnter: notAuthBehavior(store),
      name: 'UserRegistration',
      meta: {requiresIsLoggedIn: true},
    },
    {path: '/signup/confirm/email/:token/:code', component: ExRegistration, name: 'UserRegistrationByEmailLink'},
    {
      path: '/login',
      component: ExLogin,
      beforeEnter: notAuthBehavior(store),
      meta: {requiresIsLoggedIn: true},
      children: [
        {path: '', component: ExLoginCredential, name: 'LoginCredential'},
        {path: 'type', component: ExLoginSelectType, name: 'LoginSelectType'},
        {path: '2fa', component: ExLogin2FA, name: 'Login2FA'},
      ],
    },
    {
      path: '/recovery',
      component: ExRecovery,
      beforeEnter: notAuthBehavior(store),
      meta: {requiresIsLoggedIn: true},
      children: [
        {path: '', component: ExRecoveryIdentification, name: 'RecoveryIdentification'},
        {path: 'validation', component: ExRecoveryValidation, name: 'RecoveryValidation'},
        {path: 'password', component: ExRecoveryNewPassword, name: 'RecoveryNewPassword'},
        {path: 'validation/email/:token/:code', component: ExRecoveryValidation, name: 'RecoveryByLink'},
      ],
    },
  ];
};
