// util
import settingsKeeper from '@/utils/settingsKeeper';
import vueInstance from '@/utils/vueInstance';

// const
import {getWSA} from '@/constants/events/actions.type';
import {
  SUBSCRIBE_FIAT_AUTO_WITHDRAWAL,
  SUBSCRIBE_ORGANIZATION_PROFILE,
  SUBSCRIBE_INDIVIDUAL_PROFILE,
  SUBSCRIBE_REQUEST_HISTORY,
  SUBSCRIBE_TRANSACTIONS,
  SUBSCRIBE_BALANCES,
  SUBSCRIBE_REQUEST,
  SUBSCRIBE_USER,
} from '@/constants/events/subscriptions.type';

// setting
const DEFAULT_MESSAGE = 'Sorry, something went wrong. Please, click on the button to reload the page.';
const NO_DATA_MESSAGE = 'There is not enough data for the application to work. ' +
  'Please, click on the button to reload the page. If the error persists, contact the administrator.';

const initState = {
  isShowReloadSuggestion: false,
  message: DEFAULT_MESSAGE,
  basicSubscribe: false,
  socketConnected: false,
};

const state = {...initState};

const mutations = {
  showReloadSuggestion(state, {delay = 3000, isNoDataMessage = false}) {
    if (isNoDataMessage) state.message = NO_DATA_MESSAGE;
    setTimeout(() => {
      state.isShowReloadSuggestion = true;
    }, delay);
  },
  reset(state, byDisconnectEvent = false) {
    state.basicSubscribe = false;
    if (!byDisconnectEvent) {
      vueInstance.$socket.close();
      vueInstance.$socket.connect();
    }
  },
  basicSubscriptionCompleted(state) {
    state.basicSubscribe = true;
  },
};

const actions = {
  [getWSA('connect')]({state, dispatch}) {
    const socketId = vueInstance.$socket?.io?.connecting?.[0]?.id;
    settingsKeeper.socketId = socketId || null;
    settingsKeeper.socketConnected = !!socketId;
    state.socketConnected = !!socketId;
    dispatch('initBasicSubscribe');
  },
  [getWSA('disconnect')]({state, commit}) {
    settingsKeeper.socketId = null;
    settingsKeeper.socketConnected = false;
    state.socketConnected = false;
    commit('reset', true);
  },
  [getWSA('basicSubscribeCallback')]({commit}) {
    commit('basicSubscriptionCompleted');
    console.log('Basic subscription completed');
  },
  initBasicSubscribe({state, rootState}) {
    if (state.basicSubscribe) return;
    const accessId = rootState.auth.accessId;
    const token = rootState.auth.token;
    if (!accessId || !token) return;
    vueInstance.$socket.emit('basicSubscribe', {accessId, token, objects: {
      FiatAutoWithdraw: SUBSCRIBE_FIAT_AUTO_WITHDRAWAL,
      ExchangeTransaction: SUBSCRIBE_REQUEST_HISTORY,
      Organization: SUBSCRIBE_ORGANIZATION_PROFILE,
      TransactionOrders: SUBSCRIBE_TRANSACTIONS,
      User: SUBSCRIBE_INDIVIDUAL_PROFILE,
      Balance: SUBSCRIBE_BALANCES,
      Exchange: SUBSCRIBE_REQUEST,
      AccessUser: SUBSCRIBE_USER,
    }});
  },
};

export default {namespaced: true, state, mutations, actions};
