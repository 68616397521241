export default {
  1: 'Search result is empty',
  201: 'Not found entity',
  202: 'Not found entity',
  205: 'Bank not found',
  208: 'User not found',
  209: 'Value {value} of argument {name} invalid!',
  210: 'Invoice not found',
  211: 'Balance not found',
  214: 'Filter error. Order type not found',
  217: 'Payment account not found',
  218: 'Payment account with is not linked to a bank',
  219: 'Organization not found',
  220: 'No users found in organization',
  221: 'User not found',
  250: 'Sync error',
  251: 'Sync error. Failed to save history file',
  254: 'Sync files error. Fail build entity files',
  256: 'Sync error. Invalid synchronize message structure',
  257: 'Sync error. Invalid data type in a sync message',
  258: 'Sync error. Failed merge database',
  290: 'TCP socket error',
  353: 'Object not found for PDF',
  354: 'PDF assembly error',
  370: 'Office Build Error',
  403: 'Subscribers not found',
  404: 'Object not found',
  421: 'CSV Build Error',
};
