<template>
  <div class="ex-withdrawal-crypto-tabs">
    <portal to="toolbar__title">Crypto Currency Withdrawal</portal>
    <el-tabs v-model="activeTab" @tab-click="updateRoute({tab: activeTab})">
      <el-tab-pane
        v-loading="isFetchingManualWithdrawalCurrencyList"
        label="Manual withdrawal"
        :name="tabs.manual"
        lazy
      >
        <ex-withdrawal-crypto
          v-if="isFetchedManualWithdrawalCurrencyList"
          :is-creating="isCreatingManualWithdrawal"
          :balances="currencyListManualWithdrawal"
          @create="createManualWithdrawal"
          @updateCurrency="updateRoute({currency: $event})"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="hasBillingRole"
        v-loading="isFetchingAutoWithdrawalCurrencyList"
        label="Auto Withdraw"
        :name="tabs.auto"
        lazy
      >
        <ex-withdrawal-crypto
          v-if="isFetchedAutoWithdrawalCurrencyList"
          :is-creating="isCreatingAutoWithdrawal"
          :balances="currencyListAutoWithdrawal"
          @create="createWithdrawalCryptoAuto"
          @updateCurrency="updateRoute({currency: $event})"
          auto
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// lib
import {mapGetters} from 'vuex';

// component
import ExWithdrawalCrypto from '@/pages/withdrawal/crypto/ex-withdrawal-crypto';

// utils
import {socketRequest} from '@/utils/socket';

// api
import RequestApi from '@/api/requestService/request.api';
import TunnelGateway from '@/api/tunnel.api';

// const
import {
  CREATE_WITHDRAW_CRYPTO_MANUAL,
  FETCH_WITHDRAW_CRYPTO_AUTO_LIST_CURRENCIES,
  FETCH_WITHDRAW_CRYPTO_MANUAL_LIST_CURRENCIES,
} from '@/constants/events/requests/actions.type';
import {CREATE_WITHDRAW_CRYPTO_AUTO} from '@/constants/events/tunnel/actions.type';

export default {
  name: 'ExWithdrawalCryptoTabs',
  components: {ExWithdrawalCrypto},
  data() {
    const tabs = {manual: 'manual', auto: 'auto'};
    return {
      isCreatingAutoWithdrawal: false,
      isCreatingManualWithdrawal: false,
      isFetchingAutoWithdrawalCurrencyList: false,
      isFetchingManualWithdrawalCurrencyList: false,
      isFetchedAutoWithdrawalCurrencyList: false,
      isFetchedManualWithdrawalCurrencyList: false,
      currencyListAutoWithdrawal: [],
      currencyListManualWithdrawal: [],
      activeTab: '',
      tabs,
    };
  },
  computed: {
    ...mapGetters('currentUser', ['hasBillingRole', 'cryptoBalances']),
  },
  created() {
    if (Object.keys(this.tabs).includes(this.$route.params.type)) {
      this.activeTab = this.$route.params.type;
    }
    if (this.hasBillingRole) {
      this.fetchCurrencyListAutoWithdrawal();
    }
    this.fetchCurrencyListManualWithdrawal();
  },
  methods: {
    updateRoute({currency = this.$route.params.currency, tab = this.$route.params.type}) {
      if (currency === this.$route.params.currency && tab === this.$route.params.type) return;
      this.$router.replace({name: 'WithdrawCrypto', params: {type: tab, currency}});
    },
    fetchCurrencyListAutoWithdrawal() {
      socketRequest.call(this, FETCH_WITHDRAW_CRYPTO_AUTO_LIST_CURRENCIES,
        (payload) => {
          if (!_.isArray(payload)) return;
          this.currencyListAutoWithdrawal = this.cryptoBalances.filter((item) => payload.includes(item.currency));
          this.isFetchedAutoWithdrawalCurrencyList = true;
        },
        () => {},
        () => {
          this.isFetchingAutoWithdrawalCurrencyList = false;
        },
      );
      this.isFetchingAutoWithdrawalCurrencyList = true;
      RequestApi.fetchWithdrawCryptoAutoListCurrencies(FETCH_WITHDRAW_CRYPTO_AUTO_LIST_CURRENCIES);
    },
    createWithdrawalCryptoAuto(data) {
      socketRequest.call(this, CREATE_WITHDRAW_CRYPTO_AUTO,
        () => {
          this.$router.push({name: 'BalancesIndex'});
          return 'A new auto withdrawal request has been created';
        },
        (payload) => {
          if (payload.error.code === '33:33') {
            // TODO Return error handling as before
            //  `Order limit exceeded: ${payload?.error?.data?.limit} ${data.currency}`
            return payload.error.message;
          }
        },
        () => {
          this.isCreatingAutoWithdrawal = false;
        },
      );
      this.isCreatingAutoWithdrawal = true;
      TunnelGateway.createWithdrawCryptoAuto(CREATE_WITHDRAW_CRYPTO_AUTO, data);
    },
    fetchCurrencyListManualWithdrawal() {
      socketRequest.call(this, FETCH_WITHDRAW_CRYPTO_MANUAL_LIST_CURRENCIES,
        (payload) => {
          if (!_.isArray(payload)) return;
          this.currencyListManualWithdrawal = this.cryptoBalances.filter((item) => payload.includes(item.currency));
          this.isFetchedManualWithdrawalCurrencyList = true;
        },
        () => {},
        () => {
          this.isFetchingAutoWithdrawalCurrencyList = false;
        },
      );
      this.isFetchingAutoWithdrawalCurrencyList = true;
      RequestApi.fetchWithdrawCryptoManualListCurrencies(FETCH_WITHDRAW_CRYPTO_MANUAL_LIST_CURRENCIES);
    },
    createManualWithdrawal(data) {
      socketRequest.call(this, CREATE_WITHDRAW_CRYPTO_MANUAL,
        () => {
          this.$router.push({name: 'BalancesIndex'});
          return 'A new manual withdrawal request has been created';
        },
        () => {},
        () => {
          this.isCreatingManualWithdrawal = false;
        },
      );
      this.isCreatingManualWithdrawal = true;
      RequestApi.createWithdrawCryptoManual(CREATE_WITHDRAW_CRYPTO_MANUAL, data);
    },
  },
};
</script>
