<template>
  <div class="ex-login">
    <transition name="fade" mode="out-in">
      <router-view
        :authorization-profile="authorizationProfile"
        @resetAuthorizationProfile="resetAuthorizationProfile"
        @saveAuthorizationProfile="setAuthorizationProfile"
        @setSelectedAccount="setSelectedAccount"
        @smsStageCompleted="updateAuthorizationStage('SMS')"
        @gaStageCompleted="updateAuthorizationStage('GA')"
      />
    </transition>
  </div>
</template>

<script>
// utils
import {loginSessionStorage} from '@/utils/web-storage/session';

// settings
const defaultAuthorizationSettings = {SMS: false, GA: false};
const defaultAuthorizationProfile = {
  external: {
    userId: loginSessionStorage.getUserId(),
    organizations: loginSessionStorage.getCompanies(),
    validation: loginSessionStorage.getValidation(),
  },
  selectedAccount: loginSessionStorage.getSelectedCompany(),
  authorizationStage: loginSessionStorage.getProcessValidation(),
  numberOfAccounts: 0,
};

export default {
  name: 'ExLogin',
  data() {
    return {
      authorizationProfile: {authorizationStage: defaultAuthorizationSettings, ...defaultAuthorizationProfile},
    };
  },
  beforeDestroy() {
    this.resetAuthorizationProfile();
    loginSessionStorage.setValidation(undefined);
    loginSessionStorage.setProcessValidation(undefined);
    loginSessionStorage.setSmsExpire(undefined);
  },
  methods: {
    resetAuthorizationProfile() {
      this.authorizationProfile.external.userId = undefined;
      loginSessionStorage.setUserId(undefined);
      this.authorizationProfile.external.organizations = undefined;
      loginSessionStorage.setCompanies(undefined);
      this.authorizationProfile.external.validation = {...defaultAuthorizationSettings};
      loginSessionStorage.setValidation(this.authorizationProfile.external.validation);
      this.authorizationProfile.selectedAccount = undefined;
      loginSessionStorage.setSelectedCompany(undefined);
      this.authorizationProfile.authorizationStage = {...defaultAuthorizationSettings};
      loginSessionStorage.setProcessValidation(this.authorizationProfile.authorizationStage);
    },
    setAuthorizationProfile(data) {
      this.authorizationProfile.external =
        Object.assign({}, defaultAuthorizationProfile.external, {validation: defaultAuthorizationSettings}, data);
      this.authorizationProfile.numberOfAccounts
        = this.getNumberOfAccounts(this.authorizationProfile.external.organizations);
      loginSessionStorage.setUserId(data.userId);
      loginSessionStorage.setValidation(data.validation);
      loginSessionStorage.setCompanies(data.organizations);
    },
    setSelectedAccount(accountId) {
      this.authorizationProfile.selectedAccount = accountId;
      loginSessionStorage.setSelectedCompany(accountId);
    },
    updateAuthorizationStage(type) {
      this.authorizationProfile.authorizationStage[type] = true;
      loginSessionStorage.setProcessValidation(this.authorizationProfile.authorizationStage);
    },
    getNumberOfAccounts(companies) {
      let numberOfAccounts = 0;
      for (const key in companies) {
        if (Object.hasOwnProperty.call(companies, key)) numberOfAccounts++;
      }
      return numberOfAccounts;
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-login {
  width: 100%;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
