<template>
  <component :is="depositCurrencyType" />
</template>

<script>
// utils
import {isFiat} from '@/utils/converters/currency';

// components
import ExDepositTypeSelectorFiat from '@/pages/deposit/fiat/ex-deposite-type-selector-fiat';
import ExDepositCrypto from '@/pages/deposit/crypto/ex-deposit-crypto';

export default {
  name: 'ExDepositCurrencyTypeSelector',
  components: {ExDepositCrypto, ExDepositTypeSelectorFiat},
  computed: {
    isFiat() {
      return isFiat(this.$route.params.currency);
    },
    depositCurrencyType() {
      return this.isFiat ? 'ExDepositTypeSelectorFiat' : 'ExDepositCrypto';
    },
  },
};
</script>
