<template>
  <div class="ex-trading-mode">
    <div class="ex-trading-mode__title">SELECT TRADING MODE</div>
    <div class="ex-trading-mode__items">
      <ex-card-button
        class="ex-trading-mode__item"
        :disabled="autoIsBlocked"
        :value="GENERAL_DESCRIPTION_TRADING_MODE[EXCHANGE_PRICE_BEHAVIOUR_TYPES.AUTO]"
        @click="createRequest(GENERAL_DESCRIPTION_TRADING_MODE[EXCHANGE_PRICE_BEHAVIOUR_TYPES.AUTO].ROUTE_LABEL)"
      >
      </ex-card-button>
      <ex-card-button
        class="ex-trading-mode__item"
        :disabled="false"
        :value="GENERAL_DESCRIPTION_TRADING_MODE[EXCHANGE_PRICE_BEHAVIOUR_TYPES.ORDER]"
        @click="createRequest(GENERAL_DESCRIPTION_TRADING_MODE[EXCHANGE_PRICE_BEHAVIOUR_TYPES.ORDER].ROUTE_LABEL)"
      >
      </ex-card-button>
    </div>
  </div>
</template>

<script>
// component
import ExCardButton from '@/components/ex-card-button';

// const
import {
  GENERAL_DESCRIPTION_TRADING_MODE,
  EXCHANGE_PRICE_BEHAVIOUR_TYPES,
} from '@/constants/common';
import {CRYPTO_CURRENCIES} from '@/constants/currencies';

// setting
const EXCLUDED_CURRENCY = [
  CRYPTO_CURRENCIES.USDT_BEP20,
  CRYPTO_CURRENCIES.BUSD,
  CRYPTO_CURRENCIES.ETH_BEP20,
  CRYPTO_CURRENCIES.USDC_BEP20,
  CRYPTO_CURRENCIES.MATIC_Polygon,
  CRYPTO_CURRENCIES.USDT_Polygon,
  CRYPTO_CURRENCIES.TRX,
  CRYPTO_CURRENCIES.TON,
];

export default {
  name: 'ExTradingMode',
  components: {ExCardButton},
  data() {
    return {
      requestBehaviourType: '',
      dialogVisible: true,
      GENERAL_DESCRIPTION_TRADING_MODE,
      EXCHANGE_PRICE_BEHAVIOUR_TYPES,
    };
  },
  computed: {
    autoIsBlocked() {
      return EXCLUDED_CURRENCY.includes(this.currency);
    },
    currency() {
      return this.$route.params.currency;
    },
  },
  methods: {
    createRequest(requestType) {
      const operationType = this.$route.params.type;
      this.$router.push({
        name: 'RequestCreate',
        params: {requestType, currency: this.currency, operationType},
      });
    },
  },
};
</script>

<style scoped lang="scss">
.ex-trading-mode {
  &__title {
    text-align: center;
    padding-bottom: 30px;
    color: #444;
    font-size: 24px;
    font-weight: bold;
  }
  &__items {
    display: flex;
  }
  &__item {
    margin: 14px;
    flex-basis: 50%;
    height: inherit;
  }
}
</style>
