<template>
  <div class="ex-deposit-crypto">
    <portal to="toolbar__title">
      Crypto Currency Deposit
    </portal>
    <el-tabs v-model="activeTab">
      <el-tab-pane name="manual" label="Manual Crypto Deposit" lazy>
        <ex-deposit-manual-crypto />
      </el-tab-pane>
      <el-tab-pane v-if="hasBillingRole" name="auto" label="Automated Crypto Deposit" lazy>
        <ex-deposit-auto-crypto />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// components
import ExDepositManualCrypto from '@/pages/deposit/crypto/ex-deposit-manual-crypto';
import ExDepositAutoCrypto from '@/pages/deposit/crypto/ex-deposit-auto-crypto';

// lib
import {mapGetters} from 'vuex';

export default {
  name: 'ExDepositCrypto',
  components: {ExDepositAutoCrypto, ExDepositManualCrypto},
  data() {
    return {
      activeTab: 'manual',
    };
  },
  computed: {
    ...mapGetters('currentUser', ['hasBillingRole']),
  },
};
</script>

<style lang="scss" scoped>
.ex-deposit-crypto {
  ::v-deep .el-tabs__item {
    font-size: 16px;
  }
}
</style>
