import BaseApi from '@/api/base';

export default new class AuthApi extends BaseApi {
  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.accessId
   * @param {string} data.refreshToken
   * @return {Promise<*>}
   */
  refreshToken(event, data) {
    return super.post(event, '/api/refresh-token', {data});
  }
};
