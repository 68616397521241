// util
import vueInstance from '@/utils/vueInstance';
import eventBus from '@/utils/eventBus';

// const
import {VERIFICATION_STATUSES_COMPANY, VERIFICATION_STATUSES_INDIVIDUAL} from '@/constants/verification';

export const notAuthBehavior = (store) => (to, from, next) => {
  if (store.state.auth.isLoggedIn) return next({name: 'Home'});
  next();
};

export const accIsNotVerified = (store) => (to, from, next) => {
  const rolesReceived = store.state.currentUser.meta.isReceived.roles;
  const profileReceived = store.state.currentUser.meta.isReceived.profile;
  if (!rolesReceived || !profileReceived) {
    if (to.name === 'UserRedirect') return next();
    return next({name: 'UserRedirect', params: {path: to.fullPath}});
  }

  const verification = store.state.currentUser.profile.verification;
  const isCompany = store.getters['auth/isCompany'];
  const verificationCodes = isCompany ? VERIFICATION_STATUSES_COMPANY.VALUE : VERIFICATION_STATUSES_INDIVIDUAL.VALUE;
  const roles = store.state.currentUser.roles;
  if (roles.length && verification === verificationCodes.GENERAL) {
    vueInstance.errorMsg(
      `You do not have access! ${isCompany ? 'Company' : 'Personal'} account verified`,
      'Forbidden',
    );
    return next({name: 'Home'});
  }
  next();
};

export const authBehavior = (store) => async (to, from, next) => {
  if (isNotAuth(store)) {
    next({name: 'LoginCredential'});
    return eventBus.$emit('logout');
  }
  if (['idenfy-success', 'idenfy-error'].includes(to.name)) return next();

  const rolesReceived = store.state.currentUser.meta.isReceived.roles;
  const profileReceived = store.state.currentUser.meta.isReceived.profile;

  if (!rolesReceived || !profileReceived) {
    if (to.name === 'UserRedirect') return next();
    return next({name: 'UserRedirect', params: {path: to.fullPath}});
  }

  const verification = store.state.currentUser.profile.verification;
  const verificationCodesByAccType =
    store.getters['auth/isCompany'] ? VERIFICATION_STATUSES_COMPANY.VALUE : VERIFICATION_STATUSES_INDIVIDUAL.VALUE;
  let name = null;
  if (verification <= verificationCodesByAccType.WAITING) name = 'OnBoarding';

  if (name === from.name || from.name === to.name) return;
  if (name) return next({name});
  next();
};

/**
 * @param {Object} store
 * @return {boolean}
 */
function isNotAuth(store) {
  const isLoggedIn = store.state.auth.isLoggedIn;
  const currentTokenDuration = store.state.auth.authExpire - Date.now();
  const checkDelay = (30 * 1000);
  return !isLoggedIn || currentTokenDuration < checkDelay;
}

export const necessaryRole = (store, role) => (to, from, next) => {
  const rolesReceived = store.state.currentUser.meta.isReceived.roles;
  if (!rolesReceived) return;
  const profileReceived = store.state.currentUser.meta.isReceived.profile;
  if (!profileReceived) return;

  const roles = store.state.currentUser.roles || [];
  const verification = store.state.currentUser.profile.verification;
  const verificationCodes =
    store.getters['auth/isCompany'] ? VERIFICATION_STATUSES_COMPANY.VALUE : VERIFICATION_STATUSES_INDIVIDUAL.VALUE;
  if (roles.includes(role) && verification === verificationCodes.GENERAL) return next();
  vueInstance.errorMsg('You do not have access! Verification needed.', 'Forbidden');
  next(false);
};
