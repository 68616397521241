import {SOMETHING_WENT_WRONG_MESSAGE} from '@/constants/commonMessage';

export default {
  101: SOMETHING_WENT_WRONG_MESSAGE,
  102: SOMETHING_WENT_WRONG_MESSAGE,
  103: SOMETHING_WENT_WRONG_MESSAGE,
  104: SOMETHING_WENT_WRONG_MESSAGE,
  105: SOMETHING_WENT_WRONG_MESSAGE,
  106: SOMETHING_WENT_WRONG_MESSAGE,
  107: SOMETHING_WENT_WRONG_MESSAGE,
  // 201: '',
  202: 'Currency pair is not available',
  203: 'Order not found',
  205: 'Unknown order status',
  206: 'Withdrawal not found',
  208: 'Order is already closed',
  209: 'Order status has not changed',
  210: 'Cannot be changed to new order status',
  // 211: '',
  // 213: '',
  214: 'Invalid wallet address',
  // 216: '',
  // 218: '',
  219: 'Currency not found',
  220: 'Withdrawal is in closed status',
  // 221: '',
  // 222: '',
  // 223: '',
  // 224: '',
  // 225: '',
  // 226: '',
  // 227: '',
  // 228: '',
  // 229: '',
  // 230: '',
  232: 'Сan\'t get commission',
  // 233: '',
  // 234: '',
  // 235: '',
  236: 'Сan\'t get commission',
  // 237: '',
  // 238: '',
  // 239: '',
  // 240: '',
  // 241: '',
  // 242: '',
  // 245: '',
  // 246: '',
  247: 'Hash is not valid',
  249: 'Not valid price with margin',
  // 250: '',
  // 251: '',
  // 252: '',
  // 253: '',
  256: 'Order less than minimum amount',
  // 258: '',
  // 259: '',
  260: 'Сan\'t get commission',
  // 261: '',
  // 262: '',
  // 263: '',
  // 264: '',
  // 265: '',
  // 266: '',
  // 267: '',
  // 268: '',
  // 269: '',
  // 270: '',
  // 271: '',
  // 272: '',
  // 273: '',
  // 274: '',
  275: 'The order is archival',
  // 280: '',
  281: 'This transaction can\'t be executed. Please, check all fields and try again',
};
