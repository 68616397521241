<template>
  <div :class="{root: !this.disabled, root_disabled: this.disabled}" @click="handleClick">
    <div class="root__border"></div>
    <div class="root__title">{{value.LABEL}}</div>
    <div class="root__content">{{value.DESCRIPTION}}</div>
  </div>
</template>

<script>
export default {
  name: 'ExCardButton',
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.root {
  position: relative;
  padding: 29px 25px 25px;
  font-size: 14px;
  background-color: #f9f9fa;
  border-radius: 4px;
  cursor: pointer;
  &__border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #e5e5ea;
  }
  &__title {
    font-size: 1.5em;
    font-weight: bold;
    padding-bottom: 15px;
    color: #797979;
    transition: color linear .25s;
  }
  &__content {
    font-size: 1.15em;
    font-weight: lighter;
  }
  &_disabled {
    position: relative;
    padding: 19px 15px 15px;
    font-size: 14px;
    background-color: #f1efef;
    border-radius: 4px;
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
    }
  }
  &:hover {
    .root__border {
      background-color: #ff9502;
    }
    .root__title {
      color: #ff9502;
    }
  }
}
</style>
