// util
import {numberFromLocaleString} from '@/utils/converters/currency';

export const validatorAmount = (rule, value, callback) => {
  if (numberFromLocaleString(value) <= 0) {
    return callback(new Error('The amount must be greater than zero'));
  }
  return callback();
};

export const ruleRequired = {required: true, message: 'This field is required', trigger: ['blur', 'change']};
export const ruleAmount = {validator: validatorAmount, trigger: 'change'};
export const ruleMaxLength = (max = 255) => {
  return {min: 0, max, message: `Maximum for the field ${max} characters`, trigger: ['blur', 'change']};
};
// TODO USE ruleEmail
export const ruleEmail =
  (message = 'Please, enter a valid email address') => ({type: 'email', message, trigger: ['blur', 'change']});
export const ruleCreditCardLength = (value) => {
  if (Number(value.charAt(0)) === 4) {
    let exactLength = 16;
    if (value.length < 16) {
      exactLength = 16;
      return {len: exactLength, message: `This type of card can be 13 or 16 numbers in length`, trigger: ['blur', 'change']};
    }
    if (value.length > 16 && value.length < 19) {
      exactLength = 19;
      return {len: exactLength, message: `This type of card can be 13 or 16 numbers in length`, trigger: ['blur', 'change']};
    }
    return {min: 16, max: 19, message: `This type of card can be 13 or 16 numbers in length`, trigger: ['blur', 'change']};
  } else if (Number(value.charAt(0)) === 5) {
    return {min: 19, max: 19, message: `This card type must be 16 numbers length`, trigger: ['blur', 'change']};
  } else {
    return {min: 16, max: 19, message: `This type of card can be 13 or 16 numbers in length`, trigger: ['blur', 'change']};
  }
};

const typicalCharacters = new RegExp(/^[A-Za-z0-9_-]*$/, 'i');
const typicalCharactersValidator = (rule, value, callback) => {
  if (value && !typicalCharacters.test(value)) {
    return callback(new Error('Only numbers, letters, dashes and underscores are allowed'));
  }
  return callback();
};
export const ruleTypicalCharacters= {validator: typicalCharactersValidator, trigger: 'blur'};


const onlyNumbersLetters = new RegExp(/^[a-z\d]+$/, 'i');
const onlyNumbersLettersValidator = (rule, value, callback) => {
  if (value && !onlyNumbersLetters.test(value)) {
    return callback(new Error('Only numbers, letters are allowed'));
  }
  return callback();
};
export const ruleOnlyNumbersLetters = {validator: onlyNumbersLettersValidator, trigger: 'blur'};

const allCharactersExceptSpaces = new RegExp(/^\S*$/, 'i');
const allCharactersExceptSpacesValidator = (rule, value, callback) => {
  if (value && !allCharactersExceptSpaces.test(value)) {
    return callback(new Error('Spaces are not allowed'));
  }
  return callback();
};
export const ruleSpacesNotAllowed = {validator: allCharactersExceptSpacesValidator, trigger: 'blur'};
