// util
import {socketRequest} from '@/utils/socket';
import {hasPayloadError} from '@/utils/error';
import ErrorMessage from '@/utils/socketError/errorNotifier';

// api
import TunnelApi from '@/api/tunnel.api';

// const
import {CRYPTO_CURRENCIES, FIAT_CURRENCIES} from '@/constants/currencies';
import {FETCH_TUNNEL_NETWORK_FEE, FETCH_TUNNEL_NETWORK_FEE_CURRENCY_PAIR} from '@/constants/events/tunnel/actions.type';

// init setting
const NETWORK_FEE_PARAMS = {
  currency: '',
  feeAmount: null,
  feeCurrency: '',
  feeEuroEquivalent: null,
  feeHash: '',
};

const networkFeeMixin = {
  data() {
    return {
      mix_networkFeeMixin: {
        isFetching: false,
        isLoading: false,
        currencyPairList: {},
        networkFeeParams: {...NETWORK_FEE_PARAMS},
        _requestId: 0,
      },
    };
  },
  computed: {
    mix_networkFeeMixin_info() {
      if (!this.mix_networkFeeMixin.networkFeeParams.feeCurrency) return;
      if (
        [CRYPTO_CURRENCIES.MATIC_Polygon, FIAT_CURRENCIES.EUR]
          .includes(this.mix_networkFeeMixin.networkFeeParams.feeCurrency)
      ) {
        return `${this.mix_networkFeeMixin.networkFeeParams.feeAmount} ${this.mix_networkFeeMixin.networkFeeParams.feeCurrency}`;
      }
      return `${this.mix_networkFeeMixin.networkFeeParams.feeAmount} ${this.mix_networkFeeMixin.networkFeeParams.feeCurrency}
        (${this.mix_networkFeeMixin.networkFeeParams.feeEuroEquivalent} ${FIAT_CURRENCIES.EUR})
      `;
    },
  },
  destroyed() {
    this.sockets.unsubscribe(FETCH_TUNNEL_NETWORK_FEE);
  },
  methods: {
    mix_networkFeeMixin_resetCurrency() {
      this.mix_networkFeeMixin.networkFeeParams.feeCurrency = null;
    },
    mix_networkFeeMixin_fetchCurrencyPair() {
      socketRequest.call(this, FETCH_TUNNEL_NETWORK_FEE_CURRENCY_PAIR,
        (payload) => {
          this.mix_networkFeeMixin.currencyPairList = payload;
        },
        () => {},
        () => {
          this.mix_networkFeeMixin.isFetching = false;
        },
      );
      this.mix_networkFeeMixin.isFetching = true;
      return TunnelApi.fetchTunnelNetworkFeeCurrencyPair(FETCH_TUNNEL_NETWORK_FEE_CURRENCY_PAIR);
    },
    mix_networkFeeMixin_fetchFee({currency, commissionCurrency, amount, address}) {
      const requestId = ++this.mix_networkFeeMixin._requestId;
      this.mix_networkFeeMixin_initNetworkFeeState();
      if (amount <= 0) return;
      this.sockets.subscribe(FETCH_TUNNEL_NETWORK_FEE, (payload) => {
        if (hasPayloadError(payload)) {
          this.mix_networkFeeMixin.isLoading = false;
          return this.errorMsg(ErrorMessage.getMessage(payload, FETCH_TUNNEL_NETWORK_FEE));
        }
        if (this.mix_networkFeeMixin._requestId !== payload.requestId) return;
        this.mix_networkFeeMixin.isLoading = false;
        this.mix_networkFeeMixin.networkFeeParams = payload;
      });

      this.mix_networkFeeMixin.isLoading = true;
      return TunnelApi.fetchTunnelNetworkFee(
        FETCH_TUNNEL_NETWORK_FEE,
        {currency, commissionCurrency, amount, address, requestId},
      );
    },
    mix_networkFeeMixin_initNetworkFeeState() {
      this.mix_networkFeeMixin.networkFeeParams =
        Object.assign({}, this.mix_networkFeeMixin.networkFeeParams, NETWORK_FEE_PARAMS);
    },
  },
};

export default networkFeeMixin;
