// util
import {socketRequest} from '@/utils/socket';

// api
import AutoTradingApi from '@/api/requestService/autoTrading.api';

// const
import {CANCEL_AUTO_FIRST_CURRENCY, CANCEL_AUTO_SECOND_CURRENCY} from '@/constants/events/requests/actions.type';

const AutoTradingMixin = {
  data() {
    return {
      mix_autoTradingMixin: {
        isCanceling: false,
      },
    };
  },
  methods: {
    mix_autoTradingMixin_cancelOrder(requestId, successCb = () => {}, second = false) {
      const event = second ? CANCEL_AUTO_SECOND_CURRENCY : CANCEL_AUTO_FIRST_CURRENCY;
      const apiMethod = () => second
        ? AutoTradingApi.cancelAutoSecondCurrencyOrder(event, {requestId})
        : AutoTradingApi.cancelAutoFirstCurrencyOrder(event, {requestId});
      socketRequest.call(this, event,
        () => {
          successCb(); // TODO DELETE AFTER SUBSCRIPTION
          return 'Request canceled';
        },
        () => {},
        () => {
          this.mix_autoTradingMixin.isCanceling = false;
        },
      );
      this.mix_autoTradingMixin.isCanceling = true;
      return apiMethod();
    },
  },
};

export default AutoTradingMixin;
