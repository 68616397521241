export const FETCH_NOTIFICATION_SETTINGS = 'fetch:notification-settings';
export const UPDATE_NOTIFICATION_SETTING = 'update:notification-setting';
export const UPDATE_USER_GDPR_POLICY_VERSION = 'update:gdpr-policy-version';
export const REVOKE_USER_PERSONAL_DATA = 'revoke:personal-data';
export const REQUEST_USER_PERSONAL_DATA = 'request:personal-data';
export const ASSIGN_CRYPTO_BALANCE_TO_ADDRESS = 'assign:crypto-balance-to-address';
export const FETCH_MANUAL_CRYPTO_DEPOSIT_ADDRESS_LIST = 'fetch:manual-crypto-deposit-address-list';
export const CONFIRM_USER_2FAGA = 'confirm:2fa-ga';
export const UPDATE_USER_GA_SETTING = 'update:ga-setting';
export const UPDATE_USER_SMS_SETTING = 'update:sms-setting';
