var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ex-otc-order"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form}},[_c('div',{staticClass:"ex-otc-order__row"},[_c('div',{staticClass:"ex-otc-order__left"},[_c('el-form-item',{attrs:{"label":"You pay","prop":"currencySell"}},[_c('el-select',{attrs:{"value":_vm.form.currencySell},on:{"change":_vm.handleChangeCurrencySell}},_vm._l((_vm.currencyList),function(currency,key){return _c('el-option',{key:key,class:{
                'ex-otc-order__option_active': currency === _vm.form.currencySell || _vm.getPair(currency, _vm.form.currencyBuy)
              },attrs:{"label":currency,"value":currency}})}),1)],1)],1),_c('div',{staticClass:"ex-otc-order__right"},[_c('el-form-item',{attrs:{"label":"You pay","prop":"amountSell","rules":_vm.isRfq && _vm.isBuyType ? [] : [_vm.ruleRequired]}},[(_vm.isRfq && _vm.isBuyType)?_c('el-input',{attrs:{"placeholder":"You will find out the amount from the trader","disabled":""}}):_c('ex-input',{key:_vm.reRenderInitKey,attrs:{"value":_vm.form.amountSell,"has-lock":!_vm.isRfq,"is-lock":!_vm.isRfq && _vm.locks[0],"disabled":_vm.isRfq && _vm.isBuyType,"currency":_vm.form.currencySell,"placeholder":_vm.isRfq && _vm.isBuyType
              ? 'You will find out the amount from the trader'
              : `Please input amount ${_vm.form.currencySell} for pay`},on:{"change-lock":function($event){return _vm.handleChangeLock(0, $event)},"input":_vm.handleChangeAmountSell}},[_c('el-button',{attrs:{"slot":"append"},on:{"click":function($event){return _vm.handleChangeAmountSell(_vm.max)}},slot:"append"},[_vm._v("MAX")])],1)],1)],1)]),_c('div',{staticClass:"ex-otc-order__row"},[_c('div',{staticClass:"ex-otc-order__left ex-otc-order__left_center"},[_c('i',{staticClass:"el-icon-sort ex-otc-order__icon",on:{"click":_vm.handleSwapCoins}})]),_c('div',{staticClass:"ex-otc-order__right",class:{'ex-otc-order__right_hide': _vm.isRfq}},[_c('el-form-item',{attrs:{"label":`Limit Price for 1 ${_vm.pair.split(':')[0]}`,"rules":_vm.isRfq ? [] : [_vm.ruleRequired],"prop":"price"}},[_c('ex-input',{key:_vm.reRenderInitKey,attrs:{"value":_vm.form.price,"has-lock":!_vm.isRfq,"is-lock":_vm.locks[1],"currency":_vm.CRYPTO_CURRENCIES.BTC,"placeholder":"Please input price"},on:{"change-lock":function($event){return _vm.handleChangeLock(1, $event)},"input":_vm.handleChangePrice}})],1)],1)]),_c('div',{staticClass:"ex-otc-order__row"},[_c('div',{staticClass:"ex-otc-order__left"},[_c('el-form-item',{attrs:{"label":"You pay","prop":"currencyBuy"}},[_c('el-select',{attrs:{"value":_vm.form.currencyBuy},on:{"change":_vm.handleChangeCurrencyBuy}},_vm._l((_vm.currencyList),function(currency,key){return _c('el-option',{key:key,class:{
                'ex-otc-order__option_active': currency === _vm.form.currencyBuy || _vm.getPair(_vm.form.currencySell, currency)
              },attrs:{"label":currency,"value":currency}})}),1)],1)],1),_c('div',{staticClass:"ex-otc-order__right"},[_c('el-form-item',{attrs:{"rules":_vm.isRfq && _vm.isSellType ? [] : [_vm.ruleRequired],"label":"You receive","prop":"amountBuy"}},[(_vm.isRfq && _vm.isSellType)?_c('el-input',{attrs:{"placeholder":"You will find out the amount from the trader","disabled":""}}):_c('ex-input',{key:_vm.reRenderInitKey,attrs:{"value":_vm.form.amountBuy,"placeholder":_vm.isRfq && _vm.isSellType
              ? 'You will find out the amount from the trader'
              : `Please input amount ${_vm.form.currencyBuy} for receive`,"currency":_vm.form.currencyBuy,"has-lock":!_vm.isRfq,"is-lock":!_vm.isRfq && _vm.locks[2],"disabled":_vm.isRfq && _vm.isSellType},on:{"change-lock":function($event){return _vm.handleChangeLock(2, $event)},"input":_vm.handleChangeAmountBuy}})],1)],1)]),_c('ex-icon-alert',{staticClass:"ex-otc-order__result"},[_vm._v(" You "+_vm._s(_vm.type)+" "+_vm._s(_vm.getAmountInCurrencyFormat( (_vm.isBuyType ? _vm.amountBuy : _vm.amountSell) || 0, _vm.isBuyType ? _vm.form.currencyBuy : _vm.form.currencySell, ))+" "+_vm._s(_vm.isBuyType ? _vm.form.currencyBuy : _vm.form.currencySell)+" "),_c('span',{staticClass:"ex-otc-order__result-part",class:{'ex-otc-order__result-part_hide': _vm.isRfq}},[_vm._v(" at limit price "+_vm._s(_vm.formatCurrency( _vm.price || 0, _vm.CURRENCY_TYPE.CRYPTO, null, [_vm.form.currencyBuy, _vm.form.currencySell].includes(_vm.CRYPTO_CURRENCIES.TON) ? _vm.CRYPTO_CURRENCIES.TON : null, ))+" "+_vm._s(_vm.isBuyType ? _vm.form.currencyBuy : _vm.form.currencySell)+"/"+_vm._s(_vm.isBuyType ? _vm.form.currencySell : _vm.form.currencyBuy)+". You "+_vm._s(_vm.isBuyType ? 'pay' : 'receive')+" "+_vm._s(_vm.getAmountInCurrencyFormat( (_vm.isBuyType ? _vm.amountSell : _vm.amountBuy) || 0, _vm.isBuyType ? _vm.form.currencySell : _vm.form.currencyBuy, ))+" "+_vm._s(_vm.isBuyType ? _vm.form.currencySell : _vm.form.currencyBuy)+" ")])]),_c('el-button',{staticClass:"ex-otc-order__submit",attrs:{"type":"primary","loading":_vm.isLoading},on:{"click":_vm.handleSubmit}},[_vm._v(" Create Order ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }