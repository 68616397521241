<template>
  <div
    tabindex="1"
    class="ex-hamburger"
    :class="{
      'ex-hamburger_open': value
    }"
    @click="$emit('input', !value)"
  />
</template>

<script>
export default {
  name: 'ExHamburger',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-hamburger {
  height: 32px;
  width: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  cursor: pointer;
  &_open {
    &::before, &::after {
      width: 19px !important;
    }
  }
  &::before, &::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background: $--color-auth-typo-regular;
    border-radius: 1px;
    transition: .45s;
  }
  &::after {
    width: 23px;
  }
}
</style>
