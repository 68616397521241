<template>
  <div class="merchant-invoice-view">
    <div class="merchant-invoice-view__title">Invoice</div>
    <div class="merchant-invoice-view__content">
      <div class="merchant-invoice-view__detail">
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Date / Timestamp</div>
          <div class="merchant-invoice-view__detail-value">{{utilTimeFormatOrMessage(invoice.updateAt)}}</div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Merchant’s Invoice Number / Client Number</div>
          <div class="merchant-invoice-view__detail-value">{{invoice.clientInvoice}}</div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Payer Name</div>
          <div class="merchant-invoice-view__detail-value">{{invoice.payerName ? invoice.payerName : 'N/A'}}</div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Payer Description</div>
          <div class="merchant-invoice-view__detail-value">
            {{invoice.payerDescription ? invoice.payerDescription : 'N/A'}}
          </div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">{{brand}} Transaction Number</div>
          <div class="merchant-invoice-view__detail-value">{{invoice.id}}</div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Payment Currency</div>
          <div class="merchant-invoice-view__detail-value">{{getPairCurrencies(invoice.currencyPair).first}}</div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Base Currency</div>
          <div class="merchant-invoice-view__detail-value">{{getPairCurrencies(invoice.currencyPair).second}}</div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Amount in Payment Currency</div>
          <div class="merchant-invoice-view__detail-value">
            {{formatCurrency(invoice.amountCurrencyFirst, 'crypto')}} {{getPairCurrencies(invoice.currencyPair).first}}
          </div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Gross Revenue in Base Currency</div>
          <div class="merchant-invoice-view__detail-value">
            {{formatCurrency(invoice.amountCurrencyLast, 'fiat')}} {{getPairCurrencies(invoice.currencyPair).second}}
          </div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">{{brand}} Commission</div>
          <div class="merchant-invoice-view__detail-value">{{invoice.marginPercent}}%</div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">{{brand}} Commission</div>
          <div class="merchant-invoice-view__detail-value">
            {{formatCurrency(commissionFiat, 'fiat')}} {{getPairCurrencies(invoice.currencyPair).second}}
          </div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Net Revenue in Base Currency</div>
          <div class="merchant-invoice-view__detail-value">
            {{formatCurrency(netRevenueBaseCurrency, 'fiat')}} {{getPairCurrencies(invoice.currencyPair).second}}
          </div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Gross Price</div>
          <div class="merchant-invoice-view__detail-value">{{formatCurrency(grossPrice, 'crypto')}}</div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Net Price</div>
          <div class="merchant-invoice-view__detail-value">{{formatCurrency(netPrice, 'crypto')}}</div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Status</div>
          <div class="merchant-invoice-view__detail-value">{{nameStatus(invoice.status)}}</div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Receipt</div>
          <div class="merchant-invoice-view__detail-value">
            <el-button
              size="mini"
              :loading="$store.state.merchantInvoice.meta.fetchingPDF"
              :disabled="!successStatus" @click="$store.dispatch('merchantInvoice/fetchReport')">
              Get Receipt
            </el-button>
          </div>
        </div>
        <div class="merchant-invoice-view__detail-row">
          <div class="merchant-invoice-view__detail-label">Address</div>
          <div class="merchant-invoice-view__detail-value">{{invoice.walletAddress}}</div>
        </div>
      </div>
      <div class="merchant-invoice-view__action">
        <el-button type="primary" @click="goToInvoicesList">Back</el-button>
      </div>
      <div class="merchant-invoice-view__transactions">
        <el-table
          stripe
          :summary-method="getSummary"
          show-summary
          sum-text="TOTAL"
          :data="invoice.confirmations">
          <el-table-column
            label="Timestamp"
            align="center"
            prop="createAt">
            <template slot-scope="scope">
              <span>{{utilTimeFormatOrMessage(scope.row.createAt)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="HASH"
            align="center"
            prop="hashId">
            <template slot-scope="scope">
              <a
                :href="`https://btc2.trezor.io/tx/${scope.row.hashId}`"
                target="_blank">
                {{scope.row.hashId}}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            label="Amount"
            align="center"
            prop="amount">
            <template slot-scope="scope">
              {{formatCurrency(scope.row.amount, 'crypto')}} {{scope.row.currency}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
// Lib
import {mapState, mapGetters} from 'vuex';

// Utils
import {utilTimeFormatOrMessage} from '@/utils/converters/time';
import {getPairCurrencies, formatCurrency} from '@/utils/converters/currency';

export default {
  name: 'merchantInvoiceView',

  created() {
    this.$store.dispatch(
      'merchantInvoice/fetchInvoice',
      {invoiceId: this.$route.params.id},
    );
  },

  destroyed() {
    this.$store.commit('merchantInvoice/clearCurrentInvoice');
  },

  computed: {
    ...mapState({
      invoice: (state) => state.merchantInvoice.data.currentInvoice,
      pagination: (state) => state.merchantInvoice.data.invoices.pagination,
    }),
    ...mapState('content', {brand: (state) => state.content.brand}),
    ...mapGetters({
      nameStatus: 'merchantInvoice/nameStatus',
      successStatus: 'merchantInvoice/successStatus',
    }),
    commissionFiat() {
      if (this.invoice.amountCurrencyLast && this.invoice.marginPercent) {
        return ((this.invoice.marginPercent / 100) * this.invoice.amountCurrencyLast).toFixed(2);
      }

      return null;
    },
    netRevenueBaseCurrency() {
      if (this.invoice.amountCurrencyLast && this.commissionFiat) {
        return this.invoice.amountCurrencyLast - this.commissionFiat;
      }

      return null;
    },
    grossPrice() {
      if (this.invoice.amountCurrencyLast && this.invoice.amountCurrencyFirst) {
        return (this.invoice.amountCurrencyLast / this.invoice.amountCurrencyFirst).toFixed(8);
      }

      return null;
    },
    netPrice() {
      if (this.invoice.amountCurrencyFirst && this.netRevenueBaseCurrency) {
        return (this.netRevenueBaseCurrency / this.invoice.amountCurrencyFirst).toFixed(8);
      }

      return null;
    },
  },

  methods: {
    utilTimeFormatOrMessage,
    getPairCurrencies,
    formatCurrency,

    getSummary(param) {
      const coin = ` ${this.getPairCurrencies(this.invoice.currencyPair).first}`;
      const {columns, data} = param;
      const sum = [];

      columns.forEach((column, index) => {
        if (index === 0) {
          sum[index] = 'TOTAL';
          return;
        }

        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value)) && column.property === 'amount') {
          const result = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sum[index] = this.formatCurrency(result.toFixed(8), 'crypto') + coin;
        } else {
          sum[index] = '';
        }
      });

      return sum;
    },

    goToInvoicesList() {
      if (this.$route.query.page && !this.pagination.currentPage && !this.pagination.limitItems) {
        this.$store.commit(
          'merchantInvoice/setPaginationField',
          {
            field: 'currentPage',
            value: this.$route.query.page,
          });
      }

      this.$router.push({
        name: 'MerchantInvoices',
        params: {id: this.invoice.id},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.merchant-invoice-view {
  &__title {
    font-weight: 600;
    line-height: 1.2;
    font-size: 20px;
    margin-bottom: 8px;
  }
  &__content {}
  &__detail {
    max-width: 650px;
    &-row {
      min-height: 35px;
      border-bottom: 1px solid #EBEEF5;
      margin: 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    &-label {
      color: #909399;
      text-align: right;
      min-width: 300px;
      margin-right: 10px;
    }
    &-value {
      margin-left: 10px;
      color: #606266;
      min-width: 300px;
    }
  }
  &__action {
    margin-top: 15px;
  }
  &__transactions {
    margin-top: 15px;
  }
}
</style>
