<template>
  <div class="ex-one-input-form">
    <ex-authorization>
      <template v-if="title" #title>{{title}}</template>
      <template v-if="subTitle" #subTitle>{{subTitle}}</template>
      <ex-authorization-form
        @click="handleClick"
        :message="{value: message, type: 'danger'}"
        :disabled-button="disabledButton"
      >
        <el-form @submit.native.prevent="handleClick" :model="form"  label-position="top" ref="form">
          <el-form-item :label="label" prop="value" :rules="rules">
            <div class="ex-one-input-form__item">
              <el-input
                v-model="form.value"
                :class="{'ex-one-input-form__input-code': !!hasExpireCode}"
                :placeholder="placeholder"
                ref="focusMe"
                autofocus
              />
              <div v-if="hasExpireCode" class="ex-one-input-form__countdown">
                Next code:
                <ex-countdown
                  v-if="this.codeExpire > this.currentDate"
                  @timeout="updateCurrentDate"
                  :time-data="{from: currentDate, to: codeExpire}"
                  class="ex-one-input-form__countdown-time"
                />
                <i v-else class="el-icon-refresh-right" @click="$emit('resend')"/>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <template #actionLabel>{{actionLabel}}</template>
      </ex-authorization-form>
    </ex-authorization>
  </div>
</template>

<script>
import ExAuthorization from '@/components/authorization/layout/ex-authorization-layout';
import ExAuthorizationForm from '@/components/authorization/layout/ex-authorization-form';
import ExCountdown from '@/components/ex-countdown';

export default {
  name: 'ExOneInputForm',
  components: {ExCountdown, ExAuthorizationForm, ExAuthorization},
  props: {
    message: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Join the Digital!',
    },
    subTitle: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    actionLabel: {
      type: String,
      default: '',
    },
    codeExpire: {
      type: Number,
      default: 0,
    },
    additionalRules: {
      type: Array,
      default: () => [],
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        value: '',
      },
      rules: [{required: true, message: 'Field is required', trigger: 'change'}, ...this.additionalRules],
      currentDate: Date.now(),
    };
  },
  computed: {
    hasExpireCode() {
      return this.codeExpire > 0;
    },
  },
  mounted() {
    this.$refs.focusMe.focus();
  },
  methods: {
    handleClick() {
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;
        this.$emit('click', this.form.value);
      });
    },
    updateCurrentDate() {
      this.currentDate = Date.now();
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-one-input-form {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mix--media('sm', 'xs') {
      display: block;
    }
  }
  &__input-code {
    flex: 1 1 60%;
  }
  &__countdown {
    flex: 1 1 40%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: $--color-auth-typo-caption;

    &-time {
      margin-left: 3px;
      color: $--color-auth-typo-regular;
    }
    .el-icon-refresh-right {
      margin-left: 3px;
      cursor: pointer;
      font-size: 18px;
      color: $--color-auth-typo-regular;
    }
  }
}
</style>
