import momentTz from 'moment-timezone';
import moment from 'moment';

/**
 * @param {string} date
 * @param {string} timezone
 * @return {string}
 */
export function utilTimeDefaultFormat({date, timezone}) {
  return momentTz.tz(date, timezone).format('MMMM Do YYYY h:mm');
}

/**
 * @param {string} date
 * @param {string} timezone
 * @param {string} format
 * @return {string}
 */
export function utilTimeFormat({date, timezone}, format = 'YYYY-MM-DD HH:mm:ss') {
  return momentTz.tz(date, timezone).format(format);
}

/**
 * @param {Object} dateTime
 * @param {string} dateTime.date
 * @param {string} dateTime.timezone
 * @param {string} format
 * @param {string} message
 * @return {string}
 */
export function utilTimeFormatOrMessage(dateTime, format = 'YYYY-MM-DD HH:mm:ss', message = 'N/A') {
  if (_.isObject(dateTime) && _.has(dateTime, 'date') && _.has(dateTime, 'timezone')) {
    return utilTimeFormat(Object.assign({}, {...dateTime}), format);
  }
  return message;
}

/**
 * @param {number} value
 * @param {string} format
 * @return {string}
 */
export function fromSecondsToFormat(value, format = 'mm:ss') {
  return moment.utc(value * 1000).format(format);
}
