import BaseApi from '@/api/base';

export default new class RateApi extends BaseApi {
  /**
   * Get rate by http method
   * @param {string} pair
   * @return {Promise<AxiosResponse<any>|undefined>}
   */
  fetchRate(pair) {
    return super.post('', '/api/fetch-rate', {data: {pair}});
  }
};
