<template>
  <div class="ex-otc-trading-view-edit-layout">
    <el-card>
      <template #header>
        <div class="ex-otc-trading-view-edit-layout__header">
          <span class="ex-otc-trading-view-edit-layout__name"><slot name="name" /></span>
          <slot name="action"/>
        </div>
      </template>
      <div class="ex-otc-trading-view-edit-layout__body">
        <slot />
      </div>
      <div v-if="$slots.footer" class="ex-otc-trading-view-edit-layout__footer">
        <slot name="footer" />
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'ExOtcTradingViewEditLayout',
};
</script>

<style lang="scss" scoped>
.ex-otc-trading-view-edit-layout {
  &__name {
    font-weight: 600;
  }
  &__footer {
    background-color: rgba(0, 0, 25, 0.03);
    padding: 20px;
  }
  &__header,
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
  }
  ::v-deep {
    .el-card__header {
      background-color: #e5e5ea;
    }
    .el-card__body {
      padding: 0;
    }
  }
}
</style>
