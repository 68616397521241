import {isNull, isUndefined} from 'lodash';

/**
 * @param {string} name
 * @param {any} defaultReturn
 * @return {any}
 */
export const getSessionItem = (name, defaultReturn = null) => {
  const str = sessionStorage.getItem(name);
  if (!str || str === 'undefined') {
    return defaultReturn;
  }
  return JSON.parse(str);
};

/**
 * @param {string} name
 * @param {any} data
 */
export const setSessionItem = (name, data = null) => {
  if (!isNull(data) && !isUndefined(data)) {
    sessionStorage.setItem(name, JSON.stringify(data));
  } else {
    sessionStorage.removeItem(name);
  }
};

/** ***********************LOGIN**************************** */
export const loginSessionStorage = {
  setCompanies: (companies) => {
    setSessionItem('login:companies', companies);
  },
  getCompanies: () => getSessionItem('login:companies'),
  setUserId: (id) => {
    setSessionItem('login:userId', id);
  },
  getUserId: () => getSessionItem('login:userId'),
  /**
   * @param {{SMS: Boolean, GA: Boolean}} validation
   */
  setValidation: (validation) => {
    setSessionItem('login:validation', validation);
  },
  /**
   * @returns {{SMS: Boolean, GA: Boolean}}
   */
  getValidation: () => getSessionItem('login:validation', {
    SMS: false,
    GA: false,
  }),
  setSelectedCompany: (company) => {
    setSessionItem('login:selectedCompany', company);
  },
  getSelectedCompany: () => getSessionItem('login:selectedCompany'),
  /**
   * @param validation {{SMS: Boolean, GA: Boolean}}
   */
  setProcessValidation: (validation) => {
    setSessionItem('login:processValidation', validation);
  },
  /**
   * @returns {{SMS: Boolean, GA: Boolean}}
   */
  getProcessValidation: () => getSessionItem('login:processValidation', {
    SMS: false,
    GA: false,
  }),
  setSmsExpire: (time) => setSessionItem('sms:time:expire', time),
  getSmsExpire: () => getSessionItem('sms:time:expire'),
};

/***/
export class RegistrationSessionStorage {
  /**
   * @param {Object|undefined} data
   */
  static set user(data) {
    if (!data) {
      setSessionItem('registrationState');
      return;
    }
    setSessionItem('registrationState', data);
  }

  /**
   * @return {{}|*}
   */
  static get user() {
    const user = getSessionItem('registrationState');
    if (user) return user;
    return {};
  }

  /**
   * @param {Object|undefined} data
   * @param {string} data.socketId
   * @param {number} data.codeExpire
   */
  static set phoneCodeExpire(data) {
    if (!data) {
      setSessionItem('registration:phoneExpireDate');
      return;
    }
    setSessionItem('registration:phoneExpireDate', {socketId: data.socketId, codeExpire: data.codeExpire});
  }

  /**
   * @return {{socketId: null, codeExpire: null}|{socketId: string, codeExpire: number}}
   */
  static get phoneCodeExpire() {
    const phoneExpireDate = getSessionItem('registration:phoneExpireDate');
    if (phoneExpireDate) {
      const {socketId, codeExpire} = phoneExpireDate;
      return {socketId, codeExpire};
    } else {
      return {socketId: null, codeExpire: null};
    }
  }

  /**
   * @param {Object|undefined} data
   * @param {string} data.socketId
   * @param {number} data.codeExpire
   */
  static set emailCodeExpire(data) {
    if (!data) {
      setSessionItem('registration:emailExpireDate');
      return;
    }
    setSessionItem('registration:emailExpireDate', {socketId: data.socketId, codeExpire: data.codeExpire});
  }

  /**
   * @return {{socketId: null, codeExpire: null}|{socketId: string, codeExpire: number}}
   */
  static get emailCodeExpire() {
    const emailExpireDate = getSessionItem('registration:emailExpireDate');
    if (emailExpireDate) {
      const {socketId, codeExpire} = emailExpireDate;
      return {socketId, codeExpire};
    } else {
      return {socketId: null, codeExpire: null};
    }
  }
}

export class AuthSessionStorage {
  /**
   * @returns {{
   *    token: string|null,
   *    refresh: string|null,
   *    accessId: string|null,
   *    authExpire: number|null
   *    isLoggedIn: boolean
   * }}
   */
  static get auth() {
    const defaultAuth = {
      token: null, refresh: null, accessId: null, authExpire: null,
      isLoggedIn: false,
    };
    const auth = getSessionItem('auth:uc');
    if (auth) {
      const {token, refresh, accessId, authExpire} = auth;
      return {
        token, refresh, accessId, authExpire,
        isLoggedIn: !!token,
      };
    } else {
      return defaultAuth;
    }
  }

  /**
   * @param val {{
   *    token: string,
   *    refresh: string,
   *    accessId: string,
   *    authExpire: number
   *    isLoggedIn?: boolean
   * }}
   */
  static set auth(val) {
    if (!val) {
      setSessionItem('auth:uc');
      return;
    }
    const {token, refresh, accessId, authExpire} = val;
    setSessionItem('auth:uc', {token, refresh, accessId, authExpire});
  }
}
