import Vue from 'vue';

let instance = null;

/** */
class EventBus {
  /**
   * @return {*}
   */
  static getInstance() {
    if (!instance) {
      instance = new Vue();
    }
    return instance;
  }
}

export default EventBus.getInstance();
