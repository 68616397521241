<template>
  <div v-loading="isLoading" class="ex-recovery-new-password">
    <ex-authorization-layout>
      <template #title>Join the {{brand}} Digital!</template>
      <template #subTitle>Almost done!</template>
      <ex-authorization-form :message="{value: message, type: 'danger'}" @click="saveNewPassword">
        <el-form
          :model="form"
          :rules="rules"
          @keyup.enter.native="saveNewPassword"
          ref="form"
          label-position="top"
        >
          <el-form-item label="Create password" prop="password">
            <el-input
              ref="password"
              autocomplete="nope"
              placeholder="Type your new password"
              type="password"
              show-password
              v-model="form.password"
            />
          </el-form-item>
          <el-form-item label="Confirm password" prop="confirmPassword">
            <el-input
              ref="confirmPassword"
              auto-complete="nope"
              placeholder="Repeat your new password"
              type="password"
              show-password
              v-model="form.confirmPassword"
            />
          </el-form-item>
        </el-form>
        <template #actionLabel>Reset password</template>
      </ex-authorization-form>
    </ex-authorization-layout>
    <ex-dialog
      :visible="visible"
      title="Password has been successfully changed!"
      width="559px"
    >
      Now you can sign in to your account using your new password
      <template #footer>
        <ex-button style="width: 100%;" @click="$router.push({name: 'LoginCredential'})">
          Sign in
        </ex-button>
      </template>
    </ex-dialog>
  </div>
</template>

<script>
// components
import ExAuthorizationForm from '@/components/authorization/layout/ex-authorization-form';
import ExDialog from '@/components/authorization/ui/ex-dialog';
import ExButton from '@/components/authorization/ui/ex-button';
import ExAuthorizationLayout from '@/components/authorization/layout/ex-authorization-layout';

// api
import RecoveryApi from '@/api/userService/recovery.api';

// const
import {RECOVERY_COMPLETE} from '@/constants/events/recovery/actions.type';
import {RECOVERY_SESSION_TIME_LIVED} from '@/constants/common';

// utils
import {socketRequest} from '@/utils/socket';
import {ruleRequired} from '@/utils/elementUITypicalValidation';
import {mapState} from 'vuex';

export default {
  name: 'ExRecoveryNewPassword',
  components: {
    ExAuthorizationLayout,
    ExAuthorizationForm,
    ExButton,
    ExDialog,
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (this.form.confirmPassword !== '' && value !== '') {
        this.$refs.form.validateField('confirmPassword');
      }
      callback();
    };

    const validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.form.password && value !== '') {
        callback(new Error('Password mismatch.'));
      } else {
        callback();
      }
    };

    return {
      isLoading: false,
      message: '',
      form: {
        password: '',
        confirmPassword: '',
      },
      rules: {
        password: [
          ruleRequired,
          {validator: validatePassword, trigger: 'blur'},
        ],
        confirmPassword: [
          ruleRequired,
          {validator: validateConfirmPassword, trigger: 'blur'},
        ],
      },
      visible: false,
    };
  },
  computed: {...mapState('content', {brand: (state) => state.content.brand})},
  methods: {
    saveNewPassword() {
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;
        socketRequest.call(this, RECOVERY_COMPLETE,
          () => {
            this.visible = true;
          },
          (payload) => {
            if (payload.error.code === RECOVERY_SESSION_TIME_LIVED) {
              this.$emit('restartRecovery');
            } else if (payload.handledErrorMessage) {
              this.message = payload.handledErrorMessage;
            }
          },
          () => {
            this.isLoading = false;
          },
        );
        this.message = '';
        this.isLoading = true;
        RecoveryApi.completeRecovery(RECOVERY_COMPLETE, {password: this.form.password});
      });
    },
  },
};
</script>
