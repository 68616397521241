import {INIT_LOGIN} from '@/constants/events/login/actions.type';
import {
  INIT_PHONE_NUMBER_VERIFICATION,
  VERIFY_PHONE_NUMBER,
  INIT_EMAIL_VERIFICATION,
  VERIFY_EMAIL,
} from '@/constants/events/registration/actions.type';

const MSG_REGISTRATION_TIME_EXPIRED_251 = 'Registration time expired. Try again';

export default {
  [INIT_LOGIN]: {
    2: {
      202: 'The email address or password you entered is incorrect. Please try again',
    },
  },
  [INIT_PHONE_NUMBER_VERIFICATION]: {
    5: {
      251: MSG_REGISTRATION_TIME_EXPIRED_251,
    },
  },
  [VERIFY_PHONE_NUMBER]: {
    5: {
      251: MSG_REGISTRATION_TIME_EXPIRED_251,
      254: 'SMS code was expired!',
    },
  },
  [INIT_EMAIL_VERIFICATION]: {
    5: {
      251: MSG_REGISTRATION_TIME_EXPIRED_251,
    },
  },
  [VERIFY_EMAIL]: {
    2: {
      222: 'Registration time expired. Try again',
    },
  },
};
