<template>
  <div class="ex-withdrawal-info">
    <ex-icon-alert>
      <span v-if="isNoFunds" class="ex-withdrawal-info__warning">You cannot withdraw funds with negative balance</span>
      <span v-else>
        Client
        <span class="ex-withdrawal-info__info-highlight">{{userNameLabel}}</span>
        withdraw the amount
        <span class="ex-withdrawal-info__info-highlight">{{getAmountInCurrencyFormat(amount, currency)}}</span>
        to {{currency}} wallet with the address
        <span class="ex-withdrawal-info__info-highlight">{{address ? address : 'NO ADDRESS'}}</span>
      </span>
    </ex-icon-alert>
  </div>
</template>

<script>
// component
import ExIconAlert from '@/components/ex-icon-alert';

// util
import {getAmountInCurrencyFormat} from '@/utils/converters/currency';

// lib
import {mapState} from 'vuex';

export default {
  name: 'ExWithdrawalInfo',
  components: {ExIconAlert},
  props: {
    amount: {
      type: [String, Number],
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    isNoFunds: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    ...mapState('currentUser', ['profile']),
    userNameLabel() {
      if (this.profile.organizationName) return `${this.profile.organizationName}  (${this.profile.clientId})`;
      if (this.profile.username) return `${this.profile.username} ${this.profile.lastName} (${this.profile.clientId})`;
      return false;
    },
  },
  methods: {
    getAmountInCurrencyFormat,
  },
};
</script>

<style lang="scss" scoped>
.ex-withdrawal-info {
  &__warning {
    color: #f56c6c;
  }
}
</style>
