<template>
  <div class="ex-outgoing-countdown">
    <ex-icon-alert>
      <div v-if="tick">
        You will receive the price within
        <b class="ex-outgoing-countdown__countdown">{{fromSecondsToFormat(tick)}}</b>
        where estimated <b>price =
        {{formatCurrency(currentRate, currencyPay, null, currencyBuy)}}</b>
        {{currencyBuy}}/{{currencyPay}}
      </div>
      <div class="ex-outgoing-countdown__price" v-else>
        <div>
          <b>
            Price = {{formatCurrency(currentRate, currencyPay, null, currencyBuy)}}
          </b>
          {{currencyBuy}}/{{currencyPay}}.
          To fetch the price, click the button
        </div>
        <el-button type="primary" icon="el-icon-refresh-left" circle @click="$emit('restartTimer')"/>
      </div>
    </ex-icon-alert>
    <el-progress :stroke-width="2" :percentage="percentage" :show-text="false" color="#df7c74" />
  </div>
</template>

<script>
// api
import RateApi from '@/api/rate';

// utils
import {fromSecondsToFormat} from '@/utils/converters/time';
import {formatCurrency, isUSDTLike} from '@/utils/converters/currency';

// components
import ExIconAlert from '@/components/ex-icon-alert';

// settings
const HOW_MUCH_TICK = 180;

export default {
  name: 'ExOutgoingCountdown',
  components: {ExIconAlert},
  props: {
    externalRate: {
      type: Number,
      default: null,
    },
    currencyPay: {
      type: String,
      default: '0',
      required: true,
    },
    currencyBuy: {
      type: String,
      default: '0',
      required: true,
    },
    restartTrigger: {
      type: Boolean,
      required: true,
      default: true,
    },
    stopTrigger: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      tick: HOW_MUCH_TICK,
      rate: null,
      fetchTick: 0,
      timerId: null,
    };
  },
  computed: {
    percentage() {
      return ((HOW_MUCH_TICK - this.tick) * 100) / 180;
    },
    isExistExternalRate() {
      return this.externalRate !== null;
    },
    currentRate() {
      return this.isExistExternalRate ? this.externalRate : this.rate;
    },
  },
  watch: {
    tick(value) {
      if (value === this.fetchTick) {
        if (this.isExistExternalRate) {
          this.$emit('fetchRate');
        } else {
          this.fetchCurrencyPairRate();
        }
        this.updateFetchTick();
      }
    },
    updateFetchTickTrigger() {
      this.updateFetchTick();
    },
    restartTrigger() {
      this.restartTimer();
    },
    stopTrigger() {
      this.stopTimer();
    },
  },
  methods: {
    fromSecondsToFormat,
    formatCurrency,
    isUSDTLike,
    fetchCurrencyPairRate() {
      if (!this.currencyBuy || !this.currencyPay) return;
      RateApi.fetchRate(`${this.currencyBuy}:${this.currencyPay}`)
        .then((response) => {
          // this.updateFetchTick();
          if (response.data) {
            return response;
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject();
          }
        })
        .then(({data}) => {
          this.rate = data.buy;
        })
        .catch(() => {
          this.rate = null;
          this.$emit('errorRate');
          this.errorMsg('It is impossible to make a preliminary calculation');
        });
    },
    updateFetchTick() {
      this.fetchTick = this.tick - 10;
    },
    restartTimer() {
      clearTimeout(this.timerId);
      this.tick = HOW_MUCH_TICK;
      this.getNextTick();
      if (this.isExistExternalRate) {
        this.$emit('fetchRate');
      } else {
        this.fetchCurrencyPairRate();
      }
      this.updateFetchTick();
    },
    getNextTick() {
      this.timerId = setTimeout(() => {
        this.tick -= 1;
        if (this.tick) this.getNextTick();
      }, 1000);
    },
    stopTimer() {
      this.tick = 0;
      clearTimeout(this.timerId);
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-outgoing-countdown {
  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__countdown {
    color: #df7c74;
  }
}
</style>
