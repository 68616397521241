<template>
  <div v-loading="isLoading" class="ex-login-2fa">
    <ex-login2fa-sms
      v-if="showSMS"
      :authorization-profile="authorizationProfile"
      @smsStageCompleted="smsStageCompleted"
      @resetLogin="resetLogin"
    />
    <ex-login2fa-ga
      v-if="showGA"
      :authorization-profile="authorizationProfile"
      @gaStageCompleted="gaStageCompleted"
      @resetLogin="resetLogin"
    />
  </div>
</template>

<script>
// utils
import {checkExpectedKeys} from '@/utils/validatorPropObjects';
import {socketRequest} from '@/utils/socket';

// api
import AuthorizationApi from '@/api/authorization.api';

// components
import ExLogin2faSms from '@/pages/login/ex-login-2fa-sms';
import ExLogin2faGa from '@/pages/login/ex-login-2fa-ga';

// app
import {mapActions, mapMutations, mapState} from 'vuex';

// const
import {REQUEST_DEFAULT_CODE_LOGIN, TRY_LOGIN} from '@/constants/events/login/actions.type';
import {CODE_AUTH_SESSION_TIME_LIVED} from '@/constants/common';

export default {
  name: 'ExLogin2fa',
  components: {ExLogin2faGa, ExLogin2faSms},
  props: {
    authorizationProfile: {
      type: Object,
      required: true,
      validator: (authorizationProfile) => checkExpectedKeys(['userId'], authorizationProfile.external),
    },
  },
  data() {
    return {
      showSMS: false,
      showGA: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('auth', ['isLoggedIn']),
  },
  created() {
    if (this.authorizationProfile.external.userId === null) {
      return this.$router.push({name: 'LoginCredential'});
    }
    this.tryAuthorization();
  },
  methods: {
    ...mapActions('auth', ['login']),
    ...mapMutations('currentUser', ['setId']),
    smsStageCompleted() {
      this.$emit('smsStageCompleted');
      this.tryAuthorization();
    },
    gaStageCompleted() {
      this.$emit('gaStageCompleted');
      this.tryAuthorization();
    },
    tryAuthorization() {
      if (this.authorizationProfile.external.validation.SMS !== this.authorizationProfile.authorizationStage.SMS) {
        return this.showSMSForm();
      }
      if (this.authorizationProfile.external.validation.GA !== this.authorizationProfile.authorizationStage.GA) {
        return this.showGAForm();
      }
      if (this.authorizationProfile.external.validation.SMS === this.authorizationProfile.authorizationStage.SMS
        && this.authorizationProfile.external.validation.GA === this.authorizationProfile.authorizationStage.GA) {
        this.requestCode();
      }
    },
    requestCode() {
      if (this.isLoggedIn) return;
      socketRequest.call(this, REQUEST_DEFAULT_CODE_LOGIN,
        () => {
          this.tryLogin();
        },
        (payload) => {
          if (payload.error.code === CODE_AUTH_SESSION_TIME_LIVED) {
            this.resetLogin(payload.handledErrorMessage);
          }
        },
        () => {
          this.isLoading = false;
        },
      );
      this.isLoading = true;
      const userId = this.authorizationProfile.external.userId;
      const organizationId = this.authorizationProfile.selectedAccount;
      AuthorizationApi.requestCode(REQUEST_DEFAULT_CODE_LOGIN, {
        authType: 'DEFAULT',
        userId,
        organizationId: userId !== organizationId ? organizationId : undefined,
      });
    },
    tryLogin() {
      socketRequest.call(this, TRY_LOGIN,
        (payload) => {
          this.login(payload);
          this.setId({userId: this.authorizationProfile.external.userId});
          this.$router.push({name: 'UserRedirect'});
        },
        (payload) => {
          if (payload.error.code === CODE_AUTH_SESSION_TIME_LIVED) {
            this.resetLogin(payload.handledErrorMessage);
          }
        },
        () => {
          this.isLoading = false;
        },
      );
      this.isLoading = true;
      AuthorizationApi.login(TRY_LOGIN, {userId: this.authorizationProfile.external.userId});
    },
    showSMSForm() {
      this.showSMS = true;
      this.showGA = false;
    },
    showGAForm() {
      this.showSMS = false;
      this.showGA = true;
    },
    resetLogin(message) {
      this.$router.push(
        this.$route.query.redirect
          ? {path: this.$route.query.redirect}
          : {name: 'LoginCredential'},
      );
      this.errorMsg(message, 'Log In');
    },
  },
};
</script>
