<template>
  <div class="ex-deposit-type-selector-fiat">
    <div class="ex-deposit-type-selector-fiat__title">
      CHOOSE DEPOSIT METHOD
    </div>
    <div class="ex-deposit-type-selector-fiat__list">
      <div v-if="isAvailable" class="ex-deposit-type-selector-fiat__item">
        <ex-card-button :disabled="false" :value="DESCRIPTION_LIST.CARD" @click="handleClick('card')" />
      </div>
      <div class="ex-deposit-type-selector-fiat__item">
        <ex-card-button :disabled="false" :value="DESCRIPTION_LIST.TRANSFER" @click="handleClick('request')" />
      </div>
    </div>
  </div>
</template>

<script>
// components
import ExCardButton from '@/components/ex-card-button';

// lib
import {mapState} from 'vuex';

// constant
import {ACQUIRING_TYPE} from '@/constants/common';
const DESCRIPTION_LIST = {
  CARD: {
    LABEL: 'BANK CARD',
    DESCRIPTION: 'Instantly top up the account balance by your VISA or Master Card debit or credit card',
  },
  TRANSFER: {
    LABEL: 'BANK TRANSFER',
    DESCRIPTION: 'Create a deposit request via predefined PDF form in desired FIAT currency',
  },
};
const AVAILABLE_COINS_FENIGE = ['EUR'];

export default {
  name: 'ExDepositTypeSelectorFiat',
  components: {ExCardButton},
  data() {
    return {
      DESCRIPTION_LIST,
    };
  },
  computed: {
    ...mapState('currentUser', ['profile']),
    isAvailable() {
      if (this.profile.acquiring === ACQUIRING_TYPE.FENIGE &&
        !AVAILABLE_COINS_FENIGE.includes(this.$route.params.currency)) {
        return false;
      }
      return this.profile.acquiring !== 0;
    },
  },
  methods: {
    handleClick(type) {
      this.$router.push({
        name: 'FiatDeposit',
        params: {type, currency: this.$route.params.currency},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-deposit-type-selector-fiat {
  &__title {
    text-align: center;
    padding-bottom: 30px;
    color: #444;
    font-size: 24px;
    font-weight: bold;
  }
  &__list {
    display: flex;
    justify-content: center;
  }
  &__item {
    margin: 14px;
    flex-basis: 50%;
  }
}
</style>
