<template>
  <div class="ex-invoice-create">
    <portal to="toolbar__title">
      New Invoice
    </portal>
    <el-form :model="form" :rules="rules" label-position="top" ref="form">
      <div class="ex-invoice-create__sub-title"><i class="el-icon-s-home" />MERCHANT</div>
      <el-form-item label="Name" prop="merchantName">
        <el-input placeholder="ex: Adidas" v-model="form.merchantName" />
      </el-form-item>
      <el-form-item label="Description" prop="merchantDescription">
        <el-input type="textarea" :rows="2" placeholder="ex: Adidas is..." v-model="form.merchantDescription" />
      </el-form-item>
      <div class="ex-invoice-create__sub-title"><i class="el-icon-goods" />PRODUCT</div>
      <el-form-item label="Invoice number" prop="invoiceNumber">
        <el-input placeholder="ex: 5e3998b31f51029604ba14d77c92c735" v-model="form.invoiceNumber" />
      </el-form-item>
      <el-form-item label="Item name" prop="itemName">
        <el-input placeholder="ex: Book" v-model="form.itemName" />
      </el-form-item>
      <el-form-item ref="amount" label="Amount" prop="amount">
        <el-input placeholder="ex: 1000" v-model="form.amount" />
      </el-form-item>
      <el-form-item label="Currency" prop="currency">
        <el-radio-group v-model="form.currency">
          <el-radio-button v-for="(item, index) in CURRENCY" :key="index" :label="item" />
        </el-radio-group>
      </el-form-item>
      <div class="ex-invoice-create__sub-title"><i class="el-icon-user" />CLIENT</div>
      <el-form-item
        label="Payer name"
        prop="payerName"
        :rules="[{required: form.payerDescription, message: 'This field is required', trigger: 'blur'}]"
      >
        <el-input placeholder="ex: Alex" v-model="form.payerName" />
      </el-form-item>
      <el-form-item label="Payer description" prop="payerDescription">
        <el-input type="textarea" :rows="2" placeholder="ex: Alex from ..." v-model="form.payerDescription" />
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input placeholder="ex: user@gmail.com" v-model="form.email" />
      </el-form-item>
      <el-form-item label="Redirect" prop="redirectUrl">
        <el-input placeholder="ex: https://example.com/" v-model="form.redirectUrl" />
      </el-form-item>
      <el-form-item label="Webhook URL" prop="webhookUrl">
        <el-input placeholder="ex: https://example.com/" v-model="form.webhookUrl" />
      </el-form-item>
      <div class="ex-invoice-create__result" v-if="url">
        <div class="ex-invoice-create__result-title">Result URL</div>
        <el-input :value="url">
          <ex-copy slot="append" placement="top" :value="url">
            <el-button icon="el-icon-copy-document" />
          </ex-copy>
        </el-input>
      </div>
      <div class="ex-invoice-create__actions" ref="action">
        <router-link :to="{name: 'MerchantInvoices'}" class="ex-invoice-create__first_btn">
          <el-button type="info" plain :disabled="isLoading">CANCEL</el-button>
        </router-link>
        <el-button type="primary" :loading="isLoading" @click="handleSubmit">
          CREATE INVOICE
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
// api
import merchantApi from '@/api/merchant.api';

// util
import inputmasks from '@/utils/inputmasks';
import {socketRequest} from '@/utils/socket';
import {ruleMaxLength, ruleRequired} from '@/utils/elementUITypicalValidation';
import {INVOICE_URL} from '@/utils/appsURLs';

// lib
import VueScrollTo from 'vue-scrollto';

// component
import ExCopy from '@/components/ex-copy';

// const
import {CRYPTO_CURRENCIES, FIAT_CURRENCIES} from '@/constants/currencies';
import {CREATE_MERCHANT_INVOICE} from '@/constants/events/merchantInvoice/actions.type';

// settings
const CURRENCY = [
  FIAT_CURRENCIES.EUR,
  CRYPTO_CURRENCIES.USDT_ERC20,
];

export default {
  name: 'ExInvoiceCreate',
  components: {ExCopy},
  data() {
    const amountValidator = (rule, value, callback) => {
      if (this.amount < 100) {
        return callback(new Error('Amount must be greater then 100'));
      }
      callback();
    };
    const urlValidator = (rule, value, callback) => {
      const regex = '^(?:(?:http|https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
      const url = new RegExp(regex, 'i');
      if (value && (value.length > 2083 || !url.test(value))) {
        return callback(new Error('Enter the URL in the format https://www.example.com/'));
      }

      callback();
    };

    return {
      isLoading: false,
      form: {
        merchantName: '',
        merchantDescription: '',
        invoiceNumber: '',
        itemName: '',
        amount: '',
        currency: FIAT_CURRENCIES.EUR,
        payerName: '',
        payerDescription: '',
        email: '',
        redirectUrl: '',
        webhookUrl: '',
      },
      rules: {
        merchantName: [ruleMaxLength(64)],
        merchantDescription: [ruleMaxLength()],
        invoiceNumber: [ruleRequired, ruleMaxLength(64)],
        itemName: [ruleRequired, ruleMaxLength(64)],
        amount: [ruleRequired, {validator: amountValidator, trigger: 'blur'}],
        currency: [ruleRequired],
        payerName: [ruleMaxLength(64)], // Additional check in template
        payerDescription: [ruleMaxLength()],
        email: [{type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change']}],
        redirectUrl: [{validator: urlValidator, trigger: ['blur', 'change']}],
        webhookUrl: [{validator: urlValidator, trigger: ['blur', 'change']}],
      },
      hash: '',
      CURRENCY,
    };
  },
  computed: {
    isFiat() {
      const fiatCurrencies = Object.values(FIAT_CURRENCIES);
      return fiatCurrencies.includes(this.form.currency);
    },
    amount() {
      if (this.form.amount !== '') {
        let amount = this.form.amount.toString();
        amount = amount.replace(/,/g, '');
        const dotPosition = amount.indexOf('.');
        if (dotPosition !== -1) {
          if (this.isFiat) {
            amount = amount.slice(0, dotPosition + 3);
          } else {
            amount = amount.slice(0, dotPosition + 9);
          }
        }

        return Number(amount);
      }

      return this.form.amount;
    },
    url() {
      if (!this.hash) return '';
      return `${INVOICE_URL}/invoice/${this.hash}`;
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;
        socketRequest.call(this, CREATE_MERCHANT_INVOICE,
          (payload) => {
            this.hash = payload.url;
            this.scrollToElement(this.$refs['action']);
          },
          () => {},
          () => {
            this.isLoading = false;
          },
        );
        this.form.amount = this.amount;
        this.isLoading = true;
        merchantApi.createMerchantInvoiceURL(CREATE_MERCHANT_INVOICE, this.form);
      });
    },
    scrollToElement(ref) {
      VueScrollTo.scrollTo(ref, 500);
    },
  },
  watch: {
    'form.currency': {
      handler() {
        this.form.amount = this.amount;

        const currencyType = this.isFiat ? 'fiat' : 'crypto';
        const inputmask = inputmasks[currencyType].bind(this) || {mask: () => {}};
        this.$nextTick(() => {
          inputmask().mask(this.$refs.amount.$el.getElementsByTagName('INPUT')[0]);
        });
      },
      immediate: true,
    },
    'form': {
      handler() {
        this.hash = '';
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-invoice-create {
  max-width: 600px;
  &__sub-title {
    font-size: 18px;
    line-height: 21px;
    color: #8884B6;
    padding: 8px 0 30px;
  }
  &__sub-title:first-child {
    padding: 30px 0;
  }
  &__sub-title i{
    padding-right: 15px;
  }
  &__actions {
    float: right;

  }
  &__first_btn {
    margin-right: 10px;
  }
  &__result {
    padding-bottom: 25px;
    &-title {
      font-size: 14px;
      color: #606266;
      padding-bottom: 5px;
    }
    &-value {}
  }
  ::v-deep {
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: #8884B6;
      border-color: #8884B6;
      box-shadow: -1px 0 0 0 #8884B6;
    }
  }
}
</style>
