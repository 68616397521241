import BaseApi from '@/api/base';

export default new class KYCApi extends BaseApi {
  /**
   * Fetch user data from KYC
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchUser(event) {
    return super.post(event, '/api/user/kyc/fetch-user');
  }

  /**
   * Fetch country list with code
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchCountry(event) {
    return super.post(event, '/api/user/kyc/fetch-country');
  }

  /**
   * Fetch state list with code (USA, Canada, Australia)
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchState(event) {
    return super.post(event, '/api/user/kyc/fetch-state');
  }
};
