<template>
  <div class="ex-pagination">
    <el-pagination
      :current-page.sync="currentPageLocal"
      :page-size.sync="pageSizeLocal"
      :total="total"
      @current-change="fetch"
      @size-change="fetch"
      layout="prev, pager, next"
      background
    />
  </div>
</template>

<script>
export default {
  name: 'ExPagination',
  props: {
    total: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
  },

  data() {
    return {
      currentPageLocal: 1,
      pageSizeLocal: 20,
    };
  },

  methods: {
    fetch() {
      this.$emit('fetch', this.currentPageLocal, this.pageSizeLocal);
    },
  },

  watch: {
    currentPage: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.currentPageLocal = newValue;
        }
      },
      immediate: true,
    },
    pageSize: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.pageSizeLocal = newValue;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.el-pagination {
  padding: 0;
}
</style>
