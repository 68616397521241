import BaseApi from '@/api/base';

export default new class UserApi extends BaseApi {
  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.username
   * @param {string} data.lastName
   * @param {string} data.flagSMS
   * @param {string} data.flagGA
   * @return {Promise<void>}
   */
  updateSmsAndGASetting(event, data) {
    return super.post(event, '/api/user/update-profile-settings', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {string} data.userId
   * @param {string} data.code
   * @return {Promise<T>}
   */
  confirm2FAGA(event, data) {
    return super.post(event, '/api/user/confirm-ga', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.balanceId
   * @param {string} data.currency
   * @return {Promise<void>}
   */
  assignCryptoBalanceToAddress(event, data) {
    return super.post(event, '/api/user/assign-crypto-balance-to-address', {data});
  }

  /**
   * @param {string} event
   * @param {Object} data
   * @param {number} data.balanceId
   * @return {Promise<void>}
   */
  fetchCryptoDepositAddressList(event, data) {
    return super.post(event, '/api/user/fetch-manual-crypto-deposit-address-list', {data});
  }
};
