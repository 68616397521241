// error src
import defaultErrorByEvent from '@/utils/socketError/errorSources/defaultErrorByEvent';
import errorByEvent from '@/utils/socketError/errorSources/errorByEvent';
import excludedErrorByEvent from '@/utils/socketError/errorSources/excludedErrorByEvent';
import validationErrors from '@/utils/socketError/errorSources/validationErrors';

/**
 * @return {Object}
 */
function prepTypicalErrors() {
  const context = require.context('@/utils/socketError/errorSources/byService', true, /.js$/);
  const eventStorage = {};
  context.keys().forEach((file) => {
    let fileName = file.replace('./', '');
    fileName = fileName.replace('.js', '');
    const importedEventList = context(file);
    eventStorage[fileName] = [...Object.values(importedEventList)][0];
  });
  return eventStorage;
}
export default {
  ...prepTypicalErrors(),
  ERROR_BY_EVENT: {...errorByEvent},
  VALIDATION_ERROR: {...validationErrors},
  DEFAULT_ERROR_BY_EVENT: {...defaultErrorByEvent},
  EXCLUDED_ERROR_BY_EVENT: {...excludedErrorByEvent},
};
